import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { ReservationApi } from 'src/app/organization/sites/site';
import { FleetStatusConflictDetails, HttpError } from '../httpError';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { StorageKey } from '../local-storage/storageKeys';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { UserData } from '../userData';
import { CalendarConnection } from './calendar-connections/calendar-connection';
import { Fleet, FleetLight } from './fleet';

@Injectable({
  providedIn: 'root',
})
export class FleetService {
  private baseUrl: string = `${this.settingsService.settings.API_URL}/fleet`;

  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private cookieStorageService: CookieService,
    private loaderService: LoaderService,
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private translateService: TranslateService
  ) {}

  /**
   * Get current user Fleet
   */
  public getUserFleet(): Observable<Fleet | null> {
    // TODO : store in session storage !!!
    const userData: UserData | undefined = this.localStorageService.readObject<UserData>(StorageKey.userData);

    if (!userData) return of(null);

    if (userData.fleets && userData.fleets.length > 0 && userData.fleets[0] !== '') {
      // Normal user with 1 fleet code
      const userFleetKey1: string = `${userData.aud}_userFleet_${userData.fleets[0]}`;

      if (this.sessionStorageService.exists(userFleetKey1)) return of(this.sessionStorageService.readObject<Fleet>(userFleetKey1)!);
      else return this.getOne(userData.fleets[0], true).pipe(tap((fleet) => this.sessionStorageService.writeObject<Fleet>(userFleetKey1, fleet)));
    }

    const fleetCookieKey: string = `${StorageKey.selectedFleet}${userData.sub}`;

    // Administrators with no fleet code but with a selected fleet cookie
    if (this.cookieStorageService.check(fleetCookieKey)) {
      const fleetLight: FleetLight = JSON.parse(this.cookieStorageService.get(fleetCookieKey));
      const userFleetKey2: string = `${userData.aud}_userFleet_${fleetLight.technicalId}`;

      if (this.sessionStorageService.exists(userFleetKey2)) return of(this.sessionStorageService.readObject<Fleet>(userFleetKey2)!);
      else return this.getOne(fleetLight.technicalId.toString(), true).pipe(tap((fleet) => this.sessionStorageService.writeObject<Fleet>(userFleetKey2, fleet)));
    }

    // Administrator with no fleet code and no selected fleet cookie (damn!)
    return this.getLight().pipe(
      map((fleets: FleetLight[]) => {
        if (fleets.length > 0) {
          this.cookieStorageService.set(fleetCookieKey, JSON.stringify(fleets[0] as FleetLight), undefined, '/');

          const userFleetKey: string = `${userData.aud}_userFleet_${fleets[0].technicalId}`;
          this.sessionStorageService.writeObject<FleetLight>(userFleetKey, fleets[0]);

          return fleets[0] as Fleet;
        }

        throw new Error('Empty fleet list.');
      })
    );
  }

  /**
   * Get all Fleets Light
   */
  public getLight(silently: boolean = false, onlyActive: boolean = false): Observable<FleetLight[]> {
    const url: string = `${this.baseUrl}/light?active=${onlyActive}`;
    if (silently) this.loaderService.addExcludedUrl(url);

    return this.httpClient.get<FleetLight[]>(url).pipe(
      finalize(() => this.loaderService.removeExcludedUrl(url)),
      catchError((err) => {
        err.message = this.translateService.instant('sites.error.get_fleet');
        return throwError(err);
      })
    );
  }

  /**
   * Get all Fleets
   */
  public get(): Observable<Fleet[]> {
    return this.httpClient.get<Fleet[]>(`${this.baseUrl}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('sites.error.get_fleet');
        return throwError(err);
      })
    );
  }

  /**
   * Get one Fleet
   */
  public getOne(technicalId: string, silently: boolean = false): Observable<Fleet> {
    const url: string = `${this.baseUrl}/${technicalId}`;
    if (silently) this.loaderService.addExcludedUrl(url);

    return this.httpClient.get<Fleet>(url).pipe(
      finalize(() => this.loaderService.removeExcludedUrl(url)),
      catchError((err) => {
        err.message = this.translateService.instant('sites.error.get_fleet');
        return throwError(err);
      })
    );
  }

  /**
   * Get one Fleet
   */
  public getOneLight(technicalId: Guid, silently: boolean = false): Observable<FleetLight> {
    const url: string = `${this.baseUrl}/light/${technicalId}`;
    if (silently) this.loaderService.addExcludedUrl(url);

    return this.httpClient.get<Fleet>(url).pipe(
      finalize(() => this.loaderService.removeExcludedUrl(url)),
      catchError((err) => {
        err.message = this.translateService.instant('sites.error.get_fleet');
        return throwError(err);
      })
    );
  }

  /**
   * Get all Fleets for a client
   */
  public getForClient(clientId: number): Observable<Fleet[]> {
    return this.httpClient.get<Fleet[]>(`${this.baseUrl}/client/${clientId}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('sites.error.get_fleet');
        return throwError(err);
      })
    );
  }

  /**
   * Activate/Deactivate whole users in the rattached to the Fleet
   */
  public updateStatus(id: number, clientId: Guid, isActivated: boolean): Observable<void> {
    return this.httpClient
      .put<void>(`${this.baseUrl}/${id}/status?state=${isActivated}&clientId=${clientId}`, null, { headers: { impersonated_client: clientId.toString() } })
      .pipe(
        catchError((err) => {
          return this.handleUpdateStatusError(err, this.translateService.instant('fleets.error.update_status'));
        })
      );
  }

  /**
   * Save a Fleet
   */
  public save(fleet: Fleet): Observable<Fleet> {
    return fleet.id === 0 ? this.post(fleet) : this.put(fleet);
  }

  /**
   * Create a new Fleet
   */
  private post(fleet: Fleet): Observable<Fleet> {
    return this.httpClient.post<Fleet>(`${this.baseUrl}`, fleet, { observe: 'body' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('fleets.error.create');
        return throwError(err);
      })
    );
  }

  /**
   * Update a new Fleet
   */
  private put(fleet: Fleet): Observable<Fleet> {
    return this.httpClient.put<void>(`${this.baseUrl}/${fleet.id}`, fleet, { observe: 'body' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('fleets.error.update');
        return throwError(err);
      }),
      map((_) => fleet)
    );
  }

  private handleUpdateStatusError(err: HttpError<FleetStatusConflictDetails>, defaultMsg: string): Observable<never> {
    let message: string;
    if (err.status === 409) message = this.translateService.instant('fleets.error.conflict', { client: err.error.details.Client });
    else message = this.translateService.instant(defaultMsg);

    err.message = message;
    return throwError(err);
  }

  /**
   * Get one Fleet
   */
  public getCalendarConnection(fleetId: number, api: ReservationApi): Observable<CalendarConnection> {
    const url: string = `${this.baseUrl}/${fleetId}/calendarconnections/${ReservationApi[api]}`;

    return this.httpClient.get<CalendarConnection>(url).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('fleets.error.get_fleet_calendar_connections');
        return throwError(err);
      })
    );
  }

  /**
   * Set connection strings to external calendars (Google workspace or Office 365)
   */
  public setCalendarConnections(fleetId: number, connections: CalendarConnection[]): Observable<void> {
    const url: string = `${this.baseUrl}/${fleetId}/calendarconnections`;
    const tasks$: Observable<void>[] = [];

    connections.forEach((connection) => {
      tasks$.push(
        this.httpClient.post<void>(`${url}/${ReservationApi[connection.api]}`, connection, { observe: 'body' }).pipe(
          catchError((err) => {
            err.message = this.translateService.instant('fleets.error.set_fleet_calendar_connection');
            return throwError(err);
          })
        )
      );
    });

    return forkJoin(...tasks$);
  }

  /**
   * Get one Fleet
   */
  public getGoogleWorkspaceAppId(fleetId: number): Observable<string> {
    const url: string = `${this.baseUrl}/${fleetId}/calendarconnections/googleworkspace/applicationid`;

    return this.httpClient.get<string>(url).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('fleets.error.get_fleet_google_client_id');
        return throwError(err);
      })
    );
  }
}
