import { Component, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { FieldText } from 'src/app/core/template-configuration/field-text';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';

@Component({
  selector: 'app-configuration-mask-text',
  templateUrl: './configuration-mask-text.html',
  styleUrls: ['./configuration-mask-text.scss'],
})
export class ConfigurationMaskTextComponent implements ConfigurationMaskItemComponent {
  @Input() public data: FieldText;

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  private _valid: boolean = true;

  public get isValid(): boolean {
    return this._valid;
  }

  public onChange(): void {
    this._valid = this.data.isValid();
    if (this._valid) this.outputValue.next(this.data);
  }

  public onFocusOut(): void {
    this._valid = this.data.isValid();
    if (this._valid) this.outputFocus.next(this.data);
  }
}
