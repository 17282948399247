import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { createFormData } from '../formHelper';
import { SettingsService } from '../setttings/settings.service';
import { MediaLibrary } from './media-library';
import { MediaScope } from './media-scope';
import { MediaType } from './media-type';

@Injectable({
  providedIn: 'root',
})
export class MediaLibraryService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {
    this.baseUrl = `${this.settingsService.settings.API_URL}/mediaLibrary`;
  }

  public searchWithPagination(start: number, count: number, mediaType: MediaType, scope: MediaScope, searchString?: string): Observable<MediaLibrary[]> {
    const searchValue: string = searchString !== undefined && searchString !== null ? searchString : '';
    const params: HttpParams = new HttpParams({
      fromString: `start=${start}&count=${count}&searchString=${searchValue}&mediaType=${mediaType}&scope=${scope}`,
    });

    return this.httpClient
      .get<MediaLibrary[]>(`${this.baseUrl}/searchPaginated`, {
        params,
      })
      .pipe(
        catchError((err) => {
          err.message = this.translateService.instant('assets.media.error.get');
          return throwError(err);
        })
      );
  }

  public searchAllWithPagination(start: number, count: number, scope: MediaScope, searchString?: string): Observable<MediaLibrary[]> {
    const searchValue: string = searchString !== undefined && searchString !== null ? searchString : '';
    const params: HttpParams = new HttpParams({
      fromString: `start=${start}&count=${count}&searchString=${searchValue}&scope=${scope}`,
    });

    return this.httpClient
      .get<MediaLibrary[]>(`${this.baseUrl}/searchPaginated`, {
        params,
      })
      .pipe(
        catchError((err) => {
          err.message = this.translateService.instant('assets.media.error.get');
          return throwError(err);
        })
      );
  }

  public search(mediaType: MediaType, scope: MediaScope, searchString?: string): Observable<MediaLibrary[]> {
    const searchValue: string = searchString !== undefined && searchString !== null ? searchString : '';

    const params: HttpParams = new HttpParams({
      fromString: `searchString=${searchValue}&mediaType=${mediaType}&scope=${scope}`,
    });

    return this.httpClient.get<MediaLibrary[]>(`${this.baseUrl}/search`, { params }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('assets.media.error.get');
        return throwError(err);
      })
    );
  }

  public searchAll(scope: MediaScope, searchString?: string): Observable<MediaLibrary[]> {
    const searchValue: string = searchString !== undefined && searchString !== null ? searchString : '';

    const params: HttpParams = new HttpParams({
      fromString: `searchString=${searchValue}&scope=${scope}`,
    });

    return this.httpClient.get<MediaLibrary[]>(`${this.baseUrl}/search`, { params }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('assets.media.error.get');
        return throwError(err);
      })
    );
  }

  public getUserFavorites(mediaType?: MediaType, searchString?: string): Observable<MediaLibrary[]> {
    let params: HttpParams | undefined;
    const searchValue: string = searchString !== undefined && searchString !== null ? searchString : '';

    if (mediaType !== undefined) params = new HttpParams({ fromString: `mediaType=${mediaType}&searchString=${searchValue}` });

    return this.httpClient.get<MediaLibrary[]>(`${this.baseUrl}/favorites`, { params }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('assets.media.error.get');
        return throwError(err);
      })
    );
  }

  public getMediaAsset(assetUrl: string): Observable<Blob> {
    return this.httpClient.get(assetUrl, { responseType: 'blob' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('assets.media.error.get');
        return throwError(err);
      })
    );
  }

  /**
   * Upload media
   */
  public send(media: MediaLibrary): Observable<MediaLibrary> {
    const form: FormData = this.constructMediaLibraryForm(media);
    return media.id ? this.put(media.id, form) : this.post(form);
  }

  /**
   * Post a mediaLibrary
   */
  private post(form: FormData): Observable<MediaLibrary> {
    return this.httpClient.post<MediaLibrary>(`${this.baseUrl}`, form).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('media.service.error.post');
        return throwError(err);
      })
    );
  }

  /**
   * Put a mediaLibrary
   */
  private put(id: number, formData: FormData): Observable<MediaLibrary> {
    return this.httpClient.put<MediaLibrary>(`${this.baseUrl}/${id}`, formData).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('media.service.error.put');
        return throwError(err);
      })
    );
  }

  /**
   * Remove media item from user favorites
   */
  public deleteFavorite(mediaId: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${mediaId}/favorites`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('error.delete');
        return throwError(err);
      })
    );
  }

  /**
   * Remove media item from user favorites
   */
  public createFavorite(mediaId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/${mediaId}/favorites`, null).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('error.create');
        return throwError(err);
      })
    );
  }

  private constructMediaLibraryForm(media: MediaLibrary): FormData {
    const form: FormData = createFormData(media);
    if (media.value) {
      const typeValue: string = media.type == MediaType.Picture ? 'Picture.jpg' : '';
      form.append('media', media.value);
      form.set('value', typeValue);
    }
    return form;
  }

  /**
   * Delete a media
   */
  public deleteMedia(mediaId: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${mediaId}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('error.delete');
        return throwError(err);
      })
    );
  }

  /**
   * Get a media
   */
  public getMedia(mediaId: number): Observable<MediaLibrary> {
    return this.httpClient.get<MediaLibrary>(`${this.baseUrl}/${mediaId}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('media.service.error.get');
        return throwError(err);
      })
    );
  }
}
