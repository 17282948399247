import { Component, Input, OnInit } from '@angular/core';
import { BlobStorageService } from 'src/app/core/media-library/blob-storage.service';
import { UploadTask } from './uploadTask';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() public blobStorageService: BlobStorageService;

  public uploading: boolean = false;
  public progress: number = 0;

  public ngOnInit(): void {
    this.blobStorageService.uploading.subscribe((uploadTask: UploadTask) => {
      this.uploading = uploadTask.uploading;
      this.progress = uploadTask.progress > 0 && uploadTask.progress < 100 ? uploadTask.progress : 0;
    });
  }
}
