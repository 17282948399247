import { Role } from "./guards/role";

export class UserData {
  public name: string;
  public initialName: string;
  public roles: Role;
  public fleets: string[];
  public client: string;  // GUID
  public sub: string;
  public aud: string;
}
