<form class="top-form" [formGroup]="fleetFormModel" *ngIf="fleetFormModel">
  <mat-form-field>
    <mat-label>{{ 'tools.users.client' | translate }}</mat-label>
    <mat-select formControlName="clientId" required (selectionChange)="onClientChanged($event)">
      <mat-option *ngFor="let client of clients" [value]="client.technicalId">
        {{ client.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'tools.users.fleet' | translate }}</mat-label>
    <mat-select formControlName="fleetId" required>
      <mat-option *ngFor="let fleet of clientFleets" [value]="fleet.technicalId">
        {{ fleet.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
<mat-spinner [diameter]="50" *ngIf="!fleetFormModel"></mat-spinner>

<div class="dialog-buttons" mat-dialog-actions *ngIf="fleetFormModel">
  <app-busy-button [isBusy]="isBusy" [inactive]="!fleetFormModel.valid" [text]="'fleet_picker.ok' | translate" (buttonClick)="submitForm()"> </app-busy-button>
  <button mat-raised-button color="basic" (click)="cancel()">{{ 'fleet_picker.cancel' | translate }}</button>
</div>
