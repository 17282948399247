import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SettingsService } from '../core/setttings/settings.service';
import { LoaderService } from '../general/layout/services/loader.service';
import { News } from './news';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  public unreadCounterChanged: EventEmitter<number> = new EventEmitter();

  private newsBaseUrl: string;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private loaderService: LoaderService, private translateService: TranslateService) {
    this.newsBaseUrl = `${this.settingsService.settings.API_URL}/news`;
  }

  public get(count: number = 20): Observable<News[]> {
    return this.httpClient.get<News[]>(`${this.newsBaseUrl}/latest?count=${count}`).pipe(
      map((data: News[]) => {
        data.forEach((news: News) => {
          news.publicationDate = new Date(news.publicationDate);
        });
        return data;
      }),
      catchError((err) => {
        err.message = this.translateService.instant('news.error.get');
        return throwError(err);
      })
    );
  }

  public post(news: News): Observable<News> {
    return this.httpClient.post<News>(`${this.newsBaseUrl}`, news).pipe(
      map((data: News) => {
        data.publicationDate = new Date(data.publicationDate);
        return data;
      }),
      catchError((err) => {
        err.message = this.translateService.instant('news.error.post');
        return throwError(err);
      })
    );
  }

  public put(news: News): Observable<void> {
    return this.httpClient.put<void>(`${this.newsBaseUrl}/${news.id}`, news).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('news.error.put');
        return throwError(err);
      })
    );
  }

  public delete(id: Number): Observable<void> {
    return this.httpClient.delete<void>(`${this.newsBaseUrl}/${id}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('news.error.delete');
        return throwError(err);
      })
    );
  }

  public getUnreadNewsCount(silently: boolean = true): Observable<number> {
    let url: string = `${this.newsBaseUrl}/unread/count`;
    if (silently) this.loaderService.addExcludedUrl(url);

    return this.httpClient.get<number>(`${this.newsBaseUrl}/unread/count`).pipe(
      tap((count) => {
        this.unreadCounterChanged.next(count);
      }),
      catchError((err, caught) => {
        console.log('Error getting unread news counter.');
        return throwError(err);
      })
    );
  }

  public resetUnreadNewsCount(): Observable<void> {
    return this.httpClient.delete<void>(`${this.newsBaseUrl}/unread/count`).pipe(
      tap(() => {
        this.unreadCounterChanged.next(0);
      }),
      catchError((err, caught) => {
        console.log('Error resetting unread news counter.');
        return throwError(err);
      })
    );
  }
}
