import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { createFormData } from 'src/app/core/formHelper';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { SlideAsset } from './slide-asset';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { MediaAssetTitle } from '../list-assets/media-asset-title';

@Injectable({
  providedIn: 'root',
})
export class SlideAssetService {
  private baseUrl: string;

  constructor(private loaderService: LoaderService, private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {
    this.baseUrl = `${this.settingsService.settings.API_URL}/slideasset`;
  }

  /**
   * Get Media Asset infos
   */
  public getMediaAssetsInfos(blobIds: string[]): Observable<MediaAssetTitle[]> {
    this.loaderService.addExcludedUrl(this.baseUrl);

    return this.httpClient.post<MediaAssetTitle[]>(`${this.baseUrl}/mediainfos`, blobIds).pipe(
      finalize(() => this.loaderService.removeExcludedUrl(this.baseUrl)),
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide_asset.error.post');
        return throwError(err);
      })
    );
  }

  public send(slideAsset: SlideAsset): Observable<SlideAsset> {
    const form: FormData = createFormData(slideAsset);
    return slideAsset.id ? this.put(slideAsset.id, form) : this.post(form);
  }

  /**
   * Post a slide asset
   */
  private post(formData: FormData): Observable<SlideAsset> {
    this.loaderService.addExcludedUrl(this.baseUrl);

    return this.httpClient.post<SlideAsset>(this.baseUrl, formData).pipe(
      finalize(() => this.loaderService.removeExcludedUrl(this.baseUrl)),
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide_asset.error.post');
        return throwError(err);
      })
    );
  }

  /**
   * Put a slide asset
   */
  private put(id: number, formData: FormData): Observable<SlideAsset> {
    this.loaderService.addExcludedUrl(`${this.baseUrl}/${id}`);

    return this.httpClient.put<SlideAsset>(`${this.baseUrl}/${id}`, formData).pipe(
      finalize(() => this.loaderService.removeExcludedUrl(`${this.baseUrl}/${id}`)),
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide_asset.error.put', { id: id });
        return throwError(err);
      })
    );
  }

  /**
   * delete slide asset
   */
  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide_asset.error.delete', { id: id });
        return throwError(err);
      })
    );
  }
}
