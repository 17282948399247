import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import Cropper from 'cropperjs';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CropperConfiguration } from './imageCropperConfiguration';
import { CroppedImageData } from './imageDataEvent';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperComponent implements AfterViewInit, OnChanges {
  private readonly rotationAngle: number = 90;
  private readonly autoCropDebounceTime: number = 1000;
  private readonly maxZoom: number = 1;

  private cropperOptions: Cropper.Options;

  private firstCrop: boolean = true;
  private cropper: Cropper;
  private previousObjectUrl: string;
  private debouncedCroppedBlob: Subject<Blob> = new Subject<Blob>();

  @ViewChild('image', { static: false }) public imageElement: ElementRef;

  // @Input() public imageDataEvent: ImageDataEvent;
  @Input() public imageData: Blob;
  @Input() public configuration: CropperConfiguration;

  @Output() public croppedImage: EventEmitter<CroppedImageData> = new EventEmitter();
  @Output() public imageLoaded: EventEmitter<boolean> = new EventEmitter();

  public isInDragMode: boolean = false;
  public hasImage: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    // let the browser enough time to render the page with correct dimensions
    setTimeout(() => this.initCropper(), 50);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['configuration'] && this.cropper) this.cropper.setAspectRatio(this.configuration.cropRatio);
    if (changes['imageDataEvent'] && this.imageElement) this.initCropper();
  }

  public ngAfterViewInit(): void {
    this.cropperOptions = {
      zoomable: true,
      aspectRatio: this.configuration.cropRatio,
      scalable: false,
      movable: true,
      viewMode: 2,
      ready: () => this.onReady(),
      zoom: (ev) => this.onZoom(ev),
    };

    if (this.imageElement) this.initCropper();

    this.debouncedCroppedBlob.pipe(debounceTime(this.autoCropDebounceTime)).subscribe((b) => {
      this.croppedImage.emit(this.sendObjectUrl(b));
    });
  }

  public rotateRight(event: Event): void {
    event.stopPropagation();

    if (this.cropper) this.cropper.rotate(this.rotationAngle);
  }

  public selectAll(event: Event): void {
    let data: Cropper.Data = new Object() as Cropper.Data;

    event.stopPropagation();
    const imageData: Cropper.ImageData = this.cropper.getImageData();
    const curData: Cropper.Data = this.cropper.getData();

    if (this.cropper) {
      if (this.configuration.cropRatio < imageData.aspectRatio) {
        data.width = imageData.naturalHeight * this.configuration.cropRatio;
        data.height = imageData.naturalHeight;
        data.x = Math.round((imageData.naturalWidth - data.width) / 2);
        data.y = 0;
      } else {
        data.width = imageData.naturalWidth;
        data.height = imageData.naturalWidth / this.configuration.cropRatio;
        data.x = 0;
        data.y = Math.round((imageData.naturalHeight - data.height) / 2);
      }

      data.scaleX = 0;
      data.scaleY = 0;
      data.rotate = curData.rotate;

      this.cropper.setData(data);
    }
  }

  public rotateLeft(event: Event): void {
    event.stopPropagation();

    if (this.cropper) this.cropper.rotate(-this.rotationAngle);
  }

  public switchDragMode(event: Event): void {
    event.stopPropagation();

    if (this.cropper) {
      this.isInDragMode = !this.isInDragMode;
      this.cropper.setDragMode(this.isInDragMode ? 'move' : 'none');
    }
  }

  public zoomIn(event: Event): void {
    event.stopPropagation();

    if (this.cropper) this.cropper.zoom(this.configuration.zoomStep);
  }

  public zoomOut(event: Event): void {
    event.stopPropagation();

    if (this.cropper) this.cropper.zoom(-this.configuration.zoomStep);
  }

  public clearCropper(): void {
    if (this.cropper) this.cropper.destroy();

    this.hasImage = false;
  }

  public crop(): void {
    const canvas: HTMLCanvasElement = this.cropper.getCroppedCanvas({ width: this.configuration.width, height: this.configuration.height });
    if (canvas)
      canvas.toBlob((b) => {
        if (!b) return;
        if (!this.firstCrop && this.configuration.autoCrop) this.debouncedCroppedBlob.next(b);
        else {
          this.croppedImage.emit(this.sendObjectUrl(b));
          this.firstCrop = false;
        }
      });
  }

  private initCropper(): void {
    if (this.cropper) this.cropper.destroy();

    this.firstCrop = true;
    if (this.imageElement.nativeElement.src) URL.revokeObjectURL(this.imageElement.nativeElement.src);

    if (this.imageData && this.imageElement) {
      this.hasImage = true;
      this.imageElement.nativeElement.src = URL.createObjectURL(this.imageData);
      this.cropper = new Cropper(this.imageElement.nativeElement, this.cropperOptions);
    }
  }

  private onZoom(event: CustomEvent): void {
    if (event.detail.ratio > this.maxZoom) event.preventDefault();

    const data: Cropper.Data = this.cropper.getData();
    if (data.width < this.configuration.width || data.height < this.configuration.height) event.preventDefault();
  }

  private onReady(): void {
    this.imageLoaded.emit(true);
  }

  private sendObjectUrl(b: Blob): CroppedImageData {
    if (this.previousObjectUrl) URL.revokeObjectURL(this.previousObjectUrl);

    this.previousObjectUrl = URL.createObjectURL(b);
    const cropperData: Cropper.Data = this.cropper.getData(true);

    return { croppedImage: this.previousObjectUrl, height: cropperData.height, width: cropperData.width, file: new File([b], 'Picture.jpg', { type: 'image/jpeg' }) };
  }
}
