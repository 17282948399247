import { Injectable } from '@angular/core';
import MediaInfoFactory, { MediaInfo, ReadChunkFunc } from 'mediainfo.js';
import { ResultObject, Track } from 'mediainfo.js/dist/types';
import { Observable, Subject } from 'rxjs';
import { CodecData } from 'src/app/core/media-library/codec-data';

@Injectable()
export class MediainfoWasmService {
  private mediaInfoSubject: Subject<{ codecData: CodecData; file: File }> = new Subject<{ codecData: CodecData; file: File }>();
  public mediaInfoReceived: Observable<{ codecData: CodecData; file: File }> = this.mediaInfoSubject.asObservable();

  public mediainfo(file: File): void {
    MediaInfoFactory({ format: 'object' }, (mediainfo) =>
      this.onChangeFile(mediainfo as MediaInfo, file).then((codecData: CodecData) => this.mediaInfoSubject.next({ codecData: codecData, file: file }))
    );
  }

  private async onChangeFile(mediainfo: MediaInfo, file: File): Promise<CodecData> {
    return new Promise<CodecData>((resolve) => {
      const readChunk: ReadChunkFunc = async (chunkSize, offset) =>
        new Promise<Uint8Array>((resolve, reject) => {
          const reader: FileReader = new FileReader();
          reader.onload = (event) => {
            if (event.target?.error) {
              reject(event.target.error);
            }
            resolve(new Uint8Array(<ArrayBuffer>event.target?.result));
          };
          reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
        });

      const p: Promise<ResultObject> = <Promise<ResultObject>>mediainfo.analyzeData(() => file.size, readChunk);
      p.then((mediaInfoResult: ResultObject) => {
        mediaInfoResult.media.track
          .filter((m) => <string>m['@type'].toLowerCase() == 'video')
          // tslint:disable-next-line: no-string-literal
          .map((x: Track) => resolve(new CodecData(x['Height'] as string, x['Width'] as string, x['FrameRate'] as string, x['CodecID'] as string, x['Format'] as string)));
      });
    });
  }
}
