import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(value: Date, pattern: string): string {
    if (value === undefined) return '-';

    const datePipe: DatePipe = new DatePipe(this.translateService.defaultLang);

    return datePipe.transform(value, pattern, '+0000') as string;
  }
}
