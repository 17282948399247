<div class="container">
  <div id="title-bar">
    <h3>{{ 'mask.configuration.resize_modal_title' | translate }}</h3>
    <button mat-icon-button class="close" (click)="closeModale()" color="basic">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <app-image-cropper #cropper (croppedImage)="imageCropped($event)" [configuration]="cropperConfiguration" [imageData]="imageData"> </app-image-cropper>
  <div class="action-button">
    <app-busy-button [isBusy]="isBusy" [text]="'common.validate' | translate" (buttonClick)="validateModale()"> </app-busy-button>
  </div>
</div>
