import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ClientLight } from 'src/app/core/clients/client';
import { ClientService } from 'src/app/core/clients/client.service';
import { EnumHelper } from 'src/app/core/enumHelper';
import { Fleet, FleetLight } from 'src/app/core/fleet/fleet';
import { FleetService } from 'src/app/core/fleet/fleet.service';
import { Role } from 'src/app/core/guards/role';
import { ScopePolicies } from 'src/app/core/guards/scope-policies';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { StorageKey } from 'src/app/core/local-storage/storageKeys';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { EncodingMessagesService } from 'src/app/core/signalr/encoding-messages.service';
import { UserData } from './../../core/userData';
import { HeaderData } from './header/headerData';
import { LayoutService } from './layout.service';
import { RouteDefinition } from './route-definition';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  public isHandsetPortrait: boolean = false;
  public isHandsetLandscape: boolean = false;
  public isHandset: boolean = false;
  public isDesktop: boolean = false;

  public isSidebarVisible: boolean = false;
  public url: string;
  public headerData: HeaderData = { userData: new UserData(), clients: [], fleets: [] };
  public userData: UserData = new UserData();

  public get copyright(): String {
    return `Captive Audience - Copyright ${new Date().getFullYear()} Globecast`;
  }

  public get version(): String {
    const versionNumber: String = `${this.settingsService.settings.MAJOR}.${this.settingsService.settings.BUILD}`;
    return ` ${this.settingsService.settings.ENV} ${versionNumber}`;
  }

  /** Liste des pages d'accueil par défaut en fonction des rôles de l'utilisateur */
  private static RouteDictionary: Array<RouteDefinition> = [
    { role: Role.SuperAdministrator, route: '/gca/backoffice/clients-accounts' },
    { role: Role.AccountAdministrator, route: '/gca/administration/users' },
    { role: Role.ContentManager, route: '/gca/masks' },
    { role: Role.ChannelManager, route: '/gca/channels' },
    { role: Role.SiteManager, route: '/gca/organization/sites' },
    { role: Role.LiveEventsManager, route: '/gca/live-events' },
    { role: Role.Hotline, route: '/gca/masks' },
    { role: Role.Technician, route: '/gca/organization/sites' },
  ];

  constructor(
    private clientService: ClientService,
    private fleetService: FleetService,
    private settingsService: SettingsService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private layoutService: LayoutService,
    private loaderService: LoaderService,
    private encodingMessagesService: EncodingMessagesService,
    private responsive: BreakpointObserver
  ) {
    router.events.pipe(filter((ev) => ev instanceof NavigationEnd)).subscribe((val) => {
      this.url = (val as NavigationEnd).urlAfterRedirects;
    });
  }

  public ngOnInit(): void {
    this.getUserInformations();
    this.loaderService.setExcludedMethods(['POST', 'PUT', 'PATCH']);

    this.responsive.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
      const breakpoints = result.breakpoints;

      this.isHandsetPortrait = breakpoints[Breakpoints.HandsetPortrait];
      this.isHandsetLandscape = breakpoints[Breakpoints.HandsetLandscape];
      this.isHandset = this.isHandsetLandscape || this.isHandsetPortrait;
      this.isDesktop = !this.isHandset;
    });
  }

  public redirectToUserRoleHomepage(): void {
    const higherRole: Role = EnumHelper.highestFlag(this.userData.roles);
    const roleRoute: RouteDefinition = LayoutComponent.RouteDictionary.find((group) => group.role === higherRole)!;
    this.router.navigate([roleRoute.route]);
  }

  private getUserInformations(): void {
    this.userData = this.localStorageService.readObject(StorageKey.userData)!;

    const canImpersonate: boolean = ScopePolicies.CanImpersonate(this.userData.roles);

    forkJoin([
      canImpersonate ? this.clientService.getLight(true) : of(new Array<ClientLight>()),
      canImpersonate ? this.fleetService.getLight(true, true) : of(new Array<FleetLight>()),
    ])
      .pipe(
        switchMap((res) => {
          return forkJoin([of(res), res[1].length > 0 ? this.fleetService.getOne(res[1][0].technicalId.toString(), true) : of({} as Fleet)]);
        })
      )
      .subscribe((res) => {
        this.headerData = {
          userData: this.userData,
          clients: res[0][0].filter((c) => res[0][1].some((f) => f.clientId === c.technicalId)),
          fleets: res[0][1],
        };
        this.isSidebarVisible = true;
        this.encodingMessagesService.connect();

        if (this.router.url === '/gca') {
          const higherRole: Role = EnumHelper.highestFlag(this.userData.roles);
          const roleRoute: RouteDefinition = LayoutComponent.RouteDictionary.find((group) => group.role === higherRole)!;
          this.router.navigate([roleRoute.route]);
        }
      });
  }

  @ViewChild('sidenav') private sidenav: MatSidenav;

  public navigating() {
    if (!this.isDesktop) this.sidenav.close();
  }

  public addMask(event: Event): void {
    event.stopPropagation();
    this.router.navigate(['/mask', 0, 'informations']);
  }

  public ngOnDestroy(): void {
    if (this.encodingMessagesService) this.encodingMessagesService.disconnect();
  }

  public onScroll(event: Event): void {
    event.preventDefault();
    this.layoutService.onScroll(event);
  }
}
