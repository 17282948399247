import { ConfirmResult } from 'src/app/core/confirmResult';
import { HttpStatusCode } from './http-status-code';

export class HttpResponseManager {
  public static technicalErrorHappened(httpStatusCode: number): boolean {
    return httpStatusCode === HttpStatusCode.InternalServerError || httpStatusCode === HttpStatusCode.Forbidden;
  }

  public static shouldRefreshPageOnError(httpStatusCode: number): ConfirmResult {
    return httpStatusCode !== HttpStatusCode.Forbidden ? ConfirmResult.Yes : ConfirmResult.No;
  }
}
