import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { IsbaLocalization } from './isba-localization';
import { IsbaResource } from './isba-resource';

@Injectable({
  providedIn: 'root',
})
export class IsbaService {
  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {
    this.baseUrl = `${this.settingsService.settings.API_URL}/reservations/isba`;
  }
  private baseUrl: string;

  public getBuildingIsbaMeetingRooms(buildingId: number): Observable<IsbaResource[]> {
    return this.httpClient.get<IsbaResource[]>(`${this.baseUrl}/buildings/${buildingId}/meetingrooms`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('isba.error.get_resources');
        return throwError(err);
      })
    );
  }

  public getIsbaLocalizations(): Observable<IsbaLocalization[]> {
    return this.httpClient.get<IsbaLocalization[]>(`${this.baseUrl}/localizations`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('isba.error.get_localizations');
        return throwError(err);
      })
    );
  }
}
