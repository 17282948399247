import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { DialogResult } from 'src/app/core/dialogResult';
import { PromptData } from './prompt-data';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent {
  public dialogTitle?: string;
  public dialogMessage: string;
  public dialogConfirmButtonTitle: string;
  public dialogCancelButtonTitle: string;
  public dialogInputType: string = 'text';
  public minValue?: number;
  public maxValue?: number;

  public value: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PromptData, private dialogRef: MatDialogRef<PromptComponent>, private translationService: TranslateService) {
    this.dialogRef.disableClose = true;
    this.dialogTitle = data.title;
    this.dialogMessage = data.message;
    this.dialogConfirmButtonTitle = data.confirmButtonTitle === undefined ? this.translationService.instant('common.validate') : data.confirmButtonTitle;
    this.dialogCancelButtonTitle = data.cancelButtonTitle === undefined ? this.translationService.instant('common.cancel') : data.cancelButtonTitle;
    this.dialogInputType = data.inputType ?? 'text';
    this.value = data.defaultValue ?? '';
    this.minValue = data.minValue;
    this.maxValue = data.maxValue;
  }

  public ok(): void {
    const result: DialogResult<string> = new DialogResult<string>();
    result.confirm = ConfirmResult.Yes;
    result.entity = this.value;

    this.dialogRef.close(result);
  }

  public cancel(): void {
    const result: DialogResult<string> = new DialogResult<string>();
    result.confirm = ConfirmResult.No;

    this.dialogRef.close(result);
  }
}
