<div class="container">
  <div class="header">
    <div class="close-action">
      <button mat-icon-button (click)="closeDialog()">
        <i class="material-icons">clear</i>
      </button>
    </div>
    <div class="title">
      {{ 'isba.localization_picker.title' | translate }}
    </div>
    <div class="wait" *ngIf="!isLoaded">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <div class="content" *ngIf="isLoaded">
      <p>{{ 'isba.localization_picker.instructions' | translate }}</p>

      <div class="localizations">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <!-- Leaf node -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding (click)="submitBuilding(node)">
            <button mat-icon-button disabled></button>
            {{ node.name }}
          </mat-tree-node>
          <!-- Expandable node -->
          <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            {{ node.name }}
          </mat-tree-node>
        </mat-tree>
      </div>
    </div>
  </div>
</div>
