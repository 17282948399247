import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS, NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { BusyButtonComponent } from '../components/busy-button/busy-button.component';
import { ChartComponent } from '../components/chart/chart.component';
import { ConfirmComponent } from '../components/confirm/confirm.component';
import { ContextualMenuComponent } from '../components/contextual-menu/contextual-menu.component';
import { DialogImagePreviewComponent } from '../components/dialog-image-preview/dialog-image-preview.component';
import { DialogProgressComponent } from '../components/dialog-progress/dialog-progress.component';
import { DialogVideoPreviewComponent } from '../components/dialog-video-preview/dialog-video-preview.component';
import { DynamicDataPickerComponent } from '../components/dynamic-data-picker/dynamic-data-picker.component';
import { FilterComponent } from '../components/filter/filter.component';
import { FleetPickerComponent } from '../components/fleet-picker/fleet-picker.component';
import { GoogleworkspaceBuildingPickerComponent } from '../components/googleworkspace-building-picker/googleworkspace-building-picker.component';
import { GoogleworkspaceMeetingRoomPickerComponent } from '../components/googleworkspace-meetingroom-picker/googleworkspace-meetingroom-picker.component';
import { IsbaLocalizationPickerComponent } from '../components/isba-localization-picker/isba-localization-picker.component';
import { IsbaResourcePickerComponent } from '../components/isba-resource-picker/isba-resource-picker.component';
import { LoaderOverlayComponent } from '../components/loader-overlay/loader-overlay.component';
import { MaskAudiencesDialogComponent } from '../components/mask-tile/mask-audiences-dialog/mask-audiences-dialog.component';
import { MaskTileComponent } from '../components/mask-tile/mask-tile.component';
import { MediaFormComponent } from '../components/media-form/media-form.component';
import { MultipleScheduleComponent } from '../components/multiple-schedule/multiple-schedule.component';
import { NotificationComponent } from '../components/notification/notification.component';
import { PlanAudiencesFormComponent } from '../components/plan-audiences-form/plan-audiences-form.component';
import { PlanScheduleFormComponent } from '../components/plan-schedule-form/plan-schedule-form.component';
import { DialogPreviewComponent } from '../components/preview/dialog-preview/dialog-preview.component';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { PromptComponent } from '../components/prompt/prompt.component';
import { ConfigurationMaskChannelComponent } from '../components/slide-editor/configuration-mask-channel/configuration-mask-channel';
import { ConfigurationMaskImageListComponent } from '../components/slide-editor/configuration-mask-image-list/configuration-mask-image-list.component';
import { ConfigurationMaskPictureComponent } from '../components/slide-editor/configuration-mask-picture/configuration-mask-picture.component';
import { DialogConfigurationMaskPictureComponent } from '../components/slide-editor/configuration-mask-picture/dialog-configuration-mask-picture/dialog-configuration-mask-picture.component';
import { ImageCropperComponent } from '../components/slide-editor/configuration-mask-picture/image-cropper/image-cropper.component';
import { ConfigurationMaskSelectComponent } from '../components/slide-editor/configuration-mask-select/configuration-mask-select.component';
import { ConfigurationMaskTextListComponent } from '../components/slide-editor/configuration-mask-text-list/configuration-mask-text-list.component';
import { ConfigurationMaskTextComponent } from '../components/slide-editor/configuration-mask-text/configuration-mask-text';
import { ConfigurationMaskUrlComponent } from '../components/slide-editor/configuration-mask-url/configuration-mask-url.component';
import { ConfigurationMaskVideoListComponent } from '../components/slide-editor/configuration-mask-video-list/configuration-mask-video-list.component';
import { ConfigurationMaskVideoComponent } from '../components/slide-editor/configuration-mask-video/configuration-mask-video.component';
import { ConfigurationMaskComponent } from '../components/slide-editor/configuration-mask/configuration-mask.component';
import { ConfigurationMaskDirective } from '../components/slide-editor/configuration-mask/configuration-mask.directive';
import { ListAssetsComponent } from '../components/slide-editor/list-assets/list-assets.component';
import { ReorganizeSlideComponent } from '../components/slide-editor/reorganize-slide/reorganize-slide.component';
import { SlideEditorComponent } from '../components/slide-editor/slide-editor.component';
import { SlideComponent } from '../components/slide-editor/slide/slide.component';
import { SlidePreviewComponent } from '../components/slide-preview/slide-preview.component';
import { DropDirective } from '../directives/drop.directive';
import { GcaDynamicDataDirective } from '../directives/gca-dynamic-data.directive';
import { GcaTooltipDirective } from '../directives/gca-tooltip.directive';
import { LabelControlNameDirective } from '../directives/label-control-name';
import { ClientFilterPipe } from '../pipes/client-filter.pipe';
import { EnumFilterPipe } from '../pipes/enum-filter.pipe';
import { EnumToKeyValuePipe } from '../pipes/enum-to-key-value.pipe';
import { FormControlPipe } from '../pipes/form-control.pipe';
import { FormGroupPipe } from '../pipes/form-group.pipe';
import { LocalizedBooleanPipe } from '../pipes/localized-boolean.pipe';
import { LocalizedDatePipe } from '../pipes/localized-date.pipe';
import { LocalizedEnumPipe } from '../pipes/localized-enum.pipe';
import { SanitizeHtmlPipe } from '../pipes/sanitize-html.pipe';
import { ListChipsComponent } from './../components/list-chips/list-chips.component';
import { TooltipComponent } from './../components/tooltip/tooltip.component';
import { EnumToArrayPipe } from './../pipes/enumToArray.pipe';
import { MaterialModule } from './material-module';
import { RolesToKeyValuesPipe } from '../pipes/roles-to-key-values.pipe';
import { RenameSlideComponent } from '../components/slide-editor/slide-toolbar/rename-slide/rename-slide.component';
import { EditSlideTimerComponent } from '../components/slide-editor/slide-toolbar/timer-slide/timer-slide.component';
import { ConfigurationMaskTableComponent } from '../components/slide-editor/configuration-mask-table/configuration-mask-table.component';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MaterialModule,
    NgxMasonryModule,
    NgxMaterialTimepickerModule.setLocale('fr-FR'),
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [
    BreadcrumbComponent,
    BusyButtonComponent,
    ChartComponent,
    ClientFilterPipe,
    CommonModule,
    ContextualMenuComponent,
    DialogImagePreviewComponent,
    DialogPreviewComponent,
    DialogVideoPreviewComponent,
    DropDirective,
    DynamicDataPickerComponent,
    EnumFilterPipe,
    EnumToArrayPipe,
    EnumToKeyValuePipe,
    FilterComponent,
    FleetPickerComponent,
    FormControlPipe,
    FormGroupPipe,
    FormsModule,
    GcaDynamicDataDirective,
    GcaTooltipDirective,
    GoogleworkspaceBuildingPickerComponent,
    GoogleworkspaceMeetingRoomPickerComponent,
    IsbaLocalizationPickerComponent,
    IsbaResourcePickerComponent,
    LabelControlNameDirective,
    ListAssetsComponent,
    ListChipsComponent,
    LoaderOverlayComponent,
    LocalizedBooleanPipe,
    LocalizedDatePipe,
    LocalizedEnumPipe,
    MaskTileComponent,
    MaterialModule,
    MediaFormComponent,
    MultipleScheduleComponent,
    NgxMatColorPickerModule,
    PlanAudiencesFormComponent,
    PlanScheduleFormComponent,
    ProgressBarComponent,
    PromptComponent,
    ReactiveFormsModule,
    RouterModule,
    SanitizeHtmlPipe,
    SlideComponent,
    SlideEditorComponent,
    SlidePreviewComponent,
    TooltipComponent,
    TranslateModule,
    RolesToKeyValuesPipe,
  ],
  declarations: [
    BreadcrumbComponent,
    BusyButtonComponent,
    ChartComponent,
    ClientFilterPipe,
    ConfirmComponent,
    ConfigurationMaskComponent,
    ConfigurationMaskDirective,
    ConfigurationMaskChannelComponent,
    ConfigurationMaskImageListComponent,
    ConfigurationMaskTextListComponent,
    ConfigurationMaskVideoListComponent,
    ConfigurationMaskPictureComponent,
    ConfigurationMaskSelectComponent,
    ConfigurationMaskTextComponent,
    ConfigurationMaskUrlComponent,
    ConfigurationMaskVideoComponent,
    ConfigurationMaskTableComponent,
    ContextualMenuComponent,
    DialogConfigurationMaskPictureComponent,
    DialogImagePreviewComponent,
    DialogPreviewComponent,
    DialogProgressComponent,
    DialogVideoPreviewComponent,
    DropDirective,
    DynamicDataPickerComponent,
    EnumFilterPipe,
    EnumToArrayPipe,
    EnumToKeyValuePipe,
    FilterComponent,
    FleetPickerComponent,
    FormControlPipe,
    FormGroupPipe,
    GcaDynamicDataDirective,
    GcaTooltipDirective,
    GoogleworkspaceBuildingPickerComponent,
    GoogleworkspaceMeetingRoomPickerComponent,
    ImageCropperComponent,
    IsbaLocalizationPickerComponent,
    IsbaResourcePickerComponent,
    LabelControlNameDirective,
    ListAssetsComponent,
    ListChipsComponent,
    LoaderOverlayComponent,
    LocalizedBooleanPipe,
    LocalizedDatePipe,
    LocalizedEnumPipe,
    MaskAudiencesDialogComponent,
    MaskTileComponent,
    MediaFormComponent,
    MultipleScheduleComponent,
    NotificationComponent,
    PlanAudiencesFormComponent,
    PlanScheduleFormComponent,
    ProgressBarComponent,
    PromptComponent,
    RenameSlideComponent,
    EditSlideTimerComponent,
    ReorganizeSlideComponent,
    SanitizeHtmlPipe,
    SlideComponent,
    SlideEditorComponent,
    SlidePreviewComponent,
    TooltipComponent,
    RolesToKeyValuesPipe,
  ],
  providers: [LocalizedDatePipe, { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
})
export class SharedModule {}
