<h1 id="title">{{"channels.available" | translate}}</h1>
<div class="wait" *ngIf="!channels">
  <mat-spinner [diameter]="35"></mat-spinner>
</div>
<div *ngIf="channels?.length === 0">
  <p id="no-channels">{{"channels.not_available" | translate}}</p>
</div>
<div *ngIf="channels?.length ?? 0 > 0" class="list">
  <mat-list role="list">
    <mat-radio-group role="list-item" *ngFor="let channel of channels" [(ngModel)]="selectedChannel">
      <mat-card class="mat-card">
        <mat-radio-button color="primary" [value]="channel" (change)="onChange(channel)">
          <div class="radio-label">
            <p class="channel-title">{{channel.name}}</p>
            <h4>{{channel.description}}</h4>
          </div>
        </mat-radio-button>
      </mat-card>
    </mat-radio-group>
  </mat-list>
</div>
