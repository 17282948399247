import { TemplateAssetType } from 'src/app/core/template-configuration/template-asset-type.enum';

export class SlideAsset {
  public id: number;
  public slideId: number;

  // Should be named "ParameterId" instead
  public htmlId: string;
  public assetType: TemplateAssetType;
  public technicalId: string;

  // used by select list
  public selected?: string;

  // used by video and picture assets
  public mediaUrl: string;
  public blobId?: string;

  // Used by video asset
  public thumbnailUrl?: string;
  public name?: string;

  // used by image asset
  public blobFile?: File;
  public fromLibrary: boolean;

  // used by channel assets
  public playerToken?: string;
  public channelId: number;

  // used by qrcode
  public url?: string;

  // used by text
  public text?: string;

  // used by video/text/image lists assets
  public videos?: VideoData[];
  public images?: ImageData[];
  public texts?: string[];

  constructor(slideId: number, htmlId: string, assetId: number, assetType: TemplateAssetType, technicalId: string) {
    this.id = assetId;
    this.slideId = slideId;
    this.htmlId = htmlId;
    this.assetType = assetType;
    this.technicalId = technicalId;
  }
}

export class VideoData {
  blobId: string;
  videoUrl: string;
  thumbnailUrl: string;
  name?: string;
}

export class ImageData {
  blobId: string;
  imageUrl: string;
  blobDataUrl?: string;
  blobFile?: File;

  constructor(blobId: string) {
    this.blobId = blobId;
  }
}
