import { Component, Input, OnDestroy } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DynamicData } from 'src/app/core/dynamic-data/dynamic-data';
import { DynamicDataService } from 'src/app/core/dynamic-data/dynamic-data.service';

@Component({
  selector: 'app-dynamic-data-picker',
  templateUrl: './dynamic-data-picker.component.html',
  styleUrls: ['./dynamic-data-picker.component.scss'],
})
export class DynamicDataPickerComponent implements OnDestroy {
  @Input() field: HTMLInputElement | HTMLTextAreaElement;

  private _isOpen: boolean = false;
  private _loading: boolean = false;
  private static _dataList: DynamicData[];

  // utilisé pour gérer la fermeture du picker si l'utilisateur clique ailleurs
  private clickEvent: () => void = () => this.hidePicker();

  public get isOpen(): boolean {
    return this._isOpen;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public get dataList(): DynamicData[] {
    return DynamicDataPickerComponent._dataList;
  }

  constructor(private _dynamicDataService: DynamicDataService) {}

  public togglePicker(event: MouseEvent): void {
    event.stopPropagation();
    this._isOpen = !this._isOpen;

    // load data if not already loaded
    if (!DynamicDataPickerComponent._dataList && this._isOpen) {
      this.loadDynamicData();
    }

    if (this._isOpen) {
      document.addEventListener('click', this.clickEvent);
    } else {
      document.removeEventListener('click', this.clickEvent);
    }
  }

  public select(data: DynamicData): void {
    this.patchValue(`$\{${data.name}\}`);

    // triggers angular reactive forms model update
    this.field.dispatchEvent(new Event('input'));
    // triggers saving to database
    this.field.dispatchEvent(new Event('focusout'));
  }

  private loadDynamicData() {
    this._loading = true;
    this._dynamicDataService
      .get()
      .pipe(finalize(() => (this._loading = false)))
      .subscribe((data) => (DynamicDataPickerComponent._dataList = data.sort((a, b) => a.name.localeCompare(b.name))));
  }

  private hidePicker(): void {
    this._isOpen = false;
    document.removeEventListener('click', this.clickEvent);
  }

  private patchValue(variableName: string): void {
    const currentValue: string = this.field.value;
    const cursor: number[] | null = this.getCursor();

    if (!cursor) return;

    this.field.value = currentValue.substring(0, cursor[0]) + variableName + currentValue.substring(cursor[1]);
  }

  /**
   * Permet d'obtenir les indexes de début et de fin du texte sélectionné dans la zone de texte
   * @returns un tableau contenant 2 chiffres (index de début, index de fin);
   */
  private getCursor(): number[] | null {
    const start: number | null = this.field.selectionStart;
    const end: number | null = this.field.selectionEnd;

    if (start == null || end == null) return null;

    return [start, end];
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.clickEvent);
  }
}
