import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { StorageKey } from 'src/app/core/local-storage/storageKeys';
import { SessionStorageService } from 'src/app/core/session-storage/session-storage.service';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { Language, UserSettings } from 'src/app/core/users/user-settings';
import { LoaderService } from 'src/app/general/layout/services/loader.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private usersBaseUrl: string;
  public userSettingsChanged: Subject<UserSettings> = new Subject<UserSettings>();

  public get language(): Language {
    let sessionSettings: UserSettings | undefined = this.sessionStorageService.readObject<UserSettings>(StorageKey.userSettings);

    if (sessionSettings) {
      return sessionSettings.language;
    } else {
      return Language.Fr;
    }
  }

  constructor(
    private settingsService: SettingsService,
    private sessionStorageService: SessionStorageService,
    private loaderService: LoaderService,
    private httpClient: HttpClient
  ) {
    this.usersBaseUrl = `${this.settingsService.settings.API_URL}/user`;
  }

  public updateMySettings(settings: UserSettings): Observable<void> {
    const url: string = `${this.usersBaseUrl}/me/settings`;

    return this.httpClient.patch<void>(url, settings, { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }), observe: 'body' }).pipe(
      catchError((err) => {
        console.error(err);
        return throwError(null);
      }),
      tap(() => {
        // save to local session storage
        this.sessionStorageService.writeObject(StorageKey.userSettings, settings);
        this.userSettingsChanged.next(settings);
      })
    );
  }

  public initialize(silently: boolean = true): void {
    const url: string = `${this.usersBaseUrl}/me/settings`;
    if (silently) this.loaderService.addExcludedUrl(url);

    let sessionSettings: UserSettings | undefined = this.sessionStorageService.readObject<UserSettings>(StorageKey.userSettings);

    if (!sessionSettings) {
      this.httpClient
        .get<UserSettings>(url)
        .pipe(
          take(1),
          catchError((err) => {
            console.error(err);
            return of(null);
          })
        )
        .subscribe((settings: UserSettings | null) => {
          if (!settings) return;

          // save to local session storage
          this.sessionStorageService.writeObject(StorageKey.userSettings, settings);
          this.userSettingsChanged.next(settings);
        });
    } else {
      this.userSettingsChanged.next(sessionSettings);
    }
  }
}
