import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, ReplaySubject } from 'rxjs';
import { SettingsService } from 'src/app/core/setttings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _enabled: boolean = true;
  private _filteredUrlPatterns: RegExp[] = [];
  private _filteredHeaders: string[] = [];
  private _filteredMethods: string[] = [];
  private _excludedUrls: string[] = [];

  private _loading: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(private settingsService: SettingsService, private dialog: MatDialog) {
    this._filteredMethods = [];
    this._filteredUrlPatterns = []; // [new RegExp(this.settingsService.settings.API_URL)];
  }

  get loading(): Observable<boolean> {
    return this._loading.asObservable();
  }

  get excludedUrls(): string[] {
    return this._excludedUrls;
  }

  /**
   * Contains a list of regex which, if matched, will trigger the display of the loading spinner
   */
  get filteredUrlPatterns(): RegExp[] {
    return this._filteredUrlPatterns;
  }

  get filteredHeaders(): string[] {
    return this._filteredHeaders;
  }

  get filteredMethods(): string[] {
    return this._filteredMethods;
  }

  public show(): void {
    // console.log(`[Loader] open dialogs: ${this.dialog.openDialogs.length}, enabled: ${this._enabled}`);
    if (this.dialog.openDialogs.length === 0 && this._enabled) this._loading.next(true);
  }

  public hide(): void {
    this._loading.next(false);
  }

  public disable(): void {
    this._enabled = false;
    this.hide();
  }

  public enable(): void {
    this._enabled = true;
  }

  public addPatternFilter(pattern: RegExp): void {
    const patternString: string = pattern.toString();
    const index: number = this._filteredUrlPatterns.findIndex((patt) => patt.toString() == patternString);

    if (index === -1) this._filteredUrlPatterns.push(pattern);
  }

  public removePatternFilter(pattern: RegExp): void {
    const patternString: string = pattern.toString();
    const index: number = this._filteredUrlPatterns.findIndex((patt) => patt.toString() == patternString);

    if (index !== -1) this._filteredUrlPatterns = this._filteredUrlPatterns.splice(index);
  }

  public addExcludedUrl(url: string): void {
    if (this._excludedUrls.indexOf(url) === -1) this._excludedUrls.push(url);
  }

  public removeExcludedUrl(url: string): void {
    const index: number = this._excludedUrls.indexOf(url);
    if (index !== -1) this._excludedUrls = this._excludedUrls.splice(index);
  }

  public setExcludedMethods(methods: string[]): void {
    this._filteredMethods = methods;
  }
}
