import { FieldTemplateConfiguration } from './template-configuration-base';
import { TemplateAssetType } from './template-asset-type.enum';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';
import { GuidHelper } from '../guid/guid-helper';

export class FieldVideo extends FieldTemplateConfiguration {
  thumbnailUrl: string;
  mediaUrl: string;
  name?: string;
  blobId: string;

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);

    this.thumbnailUrl = asset?.thumbnailUrl ?? '';
    this.mediaUrl = asset?.mediaUrl ?? '';
    this.name = asset?.name ?? '';
    this.blobId = asset?.blobId ?? '';

    this.type = TemplateAssetType.Video;
  }

  override isValid(): boolean {
    return this.blobId == '' || GuidHelper.IsValid(this.blobId);
  }

  override isEmpty(): boolean {
    return this.blobId == '';
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    asset.blobId = this.blobId;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    return this.mediaUrl;
  }
}
