import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
  constructor(private titleService: Title, private translateService: TranslateService) {
    this.translateService.get('general.unauthorized.page_title').subscribe((value: string) => this.titleService.setTitle(value));
  }
}
