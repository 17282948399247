import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { DialogResult } from 'src/app/core/dialogResult';
import { MediaLibrary } from 'src/app/core/media-library/media-library';
import { MediaLibraryService } from 'src/app/core/media-library/media-library.service';
import { MediaStatus } from 'src/app/core/media-library/media-status';
import { MediaType } from 'src/app/core/media-library/media-type';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { FieldImage } from 'src/app/core/template-configuration/field-image';
import { AssetType } from 'src/app/shared/components/slide-editor/list-assets/assetType';
import { AssetRequest } from 'src/app/shared/components/slide-editor/list-assets/asset-request';
import { ListAssetsService } from 'src/app/shared/components/slide-editor/list-assets/list-assets.service';
import { MediaDropData } from 'src/app/shared/components/slide-editor/list-assets/mediaDropData';
import { MediaFormMode } from 'src/app/shared/components/media-form/media-form-mode';
import { MediaFormComponent } from 'src/app/shared/components/media-form/media-form.component';
import { MediaInputData } from 'src/app/shared/components/media-form/media-input-data';
import { NotificationService } from 'src/app/shared/components/notification/notification.service';
import { CropperData } from './dialog-configuration-mask-picture/cropper-data';
import { DialogConfigurationMaskPictureComponent } from './dialog-configuration-mask-picture/dialog-configuration-mask-picture.component';
import { MaskPictureService } from './maskPictureService';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { CropperConfiguration } from './image-cropper/imageCropperConfiguration';
import { CroppedImageData } from './image-cropper/imageDataEvent';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';
import { FileHelper } from 'src/app/core/fileHelper';

@Component({
  selector: 'app-configuration-mask-picture',
  templateUrl: './configuration-mask-picture.component.html',
  styleUrls: ['./configuration-mask-picture.component.scss'],
})
export class ConfigurationMaskPictureComponent implements OnInit, ConfigurationMaskItemComponent {
  private _originalPicture?: File;
  private _cropperConfig: CropperConfiguration;

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  public readonly step: number = 1;

  public isImageSaved: boolean = false;
  public canBeSavedToLibrary: boolean = false;
  public fileOver: boolean = false;
  public isInvalid: boolean = false;

  public get canCrop(): boolean {
    return this.data.blobFile != undefined || this._originalPicture != undefined;
  }

  @Input() public data: FieldImage;
  @ViewChild('input') public fileInput: ElementRef<HTMLInputElement>;

  constructor(
    private maskPictureService: MaskPictureService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private pictureLibraryService: MediaLibraryService,
    private listAssetsService: ListAssetsService
  ) {}

  public ngOnInit(): void {
    this._cropperConfig = new CropperConfiguration(this.data.minWidth, this.data.minHeight);
    this._cropperConfig.autoCrop = false;
    this._cropperConfig.showControl = true;
  }

  public openMediaForm(): void {
    const dialogRef: MatDialogRef<MediaFormComponent, DialogResult<MediaLibrary[]>> = this.dialog.open(MediaFormComponent, {
      disableClose: true,
      position: {
        top: '0',
        right: '0',
      },
      panelClass: this.settingsService.cssSideSheetPanelClass,
      data: new MediaInputData(MediaFormMode.Save, new MediaLibrary(MediaType.Picture, this._originalPicture!, Guid.createEmpty().toString(), MediaStatus.Available, true)),
    });
    dialogRef.afterClosed().subscribe((result: DialogResult<MediaLibrary[]> | undefined) => {
      this.isImageSaved = result !== undefined && result.confirm === ConfirmResult.Yes;

      if (this.isImageSaved) {
        this._originalPicture = undefined;
        this.maskPictureService.sendPictureSaved(result!.entity!);
      }
    });
  }

  public fileChangeEvent(event: Event): void {
    // extract files list and get only the first file
    const files: FileList | null = (<HTMLInputElement>event.target).files;

    if (!files || files.length == 0) return;

    this.selectFile(files[0], false);
  }

  public onFileDropped(files: FileList): void {
    if (!files || files.length == 0) return;

    this.selectFile(files[0]);
  }

  public onAssetSelected(mediaImageDropData: MediaDropData): void {
    if (mediaImageDropData.mediaType !== AssetType.Picture) return;

    this.pictureLibraryService.getMediaAsset(mediaImageDropData.blobUrl).subscribe(
      (asset: Blob) => {
        this.selectFile(asset as File);
      },
      (err: Error) => this.notificationService.displayError(err.message)
    );
  }

  /**
   * Action exécutée après la sélection d'un fichier image (blob) via l'une des actions suivantes :
   *    - drag-and-drop de fichier,
   *    - drag-and-drop d'asset (library),
   *    - sélection d'asset (library),
   *    - clic sur le bouton de sélection de fichier à partir du disque.
   * @param file
   * @returns
   */
  private selectFile(file: File, fromLibrary: boolean = true): void {
    if (file.type != 'image/jpeg' && file.type != 'image/png') return;

    this.canBeSavedToLibrary = true;
    this._originalPicture = file;

    this.openCropper(file, fromLibrary);
  }

  public openCropper(file?: File, fromLibrary?: boolean): void {
    const dialogConf: MatDialogConfig = new MatDialogConfig();

    if (!file) {
      if (this._originalPicture) file = this._originalPicture;
      else if (this.data.blobFile) file = this.data.blobFile;
      else return;
    }

    dialogConf.data = new CropperData(file, this._cropperConfig);
    dialogConf.height = '90vh';
    dialogConf.width = '70vw';

    const dialogRef: MatDialogRef<DialogConfigurationMaskPictureComponent, DialogResult<CroppedImageData>> = this.dialog.open(DialogConfigurationMaskPictureComponent, dialogConf);

    dialogRef.afterClosed().subscribe((res: DialogResult<CroppedImageData> | undefined) => {
      if (res && res.entity && res.confirm === ConfirmResult.Yes) {
        this.data.mediaUrl = res.entity.croppedImage;
        this.data.blobFile = res.entity.file;

        this.data.fromLibrary = fromLibrary ?? this.data.fromLibrary;

        // conversion en base 64 utilisée pour transmettre l'image à l'aperçu de la slide (slide.component.ts)
        FileHelper.fileToBase64(res.entity.file).subscribe((dataUrl) => {
          this.data.blobDataUrl = dataUrl;

          this.isInvalid = false;

          this.outputValue.next(this.data);
          this.outputFocus.next(this.data);
        });
      }
    });
  }

  public deletePicture(): void {
    if (this.data.mandatory) {
      this.isInvalid = true;
    }

    this.data.mediaUrl = '';
    this.data.blobDataUrl = '';
    this.data.blobFile = undefined;
    this.data.blobId = '';
    this.fileInput.nativeElement.value = '';
    this._originalPicture = undefined;

    this.canBeSavedToLibrary = false;
    this.isImageSaved = false;

    this.outputFocus.next(this.data);
    this.outputValue.next(this.data);
  }

  public openAssets(): void {
    this.listAssetsService.listAssets(new AssetRequest(AssetType.Picture, this.data.id));
  }
}
