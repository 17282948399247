<div class="row" *ngIf="data">
  <mat-form-field>
    <mat-label>{{ data.title }}</mat-label>
    <mat-select [required]="data.mandatory" [(value)]="valueSelected" (selectionChange)="onChange($event)">
      <mat-option *ngFor="let item of data.options" [value]="item.value">
        {{ item.key }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
