/*
$indigo-palette: (
 50: #e8eaf6,
 100: #c5cae9,
 200: #9fa8da,
 300: #7986cb,
 // ... continues to 900
 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: white,
   // ... continues to 900
 )
);
*/

export class Palette {
  tint50: string;
  tint100: string;
  tint200: string;
  tint300: string;
  tint400: string;
  tint500: string;
  tint600: string;
  tint700: string;
  tint800: string;
  tint900: string;
}

export class MaterialPalette extends Palette {
  contrast: Palette;

  constructor() {
    super();
    this.contrast = new Palette();
  }
}
