import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FieldUrl } from 'src/app/core/template-configuration/field-url';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';

@Component({
  selector: 'app-configuration-mask-url',
  templateUrl: './configuration-mask-url.component.html',
  styleUrls: ['./configuration-mask-url.component.scss'],
})
export class ConfigurationMaskUrlComponent implements ConfigurationMaskItemComponent {
  @Input() public data: FieldUrl;

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  public get isValid(): boolean {
    return this.data.isValid();
  }

  public onChange(): void {
    if (this.data.isValid()) this.outputValue.next(this.data);
  }

  public onFocusOut(): void {
    if (this.data.isValid()) this.outputFocus.next(this.data);
  }
}
