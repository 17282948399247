<button mat-icon-button [matMenuTriggerFor]="filterMenu" (menuOpened)="onOpen()" class="funnel">
  <mat-icon [svgIcon]="filterIcon"></mat-icon>
</button>
<mat-menu #filterMenu="matMenu" xPosition="before">
  <div class="row" (click)="$event.stopPropagation()">
    <mat-form-field (click)="$event.stopPropagation()">
      <input #searchfield="matInput" matInput="search" placeholder="Filtre" [(ngModel)]="filterInput" (keyup.enter)="onValidation()" name="filter" />
    </mat-form-field>
    <button mat-icon-button aria-label="Search" (click)="onValidation()">
      <mat-icon>search</mat-icon>
    </button>
    <button mat-icon-button aria-label="Clear" class="close" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-menu>
