import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';
import { TemplateAssetType } from './template-asset-type.enum';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';

export abstract class FieldTemplateConfiguration implements IFieldTemplateConfiguration {
  type: TemplateAssetType;
  id: string;
  title: string;
  mandatory: boolean;
  technicalId: string;
  assetId: number;

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    this.id = field.id;
    this.mandatory = field.mandatory;
    this.technicalId = field.technicalId;
    this.title = field.title;
    this.assetId = asset?.id ?? 0;
  }

  abstract isValid(): boolean;
  abstract isEmpty(): boolean;

  getSlideAsset(slideId: number): SlideAsset {
    return new SlideAsset(slideId, this.id, this.assetId, this.type, this.technicalId);
  }

  abstract getPreviewValue(): string | string[] | null;
}

export interface IFieldTemplateConfiguration {
  assetId: number;
  type: TemplateAssetType;
  id: string;
  title: string;
  mandatory: boolean;
  technicalId: string;

  isValid(): boolean;
  isEmpty(): boolean;
  getSlideAsset(slideId: number): SlideAsset;

  /**
   * this is the value which must be sent to the preview iframe
   */
  getPreviewValue(): string | string[] | null;
}
