import { MediaLibrary } from 'src/app/core/media-library/media-library';
import { MediaFormMode } from './media-form-mode';

export class MediaInputData {
  public mediaLibrary?: MediaLibrary;
  public mode: MediaFormMode;

  constructor(mode: MediaFormMode, media?: MediaLibrary) {
    this.mediaLibrary = media;
    this.mode = mode;
  }
}
