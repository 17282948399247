import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { AudienceConflictDetails, HttpError } from '../core/httpError';
import { LiveEvent } from './liveEvent';
import { createFormData } from '../core/formHelper';

@Injectable({
  providedIn: 'root',
})
export class LiveEventService {
  private baseUrl: string = `${this.settingsService.settings.API_URL}/liveevent`;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {}

  /**
   * Get all LiveEvents
   */
  public get(): Observable<LiveEvent[]> {
    return this.httpClient.get<LiveEvent[]>(`${this.baseUrl}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('live_events.error.get');
        return throwError(err);
      })
    );
  }
  /**
   * Get LiveEvent By Id
   */
  public getById(id: string): Observable<LiveEvent> {
    return this.httpClient.get<LiveEvent>(`${this.baseUrl}/${id}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('live_events.error.get', {
          id: id,
        });
        return throwError(err);
      })
    );
  }

  /**
   * Delete a LiveEvent
   */
  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('live_events.error.delete');
        return throwError(err);
      })
    );
  }

  /**
   * Send a LiveEvent
   */
  public save(liveEvent: LiveEvent): Observable<LiveEvent> {
    const form: FormData = createFormData(liveEvent);
    return liveEvent.id > 0 ? this.put(liveEvent.id, form) : this.post(form);
  }

  private post(formData: FormData): Observable<LiveEvent> {
    return this.httpClient.post<LiveEvent>(`${this.baseUrl}`, formData, { observe: 'body' }).pipe(
      catchError((err) => {
        return this.handleSaveError(err, 'live_events.error.post');
      })
    );
  }

  private put(id: number, formData: FormData): Observable<LiveEvent> {
    return this.httpClient.put<LiveEvent>(`${this.baseUrl}/${id}`, formData, { observe: 'body' }).pipe(
      catchError((err) => {
        return this.handleSaveError(err, 'live_events.error.put');
      })
    );
  }

  private handleSaveError(err: HttpError<AudienceConflictDetails>, defaultMsg: string): Observable<never> {
    let message: string;
    if (err.status === 409)
      message = this.translateService.instant('live_events.error.conflict', { audiences: err.error.details.Audiences, creatorName: err.error.details.CreatorName });
    else message = this.translateService.instant(defaultMsg);

    err.message = message;
    return throwError(err);
  }
}
