import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsService } from '../setttings/settings.service';
import { GoogleBuilding } from './google-building';
import { GoogleMeetingRoom } from './google-meeting-room';

@Injectable({
  providedIn: 'root',
})
export class GoogleWorkspaceService {
  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {
    this.baseUrl = `${this.settingsService.settings.API_URL}/reservations/google`;
  }
  private baseUrl: string;

  public getMeetingRooms(buildingId: number): Observable<GoogleMeetingRoom[]> {
    return this.httpClient.get<GoogleMeetingRoom[]>(`${this.baseUrl}/buildings/${buildingId}/meetingrooms`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('googleworkspace.error.get_resources');
        return throwError(err);
      })
    );
  }

  public getBuildings(): Observable<GoogleBuilding[]> {
    return this.httpClient.get<GoogleBuilding[]>(`${this.baseUrl}/buildings`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('googleworkspace.error.get_resources');
        return throwError(err);
      })
    );
  }
}
