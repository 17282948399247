export enum StorageKey {
  selectedFleet = 'admin_fleet#',
  userData = 'userData',
  lastUrl = 'lastUrl',
  userFleet = 'userFleet',
  redirectUrl = 'redirectUrl',
  lastUrlBeforeMaskEdition = 'lastUrlBeforeMaskEdition',
  userSettings = 'userSettings',
  mediaPlayerRegistrationToken = 'mediaPlayerRegistrationToken',
}
