import { Guid } from 'guid-typescript';
import { CodecData } from './codec-data';
import { MediaStatus } from './media-status';
import { MediaType } from './media-type';
import { Permission } from './permission';
import { Tag } from './tag';

export class MediaLibrary {
  public id: number;
  public name: string;
  public blobId: string;
  public blobUrl: string;
  public blobThumbnailUrl: string;
  public created: Date;
  public isFavorite: boolean;
  public permissions: Permission;
  public tags: Tag[];
  public type: MediaType;
  public status: MediaStatus;
  public validityEndDate: Date | null;
  public isMine: boolean;
  public value: File | null;
  public copyright: string;
  public createdBy: string;
  public codecData: CodecData | null;
  public fleetId: Guid | null;
  public width: number;
  public height: number;

  constructor(type: MediaType, value: File, blobId: string, status: MediaStatus, isMine: boolean = false) {
    this.id = 0;
    this.type = type;
    this.status = status;
    this.blobId = blobId;
    this.value = value;
    this.permissions = Permission.Private;
    this.isMine = isMine;
  }
}
