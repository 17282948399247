import { AfterContentInit, Component, ComponentRef, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { finalize, share } from 'rxjs/operators';
import { DynamicData } from 'src/app/core/dynamic-data/dynamic-data';
import { DynamicDataService } from 'src/app/core/dynamic-data/dynamic-data.service';
import { TemplateAssetType } from 'src/app/core/template-configuration/template-asset-type.enum';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { NotificationService } from 'src/app/shared/components/notification/notification.service';
import { ConfigurationMaskItem } from './configuration-mask-item';
import { ConfigurationMaskItemComponent } from './configuration-mask-item.component';
import { ConfigurationMaskDirective } from './configuration-mask.directive';
import { MaskLayoutService } from 'src/app/mask-edition/mask-layout/mask-layout.service';
import { Slide } from '../slide/slide';
import { SlideAsset } from '../slide/slide-asset';
import { SlideAssetService } from '../slide/slide-asset.service';
import { ConfigurationMaskChannelComponent } from '../configuration-mask-channel/configuration-mask-channel';
import { ConfigurationMaskPictureComponent } from '../configuration-mask-picture/configuration-mask-picture.component';
import { ConfigurationMaskSelectComponent } from '../configuration-mask-select/configuration-mask-select.component';
import { ConfigurationMaskTextComponent } from '../configuration-mask-text/configuration-mask-text';
import { ConfigurationMaskUrlComponent } from '../configuration-mask-url/configuration-mask-url.component';
import { ConfigurationMaskVideoComponent } from '../configuration-mask-video/configuration-mask-video.component';
import { Asset } from '../list-assets/asset';
import { MediaDropData } from '../list-assets/mediaDropData';
import { ConfigurationMaskVideoListComponent } from '../configuration-mask-video-list/configuration-mask-video-list.component';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';
import { ConfigurationMaskImageListComponent } from '../configuration-mask-image-list/configuration-mask-image-list.component';
import { ConfigurationMaskTextListComponent } from '../configuration-mask-text-list/configuration-mask-text-list.component';
import { ConfigurationMaskTableComponent } from '../configuration-mask-table/configuration-mask-table.component';

/**
 * Ce composant gère tout le panneau de configuration des masques (situé à droite de l'écran dans l'édition de masque - étape 2)
 */
@Component({
  selector: 'app-configuration-mask',
  templateUrl: './configuration-mask.component.html',
  styleUrls: ['./configuration-mask.component.scss'],
})
export class ConfigurationMaskComponent implements AfterContentInit {
  @ViewChild(ConfigurationMaskDirective, { static: true }) public configurationMaskHost: ConfigurationMaskDirective;
  @Output() public dataChanged: EventEmitter<Object> = new EventEmitter<Object>();

  private viewContainerRef: ViewContainerRef;
  private items: ConfigurationMaskItem<ConfigurationMaskItemComponent>[];
  private components: ComponentRef<ConfigurationMaskItemComponent>[];
  private slide: Slide;
  private enumsTemplateAssetType: typeof TemplateAssetType = TemplateAssetType;
  private loaders: Map<string, boolean> = new Map<string, boolean>();
  private dataList: DynamicData[];

  constructor(
    private loaderService: LoaderService,
    private layoutService: MaskLayoutService,
    private slideAssetService: SlideAssetService,
    private dynamicDataService: DynamicDataService,
    private notificationService: NotificationService
  ) {}

  public ngAfterContentInit(): void {
    this.viewContainerRef = this.configurationMaskHost.viewContainerRef;
  }

  // public ngOnInit(): void {
  //   /** TODO : attention, cette donnée arrive parfois trop tard et fait planter le formulaire de configuration! */

  // }

  public initConfiguration(slide: Slide): void {
    this.refreshConfiguration();
    this.loaders = new Map<string, boolean>();
    this.items = [];
    this.components = [];
    this.slide = slide;
    this.dynamicDataService
      .get()
      .pipe(share())
      .subscribe((data) => {
        this.dataList = data;

        this.slide.slideTemplate?.fields.forEach((field) => {
          let item: ConfigurationMaskItem<ConfigurationMaskItemComponent> | undefined = undefined;

          switch (field.type) {
            case TemplateAssetType.Text:
              item = new ConfigurationMaskItem(ConfigurationMaskTextComponent, field);
              break;
            case TemplateAssetType.Select:
              item = new ConfigurationMaskItem(ConfigurationMaskSelectComponent, field);
              break;
            case TemplateAssetType.Image:
              item = new ConfigurationMaskItem(ConfigurationMaskPictureComponent, field);
              break;
            case TemplateAssetType.Video:
              item = new ConfigurationMaskItem(ConfigurationMaskVideoComponent, field);
              break;
            case TemplateAssetType.Channel:
              item = new ConfigurationMaskItem(ConfigurationMaskChannelComponent, field);
              break;
            case TemplateAssetType.Url:
              item = new ConfigurationMaskItem(ConfigurationMaskUrlComponent, field);
              break;
            case TemplateAssetType.VideoList:
              item = new ConfigurationMaskItem(ConfigurationMaskVideoListComponent, field);
              break;
            case TemplateAssetType.ImageList:
              item = new ConfigurationMaskItem(ConfigurationMaskImageListComponent, field);
              break;
            case TemplateAssetType.TextList:
              item = new ConfigurationMaskItem(ConfigurationMaskTextListComponent, field);
              break;
            case TemplateAssetType.Table:
              item = new ConfigurationMaskItem(ConfigurationMaskTableComponent, field);
              break;
            default:
              break;
          }

          // save item for future usage
          if (item) this.items.push(item);
          else return;

          const componentRef: ComponentRef<ConfigurationMaskItemComponent> = this.viewContainerRef.createComponent(item.component);
          this.components.push(componentRef);

          (<ConfigurationMaskItemComponent>componentRef.instance).data = item.data;
          (<ConfigurationMaskItemComponent>componentRef.instance).outputValue.subscribe((field: IFieldTemplateConfiguration) => {
            this.emitDataChanged(field);
          });

          (<ConfigurationMaskItemComponent>componentRef.instance).outputFocus.subscribe((field: IFieldTemplateConfiguration) => {
            this.saveSlideAsset(field);
          });

          this.emitDataChanged(item.data);
          this.setLoadingItem(item.data.id, false);
        });
      });
  }

  private setLoadingItem(id: string, isWorking: boolean): void {
    this.loaders.set(id, isWorking);

    // Check if something is currently loading
    this.layoutService.setIsWorking(Array.from(this.loaders.values()).includes(true));
  }

  private emitDataChanged(field: IFieldTemplateConfiguration): void {
    switch (field.type) {
      case TemplateAssetType.ImageList:
      case TemplateAssetType.Image:
      case TemplateAssetType.Channel:
      case TemplateAssetType.Video:
      case TemplateAssetType.VideoList:
        // not looking for dynamic data variables here
        this.buildAndEmitData(field.id, field.type, field.getPreviewValue());
        break;
      default:
        this.buildAndEmitData(field.id, field.type, this.replaceDynamicDataVariables(field.getPreviewValue()));
        break;
    }
  }

  // this regex looks up for variables which need to be replaced inside string values (E.g. "This is ${myvariable} embedded into a string" will extract ${myvariable})
  private static variableSearch: RegExp = /\$\{([a-z0-9]*)\}/gi;

  /**
   * Replaces all the '$$variable' strings by the actual variable value from dynamic data
   */
  private replaceDynamicDataVariables(stringTemplate: string | string[] | null): string | string[] | null {
    if (!stringTemplate) return null;

    if (Array.isArray(stringTemplate)) {
      return stringTemplate.map((tpl) => this.replaceDynamicDataVariablesInString(tpl));
    } else {
      return this.replaceDynamicDataVariablesInString(stringTemplate);
    }
  }

  private replaceDynamicDataVariablesInString(stringTemplate: string): string {
    /** TODO : attention, cette donnée arrive parfois trop tard et fait planter le formulaire de configuration! */
    return stringTemplate.replaceAll(ConfigurationMaskComponent.variableSearch, (variableName) => {
      const variable: DynamicData | undefined = this.dataList.find((d) => d.name == variableName.substring(2, variableName.length - 1));

      if (variable) return variable.value;
      else return variableName;
    });
  }

  private buildAndEmitData(id: string, type: TemplateAssetType, value: string | string[] | null): void {
    if (!value) return;

    var data: Object = {
      method: 'updateElement',
      param: {
        elementId: id,
        type: this.enumsTemplateAssetType[type].toLowerCase(),
        value: value,
      },
    };

    this.dataChanged.emit(data);
  }

  private saveSlideAsset(field: IFieldTemplateConfiguration): void {
    if (!field.isValid()) return;

    let assetPictureToDelete: SlideAsset | undefined = undefined;

    if (field.type === TemplateAssetType.Image) {
      let indexToDelete: number = this.slide.assets.findIndex((x) => x.technicalId.toString().toLowerCase() === field.technicalId.toString().toLowerCase());
      if (indexToDelete !== -1) {
        assetPictureToDelete = this.slide.assets[indexToDelete];
        this.slide.assets.splice(indexToDelete, 1);
      }
    }

    // initialize slide asset DTO
    const asset: SlideAsset = field.getSlideAsset(this.slide.id);

    // search for existing asset index
    let currentAssetIndex: number = this.slide.assets.findIndex((x) => x.technicalId.toString().toLowerCase() === field.technicalId.toString().toLowerCase());

    // insert if not exists; otherwise, replace
    this.slide.assets.splice(currentAssetIndex, 1, asset);

    this.setLoadingItem(asset.htmlId, true);

    this.slideAssetService
      .send(asset)
      .pipe(finalize(() => this.loaderService.enable()))
      .subscribe(
        (savedAsset) => {
          asset.id = savedAsset.id;
          field.assetId = savedAsset.id;

          this.setLoadingItem(savedAsset.htmlId, false);
        },
        (err) => this.notificationService.displayError(err.message)
      );
  }

  // Efface l'affichage du masque en cours
  public refreshConfiguration(): void {
    this.viewContainerRef.clear();
  }

  /**
   * Called by the Slide Editor to trigger an asset selection behaviour on the slide configuration panel
   * @param slideAssetId
   * @param asset
   */
  public selectAsset(asset: Asset, slideAssetId: string, additionalData?: any): void {
    const component: ComponentRef<ConfigurationMaskItemComponent> = this.components.find((x) => x.instance.data.id == slideAssetId)!;

    // build the data to pass to the slide asset configuration component
    const droppedMediaData: MediaDropData = new MediaDropData(asset.type, asset.blobId, asset.blobUrl, asset.thumbnailUrl, asset.name, additionalData);

    switch (component.instance.data.type) {
      case TemplateAssetType.Image:
        (<ConfigurationMaskPictureComponent>(<unknown>component.instance)).onAssetSelected(droppedMediaData);
        break;
      case TemplateAssetType.Video:
        (<ConfigurationMaskVideoComponent>(<unknown>component.instance)).onAssetSelected(droppedMediaData);
        break;
      case TemplateAssetType.VideoList:
        (<ConfigurationMaskVideoListComponent>(<unknown>component.instance)).onAssetSelected(droppedMediaData);
        break;
      case TemplateAssetType.ImageList:
        (<ConfigurationMaskImageListComponent>(<unknown>component.instance)).onAssetSelected(droppedMediaData);
        break;
    }
  }
}
