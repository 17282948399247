import { NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CUSTOM_MAT_DATE_FORMAT } from 'src/app/core/customMatDateFormat';
import { Language } from 'src/app/core/users/user-settings';
import { UserSettingsService } from '../services/user-settings.service';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  exports: [
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTooltipModule,
    MatTreeModule,
    MatBadgeModule,
    MatRippleModule,
    MatFormFieldModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MAT_DATE_FORMAT },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class MaterialModule {
  private settingsChanged$: Subscription;

  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, userSettingsService: UserSettingsService, dateAdapter: DateAdapter<Date>) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./mdi.svg'));

    // listen for user settings changes in case the language must be dynamically updated
    this.settingsChanged$ = userSettingsService.userSettingsChanged.subscribe((settings) => {
      let lang: string = settings ? Language[settings.language].toLowerCase() : 'en';

      switch (lang) {
        case 'en':
          // we want to get the dd/MM/YYYY style instead of the US date style for now
          lang = 'en-GB';
          break;
        case 'fr':
        default:
          lang = 'fr-FR';
          break;
      }

      dateAdapter.setLocale(lang);
    });

    dateAdapter.setLocale(Language[userSettingsService.language]);
  }
}
