import { Pipe, PipeTransform } from '@angular/core';
import { EnumHelper } from 'src/app/core/enumHelper';
import { Role } from 'src/app/core/guards/role';

@Pipe({
  name: 'rolesToKeyValues',
})
export class RolesToKeyValuesPipe implements PipeTransform {
  transform(roles: Role): string[] {
    return EnumHelper.getEnumValuesFromFlag(Role, roles).map((role) => getRoleName(role));
  }
}

const getRoleName = (role: Role): string => {
  if (!role) return '';

  return `roles.${Role[role]?.toLowerCase()}`;
};
