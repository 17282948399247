// any types is needed here to support all types of enums.

import { ScreenFormat } from './enums/screen-format.enum';

export class EnumHelper {
  // tslint:disable-next-line:no-any
  public static enumToRecordWithKeys<E extends any>(enumeration: E): E {
    return (
      // @ts-ignore
      Object.keys(enumeration)
        // @ts-ignore
        .filter((key) => typeof enumeration[key as keyof Object] === 'string')
        // @ts-ignore
        .reduce((record, key) => ({ ...record, [key]: enumeration[key] }), {}) as E
    );
  }

  public static getEnumValuesFromFlag<K extends any, E>(enumeration: K, flag: E): E[] {
    return (
      // @ts-ignore
      Object.keys(enumeration)
        // @ts-ignore
        .filter((key) => (flag & key) == key)
        // @ts-ignore
        .map((key) => key as E)
    );
  }

  public static highestFlag<E extends number>(flag: E): E {
    return EnumHelper.highestBit(flag as number) as E;
  }

  public static lowestFlag<E extends number>(flag: E): E {
    return EnumHelper.lowestBit(flag as number) as E;
  }

  private static lowestBit(x: number): number {
    return ~(x & (x - 1)) & x;
  }

  private static highestBit(x: number): number {
    let last: number = x;

    while (x != 0) {
      last = x;
      x &= x - 1;
    }

    return last;
  }

  public static getIFrameClass(screenFormat: ScreenFormat): string {
    switch (screenFormat) {
      case ScreenFormat.Landscape_16_9:
        return 'preview-landscape';
      case ScreenFormat.Portrait_9_16:
        return 'preview-portrait';
      case ScreenFormat.Stretch_Vertical:
        return 'preview-vertical-stretch';
      default:
        return '';
    }
  }
}
