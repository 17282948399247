<form [formGroup]="planForm" (keydown.enter)="$event.preventDefault()">
  <div class="description">{{ 'mask.schedule.start_date_description' | translate }}</div>

  <mat-form-field id="large" class="date-time-field">
    <input matInput [matDatepicker]="pickerStart" [min]="today" formControlName="startDate" (click)="pickerStart.open()" readonly />
    <mat-datepicker #pickerStart color="primary"></mat-datepicker>
  </mat-form-field>

  <div class="description">{{ 'mask.schedule.time_slot' | translate }}</div>

  <mat-form-field id="medium" class="date-time-field">
    <input matInput [ngxTimepicker]="timePickStart" [format]="24" formControlName="startTime" readonly />
    <ngx-material-timepicker
      [appendToInput]="true"
      #timePickStart
      [theme]="timePickerTheme"
      [enableKeyboardInput]="true"
      [editableHintTmpl]="dialHint"
      [cancelBtnTmpl]="cancel"
    ></ngx-material-timepicker>
  </mat-form-field>

  <span>{{ 'mask.schedule.until' | translate }}</span>

  <mat-form-field id="medium" class="date-time-field">
    <input matInput [ngxTimepicker]="timePickEnd" [format]="24" formControlName="endTime" readonly />
    <ngx-material-timepicker
      [appendToInput]="true"
      #timePickEnd
      [theme]="timePickerTheme"
      [enableKeyboardInput]="true"
      [editableHintTmpl]="dialHint"
      [cancelBtnTmpl]="cancel"
    ></ngx-material-timepicker>
  </mat-form-field>

  <div *ngIf="!isLiveEvent">
    <div class="description">{{ 'mask.schedule.periodicity' | translate }}</div>

    <mat-form-field id="extra-large" class="repetition-field">
      <mat-select formControlName="repetition" required>
        <mat-option *ngFor="let rep of repetitions" [value]="rep.id" [title]="rep.value">
          {{ rep.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="planForm.controls['repetition'].value === repetitionTypeEnum.Weekly" class="weekdays-field">
      <label>{{ 'mask.schedule.weekdays_title' | translate }}</label>
      <mat-checkbox value="2" (change)="onWeekDayChecked($event)" [checked]="isDayChecked(2)">{{ 'common.week.monday' | translate }}</mat-checkbox>
      <mat-checkbox value="4" (change)="onWeekDayChecked($event)" [checked]="isDayChecked(4)">{{ 'common.week.tuesday' | translate }}</mat-checkbox>
      <mat-checkbox value="8" (change)="onWeekDayChecked($event)" [checked]="isDayChecked(8)">{{ 'common.week.wednesday' | translate }}</mat-checkbox>
      <mat-checkbox value="16" (change)="onWeekDayChecked($event)" [checked]="isDayChecked(16)">{{ 'common.week.thursday' | translate }}</mat-checkbox>
      <mat-checkbox value="32" (change)="onWeekDayChecked($event)" [checked]="isDayChecked(32)">{{ 'common.week.friday' | translate }}</mat-checkbox>
      <mat-checkbox value="64" (change)="onWeekDayChecked($event)" [checked]="isDayChecked(64)">{{ 'common.week.saturday' | translate }}</mat-checkbox>
      <mat-checkbox value="128" (change)="onWeekDayChecked($event)" [checked]="isDayChecked(128)">{{ 'common.week.sunday' | translate }}</mat-checkbox>
    </div>

    <div *ngIf="planForm.controls['repetition'].value !== repetitionTypeEnum.Once">
      <label>{{ 'mask.schedule.end_repeat' | translate }}</label>
      <div class="main">
        <mat-radio-group formControlName="repetitionEndType" class="column">
          <mat-radio-button [value]="repetitionEndType.Never">
            {{ this.repetitionEndTypeRadioButton[repetitionEndType.Never] }}
          </mat-radio-button>
          <mat-radio-button [value]="repetitionEndType.Date">
            {{ this.repetitionEndTypeRadioButton[repetitionEndType.Date] }}
            <mat-form-field id="large" class="date-time-field">
              <input
                matInput
                [matDatepicker]="pickerEndRepeat"
                [min]="planForm.controls['startDate'].value"
                formControlName="repetitionEndDate"
                (click)="pickerEndRepeat.open()"
                readonly
              />
              <mat-datepicker #pickerEndRepeat color="primary"></mat-datepicker>
            </mat-form-field>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</form>

<ng-template #dialHint>
  <p class="dialHint">{{ 'mask.schedule.hint_time' | translate }}</p>
</ng-template>
<ng-template #cancel>
  <!-- Empty template used to remove cancel button on timepicker -->
</ng-template>
