import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatMenu } from '@angular/material/menu';
import { ColumnPredicate } from './column-predicate';
import { Operator } from './operator';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  constructor() {
    this.filterIcon = 'filter-outline';
    this.filterInput = '';
  }

  private static includeOperator: Operator = {
    name: 'Include',
    predicate: (operand, value) => ((value as string).trim ? value.trim().toLowerCase().includes(operand.trim().toLowerCase()) : false),
  };

  private static identityOperator: Operator = {
    name: 'Identity',
    predicate: (operand, value) => true,
  };

  @ViewChild('filterMenu', { static: true }) private matMenu: MatMenu;
  @ViewChild('searchfield') private searchField: MatInput;
  @Input() public columnName: string;
  @Input() public value: string;
  @Output() public predicateEmitter: EventEmitter<ColumnPredicate> = new EventEmitter<ColumnPredicate>();

  public filterIcon: string;
  public filterInput: string;
  private selectedOperator: Operator;

  public ngOnInit(): void {
    this.selectedOperator = FilterComponent.identityOperator;
    if (this.value && this.columnName) {
      this.filterInput = this.value;
      this.onValidation();
    }
  }

  public onOpen(): void {
    console.log('[FilterComponent] filter opening');
    this.searchField.focus();
  }

  public onClose(): void {
    this.filterIcon = 'filter-outline';
    this.filterInput = '';
    this.applyPredicate(FilterComponent.identityOperator);
  }

  public onValidation(): void {
    if (!this.filterInput) return;

    this.filterIcon = 'filter';
    this.applyPredicate(FilterComponent.includeOperator);
  }

  private applyPredicate(operator: Operator): void {
    this.selectedOperator = operator;
    this.predicateEmitter.emit(this.generatePredicate());
    this.matMenu.closed.emit();
  }

  private generatePredicate(): ColumnPredicate {
    return {
      name: this.columnName,
      value: this.filterInput,
      predicate: (value) => this.selectedOperator!.predicate!.apply(undefined, [this.filterInput, value.toString()]),
    };
  }
}
