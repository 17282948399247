<app-header [headerData]="headerData" (execRedirectOnUser)="redirectToUserRoleHomepage()" (toggleSidebar)="sidenav.toggle()"></app-header>
<mat-sidenav-container class="main-container">
  <mat-sidenav #sidenav [opened]="!isHandset" [mode]="isHandset ? 'over' : 'side'" [disableClose]="!isHandset">
    <app-sidebar (navigating)="navigating()" [isVisible]="isSidebarVisible" [userData]="userData" [url]="url" (execAddMask)="addMask($event)"></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <main
      role="main"
      (scroll)="onScroll($event)"
      [class]="{ 'handset-portrait': isHandsetPortrait, 'handset-landscape': isHandsetLandscape, desktop: isDesktop, handset: isHandset }"
    >
      <app-loader-overlay></app-loader-overlay>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
