import { Component, Input, OnInit } from '@angular/core';
import { TooltipData } from '../tooltip/tooltip-data';

@Component({
  selector: 'app-list-chips',
  templateUrl: './list-chips.component.html',
  styleUrls: ['./list-chips.component.scss'],
})
export class ListChipsComponent implements OnInit {
  @Input() public items: string[] | null;

  /**
   * Maximum number of chips
   */
  @Input() public maxChip: number;
  public showTooltip: boolean;
  public more: TooltipData[];

  public ngOnInit(): void {
    this.showTooltip = this.items !== null && this.items !== undefined && this.items.length > this.maxChip;
    this.more =
      this.items?.slice(this.maxChip).map(
        (item) =>
          ({
            text: item,
          } as TooltipData)
      ) ?? [];
  }

  public showTooltipEvent(event: Event): void {
    const currentElement: HTMLElement = (event.target as HTMLElement).parentElement!;
    if (currentElement.classList.contains('active')) {
      currentElement.classList.remove('active');
      return;
    }

    currentElement.classList.add('active');
  }

  public hideTooltipEvent(event: FocusEvent): void {
    const currentElement: HTMLElement = event.target as HTMLElement;
    currentElement.parentElement!.classList.remove('active');
  }
}
