import { FieldTemplateConfiguration } from './template-configuration-base';
import { TemplateAssetType } from './template-asset-type.enum';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';
import { GuidHelper } from '../guid/guid-helper';

export class FieldImage extends FieldTemplateConfiguration {
  minHeight: number;
  minWidth: number;

  mediaUrl: string;

  blobId: string;
  blobDataUrl?: string;
  blobFile?: File;

  fromLibrary: boolean = true;

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);
    this.type = TemplateAssetType.Image;

    this.minHeight = field.minHeight!;
    this.minWidth = field.minWidth!;

    this.mediaUrl = asset?.mediaUrl ?? '';
    this.blobId = asset?.blobId ?? '';
  }

  override isValid(): boolean {
    return this.blobId == '' || GuidHelper.IsValid(this.blobId);
  }

  override isEmpty(): boolean {
    return this.blobId == '';
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    if (this.blobFile && this.blobId == '') this.blobId = crypto.randomUUID();

    asset.blobId = this.blobId;
    asset.blobFile = this.blobFile;
    asset.mediaUrl = this.blobDataUrl ?? '';
    asset.fromLibrary = this.fromLibrary;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    return this.blobDataUrl ?? this.mediaUrl;
  }
}
