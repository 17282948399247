import { Directive, HostBinding, HostListener, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AssetRequest } from '../components/slide-editor/list-assets/asset-request';
import { Asset } from '../components/slide-editor/list-assets/asset';
import { MediaDropData } from '../components/slide-editor/list-assets/mediaDropData';

@Directive({
  selector: '[appDropFile]',
})
export class DropDirective {
  private _fileSubject: Subject<FileList> = new Subject<FileList>();
  private _assetSubject: Subject<MediaDropData> = new Subject<MediaDropData>();

  @HostBinding('class.fileover') public fileOver: boolean;
  @Output() public fileDropped: Observable<FileList> = this._fileSubject.asObservable();
  @Output() public assetDropped: Observable<MediaDropData> = this._assetSubject.asObservable();
  @Input() public canDrop: boolean;

  @HostListener('dragover', ['$event']) public onDragOver(ev: Event): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (this.canDrop) this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(ev: Event): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (this.canDrop) this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(ev: DragEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (!this.canDrop) return;

    this.fileOver = false;

    const files: FileList | undefined = ev.dataTransfer?.files;

    if (files && files.length > 0) {
      this._fileSubject.next(files);
    } else {
      const data: MediaDropData = JSON.parse(ev.dataTransfer?.getData('text/plain')!) as MediaDropData;
      if (data) {
        this._assetSubject.next(data);
      }
    }
  }
}
