import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { DialogResult } from 'src/app/core/dialogResult';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { NotificationService } from 'src/app/shared/components/notification/notification.service';
import { CropperData } from './cropper-data';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { CropperConfiguration } from '../image-cropper/imageCropperConfiguration';
import { CroppedImageData } from '../image-cropper/imageDataEvent';

@Component({
  selector: 'app-configuration-mask-picture',
  templateUrl: './dialog-configuration-mask-picture.component.html',
  styleUrls: ['./dialog-configuration-mask-picture.component.scss'],
})
export class DialogConfigurationMaskPictureComponent implements OnDestroy, OnInit {
  @ViewChild('cropper', { read: ImageCropperComponent }) public imageCropper: ImageCropperComponent;

  private _closeOnCropReceived: boolean = false;
  private _originalData: CropperData | null;
  private _croppedData: CroppedImageData | null;

  public imageData: Blob;
  public cropperConfiguration: CropperConfiguration;
  public isBusy: boolean = false;

  public isHandsetPortrait: boolean = false;
  public isHandsetLandscape: boolean = false;
  public isHandset: boolean = false;
  public isDesktop: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: CropperData,
    private dialogRef: MatDialogRef<ConfirmComponent>,
    private translationService: TranslateService,
    private notificationService: NotificationService,
    private responsive: BreakpointObserver
  ) {
    this._originalData = data;
    this.cropperConfiguration = this._originalData.cropperConfiguration;
    this.imageData = this._originalData.imageData;
  }

  ngOnInit(): void {
    this.responsive.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
      const breakpoints = result.breakpoints;

      this.isHandsetPortrait = breakpoints[Breakpoints.HandsetPortrait];
      this.isHandsetLandscape = breakpoints[Breakpoints.HandsetLandscape];
      this.isHandset = this.isHandsetLandscape || this.isHandsetPortrait;
      this.isDesktop = !this.isHandset;

      if (this.isHandset) this.dialogRef.addPanelClass('handset');
      else this.dialogRef.removePanelClass('handset');
    });
  }

  public ngOnDestroy(): void {
    this._croppedData = null;
    this._originalData = null;
  }

  public imageCropped(event: CroppedImageData): void {
    if (!event) return;
    if (event.width < this.cropperConfiguration.width || event.height < this.cropperConfiguration.height) {
      this.notificationService.displayError(
        this.translationService.instant('assets.media.error.picture_size', {
          min: `${this.cropperConfiguration.width}x${this.cropperConfiguration.height}`,
          actual: `${event.width}x${event.height}`,
        })
      );
      this.isBusy = false;
      return;
    }

    this._croppedData = event;

    if (this._closeOnCropReceived) this.sendResult(ConfirmResult.Yes);

    this._closeOnCropReceived = false;
    this.isBusy = false;
  }

  public closeModale(): void {
    this.sendResult(ConfirmResult.No);
  }

  public validateModale(): void {
    this.isBusy = true;
    this._closeOnCropReceived = true;
    this.imageCropper.crop();
  }

  private sendResult(confirm: ConfirmResult): void {
    const result: DialogResult<CroppedImageData> = new DialogResult<CroppedImageData>();
    result.confirm = confirm;
    result.entity = confirm == ConfirmResult.Yes ? this._croppedData : null;

    this.dialogRef.close(result);
  }
}
