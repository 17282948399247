import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MaskCreationStep } from './mask-creation-step';
import { Router } from '@angular/router';
import { EnumHelper } from 'src/app/core/enumHelper';

@Injectable()
export class MaskLayoutService {
  rootMaskId: number = 0;

  constructor(private router: Router) {
    const segments: string[] = this.router.url.split('/');

    // retrieve requested root mask id from URL
    this.rootMaskId = parseInt(segments[2]);
  }

  public requestedStep: Subject<{ currentStep: MaskCreationStep; requestedStep: MaskCreationStep }> = new Subject<{
    currentStep: MaskCreationStep;
    requestedStep: MaskCreationStep;
  }>();

  public isWorking: Subject<boolean> = new Subject<boolean>();

  /**
   * Navigate to the requested step
   * @param step step to navigate to
   */
  public navigate(step: MaskCreationStep, rootMaskId?: number): void {
    const stepRouteSegment: string = EnumHelper.enumToRecordWithKeys(MaskCreationStep)[step];

    // la propriété rootMaskId est utilisée uniquement par l'étape de création de masque car sinon la valeur
    // extraite de l'url serait '0'
    if (rootMaskId) this.rootMaskId = rootMaskId;

    this.router.navigate(['/mask', this.rootMaskId, stepRouteSegment]);
  }

  /**
   * Triggers a step change event for the requested step
   * @param currentStep Current step
   * @param askedStep Requested step
   */
  public goToStep(currentStep: MaskCreationStep, askedStep: MaskCreationStep): void {
    this.requestedStep.next({ currentStep, requestedStep: askedStep });
  }

  public setIsWorking(value: boolean): void {
    this.isWorking.next(value);
  }
}
