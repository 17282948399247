import * as moment from 'moment';
import { RepetitionType } from './repetition-type';

export class MaskSchedule {
  public id: number;
  public maskId: number;
  public startDate: Date;
  public endDate: Date;
  public repetitionType: RepetitionType;
  public repetitionEndDate: Date | null;
  public weekDays: WeekDay;

  constructor(maskId: number) {
    const dateNow: Date = moment.utc().toDate();
    this.id = 0;
    this.maskId = maskId;
    this.startDate = new Date(Date.UTC(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), 7, 0, 0));
    this.endDate = new Date(Date.UTC(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate() + 1, 22, 0, 0));
    this.repetitionType = RepetitionType.Once;

    dateNow.setDate(dateNow.getDate() + 1); // Tomorrow
    dateNow.setHours(23);
    dateNow.setMinutes(59);
    dateNow.setSeconds(59);
    dateNow.setMilliseconds(999);

    this.repetitionEndDate = dateNow; // new Date(Date.UTC(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), 23, 59, 999));
    this.weekDays = WeekDay.None;
  }
}

export enum WeekDay {
  None = 0,
  Monday = 2,
  Tuesday = 4,
  Wednesday = 8,
  Thursday = 16,
  Friday = 32,
  Saturday = 64,
  Sunday = 128,
}
