import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, tap } from 'rxjs/operators';
import { GoogleBuilding } from 'src/app/core/googleworkspace/google-building';
import { GoogleWorkspaceService } from 'src/app/core/googleworkspace/google-workspace.service';
import { KeyboardKey } from 'src/app/core/enums/keyboard-key.enum';

@Component({
  selector: 'app-googleworkspace-building-picker',
  templateUrl: './googleworkspace-building-picker.component.html',
  styleUrls: ['./googleworkspace-building-picker.component.scss'],
})
export class GoogleworkspaceBuildingPickerComponent implements OnInit, OnDestroy, AfterViewInit {
  public buildings: GoogleBuilding[];
  public filteredBuildings: GoogleBuilding[];
  private inputSubscription: Subscription;

  @ViewChild('filter', { static: true }) public searchInput: ElementRef;

  constructor(private dialogRef: MatDialogRef<GoogleworkspaceBuildingPickerComponent>, private googleWorkspaceService: GoogleWorkspaceService) {}

  ngOnDestroy(): void {
    if (this.inputSubscription) this.inputSubscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
    console.log(this.searchInput);

    this.inputSubscription = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'input')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          console.log(this.searchInput.nativeElement.value);

          this.filter(this.searchInput.nativeElement.value);
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === KeyboardKey.Escape) {
        this.dialogRef.close(false);
      }
    });

    this.googleWorkspaceService
      .getBuildings()
      .pipe(first())
      .subscribe((buildings) => {
        this.buildings = buildings;
        this.filteredBuildings = this.buildings;
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submitCalendar(building: GoogleBuilding): void {
    this.dialogRef.close(building.id);
  }

  public filter(search: string): void {
    if (search.length < 2 || search.trim() === '') {
      this.filteredBuildings = this.buildings;
      return;
    }

    // case insensitive search
    search = search.toLowerCase();

    // remove calendars with names which don't contain the search string
    this.filteredBuildings = this.buildings.filter((cal) => cal.name.toLowerCase().includes(search));
  }
}
