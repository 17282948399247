<div class="img-container">
  <img #image [style.display]="'none'" crossorigin />
</div>
<div></div>
<div *ngIf="configuration.showControl" class="controls">
  <button mat-icon-button color="basic" (click)="rotateLeft($event)" appTooltip [tooltipText]="'mask.crop.rotate_left' | translate">
    <mat-icon svgIcon="rotate-left"></mat-icon>
  </button>
  <button mat-icon-button color="basic" (click)="zoomOut($event)" appTooltip [tooltipText]="'mask.crop.zoom_out' | translate">
    <mat-icon svgIcon="magnify-minus-outline"></mat-icon>
  </button>
  <button mat-icon-button color="basic" (click)="switchDragMode($event)" appTooltip [tooltipText]="'mask.crop.drag_mode' | translate">
    <mat-icon [svgIcon]="isInDragMode ? 'crop' : 'arrow-all'"></mat-icon>
  </button>
  <button mat-icon-button color="basic" (click)="zoomIn($event)" appTooltip [tooltipText]="'mask.crop.zoom_in' | translate">
    <mat-icon svgIcon="magnify-plus-outline"></mat-icon>
  </button>
  <button mat-icon-button color="basic" (click)="rotateRight($event)" appTooltip [tooltipText]="'mask.crop.rotate_right' | translate">
    <mat-icon svgIcon="rotate-right"></mat-icon>
  </button>
  <button mat-icon-button color="basic" (click)="selectAll($event)" appTooltip [tooltipText]="'mask.crop.select_all' | translate">
    <mat-icon svgIcon="crop-free"></mat-icon>
  </button>
</div>
