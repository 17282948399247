<div class="dynamic-data-picker">
  <button mat-icon-button (click)="togglePicker($event)" appTooltip [tooltipText]="'dynamic_data.picker.open' | translate">
    <mat-icon>more</mat-icon>
  </button>
  <div class="data-list" *ngIf="isOpen" (click)="$event.stopPropagation()">
    <div class="wait" *ngIf="loading">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <ul *ngIf="dataList">
      <li *ngFor="let item of dataList">
        <span class="data" (click)="select(item)">{{ '${' + item.name + '}' }}</span>
        <span class="value">{{ item.value }}</span>
      </li>
    </ul>
    <div class="no-data" *ngIf="dataList?.length === 0">
      {{ 'dynamic_data.no_data' | translate }}
    </div>
  </div>
</div>
