import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthorizationGuard } from './core/guards/authorization-azure.guard';
import { ScopeGuard } from './core/guards/permission.guard';
import { AutoLoginComponent } from './general/auto-login/auto-login.component';
import { ForbiddenComponent } from './general/forbidden/forbidden.component';
import { LayoutComponent } from './general/layout/layout.component';
import { NotFoundComponent } from './general/not-found/not-found.component';
import { UnauthorizedComponent } from './general/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/gca' },
  { path: 'autologin', component: AutoLoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  {
    path: 'gca',
    canActivate: [AuthorizationGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'page-not-found',
        component: NotFoundComponent,
      },
      {
        path: 'masks',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./mask-list/mask-list.module').then((m) => m.MaskListModule),
      },
      {
        path: 'planning',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./planning/planning.module').then((m) => m.PlanningModule),
      },
      {
        path: 'channels',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./channels/channels.module').then((m) => m.ChannelsModule),
      },
      {
        path: 'live-events',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./live-events/live-events.module').then((m) => m.LiveEventsModule),
      },
      {
        path: 'medias',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./media/media.module').then((m) => m.MediaModule),
      },
      {
        path: 'encoding-notifications',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./encoding-notifications/encoding-notifications.module').then((m) => m.EncodingNotificationsModule),
      },
      { path: 'help', canActivateChild: [ScopeGuard], loadChildren: () => import('./help/help.module').then((m) => m.HelpModule) },
      { path: 'news', canActivateChild: [ScopeGuard], loadChildren: () => import('./news/news.module').then((m) => m.NewsModule) },
      {
        path: 'organization',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule),
      },
      {
        path: 'reporting',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./reporting/reporting.module').then((m) => m.ReportingModule),
      },
      {
        path: 'administration',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule),
      },
      {
        path: 'backoffice',
        canActivateChild: [ScopeGuard],
        loadChildren: () => import('./back-office/back-office.module').then((m) => m.BackOfficeModule),
      },
    ],
  },
  {
    path: 'mask/:maskId',
    canActivate: [AuthorizationGuard],
    canActivateChild: [ScopeGuard],
    loadChildren: () => import('./mask-edition/mask.module').then((m) => m.MaskModule),
  },
  {
    path: 'live-event/:id',
    canActivate: [AuthorizationGuard],
    canActivateChild: [ScopeGuard],
    loadChildren: () => import('./live-event-edition/live-event-edition.module').then((m) => m.LiveEventEditionModule),
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
