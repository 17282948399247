import { CropperConfiguration } from '../image-cropper/imageCropperConfiguration';

export class CropperData {
  public imageData: Blob;
  public cropperConfiguration: CropperConfiguration;

  constructor(imageData: Blob, cropperConfiguration: CropperConfiguration) {
    this.imageData = imageData;
    this.cropperConfiguration = cropperConfiguration;
  }
}
