<div [class]="isInvalid ? 'field-video field-video-invalid' : 'field-video'">
  {{ data.title }}<span *ngIf="data.mandatory"> *</span>
  <input type="text" [required]="data.mandatory" [(ngModel)]="data.blobId" hidden />
</div>
<div id="container">
  <div id="controls">
    <button *ngIf="!finalVideo || data.mandatory; else deleteButton" mat-icon-button (click)="openAssets()">
      <mat-icon class="icon">movie_creation</mat-icon>
    </button>
    <ng-template #deleteButton>
      <button mat-icon-button>
        <mat-icon class="icon-delete" (click)="deleteVideo()">delete</mat-icon>
      </button>
    </ng-template>
  </div>
  <div id="video-container" appDropFile [canDrop]="!finalVideo" (assetDropped)="onAssetSelected($event)">
    <img [src]="mediaThumbnail" />
  </div>
</div>
