import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationComponent } from './notification.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private zone: NgZone, private snackBar: MatSnackBar) {}

  public displayError(message: string, linkUrl?: string): void {
    const config: MatSnackBarConfig = {
      panelClass: 'snack-bar-error',
      data: { message: message, url: linkUrl },
      duration: 5000,
    };

    this.zone.run(() => {
      const snackBarRef: MatSnackBarRef<NotificationComponent> = this.snackBar.openFromComponent(NotificationComponent, config);
      snackBarRef.instance.snackBarRef = snackBarRef;
    });
  }

  public displaySuccess(message: string, linkUrl?: string): void {
    const config: MatSnackBarConfig = {
      panelClass: 'snack-bar-success',
      data: { message: message, url: linkUrl },
      duration: 5000,
    };

    this.zone.run(() => {
      const snackBarRef: MatSnackBarRef<NotificationComponent> = this.snackBar.openFromComponent(NotificationComponent, config);
      snackBarRef.instance.snackBarRef = snackBarRef;
    });
  }

  public displayInfo(message: string, linkUrl?: string): void {
    const config: MatSnackBarConfig = {
      panelClass: 'snack-bar-info',
      data: { message: message, url: linkUrl },
      duration: 5000,
    };

    this.zone.run(() => {
      const snackBarRef: MatSnackBarRef<NotificationComponent> = this.snackBar.openFromComponent(NotificationComponent, config);
      snackBarRef.instance.snackBarRef = snackBarRef;
    });
  }
}
