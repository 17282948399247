import { Guid } from 'guid-typescript';
import { Audience } from '../../../administration/audiences/audience';
import { MaskScheduleStatus } from 'src/app/mask-edition/models/mask-schedule-status';
import { MaskSchedule } from 'src/app/mask-edition/models/mask-schedule';

export class MaskTile {
  public id: number;
  public name: string;
  public screenFormatId: number;
  public isPublished: boolean;
  public isFavorite: boolean;
  public isChannelMask: boolean;
  public slidesCount: number;
  public totalDuration: number;
  public modified: Date;
  public maskSchedule: MaskSchedule;
  public maskScheduleStatus: MaskScheduleStatus;
  public isReadOnly: boolean;
  public audiences: Audience[];
  public authorName: string;
  public authorEmail: string;
  public authorId: Guid;
  public rootMaskId: number;
}
