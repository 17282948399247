<div id="sidebar_menu" [class]="{ isDesktop: !isHandsetPortrait && !isHandsetLandscape, isHandsetPortrait, isHandsetLandscape }">
  <div mat-button *ngIf="isNavItemVisible('/gca/masks')" id="btn-add-mask" color="accent" (click)="addMask($event)">
    <mat-icon class="mainIcon">add</mat-icon>
    <span> {{ 'menu.add_mask' | translate }}</span>
  </div>
  <ul *ngIf="isVisible" class="menu-list">
    <li (click)="navigate(['/gca/masks'])" [class]="isActive('/gca/masks')" *ngIf="isNavItemVisible('/gca/masks')">
      <mat-icon class="mainIcon">photo_size_select_large</mat-icon>
      <span class="label">{{ 'menu.masks' | translate }}</span>
    </li>

    <li (click)="navigate(['/gca/planning'])" [class]="isActive('/gca/planning')" *ngIf="isNavItemVisible('/gca/planning')">
      <mat-icon class="mainIcon">insert_invitation</mat-icon>
      <span class="label">{{ 'menu.planning' | translate }} </span>
    </li>

    <li (click)="navigate(['/gca/channels'])" [class]="isActive('/gca/channels')" *ngIf="isNavItemVisible('/gca/channels')">
      <mat-icon class="mainIcon">cast</mat-icon>
      <span class="label">{{ 'menu.channels' | translate }}</span>
    </li>

    <li (click)="navigate(['/gca/live-events'])" [class]="isActive('/gca/live-events')" *ngIf="isNavItemVisible('/gca/live-events')">
      <mat-icon class="mainIcon">live_tv</mat-icon>
      <span class="label">{{ 'menu.live_events' | translate }}</span>
    </li>

    <li (click)="navigate(['/gca/medias'])" [class]="isActive('/gca/medias')" *ngIf="isNavItemVisible('/gca/medias')">
      <mat-icon class="mainIcon">photo_library</mat-icon>
      <span class="label">{{ 'menu.medias' | translate }}</span>
    </li>

    <li *ngIf="isNavItemVisible('/gca/organization')" (click)="expandMenu('/gca/organization')">
      <mat-icon mat-list-icon class="mainIcon">account_tree</mat-icon>
      <span class="label"> {{ 'menu.organization.title' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/organization/sites'])"
      [class]="isActive('/gca/organization/sites')"
      *ngIf="isNavItemVisible('/gca/organization/sites') && isExpanded('/gca/organization/sites')"
    >
      <mat-icon mat-list-icon class="subIcon">place</mat-icon>
      <span class="subLabel">{{ 'menu.organization.sites' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/organization/buildings'])"
      [class]="isActive('/gca/organization/buildings')"
      *ngIf="isNavItemVisible('/gca/organization/buildings') && isExpanded('/gca/organization/buildings')"
    >
      <mat-icon mat-list-icon class="subIcon">apartment</mat-icon>
      <span class="subLabel">{{ 'menu.organization.buildings' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/organization/media-servers'])"
      [class]="isActive('/gca/organization/media-servers')"
      *ngIf="isNavItemVisible('/gca/organization/media-servers') && isExpanded('/gca/organization/media-servers')"
    >
      <mat-icon mat-list-icon class="subIcon">storage</mat-icon>
      <span class="subLabel">{{ 'menu.organization.media_servers' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/organization/media-players'])"
      [class]="isActive('/gca/organization/media-players')"
      *ngIf="isNavItemVisible('/gca/organization/media-players') && isExpanded('/gca/organization/media-players')"
    >
      <mat-icon mat-list-icon class="subIcon">ondemand_video</mat-icon>
      <span class="subLabel">{{ 'menu.organization.media_players' | translate }}</span>
    </li>

    <li *ngIf="isNavItemVisible('/gca/reporting')" (click)="expandMenu('/gca/reporting')">
      <mat-icon mat-list-icon class="mainIcon">timeline</mat-icon>
      <span class="label"> {{ 'menu.supervision.title' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/reporting/templates'])"
      [class]="isActive('/gca/reporting/templates')"
      *ngIf="isNavItemVisible('/gca/reporting/templates') && isExpanded('/gca/reporting/templates')"
    >
      <mat-icon mat-list-icon class="subIcon">chrome_reader_mode</mat-icon>
      <span class="subLabel">{{ 'menu.supervision.templates' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/reporting/devices'])"
      [class]="isActive('/gca/reporting/devices')"
      *ngIf="isNavItemVisible('/gca/reporting/devices') && isExpanded('/gca/reporting/devices')"
    >
      <mat-icon mat-list-icon class="subIcon">dns</mat-icon>
      <span class="subLabel">{{ 'menu.supervision.devices' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/reporting/exports'])"
      [class]="isActive('/gca/reporting/exports')"
      *ngIf="isNavItemVisible('/gca/reporting/exports') && isExpanded('/gca/reporting/exports')"
    >
      <mat-icon mat-list-icon class="subIcon">save_alt</mat-icon>
      <span class="subLabel">{{ 'menu.supervision.exports' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/reporting/channels'])"
      [class]="isActive('/gca/reporting/channels')"
      *ngIf="isNavItemVisible('/gca/reporting/channels') && isExpanded('/gca/reporting/channels')"
    >
      <mat-icon mat-list-icon class="subIcon">cast</mat-icon>
      <span class="subLabel">{{ 'menu.supervision.channels' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/reporting/orphaned'])"
      [class]="isActive('/gca/reporting/orphaned')"
      *ngIf="isNavItemVisible('/gca/reporting/orphaned') && isExpanded('/gca/reporting/orphaned')"
    >
      <mat-icon mat-list-icon class="subIcon">groups</mat-icon>
      <span class="subLabel">{{ 'menu.supervision.orphaned' | translate }}</span>
    </li>

    <li *ngIf="isNavItemVisible('/gca/administration')" (click)="expandMenu('/gca/administration')">
      <mat-icon mat-list-icon class="mainIcon">settings_system_daydream</mat-icon>
      <span class="label"> {{ 'menu.administration.title' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/administration/users'])"
      [class]="isActive('/gca/administration/users')"
      *ngIf="isNavItemVisible('/gca/administration/users') && isExpanded('/gca/administration/users')"
    >
      <mat-icon mat-list-icon class="subIcon">person</mat-icon>
      <span class="subLabel">{{ 'menu.administration.users' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/administration/audiences'])"
      [class]="isActive('/gca/administration/audiences')"
      *ngIf="isNavItemVisible('/gca/administration/audiences') && isExpanded('/gca/administration/audiences')"
    >
      <mat-icon mat-list-icon class="subIcon">wifi_tethering</mat-icon>
      <span class="subLabel">{{ 'menu.administration.audiences' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/administration/datasources'])"
      [class]="isActive('/gca/administration/datasources')"
      *ngIf="isNavItemVisible('/gca/administration/datasources') && isExpanded('/gca/administration/datasources')"
    >
      <mat-icon mat-list-icon class="subIcon" svgIcon="database"></mat-icon>
      <span class="subLabel">{{ 'menu.administration.datasources' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/administration/dynamicdata'])"
      [class]="isActive('/gca/administration/dynamicdata')"
      *ngIf="isNavItemVisible('/gca/administration/dynamicdata') && isExpanded('/gca/administration/dynamicdata')"
    >
      <mat-icon mat-list-icon class="subIcon">more</mat-icon>
      <span class="subLabel">{{ 'menu.administration.dynamicdata' | translate }}</span>
    </li>

    <li *ngIf="isNavItemVisible('/gca/backoffice')" (click)="expandMenu('/gca/backoffice')">
      <mat-icon class="mainIcon">settings_applications</mat-icon>
      <span class="label">{{ 'menu.backoffice.title' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/backoffice/clients-accounts'])"
      [class]="isActive('/gca/backoffice/clients-accounts')"
      *ngIf="isNavItemVisible('/gca/backoffice/clients-accounts') && isExpanded('/gca/backoffice/clients-accounts')"
    >
      <mat-icon mat-list-icon class="subIcon">supervisor_account</mat-icon>
      <span class="subLabel">{{ 'menu.backoffice.clients_accounts' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/backoffice/fleets'])"
      [class]="isActive('/gca/backoffice/fleets')"
      *ngIf="isNavItemVisible('/gca/backoffice/fleets') && isExpanded('/gca/backoffice/fleets')"
    >
      <mat-icon mat-list-icon class="subIcon">account_balance</mat-icon>
      <span class="subLabel">{{ 'menu.backoffice.fleets' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/backoffice/admins'])"
      [class]="isActive('/gca/backoffice/admins')"
      *ngIf="isNavItemVisible('/gca/backoffice/admins') && isExpanded('/gca/backoffice/admins')"
    >
      <mat-icon mat-list-icon class="subIcon">admin_panel_settings</mat-icon>
      <span class="subLabel">{{ 'menu.backoffice.admins' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/backoffice/templates'])"
      [class]="isActive('/gca/backoffice/templates')"
      *ngIf="isNavItemVisible('/gca/backoffice/templates') && isExpanded('/gca/backoffice/templates')"
    >
      <mat-icon mat-list-icon class="subIcon">chrome_reader_mode</mat-icon>
      <span class="subLabel">{{ 'menu.backoffice.templates' | translate }}</span>
    </li>

    <li
      (click)="navigate(['/gca/backoffice/tools/users'])"
      [class]="isActive('/gca/backoffice/tools/users')"
      *ngIf="isNavItemVisible('/gca/backoffice/tools/users') && isExpanded('/gca/backoffice/tools/users')"
    >
      <mat-icon mat-list-icon class="subIcon">construction</mat-icon>
      <span class="subLabel">{{ 'menu.backoffice.tools.users' | translate }}</span>
    </li>
  </ul>
</div>
