import { Inject, Injectable, InjectionToken, OnDestroy, Type } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights, IConfiguration } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { SettingsService } from '../setttings/settings.service';

export const APP_INSIGHTS_CONFIG: InjectionToken<string> = new InjectionToken<string>('APP_INSIGHTS_CONFIG');
export const APP_VERSION: InjectionToken<string> = new InjectionToken<string>('APP_VERSION');

@Injectable({
  providedIn: 'root',
})
export class LoggingService implements OnDestroy {
  private routerSubscription: Subscription;
  private appInsights: ApplicationInsights;
  private appVersion: string;

  constructor(@Inject(APP_INSIGHTS_CONFIG) private insightsConfig: IConfiguration, private settingsService: SettingsService, private router: Router) {
    this.appInsights = new ApplicationInsights({ config: this.insightsConfig });
    this.appInsights.loadAppInsights();
    this.appVersion = `${this.settingsService.settings.MAJOR}.${this.settingsService.settings.BUILD}`;

    this.routerSubscription = this.router.events.pipe(filter((ev) => ev instanceof ResolveEnd)).subscribe((ev) => {
      const activatedComponent: string = this.getActivatedComponent((ev as ResolveEnd).state.root);
      if (activatedComponent) {
        this.logPageView(`${activatedComponent} ${this.getRouteTemplate((ev as ResolveEnd).state.root)}`, (ev as ResolveEnd).urlAfterRedirects);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public setUserId(userId: string): void {
    this.appInsights.setAuthenticatedUserContext(Md5.hashStr(userId, false) as string);
  }

  public clearUserId(): void {
    this.appInsights.clearAuthenticatedUserContext();
  }

  public logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name, uri, properties: { ['app_version']: this.appVersion } });
  }

  public logError(error: Error): void {
    this.appInsights.trackException({ exception: error, properties: { ['app_version']: this.appVersion } });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): string {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return String(snapshot.component);
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path: string = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
