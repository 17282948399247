import { Pipe, PipeTransform } from '@angular/core';
import { ScreenFormat } from 'src/app/core/enums/screen-format.enum';
import { EnumTranslate } from 'src/app/core/enumTranslate';

@Pipe({
  name: 'enumFilterPipe',
  pure: false,
})
export class EnumFilterPipe implements PipeTransform {
  public transform(items: EnumTranslate[], filter: ScreenFormat[]): EnumTranslate[] {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item) => filter.find((x) => x === item.index));
  }
}
