import { AssetType } from './assetType';

export class MediaDropData {
  public mediaType: AssetType;
  public blobUrl: string;
  public thumbnailUrl: string;
  public blobId: string;
  public name: string;

  /** Used to pass any additional data back to the asset configuration component */
  public additionalData?: any;

  constructor(mediaType: AssetType, blobId: string, blobUrl: string, thumbnailUrl: string, name: string, additionalData?: any) {
    this.mediaType = mediaType;
    this.blobUrl = blobUrl;
    this.thumbnailUrl = thumbnailUrl;
    this.additionalData = additionalData;
    this.blobId = blobId;
    this.name = name;
  }
}
