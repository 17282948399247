<div class="container">
  <button mat-icon-button class="close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <div class="video-container">
  <video controls autoplay>
    <source [src]="videoUrl" type="video/mp4" />
  </video>
  </div>
</div>
