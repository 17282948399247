import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { Template } from './template';
import { TemplateLight } from './templateLight';
import { ScreenFormat } from '../enums/screen-format.enum';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  public templateBaseUrl: string = `${this.settingsService.settings.API_URL}/template`;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {}

  public getTemplates(screenFormatId: ScreenFormat, includeSingleSlideTemplate: boolean): Observable<Template[]> {
    const params: HttpParams = new HttpParams({
      fromString: `screenFormatId=${screenFormatId}&includeSingleSlideTemplate=${includeSingleSlideTemplate}`,
    });

    return this.httpClient
      .get<Template[]>(`${this.templateBaseUrl}/details`, {
        params,
      })
      .pipe(
        catchError((err) => {
          err.message = this.translateService.instant('assets.template.error.get');
          return throwError(err);
        })
      );
  }

  public getTemplate(templateId: number): Observable<Template> {
    return this.httpClient.get<Template>(`${this.templateBaseUrl}/${templateId}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('assets.template.error.get');
        return throwError(err);
      })
    );
  }

  public get(): Observable<TemplateLight[]> {
    return this.httpClient.get<TemplateLight[]>(`${this.templateBaseUrl}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('templates.error.get');
        return throwError(err);
      })
    );
  }
}
