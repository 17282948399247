import { Exception } from '@microsoft/applicationinsights-web';
import { MediaLibrary } from 'src/app/core/media-library/media-library';
import { MediaStatus } from 'src/app/core/media-library/media-status';
import { MediaType } from 'src/app/core/media-library/media-type';
import { Tag } from 'src/app/core/media-library/tag';
import { Template } from 'src/app/core/template/template';
import { AssetType } from './assetType';

export class Asset {
  public id: number;
  public name: string;
  public blobUrl: string;
  public thumbnailUrl: string;
  public tags?: Tag[];
  public type: AssetType;
  public isVideo: boolean;
  public status?: MediaStatus;
  public progress: number = 0;
  // public hover: boolean = false;
  public blobId: string;

  public get isEncoding(): boolean {
    return this.status === MediaStatus.Encoding;
  }

  public static FromMedia(media: MediaLibrary): Asset {
    let assetType: AssetType;
    switch (media.type) {
      case MediaType.Picture:
        assetType = AssetType.Picture;
        break;
      case MediaType.Video:
        assetType = AssetType.Video;
        break;
      default:
        throw 'Unsupported media type';
    }

    return new Asset(media.id, media.name, media.blobUrl, media.blobThumbnailUrl, assetType, media.type == MediaType.Video, media.blobId, media.tags, media.status);
  }

  public static FromTemplate(template: Template): Asset {
    return new Asset(template.id, template.name, '', template.templateBlob.thumbnailUrl, AssetType.Template, false, template.blobId);
  }

  private constructor(id: number, name: string, blobUrl: string, thumbnailUrl: string, type: AssetType, isVideo: boolean, blobId: string, tags?: Tag[], status?: MediaStatus) {
    this.id = id;
    this.name = name;
    this.blobUrl = blobUrl;
    this.thumbnailUrl = thumbnailUrl;
    this.type = type;
    this.tags = tags;
    this.isVideo = isVideo;
    this.status = status;
    this.blobId = blobId;
  }
}
