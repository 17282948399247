<div class="container">
  <div class="title-bar">
    <h3>{{ 'mask_list.tile.audiences_dialog_title' | translate }}</h3>
    <button mat-icon-button class="close" (click)="close()" color="basic">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <p class="description">{{ 'mask_list.tile.readonly_info' | translate }}</p>

  <app-plan-audiences-form (selectedAudiencesChange)="updateMaskAudiencesValues($event)" [maskAudiences]="maskAudiences" [columns]="columns" [rows]="rowCount">
  </app-plan-audiences-form>
  <div class="action-button">
    <app-busy-button [isBusy]="isBusy" [text]="'common.validate' | translate" (buttonClick)="validate()"> </app-busy-button>
  </div>
</div>
