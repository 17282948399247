import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';
import { TemplateAssetType } from './template-asset-type.enum';
import { FieldTemplateConfiguration, IFieldTemplateConfiguration } from './template-configuration-base';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';

export class FieldChannel extends FieldTemplateConfiguration {
  playerToken: string;
  channelId: number;

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);

    this.type = TemplateAssetType.Channel;
    this.playerToken = asset?.playerToken ?? '';
    this.channelId = asset?.channelId ?? -1;
  }

  override isValid(): boolean {
    return this.playerToken.length > 0 && this.channelId > 0;
  }

  override isEmpty(): boolean {
    return this.playerToken == '' || this.channelId <= 0;
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    asset.channelId = this.channelId;
    asset.playerToken = this.playerToken;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    // store both the channel id and the permanent token for later use by GetSchedule and the Mask Editor
    return `${window.location.origin}/player/?token=${this.playerToken}`;
  }
}
