<div class="container">
  <div class="side-sheet-close">
    <button mat-icon-button [disabled]="isBusy" (click)="cancelAction()">
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div class="side-sheet-title">
    {{ (this.formMode === formModeEnum.Edit ? 'media.form.edit' : 'media.form.save') | translate }}
  </div>
  <div class="side-sheet-form">
    <form [formGroup]="mediaForm" (keydown.enter)="$event.preventDefault()">
      <!-- Media -->
      <div id="container">
        <div id="controls" *ngIf="isMediaDeletable && hasMedia && mediaSources!.length === 1">
          <button mat-icon-button (click)="deleteMedia()" [disabled]="isBusy" [style.display]="hasMedia && !loading ? null : 'none'">
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        </div>
        <div id="media-container" appDropFile [canDrop]="formMode === formModeEnum.Create" [class]="loading || hasMedia ? 'null' : 'border'" (fileDropped)="onFileDropped($event)">
          <div *ngIf="!hasMedia && !loading">
            <p style="text-align: center">
              <span class="nowrap">{{ 'media.form.add_media_instructions_1' | translate }}</span>
              <span class="selectable" (click)="input.click()">{{ 'media.form.add_media_instructions_2' | translate }}</span>
            </p>
          </div>
          <div class="wait" *ngIf="loading">
            <mat-spinner [diameter]="35"></mat-spinner>
          </div>
          <div *ngIf="!loading && hasMedia" class="medias">
            <div class="multiple-media-list" *ngIf="mediaSources && mediaSources.length > 1">
              <ul>
                <li *ngFor="let source of mediaSources">
                  <label>{{ source.file?.name }}</label>
                  <span class="actions" *ngIf="!isBusy">
                    <button mat-icon-button (click)="removeFile(source)" appTooltip [tooltipText]="'media.form.remove_file' | translate">
                      <mat-icon color="warn">close</mat-icon>
                    </button>
                  </span>
                  <div class="file-upload-progress" *ngIf="source.progress">
                    <div class="background">
                      <div class="progress" [style.width]="(source.progress ? source.progress : '0') + '%'"></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <img *ngIf="mediaSources && mediaSources.length === 1 && mediaSources[0].type === mediaTypeEnum.Picture" class="image-container" [src]="mediaSources[0].url" />
            <video controls *ngIf="mediaSources && mediaSources.length === 1 && mediaSources[0].type === mediaTypeEnum.Video" class="image-container" [src]="mediaSources[0].url">
              <source type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <!-- Favorite -->
      <div class="favorite-field">
        <button mat-icon-button class="favorite" [disabled]="isBusy" (click)="checkFavoriteMedia()">
          <i class="material-icons">{{ isMediaFavorite ? 'star' : 'star_border' }}</i>
        </button>
        <button mat-flat-button class="favorite-button" [disabled]="isBusy" (click)="checkFavoriteMedia()">{{ 'media.form.favorite' | translate }}</button>
      </div>
      <!-- Name -->
      <mat-form-field>
        <mat-label>{{ 'media.form.name' | translate }}</mat-label>
        <input [readonly]="isBusy" matInput placeholder="" formControlName="name" />
        <mat-icon *ngIf="mediaSources && mediaSources!.length > 1" appTooltip [tooltipText]="'media.form.name_tooltip' | translate" matSuffix>info_outline</mat-icon>
        <mat-hint></mat-hint>
      </mat-form-field>
      <!-- Tags -->
      <mat-form-field>
        <mat-chip-list [disabled]="(!mediaLibraryItem.isMine && !isAdminOrHotline) || isBusy" #tagChipList aria-label="tag selection">
          <mat-chip *ngFor="let tag of selectedTags" (removed)="removeTag(tag)">
            {{ tag.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="{{ 'media.form.add_tags' | translate }}"
            [matChipInputFor]="tagChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addTag($event)"
          />
        </mat-chip-list>
      </mat-form-field>
      <!-- Public / Private -->
      <div class="media-permissions" *ngIf="mediaLibraryItem.isMine || isAdminOrHotline">
        <mat-radio-group formControlName="permissions">
          <mat-label>{{ 'media.form.visibility' | translate }}</mat-label>
          <mat-radio-button [value]="0">{{ 'media.form.private' | translate }}</mat-radio-button>
          <mat-radio-button [value]="1">{{ userFleet.name }}</mat-radio-button>
          <mat-radio-button [value]="2">{{ userClient.name }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <!-- Advanced Properties -->
      <div class="advanced-properties">
        <button mat-flat-button (click)="openCollapsible()" class="collapsible">{{ 'media.form.advanced_properties' | translate }}</button>
        <div [ngClass]="{ 'content-show': isCollapsibleOpen, 'content-hide': !isCollapsibleOpen }">
          <!-- Copyright -->
          <mat-form-field>
            <mat-label>{{ 'media.form.copyright' | translate }}</mat-label>
            <input [readonly]="isBusy" matInput placeholder="" formControlName="copyright" />
            <mat-hint></mat-hint>
          </mat-form-field>
          <!-- Validity end date -->
          <div class="row">
            <mat-form-field class="date-field">
              <input matInput placeholder="{{ 'media.form.validity_end' | translate }}" [matDatepicker]="validityEndDate" formControlName="validityEndDate" [readOnly]="isBusy" />
              <mat-datepicker-toggle matSuffix [for]="validityEndDate"></mat-datepicker-toggle>
              <mat-datepicker [disabled]="(!mediaLibraryItem.isMine && !isAdminOrHotline) || isBusy" #validityEndDate></mat-datepicker>
            </mat-form-field>
            <!-- <div class="date-remove">
              <button [disabled]="(!mediaLibraryItem.isMine && !isAdminOrHotline) || isBusy" mat-icon-button class="rescale-mat-button" (click)="clearValidityEndDate()">
                <i class="material-icons">clear</i>
              </button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="single-file-upload-progress" *ngIf="mediaSources && mediaSources.length === 1 && mediaSources[0].progress">
        <div class="background">
          <div class="progress" [style.width]="(mediaSources[0].progress ? mediaSources[0].progress : '0') + '%'"></div>
        </div>
      </div>
    </form>
  </div>

  <div class="side-sheet-actions">
    <app-busy-button
      [isBusy]="isBusy"
      [inactive]="!mediaForm.valid || !hasMedia || (this.formMode === formModeEnum.Edit && !formModificationHappened)"
      [text]="(this.formMode === formModeEnum.Edit ? 'common.save_modif' : 'common.save') | translate"
      (buttonClick)="onSubmit()"
    >
    </app-busy-button>

    <button mat-raised-button type="button" [disabled]="isBusy" (click)="cancelAction()" class="action-button">
      {{ 'common.cancel' | translate }}
    </button>
  </div>
</div>
<input #input type="file" accept=".jpg,.jpeg,.png,.tif,.tiff,.bmp,video/*" multiple (change)="fileChangeEvent($event)" [style.display]="'none'" />
