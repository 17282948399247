import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Observable, Subject } from 'rxjs';
import { FieldSelect } from 'src/app/core/template-configuration/field-select';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';

@Component({
  selector: 'app-configuration-mask-select',
  templateUrl: './configuration-mask-select.component.html',
  styleUrls: ['./configuration-mask-select.component.scss'],
})
export class ConfigurationMaskSelectComponent implements ConfigurationMaskItemComponent, OnInit {
  @Input() public data: FieldSelect;

  public valueSelected: string;

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  public ngOnInit(): void {
    if (this.data.selected !== undefined) this.valueSelected = this.data.selected;
    if (this.data.mandatory === false) this.data.options.unshift({ key: '', value: '' });
  }

  public onChange(event: MatSelectChange): void {
    this.data.selected = event.value;

    if (this.data.isValid()) {
      this.outputValue.next(this.data);
      this.outputFocus.next(this.data);
    }
  }
}
