<div class="container">
  <div class="header">
    <div class="close-action">
      <button mat-icon-button (click)="closeDialog()">
        <i class="material-icons">clear</i>
      </button>
    </div>
    <div class="title">
      {{ 'isba.resource_picker.title' | translate }}
    </div>
    <p>{{ 'isba.resource_picker.instructions' | translate }}</p>
    <div class="search">
      <mat-form-field style="width: 100%">
        <mat-label>{{ 'isba.resource_picker.search' | translate }}</mat-label>
        <input matInput placeholder="" type="search" #filter />
      </mat-form-field>
    </div>
    <div class="wait" *ngIf="!filteredRooms">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <div class="content" *ngIf="filteredRooms">
      <div class="room-list">
        <div [className]="room.efacilitiesId === data.efacilitiesId ? 'suggestion' : ''" *ngFor="let room of filteredRooms" (click)="submitRoom(room)">
          <span>{{ room.name }}</span
          ><span>{{ 'isba.isba_id' | translate }} {{ room.isbaId }}</span
          ><span>{{ 'isba.efacilities_id' | translate }} {{ room.efacilitiesId }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
