<div class="main-container">
  <div class="col side-1 handset-hidden">
    <mat-button-toggle-group vertical="true" #group="matButtonToggleGroup" [value]="buttonToggleSelected" (change)="onButtonToggleChange(group.value)">
      <mat-button-toggle (click)="showTemplates()" value="models">
        <mat-icon>photo_size_select_small</mat-icon>
        <p>{{ 'mask.menu.models' | translate }}</p>
      </mat-button-toggle>

      <mat-button-toggle (click)="showPictures()" value="pictures">
        <mat-icon>photo</mat-icon>
        <p>{{ 'mask.menu.pictures' | translate }}</p>
      </mat-button-toggle>

      <mat-button-toggle (click)="showVideos()" value="videos">
        <mat-icon>movie</mat-icon>
        <p>{{ 'mask.menu.videos' | translate }}</p>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="col side-2">
    <mat-toolbar>
      <div *ngIf="!isLiveEvent" class="slide-toolbar">
        <button
          mat-icon-button
          [ngClass]="{ 'disable-click': mask.slides.length === 1 }"
          [disableRipple]="mask.slides.length === 1"
          (click)="reorganizeSlides()"
          appTooltip
          [tooltipText]="mask.slides.length === 1 ? ('mask.slide.reorganize.unavailable' | translate) : ('mask.slide.reorganize.tooltip' | translate)"
        >
          <mat-icon>format_list_numbered</mat-icon>
        </button>
        <div class="slide-name" *ngIf="selectedSlide">
          <mat-select [(value)]="selectedSlide" (selectionChange)="changeSlide()">
            <mat-option *ngFor="let slide of mask.slides; let i = index" [value]="slide">
              {{ slide.name }}
            </mat-option>
          </mat-select>
          <app-rename-slide [name]="selectedSlide!.name" (nameChanged)="onNameChanged($event)"></app-rename-slide>
        </div>
        <button
          mat-icon-button
          [ngClass]="{ 'disable-click': selectedSlide?.slideTemplate?.template?.multiSlide === false }"
          [disableRipple]="selectedSlide?.slideTemplate?.template?.multiSlide === false"
          (click)="addSlide(true)"
          appTooltip
          [tooltipText]="selectedSlide?.slideTemplate?.template?.multiSlide === true ? ('mask.slide.add.tooltip' | translate) : ''"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button class="btn-delete" (click)="deleteSlide()" appTooltip [tooltipText]="'mask.slide.delete.tooltip' | translate">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
      <mat-divider [vertical]="true" *ngIf="!isLiveEvent"></mat-divider>

      <div *ngIf="showSlideDurationBlock()" class="slide-toolbar">
        <mat-form-field appearance="outline" class="mat-form-field-duration">
          <input matInput type="number" class="timer-input" [(ngModel)]="selectedSlide!.duration" readonly />
          <span matPrefix class="handset-hidden">{{ 'mask.menu.duration' | translate }}</span>
          <span matSuffix>&nbsp;s</span>
        </mat-form-field>

        <app-timer-slide [minDuration]="minDuration!" [duration]="selectedSlide!.duration" [disabled]="!canChangeDuration" (durationChanged)="onToolbarDurationChanged($event)">
        </app-timer-slide>
      </div>
      <mat-divider [vertical]="true" *ngIf="showSlideDurationBlock()"></mat-divider>

      <!-- only visible on portrait handsets -->
      <button mat-icon-button (click)="showTemplates()" class="handset-visible">
        <mat-icon>photo_size_select_small</mat-icon>
      </button>
    </mat-toolbar>
    <mat-divider></mat-divider>

    <div class="slide-2-preview">
      <app-slide
        [screenFormat]="mask.screenFormatId"
        [theme]="theme"
        (durationChanged)="onAutomaticDurationChanged($event)"
        (initConfiguration)="initConfiguration()"
        #slideComponent
      ></app-slide>
      <div class="playback-controls">
        <button mat-icon-button (click)="playTemplate()">
          <mat-icon>play_arrow</mat-icon>
        </button>
        <button mat-icon-button (click)="pauseTemplate()">
          <mat-icon>pause</mat-icon>
        </button>
        <button mat-icon-button (click)="replayTemplate()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="col side-3" id="horizontalScroll">
    <app-configuration-mask #configurationComponent (dataChanged)="slideComponent.postData($event)"></app-configuration-mask>
  </div>

  <app-list-assets (assetSelected)="assetSelected($event)" [screenFormat]="mask.screenFormatId" [channelMode]="mask.channelId !== undefined && mask.channelId > 0" #listAssets>
  </app-list-assets>
</div>
