import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatMenu } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';

interface SlideDurationModel
  extends FormGroup<{
    duration: FormControl<number>;
  }> {}

@Component({
  selector: 'app-timer-slide',
  templateUrl: './timer-slide.component.html',
  styleUrls: ['../slide-toolbar-popup.scss', './timer-slide.component.scss'],
})
export class EditSlideTimerComponent implements OnInit {
  @ViewChild('slideTimerMenu', { static: true }) public matMenu: MatMenu;

  @Input() public disabled: boolean;
  @Input() public duration: number;
  @Input() public minDuration: number;
  @Output() public durationChanged: EventEmitter<number> = new EventEmitter<number>();

  public form!: SlideDurationModel;
  private baseDuration: number;

  constructor(private fb: NonNullableFormBuilder, private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      duration: [this.duration, [Validators.required, Validators.min(this.minDuration)]],
    });
  }

  public onMenuClosed(): void {
    this.form.setValue({ duration: this.baseDuration });
  }

  public onMenuOpened(): void {
    this.baseDuration = this.duration;
  }

  public getErrorMessage(): string {
    if (this.form.controls.duration.hasError(Validators.required.name)) {
      return this.translateService.instant('mask.slide.duration.error.required');
    } else if (this.form.controls.duration.hasError(Validators.min.name)) {
      return this.translateService.instant('mask.slide.duration.error.minimum', {
        minDuration: this.minDuration,
      });
    }

    return '';
  }

  public onValidation(): void {
    const duration = this.form.value.duration;

    if (duration) {
      this.baseDuration = duration;
      this.durationChanged.emit(duration);
      this.matMenu.closed.emit();
    }
  }
}
