import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/modules/shared-module';
import { AutoLoginComponent } from './auto-login/auto-login.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { ClientsMenuComponent } from './layout/clients-menu/clients-menu.component';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
  declarations: [UnauthorizedComponent, ForbiddenComponent, LayoutComponent, NotFoundComponent, AutoLoginComponent, HeaderComponent, ClientsMenuComponent, SidebarComponent],
  imports: [SharedModule],
})
export class GeneralModule {}
