import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedBoolean',
  pure: false,
})
export class LocalizedBooleanPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(value: boolean): string | undefined {
    if (value === undefined) return undefined;
    const booleanString: string = String(value);
    const booleanLocalized: string = this.translateService.instant(`common.${booleanString}`);
    return booleanLocalized;
  }
}
