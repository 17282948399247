<div class="multiple-schedule" (click)="hideAllActions()">
  <div class="details-form" *ngIf="showDetails">
    <div class="form">
      <mat-form-field id="startTime" class="date-time-field">
        <mat-label>{{ 'multiple_schedule.start_time' | translate }}</mat-label>
        <input matInput [ngxTimepicker]="timePickStart" [format]="24" readonly [(ngModel)]="startTime" />
        <ngx-material-timepicker
          [editableHintTmpl]="timePickerDialHint"
          [cancelBtnTmpl]="timePickerCancel"
          [confirmBtnTmpl]="timePickerConfirm"
          [theme]="timePickerTheme"
          [appendToInput]="true"
          #timePickStart
          [enableKeyboardInput]="true"
        ></ngx-material-timepicker>
      </mat-form-field>

      <mat-form-field id="endTime" class="date-time-field">
        <mat-label>{{ 'multiple_schedule.end_time' | translate }}</mat-label>
        <input matInput [ngxTimepicker]="timePickEnd" [format]="24" readonly [(ngModel)]="endTime" />
        <ngx-material-timepicker
          [editableHintTmpl]="timePickerDialHint"
          [cancelBtnTmpl]="timePickerCancel"
          [confirmBtnTmpl]="timePickerConfirm"
          [theme]="timePickerTheme"
          [appendToInput]="true"
          #timePickEnd
          [enableKeyboardInput]="true"
        ></ngx-material-timepicker>
      </mat-form-field>
    </div>
    <div class="validation" *ngIf="validationMessage">{{ validationMessage | translate }}</div>
    <div class="buttons">
      <button mat-raised-button color="primary" (click)="saveInterval()">{{ 'common.ok' | translate }}</button>
      <button mat-raised-button (click)="closeDetails()">{{ 'common.cancel' | translate }}</button>
    </div>
    <ng-template #timePickerDialHint>
      <p class="dial-hint">{{ 'mask.schedule.hint_time' | translate }}</p>
    </ng-template>
    <ng-template #timePickerCancel>
      <!-- Empty template used to remove cancel button on timepicker -->
    </ng-template>
    <ng-template #timePickerConfirm>
      <div class="dial-ok">
        <button mat-raised-button color="primary">{{ 'common.ok' | translate }}</button>
      </div>
    </ng-template>
  </div>

  <div class="header">
    <div class="title">{{ title }}</div>
    <!-- <div class="main-actions">
      <button (click)="addInterval()" appTooltip [tooltipText]="'multiple_schedule.add' | translate">+</button>
    </div> -->
  </div>
  <div class="scroller" #scroller>
    <div class="day" #day>
      <ul>
        <li>00</li>
        <li>01</li>
        <li>02</li>
        <li>03</li>
        <li>04</li>
        <li>05</li>
        <li>06</li>
        <li>07</li>
        <li>08</li>
        <li>09</li>
        <li>10</li>
        <li>11</li>
        <li>12</li>
        <li>13</li>
        <li>14</li>
        <li>15</li>
        <li>16</li>
        <li>17</li>
        <li>18</li>
        <li>19</li>
        <li>20</li>
        <li>21</li>
        <li>22</li>
        <li>23</li>
        <li>00</li>
      </ul>

      <ng-container *ngFor="let day of timetable; let i = index">
        <div class="line" (mousemove)="moveCursor($event, cursor)">
          <div class="day-name">{{ 'common.week_days.' + i | translate }}</div>
          <div class="cursor" (mousedown)="addInterval($event, i)" [style.display]="!currentRightHandle && !currentLeftHandle ? 'block' : 'none'" #cursor></div>
          <div *ngFor="let interval of day" class="interval" [style.width]="interval.width + 'px'" [style.left]="interval.left + 'px'">
            <!-- Start and end information bubles (displaying the time) -->
            <div class="time start" *ngIf="interval.showTips || interval.dragging">{{ interval.startTime }}</div>
            <div class="time end" *ngIf="interval.showTips || interval.dragging">{{ interval.endTime }}</div>

            <!-- Contextual menu with actions -->
            <div
              class="actions"
              [style.opacity]="interval.showActions ? 1 : 0"
              [style.visibility]="interval.showActions ? 'visible' : 'hidden'"
              [style.left]="interval.actionsX + 'px'"
            >
              <mat-icon (click)="openDetails(interval)">edit</mat-icon>
              <mat-icon color="warn" (click)="remove($event, interval)">delete</mat-icon>
            </div>

            <!-- Left resize handle -->
            <div class="handle left" (mousedown)="leftHandleMouseDown($event, interval, i)"></div>

            <!-- Central content area -->
            <div
              class="content"
              [style.cursor]="interval.dragging ? 'ew-resize' : 'pointer'"
              (mouseover)="interval.showTips = true"
              (mouseout)="interval.showTips = false"
              (click)="showActions($event, interval)"
            ></div>

            <!-- Right resize handle -->
            <div class="handle right" (mousedown)="rightHandleMouseDown($event, interval, i)"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
