import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { DialogResult } from 'src/app/core/dialogResult';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { TemplateConfigurationService } from 'src/app/core/template-configuration/template-configuration.service';
import { FleetTheme } from 'src/app/core/template/fleetTheme';
import { Renderer, Template } from 'src/app/core/template/template';
import { TemplateService } from 'src/app/core/template/template.service';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { LiveEventService } from 'src/app/live-events/live-events.service';
import { LiveEvent } from 'src/app/live-events/liveEvent';
import { ReorganizeSlideComponent } from 'src/app/shared/components/slide-editor/reorganize-slide/reorganize-slide.component';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { NotificationService } from 'src/app/shared/components/notification/notification.service';
import { Asset } from './list-assets/asset';
import { AssetType } from './list-assets/assetType';
import { AssetRequest } from './list-assets/asset-request';
import { ListAssetsComponent } from './list-assets/list-assets.component';
import { ListAssetsService } from './list-assets/list-assets.service';
import { Slide } from './slide/slide';
import { SlideAsset } from './slide/slide-asset';
import { SlideTemplate } from './slide/slide-template';
import { SlideComponent } from './slide/slide.component';
import { SlideService } from './slide/slide.service';
import { MaskLayoutService } from 'src/app/mask-edition/mask-layout/mask-layout.service';
import { MaskCreationStep } from 'src/app/mask-edition/mask-layout/mask-creation-step';
import { ConfigurationMaskComponent } from './configuration-mask/configuration-mask.component';
import { Mask } from 'src/app/mask-edition/models/mask';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';
import { FieldTemplateConfigurationFactory } from 'src/app/core/template-configuration/template-configuration-factory';
import { IFieldTemplateConfigurationModel } from 'src/app/core/template-configuration/template-configuration-model';
import * as jsonpatch from 'fast-json-patch';
import { Observer } from 'fast-json-patch';
import { ConfirmData } from '../confirm/confirm-data';

/**
 * The Slide Editore is Step 2 of the mask edition/creation process, where users actually create the content of their masks
 */
@Component({
  selector: 'app-slide-editor',
  templateUrl: './slide-editor.component.html',
  styleUrls: ['./slide-editor.component.scss'],
})
export class SlideEditorComponent implements OnInit, OnDestroy {
  private stepAskedSubscription: Subscription;
  private liveEventSaveSubscription: Subscription;
  private liveEventDeleteSubscription: Subscription;

  private observer: Observer<Slide>;
  private templates: Template[] = [];

  /**
   * Used to detect when the configuration panel requests an asset from the assets list
   * TODO : remote it and use an eventemitter on the "configuration-mask.component"
   */
  private listAssetsSubscription: Subscription;

  /** Current slide index */
  private slideIndex: number = 0;

  public mask: Mask;
  public isLiveEvent: boolean = false;
  public selectedSlide?: Slide;
  public theme: FleetTheme;

  public minDuration?: number;
  public canChangeDuration: boolean = true;

  public Renderer: typeof Renderer = Renderer;
  public buttonToggleSelected: string;

  @Input() public slideId?: number;
  @Input() public savingAsked: Subject<void>;
  @Input() public cancellationAsked: Subject<void>;
  @Output() public validated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('slideComponent', { static: true }) public slideComponent: SlideComponent;
  @ViewChild('listAssets', { static: true }) public listAssetsComponent: ListAssetsComponent;
  @ViewChild('configurationComponent', { static: true }) public configurationComponent: ConfigurationMaskComponent;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private route: ActivatedRoute,
    private slideService: SlideService,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private settingsService: SettingsService,
    private templateService: TemplateService,
    private translateService: TranslateService,
    private liveEventService: LiveEventService,
    private listAssetsService: ListAssetsService,
    private layoutMaskService: MaskLayoutService,
    private notificationService: NotificationService,
    private templateConfigurationService: TemplateConfigurationService
  ) {
    // Depending on the user's origin (mask list/channel masks list/live events, this will vary)
    if (this.route.parent!.snapshot.data['mask']) {
      this.mask = this.route.parent!.snapshot.data['mask'];
      this.theme = this.route.parent!.snapshot.data['fleet'].theme;
    } else if (this.route.snapshot.data['mask']) {
      this.mask = this.route.snapshot.data['mask'];
      this.theme = this.route.snapshot.data['fleet'].theme;
      this.isLiveEvent = true;
    }

    this.sortSlides();
    this.translate.get('mask.page_title', { name: this.mask.name }).subscribe((t) => this.titleService.setTitle(t));
  }

  public ngOnInit(): void {
    this.listAssetsSubscription = this.listAssetsService.listAssetsAsked.subscribe((filter) => this.showListAssets(filter));

    this.listAssetsComponent.templatesLoaded.subscribe((templates) => {
      this.templates = templates;
      this.initSlideEditor();
    });

    if (!this.isLiveEvent)
      this.stepAskedSubscription = this.layoutMaskService.requestedStep
        .pipe(filter((s) => s.currentStep === MaskCreationStep.fill))
        .subscribe((s) => this.saveMaskAndGoToStep(s.requestedStep));
    else {
      this.liveEventDeleteSubscription = this.cancellationAsked.subscribe(() => this.deleteLiveEventSlide());
      this.liveEventSaveSubscription = this.savingAsked.subscribe(() => this.saveLiveEvent());
    }
  }

  public ngOnDestroy(): void {
    if (this.stepAskedSubscription) this.stepAskedSubscription.unsubscribe();
    if (this.liveEventSaveSubscription) this.liveEventSaveSubscription.unsubscribe();
    if (this.liveEventDeleteSubscription) this.liveEventDeleteSubscription.unsubscribe();
    if (this.listAssetsSubscription) this.listAssetsSubscription.unsubscribe();
    if (this.savingAsked) this.savingAsked.unsubscribe();
  }

  public onAutomaticDurationChanged(milliseconds: number): void {
    if (this.selectedSlide) {
      this.observer = jsonpatch.observe(this.selectedSlide);

      this.selectedSlide.duration = milliseconds === -1 ? 0 : Math.ceil(milliseconds / 1000);
      this.updateDuration();
    }
  }

  private initSlideEditor(): void {
    this.slideIndex = this.mask.slides.length;
    if (this.mask.slides.length === 0) {
      this.addSlide();
    } else {
      this.initAllSlides();
    }
  }

  /**
   * Initialize all fields with real instances containing actual field values, and filtering out invalid fields
   * @param fields fields (with no actual values)
   * @param slide slide (with assets from which the field values will be extracted)
   * @returns a list of field instances with their actual value from the slide's assets
   */
  private getFields(fields: IFieldTemplateConfigurationModel[], slide: Slide): IFieldTemplateConfiguration[] {
    // reduce is used to : initialize all fields with real instances and filter out invalid fields
    return fields.reduce((result, field) => {
      const asset: SlideAsset | undefined = slide.assets.find((a) => a.htmlId == field.id);
      const fieldConfig: IFieldTemplateConfiguration = FieldTemplateConfigurationFactory.GetInstance(field, asset);

      if (fieldConfig) result.push(fieldConfig);

      return result;
    }, [] as IFieldTemplateConfiguration[]);
  }

  /**
   * Retrieves and prepares all the data (including template field information) for the existing slides
   */
  private initAllSlides(): void {
    const loaders = this.mask.slides.map((slide) => this.initSlideFields(slide));

    // initialise la slide à afficher maintenant que tous les templates sont chargés.
    forkJoin(loaders).subscribe({
      next: () => {
        // si c'est un live event, récupérer l'id du slide qu'on veut éditer et pointer sur le bon
        if (this.isLiveEvent) {
          if (this.slideId) {
            this.selectedSlide = this.mask.slides.find((x) => x.id === this.slideId)!;
          } else {
            this.addSlide();
          }
        } else {
          this.selectedSlide = this.mask.slides[0];
        }

        if (this.selectedSlide && this.selectedSlide.templateId === null) this.showTemplates();
        else this.changeSlide();
      },
      error: (err) => console.log('Error initializing slides: ', err),
    });
  }

  /**
   * Initializes a provided slide's fields
   * @param slide to initialize
   * @returns
   */
  public initSlideFields(slide: Slide): Observable<void> {
    if (slide.templateId === null) return of(undefined);

    const selectedTemplate: Template | undefined = this.templates.find((x) => x.id === slide.templateId);

    if (!selectedTemplate) {
      // Si le template n'est pas trouvé on est dans le cas d'un utilisateur qui édite une slide pour laquelle
      // le template utilisé n'est pas disponible pour sa flotte : on va aller le récupérer juste pour l'édition.
      return this.templateService.getTemplate(slide.templateId!).pipe(
        switchMap((template) => {
          slide.slideTemplate = new SlideTemplate();
          slide.slideTemplate.template = template;

          return this.templateConfigurationService.getTemplateFields(template.templateBlob.structureUrl).pipe(
            map((fields) => {
              slide.slideTemplate!.fields = this.getFields(fields, slide);
            })
          );
        })
      );
    } else {
      slide.slideTemplate = new SlideTemplate();
      slide.slideTemplate.template = selectedTemplate;

      return this.templateConfigurationService.getTemplateFields(selectedTemplate.templateBlob.structureUrl).pipe(
        map((fields) => {
          slide.slideTemplate!.fields = this.getFields(fields, slide);
        })
      );
    }
  }

  public initConfiguration(): void {
    if (this.selectedSlide) this.configurationComponent.initConfiguration(this.selectedSlide);
  }

  /**
   * Affiche la liste des assets correspondant au filtre demandé.
   * Cette méthode est appelée lorsque l'utilisateur clique sur un bouton image/vidéo ouvrant la liste des médias correspondants dans le panneau de configuration de masque.
   * @param request
   */
  public showListAssets(request: AssetRequest): void {
    switch (request.assetType) {
      case AssetType.Template:
        this.showTemplates();
        break;
      case AssetType.Picture:
        this.showPictures();
        if (request.slideAssetId != null) {
          // permet de savoir quel composant a demandé l'affichage de la liste d'images
          this.listAssetsComponent.request = request;
        }
        break;
      case AssetType.Video:
        this.showVideos();
        if (request.slideAssetId != null) {
          // permet de savoir quel composant a demandé l'affichage de la liste de vidéos
          this.listAssetsComponent.request = request;
        }
        break;
    }
  }

  public showTemplates(newSlideRequestedFromUser: boolean = false): void {
    const includeSingleSlideTemplate: boolean = this.mask.slides.length < 2 && !newSlideRequestedFromUser;
    this.listAssetsComponent.openTemplatesWindow(includeSingleSlideTemplate);
  }

  public showPictures(): void {
    this.listAssetsComponent.open(AssetType.Picture);
  }

  public showVideos(): void {
    this.listAssetsComponent.open(AssetType.Video);
  }

  public onButtonToggleChange(val: string): void {
    this.buttonToggleSelected = val;
  }

  public assetSelected(data: { asset: Asset; filter?: AssetRequest }): void {
    switch (data.asset.type) {
      case AssetType.Template:
        // select a new template for the current slide
        this.selectTemplate(data.asset);
        break;
      case AssetType.Picture:
      case AssetType.Video:
        if (!data.filter || !data.filter.slideAssetId) return;
        // Forward the selected asset details to the configuration panel
        this.configurationComponent.selectAsset(data.asset, data.filter!.slideAssetId, data.filter.additionalData);
        break;
    }
  }

  /**
   * Remplace le template de la slide en cours par celui en paramètre
   * @param templateAsset Template à utiliser pour la slide en cours
   * @returns
   */
  private selectTemplate(templateAsset: Asset): void {
    if (this.selectedSlide?.slideTemplate === undefined || this.selectedSlide?.slideTemplate?.template === undefined) {
      this.updateSelectedTemplate(templateAsset);
      return;
    }

    let dialogTitle: string = this.translateService.instant('assets.template.changing.title');
    let dialogMessage: string = this.translateService.instant('assets.template.changing.message');
    let dialogConfirmButtonTitle: string = this.translateService.instant('assets.template.changing.confirm');
    let dialogCancelButtonTitle: string = this.translateService.instant('assets.template.changing.cancel');

    // Si la slide en cours a déjà un template, on demande confirmation à l'utilisateur
    const dialogRef: MatDialogRef<ConfirmComponent, DialogResult<ConfirmResult>> = this.dialog.open(ConfirmComponent, {
      data: { title: dialogTitle, message: dialogMessage, confirmButtonTitle: dialogConfirmButtonTitle, cancelButtonTitle: dialogCancelButtonTitle },
    });

    dialogRef.afterClosed().subscribe((result: DialogResult<ConfirmResult> | undefined) => {
      if (result && result.confirm === ConfirmResult.Yes) {
        this.updateSelectedTemplate(templateAsset);
      }
    });
  }

  /**
   * Changes the selected template for the current slide with the provided template asset
   * @param asset should be of type "Template"
   * @returns
   */
  private updateSelectedTemplate(asset: Asset): void {
    if (!this.selectedSlide) return;

    if (this.selectedSlide.slideTemplate === undefined) {
      this.selectedSlide.slideTemplate = new SlideTemplate();
      this.loaderService.disable();
    }

    const selectedTemplate: Template | undefined = this.templates.find((x) => x.id == asset.id);

    if (!selectedTemplate) return;

    this.minDuration = this.getMinDuration(selectedTemplate);
    this.canChangeDuration = this.getCanChangeDuration(selectedTemplate);

    this.selectedSlide.slideTemplate.template = selectedTemplate;
    this.selectedSlide.templateId = selectedTemplate.id;
    this.selectedSlide.assets = [];
    this.selectedSlide.duration = selectedTemplate.automaticDuration ? 0 : this.minDuration!;

    // Update slide data and initiate a slide change (to force refresh the display)
    this.slideService
      .put(this.selectedSlide!)
      .pipe(finalize(() => this.loaderService.enable()))
      .subscribe(
        () => {
          this.initSlideFields(this.selectedSlide!)?.subscribe(() => this.changeSlide());
        },
        (err) => {
          this.notificationService.displayError(err.message);
        }
      );
  }

  // Affiche la première slide de la liste ou la slide sélectionnée
  public changeSlide(): void {
    if (this.selectedSlide == undefined) return;

    const selectedTemplate: Template | null = this.selectedSlide.slideTemplate === undefined ? null : this.selectedSlide.slideTemplate.template;

    // clear display
    this.configurationComponent.refreshConfiguration();

    if (selectedTemplate === undefined || selectedTemplate === null) {
      this.slideComponent.setTemplate(null);
      return;
    }

    // set newly selected template
    this.slideComponent.slide = this.selectedSlide;
    this.slideComponent.setTemplate(selectedTemplate);
    this.minDuration = this.getMinDuration(selectedTemplate);
    this.canChangeDuration = this.getCanChangeDuration(selectedTemplate);
  }

  private getCanChangeDuration(selectedTemplate: Template): boolean {
    return !selectedTemplate.automaticDuration;
  }

  private getMinDuration(selectedTemplate: Template): number | undefined {
    return selectedTemplate.minDuration;
  }

  public addSlide(newSlideRequestedFromUser: boolean = false): void {
    const newSlide: Slide = new Slide(this.mask.id);
    this.slideIndex = this.mask.slides.length + 1;
    newSlide.name = this.translateService.instant('mask.menu.untitle', { index: this.slideIndex });
    newSlide.order = this.slideIndex;

    this.loaderService.disable();
    this.slideService
      .post(newSlide)
      .pipe(finalize(() => this.loaderService.enable()))
      .subscribe(
        (slide) => {
          this.mask.slides.push(slide);
          this.selectedSlide = slide;
          this.changeSlide();
          this.showTemplates(newSlideRequestedFromUser);
        },
        (err) => {
          this.notificationService.displayError(err.message);
        }
      );
  }

  public deleteSlide(): void {
    if (!this.isLiveEvent && this.mask.slides.length === 1) return;

    const dialogRef: MatDialogRef<ConfirmComponent, DialogResult<ConfirmResult>> = this.dialog.open(ConfirmComponent, {
      data: {
        withConfirmEvent: true,
        title: this.translateService.instant('mask.slide.delete.title'),
        message: this.translateService.instant('mask.slide.delete.message'),
        confirmButtonTitle: this.translateService.instant('mask.slide.delete.confirm'),
        cancelButtonTitle: this.translateService.instant('mask.slide.delete.cancel'),
      } as ConfirmData,
    });

    if (!this.selectedSlide) {
      this.router.navigateByUrl(`/gca/live-events/${this.mask.id}/slides`);
    }

    dialogRef.componentInstance.validated.subscribe((confirmResult: DialogResult<ConfirmResult> | undefined) => {
      if (confirmResult && confirmResult.confirm === ConfirmResult.Yes) {
        this.sendDeletionSlideRequest(dialogRef);
      }
    });
  }

  private sendDeletionSlideRequest(dialogRef: MatDialogRef<ConfirmComponent, DialogResult<ConfirmResult>>): void {
    this.slideService.delete(this.selectedSlide!.id).subscribe({
      next: () => {
        if (!this.isLiveEvent) {
          const index: number = this.mask.slides.findIndex((x) => x.order === this.selectedSlide!.order);
          const indexToSelected: number = index === this.mask.slides.length - 1 ? index - 1 : index + 1;
          this.selectedSlide = this.mask.slides[indexToSelected];
          this.mask.slides.splice(index, 1);
          this.sortSlides();
          this.changeSlide();
        } else {
          this.router.navigateByUrl(`/gca/live-events/${this.mask.id}/slides`);
        }
        dialogRef.close();
      },
      error: (err) => this.notificationService.displayError(err.message),
    });
  }

  private sortSlides(): void {
    this.mask.slides.sort((a, b) => a.order - b.order).forEach((item, index) => (item.order = index + 1));
  }

  public reorganizeSlides(): void {
    const dialogRef: MatDialogRef<ReorganizeSlideComponent, DialogResult<Slide[]>> = this.dialog.open(ReorganizeSlideComponent, {
      disableClose: true,
      position: {
        top: '0',
        right: '0',
      },
      panelClass: this.settingsService.cssSideSheetPanelClass,
      data: this.mask.slides.map((slide) => Object.assign({}, slide)),
    });

    dialogRef.afterClosed().subscribe((result: DialogResult<Slide[]> | undefined) => {
      if (result && result.confirm === ConfirmResult.Yes) {
        const orderedSlides: Slide[] = [...result.entity!];

        this.mask.slides = [];
        this.mask.slides.push(...orderedSlides);
        this.selectedSlide = this.mask.slides[0];

        // update interface
        this.changeSlide();
      }
    });
  }

  public replayTemplate(): void {
    this.slideComponent.replayTemplate();
  }

  public pauseTemplate(): void {
    this.slideComponent.pauseTemplate();
  }

  public playTemplate(): void {
    this.slideComponent.playTemplate();
  }

  public showSlideDurationBlock(): boolean {
    return this.selectedSlide != undefined && this.selectedSlide.slideTemplate != undefined && this.selectedSlide.slideTemplate.template.rendererId !== Renderer.Channel;
  }

  public onToolbarDurationChanged(duration: number): void {
    if (this.selectedSlide == null || this.selectedSlide.duration === duration) return;

    this.observer = jsonpatch.observe(this.selectedSlide);

    if (this.minDuration && duration < this.minDuration) {
      this.selectedSlide.duration = this.minDuration;
    } else {
      this.selectedSlide.duration = duration;
    }

    this.slideComponent.changeDuration(this.selectedSlide.duration);
    this.updateDuration();
  }

  private updateDuration(): void {
    this.loaderService.disable();

    let patch: jsonpatch.Operation[] | undefined;
    patch = jsonpatch.generate(this.observer, true);
    patch = patch.filter((o) => o.path.includes('duration'));

    if (patch.some((op) => op.path.includes('duration'))) {
      this.slideService
        .patch(this.selectedSlide!.id, patch)
        .pipe(finalize(() => this.loaderService.enable()))
        .subscribe({
          next: (slide) => (this.selectedSlide!.duration = slide.duration),
          error: (err) => this.notificationService.displayError(err.message),
        });
    }
  }

  private isMaskValid(): boolean {
    let hasEmptySlide: boolean = false;
    let hasAllMandatoryFields: boolean = true;
    let hasAllValidFields: boolean = true;

    this.mask.slides.forEach((slide: Slide) => {
      if (!slide.templateId) {
        hasEmptySlide = true;
      } else {
        slide.slideTemplate?.fields.forEach((field: IFieldTemplateConfiguration) => {
          if (field.mandatory && field.isEmpty()) {
            hasAllMandatoryFields = false;
          }

          hasAllValidFields = hasAllValidFields && field.isValid();
        });
      }
    });

    if (hasEmptySlide) {
      const translateError: string = this.translateService.instant('mask.error.empty_slides');
      this.notificationService.displayError(translateError);
      return false;
    }

    if (!hasAllMandatoryFields) {
      const translateError: string = this.translateService.instant('mask.error.mandatory_fields');
      this.notificationService.displayError(translateError);
      return false;
    }

    if (!hasAllValidFields) {
      const translateError: string = this.translateService.instant('mask.error.fields');
      this.notificationService.displayError(translateError);
      return false;
    }
    return true;
  }

  public saveMaskAndGoToStep(stepToGo: MaskCreationStep): void {
    if (!this.isMaskValid()) {
      return;
    }

    this.navigateToRequestedStep(stepToGo);
  }

  private saveLiveEvent(): void {
    if (!this.isMaskValid()) return;

    this.validated.emit(true);
    this.liveEventService
      .save(this.mask as unknown as LiveEvent)
      .pipe(finalize(() => this.validated.emit(false)))
      .subscribe({
        next: () => this.router.navigateByUrl(`/gca/live-events/${this.mask.id}/slides`),
        error: (err) => this.notificationService.displayError(err.message),
      });
  }

  private deleteLiveEventSlide(): void {
    this.deleteSlide();
  }

  private navigateToRequestedStep(askedStep: MaskCreationStep): void {
    // Depending on the user's origin (mask list/channel masks list/live events, this will vary)
    if (this.isLiveEvent) {
      this.route.snapshot.data['mask'] = this.mask;
    } else {
      this.route.parent!.snapshot.data['mask'] = this.mask;
    }

    this.layoutMaskService.navigate(askedStep);
  }

  public onNameChanged(name: string): void {
    if (name === '' || name == undefined || this.selectedSlide == undefined || this.selectedSlide.name === name) return;

    this.observer = jsonpatch.observe(this.selectedSlide);

    this.updateSlideName(name);
  }

  private updateSlideName(name: string): void {
    if (this.selectedSlide == undefined) return;

    this.selectedSlide.name = name;

    let patch: jsonpatch.Operation[] | undefined;
    patch = jsonpatch.generate(this.observer, true);
    patch = patch.filter((o) => o.path.includes('name'));

    if (patch.some((op) => op.path.includes('name'))) {
      this.loaderService.disable();

      this.slideService
        .patch(this.selectedSlide.id, patch)
        .pipe(finalize(() => this.loaderService.enable()))
        .subscribe({
          next: (slide) => (this.selectedSlide!.name = slide.name),
          error: (err) => this.notificationService.displayError(err.message),
        });
    }
  }
}
