export enum Role {
  None = 1,
  SuperAdministrator = 2,
  AccountAdministrator = 4,
  ChannelManager = 8,
  ContentManager = 16,
  SiteManager = 32,
  LiveEventsManager = 64,
  Hotline = 128,
  Technician = 256,
}
