import { InjectionToken } from '@angular/core';
import { IConfiguration } from '@microsoft/applicationinsights-web';
import { OidcSettings } from './oidcSettings';

export const APP_CONFIG: InjectionToken<Settings> = new InjectionToken<Settings>('app.config');

export class Settings {
  public ENV: string;
  public MAJOR: string;
  public BUILD: string;
  public API_URL: string;
  public OIDC_CONFIG: OidcSettings;
  public APP_INSIGHTS_CONFIG: IConfiguration;
  public BLOB_URL: string;
  public CODEC_AUTHORIZED_4K: string;
  public STORAGE_ACCOUNT: string;
}
