import { FieldTemplateConfiguration } from './template-configuration-base';
import { TemplateAssetType } from './template-asset-type.enum';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';

export class FieldUrl extends FieldTemplateConfiguration {
  private static urlRegex: RegExp = new RegExp('^(http://www.|https://www.|http://|https://)[a-z0-9]+([-|.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$');
  public url: string;

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);
    this.type = TemplateAssetType.Url;
    this.url = asset?.url ?? '';
  }

  override isValid(): boolean {
    return this.url.length == 0 || FieldUrl.urlRegex.test(this.url);
  }

  override isEmpty(): boolean {
    return this.url === '';
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    asset.url = this.url;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    return this.url;
  }
}
