import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AssetRequest } from './asset-request';

@Injectable({
  providedIn: 'root',
})
export class ListAssetsService {
  public listAssetsAsked: Subject<AssetRequest> = new Subject<AssetRequest>();

  public listAssets(filter: AssetRequest): void {
    this.listAssetsAsked.next(filter);
  }
}
