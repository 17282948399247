import { FieldTemplateConfiguration } from './template-configuration-base';
import { TemplateAssetType } from './template-asset-type.enum';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';

export class FieldTable extends FieldTemplateConfiguration {
  selected: string;
  searchEnabled: boolean;
  filterEnabled: boolean;
  externalResource: string;

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);

    this.type = TemplateAssetType.Table;
    this.selected = asset?.selected ?? '';
    this.searchEnabled = field.searchEnabled;
    this.filterEnabled = field.filterEnabled;
    this.externalResource = field.externalResource;
  }

  override isValid(): boolean {
    return !this.isEmpty();
  }

  override isEmpty(): boolean {
    return this.selected === '';
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    asset.selected = this.selected;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    return this.selected ?? '';
  }
}
