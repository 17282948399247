import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumHelper } from 'src/app/core/enumHelper';
import { ScreenFormat } from 'src/app/core/enums/screen-format.enum';
import { SlidePreview } from '../slidePreview';

export interface DialogPreviewData {
  /**
   * This property must come from the mask (and not the slide)
   */
  screenFormat: ScreenFormat;
  slidePreviews: SlidePreview[];
}

@Component({
  selector: 'app-dialog-preview',
  templateUrl: './dialog-preview.component.html',
  styleUrls: ['./dialog-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogPreviewComponent {
  private _screenFormatCssClass: string = '';

  public get screenFormatCssClass(): string {
    return this._screenFormatCssClass;
  }

  constructor(public dialogRef: MatDialogRef<DialogPreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogPreviewData) {
    this._screenFormatCssClass = EnumHelper.getIFrameClass(this.data.screenFormat);
  }
}
