import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NotificationData } from './notification-data';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
  public snackBarRef: MatSnackBarRef<NotificationComponent>;
  public message: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData, private router: Router) {}

  public ngOnInit(): void {
    this.message = this.data.message;
  }

  public openLink(): void {
    if (this.data.url) this.router.navigateByUrl(this.data.url);

    this.snackBarRef.dismiss();
  }
}
