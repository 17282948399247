import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IBreadCrumb } from './breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  public breadcrumbs: IBreadCrumb[];

  constructor(private activatedRoute: ActivatedRoute, private translateService: TranslateService) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root, 0);
  }

  public buildBreadCrumb(route: ActivatedRoute, segmentIndex: number, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    //If no routeConfig is available we are on the root path
    const breadcrumbData: string = route.routeConfig && route.routeConfig.data ? route.routeConfig.data['breadcrumb'] : '';
    const parentPath: string = route.routeConfig && route.routeConfig.data ? route.routeConfig.data['parentPath'] : '';

    let breadcrumbLabel: string = '';
    let path: string = route.routeConfig && route.routeConfig.data ? route.routeConfig.path ?? '' : '';
    let newBreadcrumbs: IBreadCrumb[];

    const pathElements: string[] = path.split('/');

    // If the route is dynamic route such as ':id', edit it
    const elVariable: string = pathElements.find((el) => el.startsWith(':'))!;
    if (elVariable) {
      const paramName: string = elVariable.slice(1);

      path = path.replace(elVariable, route.snapshot.params[paramName]);
      breadcrumbLabel = this.translateService.instant(breadcrumbData, { name: route.snapshot.data[route.snapshot.data['paramBreadcrumb']].name });
    } else if (breadcrumbData !== undefined && breadcrumbData.length > 0) {
      breadcrumbLabel = this.translateService.instant(breadcrumbData);
    }

    const brUrl: string = path ? (parentPath ? `${url}/${parentPath}/${path}` : `${url}/${path}`) : url;
    const breadcrumb: IBreadCrumb = {
      label: breadcrumbLabel,
      url: brUrl,
    };

    newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    breadcrumbs.push(breadcrumb);

    if (route.firstChild) {
      const nextUrl: string = path ? (parentPath ? `${url}/${parentPath}/${path}` : `${url}/${path}`) : url;
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      const result: IBreadCrumb[] = this.buildBreadCrumb(route.firstChild, segmentIndex + 1, nextUrl, newBreadcrumbs);

      if (segmentIndex == 0 && result.length == 1) return [];

      return result;
    }

    return newBreadcrumbs;
  }
}
