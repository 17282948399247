export interface IRepetitionType {
  id: RepetitionType;
  value: string;
}

export enum RepetitionType {
  Once = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Yearly = 4,
}

export enum RepetitionEndType {
  Never = 0,
  Date = 1,
}
