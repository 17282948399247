<button
  mat-icon-button
  [matMenuTriggerFor]="slideRenameMenu"
  (onMenuClose)="onMenuClosed()"
  (onMenuOpen)="onMenuOpened()"
  appTooltip
  [tooltipText]="'mask.slide.rename.tooltip' | translate"
>
  <mat-icon>edit</mat-icon>
</button>

<mat-menu #slideRenameMenu="matMenu" xPosition="before">
  <div class="row" (click)="$event.stopPropagation()">
    <form [formGroup]="form" novalidate (keydown.tab)="$event.stopPropagation()">
      <mat-form-field (click)="$event.stopPropagation()">
        <input matInput minlength="1" maxlength="32" placeholder="{{ 'mask.menu.title' | translate }}" (keyup.enter)="onValidation()" formControlName="name" />
        <mat-error *ngIf="form.invalid">
          {{ getErrorMessage() }}
        </mat-error>
      </mat-form-field>
    </form>
    <button mat-raised-button color="accent" class="button-ok" [disabled]="form.invalid" (click)="onValidation()">Ok</button>
  </div>
</mat-menu>
