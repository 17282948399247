<div
  [class]="{ 'contextual-menu-container': true, active: isVisible, dark: darkMode, light: !darkMode }"
  [style.z-index]="isVisible ? 999 : 'initial'"
  (mouseover)="showMenuEvent()"
  (mouseout)="hideMenuEvent()"
>
  <button mat-icon-button class="trigger">
    <mat-icon>more_vert</mat-icon>
  </button>
  <div [style.display]="isVisible ? null : 'none'" class="items">
    <div (click)="edit()" *ngIf="showEdit" role="button">
      <mat-icon appTooltip [tooltipText]="'menu.contextual.edit' | translate"  class="default-icon">create</mat-icon>
    </div>
    <div (click)="duplicate()" *ngIf="showDuplicate" role="button">
      <mat-icon appTooltip [tooltipText]="'menu.contextual.duplicate' | translate"  class="default-icon">content_copy</mat-icon>
    </div>
    <div (click)="list()" *ngIf="showList" role="button">
      <mat-icon appTooltip [tooltipText]="'menu.contextual.list' | translate"  class="default-large-icon">art_track</mat-icon>
    </div>
    <div (click)="preview()" *ngIf="showPreview" role="button">
      <mat-icon appTooltip [tooltipText]="'menu.contextual.preview' | translate"  class="default-icon">remove_red_eye</mat-icon>
    </div>
    <div (click)="delete()" *ngIf="canDelete && showDelete && !blockEdition" role="button">
      <mat-icon appTooltip [tooltipText]="'menu.contextual.delete' | translate"  class="default-icon delete-icon">delete_forever</mat-icon>
    </div>
    <div (click)="disable()" *ngIf="isEnable && showAvailability && !blockEdition" role="button">
      <mat-icon appTooltip [tooltipText]="'menu.contextual.disable' | translate"  class="disable-icon">tv_off</mat-icon>
    </div>
    <div (click)="enable()" *ngIf="!isEnable && showAvailability && !blockEdition" role="button">
      <mat-icon appTooltip [tooltipText]="'menu.contextual.enable' | translate"  class="enable-icon">live_tv</mat-icon>
    </div>
    <div (click)="favorite()" *ngIf="showFavorite" role="button">
      <mat-icon
        appTooltip [tooltipText]="'menu.contextual.favorite' | translate"
        
        class="default-icon fav-icon"
        [svgIcon]="isFavorite ? 'star' : 'star-outline'"
      ></mat-icon>
    </div>
  </div>
</div>
