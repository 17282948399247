<mat-chip-list>
  <div class="tooltip" *ngFor="let item of items | slice: 0:maxChip">
    <mat-chip class="chip" appTooltip [tooltipText]="item">{{ item }}</mat-chip>
    <div class="tooltip-text">
      <span>{{ item }}</span>
      <i></i>
    </div>
  </div>
  <div class="tooltip">
    <mat-chip
      *ngIf="showTooltip"
      appTooltip
      [tooltipUseBulletPoints]="true"
      [tooltipItems]="more"
      (mouseover)="showTooltipEvent($event)"
      (mouseout)="hideTooltipEvent($event)"
      >...</mat-chip
    >
    <!-- <ul class="tooltip-text bottom">
      <li *ngFor="let item of items | slice: maxChip">{{ item }}</li>
      <i></i>
    </ul> -->
  </div>
</mat-chip-list>
