import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IConfiguration } from '@microsoft/applicationinsights-web';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthModule, LogLevel, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import * as moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandlerService } from './core/global-error-handler/global-error-handler.service';
import { guardProviders } from './core/guards/guard.provider';
import { httpInterceptorProviders } from './core/http-interceptors/http-interceptor.provider';
import { LocalStorageService } from './core/local-storage/local-storage.service';
import { StorageKey } from './core/local-storage/storageKeys';
import { APP_INSIGHTS_CONFIG, LoggingService } from './core/logging/logging.service';
import { PaginatorI18n } from './core/paginatorI18n';
import { SettingsService } from './core/setttings/settings.service';
import { Language } from './core/users/user-settings';
import { GeneralModule } from './general/general.module';
import { LoaderService } from './general/layout/services/loader.service';
import { UserSettingsService } from './shared/services/user-settings.service';

export function loadSettings(settingsService: SettingsService, oidcConfigService: OidcConfigService, localStorageService: LocalStorageService): Function {
  const path: string = window.location.pathname;

  // Si l'utilisateur a demandé une sous-page du site et qu'on doit le réauthentifier avant, on sauvegarde d'abord la page demandée pour pouvoir l'utiliser plus tard
  if (path !== '/gca' && path !== '/forbidden' && path !== '/autologin' && path !== '/forbidden') localStorageService.write(StorageKey.redirectUrl, path);

  return () => {
    oidcConfigService.withConfig({
      stsServer: `https://login.microsoftonline.com/${settingsService.settings.OIDC_CONFIG.b2cTenantName}.onmicrosoft.com/v2.0`,
      authWellknownEndpoint:
        `https://${settingsService.settings.OIDC_CONFIG.b2cTenantName}.b2clogin.com/` +
        `${settingsService.settings.OIDC_CONFIG.b2cTenantName}.onmicrosoft.com/${settingsService.settings.OIDC_CONFIG.b2cSignInPolicy}/v2.0/.well-known/openid-configuration`,
      redirectUrl: window.location.origin + '/gca',
      clientId: settingsService.settings.OIDC_CONFIG.clientId,
      responseType: 'code',
      scope: settingsService.settings.OIDC_CONFIG.scope,
      postLogoutRedirectUri: window.location.origin + '/gca',
      silentRenew: true,
      silentRenewUrl: window.location.origin + '/silent-renew.html',
      autoUserinfo: false,
      logLevel: LogLevel.Error,
      renewTimeBeforeTokenExpiresInSeconds: 60,
      unauthorizedRoute: 'autologin',
    });
  };
}

export function getAppInsightsConfig(settingsService: SettingsService): IConfiguration {
  return settingsService.settings.APP_INSIGHTS_CONFIG;
}

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    GeneralModule,
    HttpClientModule,
    ReactiveFormsModule,
    // [NgxMaterialTimepickerModule.setLocale('fr-FR')], //issue with lazy-loading module and NgxMaterialTimepicker v5.4.3, i have to put the module inside app.module too
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule.forRoot(),
  ],
  providers: [
    CookieService,
    httpInterceptorProviders,
    guardProviders,
    OidcSecurityService,
    OidcConfigService,
    LoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadSettings,
      deps: [SettingsService, OidcConfigService, LocalStorageService],
      multi: true,
    },
    LoggingService,
    {
      provide: APP_INSIGHTS_CONFIG,
      useFactory: getAppInsightsConfig,
      deps: [SettingsService],
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private settingsChanged$: Subscription;

  constructor(dateAdapter: DateAdapter<Date>, translate: TranslateService, userSettingsService: UserSettingsService) {
    // listen for user settings changes in case the language must be dynamically updated
    this.settingsChanged$ = userSettingsService.userSettingsChanged.subscribe((settings) => {
      let lang: string = settings ? Language[settings.language].toLowerCase() : 'en';

      if (!lang || lang === '') lang = window.navigator.language.split('-')[0];

      console.log('Setting locale: ', lang);

      // traduction de l'interface
      translate.use(lang);

      let dateLang: string = lang;
      switch (lang) {
        case 'en':
          // we want to get the dd/MM/YYYY style instead of the US date style for now
          dateLang = 'en-GB';
          break;
        case 'fr':
        default:
          dateLang = 'fr-FR';
          break;
      }

      // gestion des dates
      moment.locale(lang);
      dateAdapter.setLocale(dateLang);
    });

    // gestion des dates
    moment.locale(Language[userSettingsService.language]);
    dateAdapter.setLocale(Language[userSettingsService.language]);
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
