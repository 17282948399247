export class CropperConfiguration {
  public width: number;
  public height: number;
  public showControl: boolean = false;
  public autoCrop: boolean = true;
  public zoomStep: number = 0.1;

  public get cropRatio(): number {
    return this.width && this.height ? this.width / this.height : 1;
  }

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}
