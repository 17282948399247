import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';

@Injectable({
  providedIn: 'root',
})
export class TemplateConfigurationService {
  constructor(private httpClient: HttpClient, private translateService: TranslateService) {}

  public getTemplateFields(url: string): Observable<IFieldTemplateConfigurationModel[]> {
    return this.httpClient.get<IFieldTemplateConfigurationModel[]>(url).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide.error.get_structure_json');
        return throwError(err);
      })
    );
  }

  public getTemplateHTML(urlString: string): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'text/html');
    return this.httpClient.get(urlString, { headers, responseType: 'text' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide.error.get_template_html');
        return throwError(err);
      })
    );
  }
}
