import { Pipe, PipeTransform } from '@angular/core';
import { EnumTranslate } from 'src/app/core/enumTranslate';

@Pipe({ name: 'enumToArray' })
export class EnumToArrayPipe implements PipeTransform {
  public transform(value: Object): EnumTranslate[] {
    const translations: EnumTranslate[] = [];
    const keys = Object.keys(value);
    const values = Object.values(value);

    for (var i = 0; i < keys.length; i++) {
      const key: string = keys[i];

      // filter out string keys
      if (isNaN(+key)) continue;

      const name: string = values[i];

      // Find translation index
      const translationIndex: number = keys.indexOf(`${name}Translate`);

      translations.push(new EnumTranslate(parseInt(key), name, values[translationIndex]));
    }

    return translations;
  }
}
