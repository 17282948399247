<div class="container">
  <div class="header">
    <div class="close-action">
      <button mat-icon-button (click)="closeDialog()">
        <i class="material-icons">clear</i>
      </button>
    </div>
    <div class="title">
      {{ 'googleworkspace.meetingroom_picker.title' | translate }}
    </div>
    <p>{{ 'googleworkspace.meetingroom_picker.instructions' | translate }}</p>
    <div class="search">
      <mat-form-field style="width: 100%;">
        <mat-label>{{ 'googleworkspace.meetingroom_picker.search' | translate }}</mat-label>
        <input matInput placeholder="" type="search" #filter />
      </mat-form-field>
    </div>
    <div class="wait" *ngIf="!filteredMeetingRooms">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <div class="content" *ngIf="filteredMeetingRooms">
      <div class="calendar-list">
        <div *ngFor="let room of filteredMeetingRooms" (click)="submitMeetingRoom(room)">
          <span>{{ room.name }}</span
          ><span>{{ 'googleworkspace.meetingroom_picker.meetingroom_id' | translate }} {{ room.id }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
