import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FieldVideo } from 'src/app/core/template-configuration/field-video';
import { AssetType } from 'src/app/shared/components/slide-editor/list-assets/assetType';
import { AssetRequest } from 'src/app/shared/components/slide-editor/list-assets/asset-request';
import { ListAssetsService } from 'src/app/shared/components/slide-editor/list-assets/list-assets.service';
import { MediaDropData } from 'src/app/shared/components/slide-editor/list-assets/mediaDropData';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';

@Component({
  selector: 'app-configuration-mask-video',
  templateUrl: './configuration-mask-video.component.html',
  styleUrls: ['./configuration-mask-video.component.scss'],
})
export class ConfigurationMaskVideoComponent implements ConfigurationMaskItemComponent, OnInit {
  private readonly defaultThumbnail: string = '../../../assets/img/thumbnail_upload_video.jpg';

  @Input() public data: FieldVideo;

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  public finalVideo: string | null;
  public isInvalid: boolean = false;
  public videoDuration: number;

  public mediaThumbnail: string;

  constructor(private listAssetsService: ListAssetsService) {}

  public ngOnInit(): void {
    this.initMediaThumbnail();
    this.initFinalVideo();
  }

  private initFinalVideo(): void {
    this.finalVideo = this.data.mediaUrl ?? null;
  }

  private initMediaThumbnail(): void {
    this.mediaThumbnail = this.data.thumbnailUrl ? this.data.thumbnailUrl : this.defaultThumbnail;
  }

  public onAssetSelected(mediaVideoDropData: MediaDropData): void {
    if (mediaVideoDropData.mediaType !== AssetType.Video) return;

    this.data.blobId = mediaVideoDropData.blobId;
    this.mediaThumbnail = mediaVideoDropData.thumbnailUrl;
    this.data.mediaUrl = mediaVideoDropData.blobUrl;
    this.data.thumbnailUrl = this.mediaThumbnail;
    this.finalVideo = this.data.mediaUrl;
    this.isInvalid = false;

    if (this.data.isValid()) {
      this.outputValue.next(this.data);

      // fake 'blur' event
      this.outputFocus.next(this.data);
    }
  }

  public deleteVideo(): void {
    if (this.data.mandatory) {
      this.isInvalid = true;
    }

    this.data.blobId = '';
    this.videoDuration = -1;

    if (this.finalVideo) {
      this.outputFocus.next(this.data);
    }

    this.mediaThumbnail = this.defaultThumbnail;
    this.finalVideo = null;

    if (this.data.isValid()) this.outputValue.next(this.data);
  }

  public openAssets(): void {
    this.listAssetsService.listAssets(new AssetRequest(AssetType.Video, this.data.id));
  }
}
