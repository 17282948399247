<div matRipple class="menu-container" [matMenuTriggerFor]="menu">
  <div class="client-container">
    <div>
      <span class="title">{{ 'home.impersonation_menu_title' | translate }}</span>
      <span class="value">{{ selectedClient.name }}</span>
    </div>
    <div>
      <span class="title">{{ 'home.impersonation_menu_title_fleet' | translate }}</span>
      <span class="value">{{ selectedFleet.name }}</span>
    </div>
  </div>
  <mat-icon>keyboard_arrow_down</mat-icon>
</div>
<mat-menu #menu="matMenu" yPosition="below" xPosition="after">
  <ng-container *ngFor="let client of clients">
    <button mat-menu-item [matMenuTriggerFor]="submenu">
      {{ client.name }}
    </button>
    <mat-menu #submenu="matMenu">
      <button
        *ngFor="let fleet of fleets | clientFilter: client.technicalId.toString()"
        [disabled]="fleet.technicalId === selectedFleet.technicalId"
        mat-menu-item
        (click)="fleetSelected(client, fleet)"
      >
        {{ fleet.name }}
      </button>
    </mat-menu>
  </ng-container>
</mat-menu>
