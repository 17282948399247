import { EventEmitter } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { CodecData } from 'src/app/core/media-library/codec-data';
import { MediaType } from 'src/app/core/media-library/media-type';

export class MediaFile {
  public url: SafeUrl;
  public type: MediaType;
  public file?: File;
  public codecData?: CodecData;
  public progress?: number;

  public onUploadComplete: EventEmitter<void>;
  public onUploadFailed: EventEmitter<void>;

  constructor(url: SafeUrl, type: MediaType, file?: File, codecData?: CodecData, progress?: number) {
    this.url = url;
    this.type = type;
    this.file = file;
    this.codecData = codecData;
    this.progress = progress;

    this.onUploadComplete = new EventEmitter<void>();
    this.onUploadFailed = new EventEmitter<void>();
  }
}
