import { ConfirmResult } from './confirmResult';

export class DialogResult<T> {
  public confirm: ConfirmResult;
  public entity: T | null;

  constructor() {
    this.entity = null;
  }
}
