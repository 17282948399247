import { FleetLight } from 'src/app/core/fleet/fleet';
import { SiteType } from './site-type';

export enum ReservationApi {
  Other = 0,
  Isba = 1,
  Efacilities = 2,
  GoogleWorkspace = 3,
  Office365 = 4,
}

export class Site {
  public id: number;
  public code: string;
  public name: string;
  public type: SiteType;
  public address: string;
  public postcode: string;
  public phoneNumber: string;
  public city: string;
  public country: string;
  public reservationApi: ReservationApi;
  public contact: string;
  public buildingCount: number;
  public fleet: FleetLight;
}
