import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../setttings/settings.service';
import { EncodingMessage } from './encodingMessage';
import { NegotiateResult } from './negotiateResult';
import { EncodingProgressMessage } from './progressMessage';

@Injectable({
  providedIn: 'root',
})
export class EncodingMessagesService {
  private hubConnection: signalR.HubConnection;
  private baseUrl: string = `${this.settingsService.settings.API_URL}/signalr/encoding/negotiate`;

  public progressReceived: Subject<EncodingProgressMessage>;
  public completeReceived: Subject<EncodingMessage>;
  public errorReceived: Subject<EncodingMessage>;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private loaderService: LoaderService) {
    this.progressReceived = new Subject<EncodingProgressMessage>();
    this.completeReceived = new Subject<EncodingMessage>();
    this.errorReceived = new Subject<EncodingMessage>();
  }

  public connect(): void {
    this.loaderService.addExcludedUrl(this.baseUrl);
    this.httpClient
      .get<NegotiateResult>(this.baseUrl)
      .pipe(finalize(() => this.loaderService.removeExcludedUrl(this.baseUrl)))
      .subscribe((r) => {
        this.hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(r.url, { accessTokenFactory: () => r.accessToken })
          .configureLogging(environment.production ? signalR.LogLevel.Error : signalR.LogLevel.Information)
          .build();
        this.setUpListeners();
      });
  }

  public disconnect(): void {
    if (this.hubConnection) {
      this.hubConnection.stop();
    }
  }

  private setUpListeners(): void {
    this.hubConnection.on('sendProgress', (progress, mediaId, mediaName) => {
      if (progress === 0 || !progress) return;

      this.progressReceived.next({ encodingProgress: Number.parseInt(progress), mediaName: mediaName, mediaBlobId: mediaId });
    });

    this.hubConnection.on('sendCompleted', (mediaId, mediaName) => {
      this.completeReceived.next({ mediaName: mediaName, mediaBlobId: mediaId });
    });

    this.hubConnection.on('sendError', (message, mediaId, mediaName) => {
      this.errorReceived.next({ mediaName: mediaName, mediaBlobId: mediaId });
    });

    this.hubConnection.onclose((err) => console.log(`signalR connection closed. Error : ${err}`));
    this.hubConnection
      .start()
      .then(() => console.log(`signalR connected state : ${this.hubConnection.state}!`))
      .catch(console.error);
  }
}
