import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, Settings } from './settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  // why is this centralized here? a special side sheet pane component should have been created
  public readonly cssSideSheetPanelClass: string = 'overlay-side-sheet-pane';

  private settingsValue: Settings;

  public get settings(): Settings {
    return this.settingsValue;
  }

  constructor(@Inject(APP_CONFIG) config: Settings) {
    this.settingsValue = config;
  }

  // /**
  //  * Load initial settings (like api base url, ...)
  //  */
  // public async loadSettings(): Promise<void | Settings> {
  //   return fetch('/settings/settings.json')
  //     .then((response: Response) => response.json())
  //     .then((response: Settings) => (this.settingsValue = response))
  //     .catch((error: Error) => {
  //       console.error(error);
  //     });
  // }
}
