import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { SettingsService } from '../setttings/settings.service';
import { DynamicData } from './dynamic-data';

@Injectable({
  providedIn: 'root',
})
export class DynamicDataService {
  private baseUrl: string = `${this.settingsService.settings.API_URL}/dynamicdata`;

  constructor(private loaderService: LoaderService, private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {
    this.loaderService.addExcludedUrl(`${this.baseUrl}`);
  }

  /**
   * Get all dynamic data
   */
  public get(): Observable<DynamicData[]> {
    return this.httpClient.get<DynamicData[]>(`${this.baseUrl}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('dynamic_data.error.get_all');
        return throwError(err);
      })
    );
  }

  public save(data: DynamicData): Observable<DynamicData> {
    if (data.id <= 0) return this.create(data);
    else return this.update(data);
  }

  public create(data: DynamicData): Observable<DynamicData> {
    return this.httpClient.post<DynamicData>(`${this.baseUrl}`, data, { observe: 'body' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('dynamic_data.error.post');
        return throwError(err);
      })
    );
  }

  public update(data: DynamicData): Observable<DynamicData> {
    return this.httpClient.put<DynamicData>(`${this.baseUrl}/${data.id}`, data, { observe: 'body' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('dynamic_data.error.put');
        return throwError(err);
      })
    );
  }

  /**
   * Delete a dynamic variable
   */
  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('dynamic_data.error.delete');
        return throwError(err);
      })
    );
  }
}
