<div [class]="isInvalid ? 'field-picture field-picture-invalid' : 'field-picture'">
  <mat-form-field>
    <mat-label>{{ data.title }}</mat-label>
    <input matInput type="text" [required]="data.mandatory" [(ngModel)]="data.blobId" hidden />
  </mat-form-field>
</div>
<div id="container">
  <div id="controls">
    <button
      mat-icon-button
      (click)="input.click()"
      [style.display]="data.blobDataUrl || data.mediaUrl ? 'none' : null"
      title="{{ 'mask.configuration.workstation_title' | translate }}"
    >
      <mat-icon svgIcon="upload"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="openAssets()"
      [style.display]="data.blobDataUrl || data.mediaUrl ? 'none' : null"
      title="{{ 'mask.configuration.media_library_title' | translate }}"
    >
      <mat-icon svgIcon="image"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="openMediaForm()"
      [style.display]="(data.blobDataUrl || data.mediaUrl) && !isImageSaved && canBeSavedToLibrary ? null : 'none'"
      title="{{ 'mask.configuration.media_library_save' | translate }}"
    >
      <mat-icon svgIcon="content-save"></mat-icon>
    </button>
    <button mat-icon-button (click)="deletePicture()" [style.display]="data.blobDataUrl || data.mediaUrl ? null : 'none'">
      <mat-icon svgIcon="delete"></mat-icon>
    </button>
    <button mat-icon-button (click)="openCropper()" [style.display]="canCrop ? null : 'none'">
      <mat-icon svgIcon="crop"></mat-icon>
    </button>
  </div>
  <div
    id="cropper-container"
    appDropFile
    [class]="data.blobDataUrl || data.mediaUrl ? 'null' : 'border'"
    (assetDropped)="onAssetSelected($event)"
    (fileDropped)="onFileDropped($event)"
  >
    <mat-icon *ngIf="!(data.blobDataUrl || data.mediaUrl)" class="center">image</mat-icon>
    <img [src]="data.blobDataUrl ?? data.mediaUrl" *ngIf="data.blobDataUrl || data.mediaUrl" />
    <span class="dimensions" *ngIf="!(data.blobDataUrl || data.mediaUrl)">
      <mat-icon>straighten</mat-icon>
      {{ data.minWidth }} x {{ this.data.minHeight }} px
    </span>
  </div>
</div>
<input #input type="file" accept="image/*" (change)="fileChangeEvent($event)" [style.display]="'none'" />
