import { Role } from './role';

export class ScopePolicies {
  public static RoutePolicies: { [route: string]: Role } = {
    '/gca/masks': Role.ContentManager | Role.Hotline | Role.SuperAdministrator,
    '/gca/planning': Role.ContentManager | Role.Hotline | Role.SuperAdministrator,
    '/gca/channels': Role.ChannelManager | Role.ContentManager | Role.Hotline | Role.SuperAdministrator,
    '/gca/live-events': Role.LiveEventsManager | Role.Hotline | Role.SuperAdministrator,
    '/gca/medias': Role.ContentManager | Role.LiveEventsManager | Role.Hotline | Role.SuperAdministrator | Role.ChannelManager,
    '/gca/organization/sites': Role.SiteManager | Role.Technician | Role.Hotline | Role.SuperAdministrator,
    '/gca/organization/buildings': Role.SiteManager | Role.Technician | Role.Hotline | Role.SuperAdministrator,
    '/gca/organization/media-servers': Role.SiteManager | Role.Technician | Role.Hotline | Role.SuperAdministrator,
    '/gca/organization/media-players': Role.SiteManager | Role.Technician | Role.Hotline | Role.SuperAdministrator,
    '/gca/organization': Role.SiteManager | Role.Technician | Role.Hotline | Role.SuperAdministrator,
    '/gca/reporting/templates': Role.SuperAdministrator | Role.Hotline,
    '/gca/reporting/devices': Role.SuperAdministrator | Role.Hotline,
    '/gca/reporting/exports': Role.SuperAdministrator | Role.Hotline,
    '/gca/reporting/channels': Role.SuperAdministrator | Role.Hotline | Role.ChannelManager,
    '/gca/reporting/orphaned': Role.SuperAdministrator | Role.Hotline | Role.SiteManager,
    '/gca/reporting': Role.SuperAdministrator | Role.Hotline | Role.ChannelManager | Role.SiteManager,
    '/gca/administration': Role.SuperAdministrator | Role.Hotline | Role.AccountAdministrator,
    '/gca/administration/users': Role.SuperAdministrator | Role.Hotline | Role.AccountAdministrator,
    '/gca/administration/audiences': Role.SuperAdministrator | Role.Hotline | Role.AccountAdministrator,
    '/gca/administration/dynamicdata': Role.SuperAdministrator | Role.Hotline | Role.AccountAdministrator,
    '/gca/administration/datasources': Role.SuperAdministrator | Role.Hotline | Role.AccountAdministrator,
    '/gca/backoffice/tools/users': Role.SuperAdministrator,
    '/gca/backoffice/clients-accounts': Role.SuperAdministrator,
    '/gca/backoffice/fleets': Role.SuperAdministrator,
    '/gca/backoffice/admins': Role.SuperAdministrator,
    '/gca/backoffice/templates': Role.SuperAdministrator,
    '/gca/backoffice': Role.SuperAdministrator,
    '/mask': Role.ChannelManager | Role.ContentManager | Role.Hotline | Role.LiveEventsManager | Role.SuperAdministrator,
    '/live-event': Role.LiveEventsManager | Role.Hotline | Role.SuperAdministrator,
    '/gca':
      Role.AccountAdministrator | Role.ChannelManager | Role.ContentManager | Role.Hotline | Role.LiveEventsManager | Role.SiteManager | Role.SuperAdministrator | Role.Technician,
  };

  public static CanImpersonate(userRoles: Role): boolean {
    return (userRoles & (Role.Hotline | Role.SuperAdministrator | Role.Technician)) > 0;
  }

  public static IsRouteAccessible(route: string, userRoles: Role): boolean {
    let routeRoles: Role = ScopePolicies.RoutePolicies[route];

    // route not found
    if (!routeRoles) {
      for (let [key, value] of Object.entries(ScopePolicies.RoutePolicies)) {
        if (route.startsWith(key)) {
          routeRoles = value;
          break;
        }
      }
    }

    // still not found!
    if (!routeRoles) return false;

    return (routeRoles & userRoles) > 0;
  }
}
