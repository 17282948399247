<div class="tooltip" #tooltipElement [style.top]="posYtop" [style.bottom]="posYbottom" [style.left]="posXleft" [style.right]="posXright">
  <div class="tooltip-text center">
    <p *ngIf="title">
      <b>{{ title }}</b>
    </p>
    <p *ngIf="text">{{ text }}</p>
    <table *ngIf="!useBulletPoints && items && items.length > 0">
      <tr *ngFor="let item of items">
        <th *ngIf="item.title">{{ item.title }}</th>
        <td>{{ item.text }}</td>
      </tr>
    </table>
    <ul *ngIf="useBulletPoints && items && items.length > 0" [class]="!text && !title ? 'no-margin' : ''">
      <li *ngFor="let item of items">
        <b *ngIf="item.title">{{ item.title }}</b>
        <span>{{ item.text }}</span>
      </li>
    </ul>
  </div>
</div>
