<div class="container">
  <div class="header">
    <div class="close-action">
      <button mat-icon-button (click)="closeDialog()">
        <i class="material-icons">clear</i>
      </button>
    </div>
    <div class="title">
      {{ 'googleworkspace.building_picker.title' | translate }}
    </div>
    <p>{{ 'googleworkspace.building_picker.instructions' | translate }}</p>
    <div class="search">
      <mat-form-field style="width: 100%;">
        <mat-label>{{ 'googleworkspace.building_picker.search' | translate }}</mat-label>
        <input matInput placeholder="" type="search" #filter />
      </mat-form-field>
    </div>
    <div class="wait" *ngIf="!filteredBuildings">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <div class="content" *ngIf="filteredBuildings">
      <div class="calendar-list">
        <div *ngFor="let building of filteredBuildings" (click)="submitCalendar(building)">
          <span>{{ building.name }}</span
          ><span>{{ 'googleworkspace.building_picker.building_id' | translate }} {{ building.id }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
