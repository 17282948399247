import { HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, Type } from '@angular/core';
import { AuthTokenInterceptor } from './auth-token.interceptor';
import { ForbiddenInterceptor } from './forbidden.interceptor';
import { LoaderInterceptor } from './loader.interceptor';

export const httpInterceptorProviders: { provide: InjectionToken<HttpInterceptor[]>; useClass: Type<object>; multi: boolean }[] = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
];
