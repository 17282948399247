import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PlanAudiencesEvent } from './plan-audiences-event';
import { MaskAudience } from 'src/app/mask-edition/models/mask-audience';

@Component({
  selector: 'app-plan-audiences-form',
  templateUrl: './plan-audiences-form.component.html',
  styleUrls: ['./plan-audiences-form.component.scss'],
})
export class PlanAudiencesFormComponent implements OnChanges {
  @Input() public set maskAudiences(value: MaskAudience[]) {
    if (!value) return;

    // Tri par ordre alphabétique naturel (prenant en compte les chiffres intelligemment)
    this.sortedMaskAudiences = value.sort((a, b) => a.audienceName.localeCompare(b.audienceName, undefined, { numeric: true, sensitivity: 'base' }));
  }

  @Input() public columns: number;
  @Input() public rows: number;
  @Output() private selectedAudiencesChange: EventEmitter<PlanAudiencesEvent> = new EventEmitter<PlanAudiencesEvent>();

  public audiencesSelected: number[];
  public sortedMaskAudiences: MaskAudience[];

  public onModelChange(): void {
    const event: PlanAudiencesEvent = { selectedAudienceIds: this.audiencesSelected, isFormValid: this.isFormValid() };
    this.selectedAudiencesChange.emit(event);
  }

  public ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.audiencesSelected = this.sortedMaskAudiences?.filter((ma) => ma.selected).map((x) => x.audienceId);
  }

  private isFormValid(): boolean {
    if (this.audiencesSelected.length > 0) return true;
    else return false;
  }
}
