import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, tap } from 'rxjs/operators';
import { IsbaService } from 'src/app/core/isba/isba.service';
import { IsbaResourceModel } from './isba-resource-model';
import { IsbaResourcePickerData } from './isba-resource-picker-data';
import { KeyboardKey } from 'src/app/core/enums/keyboard-key.enum';

@Component({
  selector: 'app-isba-picker',
  templateUrl: './isba-resource-picker.component.html',
  styleUrls: ['./isba-resource-picker.component.scss'],
})
export class IsbaResourcePickerComponent implements OnInit, OnDestroy, AfterViewInit {
  private rooms: IsbaResourceModel[];
  public filteredRooms: IsbaResourceModel[];

  private inputSubscription: Subscription;

  @ViewChild('filter', { static: true }) public searchInput: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IsbaResourcePickerData,
    private dialogRef: MatDialogRef<IsbaResourcePickerComponent>,
    private isbaService: IsbaService
  ) {}

  public ngAfterViewInit(): void {
    console.log(this.searchInput);

    this.inputSubscription = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'input')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.filter(this.searchInput.nativeElement.value);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    if (this.inputSubscription) this.inputSubscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === KeyboardKey.Escape) {
        this.dialogRef.close(false);
      }
    });

    this.isbaService
      .getBuildingIsbaMeetingRooms(this.data.buildingId)
      .pipe(first())
      .subscribe((resources) => {
        this.rooms = new Array<IsbaResourceModel>();
        resources.forEach((room) => {
          let idSplit: string[] = room.id.split('/');
          this.rooms.push({
            name: room.name,
            efacilitiesId: idSplit[idSplit.length - 2],
            isbaId: room.id,
          });
        });

        this.filteredRooms = this.rooms;
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submitRoom(room: IsbaResourceModel): void {
    this.dialogRef.close(room.isbaId);
  }

  public filter(search: string): void {
    if (search.length < 2 || search.trim() === '') {
      this.filteredRooms = this.rooms;
      return;
    }

    // case insensitive search
    search = search.toLowerCase();

    // remove rooms with names which don't contain the search string
    this.filteredRooms = this.rooms.filter((room) => room.name.toLowerCase().includes(search));
  }
}
