import { Pipe, PipeTransform } from '@angular/core';
import { EnumToArrayPipe } from './enumToArray.pipe';

import { TranslateService } from '@ngx-translate/core';
import { EnumTranslate } from 'src/app/core/enumTranslate';

@Pipe({
  name: 'localizedEnum',
  pure: false,
})
export class LocalizedEnumPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(value: number | Object, enumValues: Object): string | undefined {
    if (value === undefined || value === null || enumValues === undefined) return undefined;

    let enumValuesTransform: EnumTranslate[] = new EnumToArrayPipe().transform(enumValues);
    let enumSelected: EnumTranslate = enumValuesTransform[value as number];

    return this.translateService.instant(enumSelected.translate);
  }
}
