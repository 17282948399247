<div class="dialog-component">
  <h1 mat-dialog-title>{{ dialogTitle }}</h1>
  <div class="body">
    <p>{{ dialogMessage }}</p>
    <mat-form-field>
      <input matInput [type]="dialogInputType" [(ngModel)]="value" [max]="maxValue" [min]="minValue" />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="ok()" color="accent">
      {{ dialogConfirmButtonTitle }}
    </button>
    <button mat-raised-button (click)="cancel()" color="basic">{{ dialogCancelButtonTitle }}</button>
  </div>
</div>
