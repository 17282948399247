<div class="container">
  <mat-card layout-align="center center">
    <mat-card-title>
      <img src="../../../assets/img/gca-logo-black-medium.png" class="logo" alt="Captive Audience" />
    </mat-card-title>
    <div>
      <label>{{ 'general.login.in_progress' | translate }}</label>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </mat-card>
</div>
