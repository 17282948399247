<div class="row images" *ngIf="data">
  <div class="header">
    <mat-label>{{ data.title }}</mat-label>
    <button mat-icon-button (click)="addImage()" appTooltip [tooltipText]="'mask.configuration.list.add_image' | translate"><mat-icon>add</mat-icon></button>
  </div>
  <div class="no-data" *ngIf="data.images.length === 0; else imageList">{{ 'mask.configuration.list.no_image' | translate }}</div>
  <ng-template #imageList>
    <div>
      <ul>
        <li *ngFor="let image of data.images; let index = index">
          <img [src]="image.blobDataUrl ?? image.imageUrl" *ngIf="image.blobDataUrl || image.imageUrl" />
          <input #input type="file" accept="image/*" (change)="fileChangeEvent($event, image)" [style.display]="'none'" />

          <span class="actions">
            <button *ngIf="showDeleteButton | async" mat-icon-button (click)="removeImage(index)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <button mat-icon-button (click)="openAssets(index)" *ngIf="!image.blobDataUrl && !image.imageUrl" title="{{ 'mask.configuration.media_library_title' | translate }}">
              <mat-icon svgIcon="image"></mat-icon>
            </button>
            <button mat-icon-button (click)="input.click()" *ngIf="!image.blobDataUrl && !image.imageUrl" title="{{ 'mask.configuration.workstation_title' | translate }}">
              <mat-icon svgIcon="upload"></mat-icon>
            </button>
          </span>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
