import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ClientLight } from 'src/app/core/clients/client';
import { Fleet, FleetLight } from 'src/app/core/fleet/fleet';
import { FleetService } from 'src/app/core/fleet/fleet.service';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { StorageKey } from 'src/app/core/local-storage/storageKeys';
import { UserData } from 'src/app/core/userData';
import { PaletteGenerator } from 'src/app/core/theming/palette-generator';

@Component({
  selector: 'app-clients-menu',
  templateUrl: './clients-menu.component.html',
  styleUrls: ['./clients-menu.component.scss'],
})
export class ClientsMenuComponent implements OnChanges, OnInit {
  private _userId: string;

  private get userFleetKey(): string {
    return `${StorageKey.selectedFleet}${this._userId}`;
  }

  @Input() public fleets: FleetLight[] = [];
  @Input() public clients: ClientLight[] = [];
  @Output() public fleetChanged: EventEmitter<void> = new EventEmitter<void>();

  public selectedClient: ClientLight;
  public selectedFleet: FleetLight;

  constructor(private router: Router, private localStorageService: LocalStorageService, private cookieService: CookieService, private fleetService: FleetService) {
    this._userId = this.localStorageService.readObject<UserData>(StorageKey.userData)?.sub ?? '';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.clients.length == 0 || this.fleets.length == 0) return;

    this.selectedFleet = this.cookieService.check(this.userFleetKey)
      ? JSON.parse(this.cookieService.get(this.userFleetKey))
      : this.fleets.find((f) => f.clientId === this.clients[0].technicalId);
    this.selectedClient = this.clients.find((c) => c.technicalId === this.selectedFleet.clientId)!;

    this.cookieService.set(this.userFleetKey, JSON.stringify(this.selectedFleet), undefined, '/');
  }

  public ngOnInit(): void {
    this.clients.sort((c1, c2) => c1.name.localeCompare(c2.name));
    this.fleets.sort((f1, f2) => f1.name.localeCompare(f2.name));
  }

  public redirectTo(uri: string): void {
    this.router.navigateByUrl('/gca', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }

  public fleetSelected(selectedClient: ClientLight, selectedFleet: FleetLight): void {
    this.selectedClient = selectedClient;
    this.selectedFleet = selectedFleet;
    this.cookieService.set(this.userFleetKey, JSON.stringify(this.selectedFleet), undefined, '/');

    this.fleetService.getUserFleet().subscribe((fleet: Fleet | null) => {
      PaletteGenerator.setUserTheme(fleet!);
      this.fleetChanged.emit();
      this.redirectTo(this.router.url);
    });
  }
}
