import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { DialogFormResult } from 'src/app/core/dialogFormResult';
import { NotificationService } from '../notification/notification.service';
import { PlanificationMask } from './planification-mask';
import { MaskSchedule, WeekDay } from 'src/app/mask-edition/models/mask-schedule';
import { dateConsistencyValidator } from 'src/app/mask-edition/step4-planification-mask/date-consistency-validator';
import { IRepetitionType, RepetitionEndType, RepetitionType } from 'src/app/mask-edition/models/repetition-type';
import { timePickerTheme } from '../../component-themes/time-picker-theme';

@Component({
  selector: 'app-plan-schedule-form',
  templateUrl: './plan-schedule-form.component.html',
  styleUrls: ['./plan-schedule-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanScheduleFormComponent implements OnInit {
  @Input() public isLiveEvent: boolean;
  @Input() public maskScheduleSrc: MaskSchedule;
  @Input() public editingPublishedMask: boolean;
  @Output() private formValuesChange: EventEmitter<DialogFormResult<MaskSchedule>> = new EventEmitter<DialogFormResult<MaskSchedule>>();

  private weekDays: WeekDay = WeekDay.None;
  private maskScheduleOutput: MaskSchedule;

  public readonly today: Date;

  public repetitions: IRepetitionType[];
  public repetitionEndTypeRadioButton: Record<RepetitionEndType, string>;

  public repetitionTypeEnum: typeof RepetitionType = RepetitionType;
  public repetitionEndType: typeof RepetitionEndType = RepetitionEndType;

  public timePickerTheme: NgxMaterialTimepickerTheme = timePickerTheme();

  public planForm: FormGroup;
  public formModificationHappened: boolean = false;

  constructor(private fb: NonNullableFormBuilder, private translateService: TranslateService, private notificationService: NotificationService) {
    this.today = new Date();
  }

  public ngOnInit(): void {
    this.weekDays = this.maskScheduleSrc.weekDays;
    this.maskScheduleOutput = { ...this.maskScheduleSrc };
    this.repetitionEndTypeRadioButton = {
      [RepetitionEndType.Never]: this.translateService.instant('mask.schedule.no_end_repeat'),
      [RepetitionEndType.Date]: this.translateService.instant('mask.schedule.end_repeat_date'),
    };
    this.getRepetitions(moment(this.maskScheduleSrc.startDate));
    this.initForm();
  }

  private getRepetitions(date: Moment): void {
    this.repetitions = [
      { id: RepetitionType.Once, value: this.translateService.instant('mask.schedule.repetition_type.once') },
      { id: RepetitionType.Daily, value: this.translateService.instant('mask.schedule.repetition_type.daily') },
      { id: RepetitionType.Weekly, value: this.translateService.instant('mask.schedule.repetition_type.weekly') },
      { id: RepetitionType.Monthly, value: this.translateService.instant('mask.schedule.repetition_type.monthly', { day: date.format('Do') }) },
      { id: RepetitionType.Yearly, value: this.translateService.instant('mask.schedule.repetition_type.yearly', { day: date.format('Do'), month: date.format('MMMM') }) },
    ];
  }

  private initForm(): void {
    const startDate: Date = new Date(this.maskScheduleSrc.startDate);
    const startHours: number = this.isLiveEvent ? startDate.getHours() : startDate.getUTCHours();
    const startMinutes: number = this.isLiveEvent ? startDate.getMinutes() : startDate.getUTCMinutes();

    const endDate: Date = new Date(this.maskScheduleSrc.endDate);
    const endHours: number = this.isLiveEvent ? endDate.getHours() : endDate.getUTCHours();
    const endMinutes: number = this.isLiveEvent ? endDate.getMinutes() : endDate.getUTCMinutes();

    const repEndType: RepetitionEndType = this.maskScheduleSrc.id === 0 || this.maskScheduleSrc.repetitionEndDate === null ? RepetitionEndType.Never : RepetitionEndType.Date;
    const endRepDate: Moment = this.maskScheduleSrc.repetitionEndDate === null ? moment(startDate).add(1, 'd') : moment.utc(this.maskScheduleSrc.repetitionEndDate);

    this.planForm = this.fb.group(
      {
        id: [this.maskScheduleSrc.id, []],
        startDate: [
          {
            value: this.isLiveEvent ? moment(this.maskScheduleSrc.startDate) : moment.utc(this.maskScheduleSrc.startDate),
            disabled: this.editingPublishedMask === true && moment(startDate).isBefore(this.today, 'day'),
          },
          [Validators.required],
        ],
        startTime: [`${startHours}:${startMinutes}`, [Validators.required]],
        endTime: [`${endHours}:${endMinutes}`, [Validators.required]],
        repetition: [this.repetitions[this.maskScheduleSrc.repetitionType].id, [Validators.required]],
        repetitionEndType: [repEndType, [Validators.required]],
        repetitionEndDate: [endRepDate, [Validators.required]],
      },
      {
        validators: [dateConsistencyValidator()],
      }
    );

    this.onFormValueChanges();
  }

  public onWeekDayChecked(event: MatCheckboxChange): void {
    // active le bit correspondant au jour sélctionné
    if (event.checked) this.weekDays = this.weekDays | parseInt(event.source.value);
    // désactive le bit correspondant au jour désélectionné
    else this.weekDays = this.weekDays & ~parseInt(event.source.value);

    this.formModificationHappened = true;

    this.validatePlanForm();
  }

  public isDayChecked(weekDay: WeekDay): boolean {
    return (this.weekDays & weekDay) === weekDay;
  }

  private onFormValueChanges(): void {
    this.formModificationHappened = true;

    this.planForm.controls['startDate'].valueChanges.subscribe(() => {
      this.changeRepetitionEndDate();
    });

    this.planForm.valueChanges.subscribe(() => {
      this.formModificationHappened = true;
      this.validatePlanForm();
    });
  }

  private validatePlanForm(): void {
    const planificationMask: PlanificationMask = { ...this.planForm.getRawValue() };
    this.convertPlanificationMaskToMaskSchedule(this.maskScheduleOutput, planificationMask);

    let dialogFormResult: DialogFormResult<MaskSchedule> = new DialogFormResult<MaskSchedule>();
    dialogFormResult.entity = this.maskScheduleOutput;
    dialogFormResult.valid = this.isFormValid() ? ConfirmResult.Yes : ConfirmResult.No;
    dialogFormResult.change = this.formModificationHappened && this.maskScheduleHasChanged() ? ConfirmResult.Yes : ConfirmResult.No;

    this.formValuesChange.emit(dialogFormResult);
  }

  private maskScheduleHasChanged(): boolean {
    if (
      new Date(this.maskScheduleSrc.startDate).getTime() !== new Date(this.maskScheduleOutput.startDate).getTime() ||
      new Date(this.maskScheduleSrc.endDate).getTime() !== new Date(this.maskScheduleOutput.endDate).getTime() ||
      this.maskScheduleSrc.repetitionType !== this.maskScheduleOutput.repetitionType ||
      new Date(this.maskScheduleSrc.repetitionEndDate!).getTime() !== new Date(this.maskScheduleOutput.repetitionEndDate!).getTime() ||
      this.weekDays !== this.maskScheduleSrc.weekDays
    ) {
      return true;
    }

    return false;
  }

  public isFormValid(): boolean {
    if (this.planForm.errors) {
      if (this.formModificationHappened) {
        if (this.planForm.errors['required']) {
          this.notificationService.displayError(this.translateService.instant('mask.schedule.error.mandatory'));
        } else if (this.planForm.errors && this.planForm.errors['dateConsistency']) {
          this.notificationService.displayError(this.translateService.instant('mask.schedule.error.date_consistency'));
        }
      }
      return false;
    }
    return true;
  }

  private changeRepetitionEndDate(): void {
    const startDate: Moment = moment(this.planForm.controls['startDate'].value);

    if (this.planForm.controls['repetitionEndType'].value === RepetitionEndType.Never) {
      let newRepetitionEndDate: Moment;
      if (this.planForm.controls['repetition'].value === RepetitionType.Yearly) {
        newRepetitionEndDate = moment.utc(startDate).year(startDate.year() + 1);
      } else {
        newRepetitionEndDate = moment.utc(startDate).month(startDate.month() + 1);
      }

      this.planForm.controls['repetitionEndDate'].setValue(newRepetitionEndDate);
    }

    this.getRepetitions(moment(startDate));
  }

  private convertPlanificationMaskToMaskSchedule(maskSchedule: MaskSchedule, planificationMask: PlanificationMask): void {
    maskSchedule.startDate = new Date(
      Date.UTC(
        planificationMask.startDate.year(),
        planificationMask.startDate.month(),
        planificationMask.startDate.date(),
        +planificationMask.startTime.split(':')[0],
        +planificationMask.startTime.split(':')[1],
        0
      )
    );

    maskSchedule.endDate = new Date(
      Date.UTC(
        planificationMask.startDate.year(),
        planificationMask.startDate.month(),
        planificationMask.startDate.date(),
        +planificationMask.endTime.split(':')[0],
        +planificationMask.endTime.split(':')[1],
        0
      )
    );

    maskSchedule.repetitionType = planificationMask.repetition;

    if (maskSchedule.repetitionType == RepetitionType.Weekly) maskSchedule.weekDays = this.weekDays;
    else maskSchedule.weekDays = WeekDay.None;

    if (maskSchedule.repetitionType !== RepetitionType.Once && planificationMask.repetitionEndType !== RepetitionEndType.Never) {
      maskSchedule.repetitionEndDate = new Date(
        Date.UTC(planificationMask.repetitionEndDate.year(), planificationMask.repetitionEndDate.month(), planificationMask.repetitionEndDate.date(), 23, 59, 59, 999)
      );
    } else {
      maskSchedule.repetitionEndDate = null;
    }
  }
}
