<button
  mat-icon-button
  [ngClass]="{ 'disable-click': disabled }"
  [disableRipple]="disabled"
  [matMenuTriggerFor]="slideTimerMenu"
  (onMenuClose)="onMenuClosed()"
  (onMenuOpen)="onMenuOpened()"
  appTooltip
  [tooltipText]="disabled ? ('mask.slide.duration.edit_unavailable' | translate) : ('mask.slide.duration.edit' | translate)"
>
  <mat-icon *ngIf="!disabled; else noTimer">timer</mat-icon>
  <ng-template #noTimer>
    <mat-icon>timer_off</mat-icon>
  </ng-template>
</button>

<mat-menu #slideTimerMenu="matMenu" xPosition="before">
  <div class="row" (click)="$event.stopPropagation()">
    <form [formGroup]="form" novalidate (keydown.tab)="$event.stopPropagation()">
      <mat-form-field (click)="$event.stopPropagation()">
        <input matInput type="number" [min]="minDuration" (keyup.enter)="onValidation()" formControlName="duration" />
        <mat-error *ngIf="form.invalid">
          {{ getErrorMessage() }}
        </mat-error>
      </mat-form-field>
    </form>
    <button mat-raised-button color="accent" class="button-ok" [disabled]="form.invalid" (click)="onValidation()">Ok</button>
  </div>
</mat-menu>
