import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { StorageKey } from '../local-storage/storageKeys';
import { SettingsService } from '../setttings/settings.service';

// Any rule disabled here due to HttpInterceptor inheritage.
// tslint:disable: no-any
@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {
  private settingsService: SettingsService;
  private router: Router;
  private securityService: OidcSecurityService;
  private localStorageService: LocalStorageService;

  constructor(private injector: Injector) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.settingsService === undefined) this.settingsService = this.injector.get(SettingsService);

    if (this.router === undefined) this.router = this.injector.get(Router);

    if (this.securityService === undefined) this.securityService = this.injector.get(OidcSecurityService);

    if (this.localStorageService === undefined) this.localStorageService = this.injector.get(LocalStorageService);

    if (req.url.startsWith(this.settingsService.settings.API_URL)) {
      return next.handle(req).pipe(
        tap(
          (ev: HttpEvent<any>) => {
            return;
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) this.localStorageService.write(StorageKey.redirectUrl, this.router.url);
              if (err.status === 403) this.router.navigate(['/autologin']);
            }
          }
        )
      );
    } else {
      return next.handle(req);
    }
  }
}
