import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MediaLibrary } from 'src/app/core/media-library/media-library';
import { MediaLibraryService } from 'src/app/core/media-library/media-library.service';

@Injectable({
  providedIn: 'root',
})
export class MaskPictureService {
  public pictureSavedToLibrary: Subject<MediaLibrary> = new Subject<MediaLibrary>();

  constructor(private mediaService: MediaLibraryService) {}

  public sendPictureSaved(pictureSaved: MediaLibrary[]): void {
    this.mediaService.getMedia(pictureSaved[0].id).subscribe((m) => this.pictureSavedToLibrary.next(m));
  }
}
