<div class="list row" *ngIf="data">
  <form class="example-form" *ngIf="data.filterEnabled">
    <mat-form-field class="example-full-width">
      <mat-label>Entités du groupe Orange</mat-label>
      <input class="autocomplete" #filterInput type="text" placeholder="" aria-label="Entités du groupe Orange" matInput [formControl]="categoryControl" [matAutocomplete]="auto" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayOptionValue" (optionSelected)="selectOptionForFiltering($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option.value }}</mat-option>
      </mat-autocomplete>
      <button mat-button matSuffix *ngIf="filterInput.value" mat-icon-button (click)="clearFilterInput()">
        <mat-icon class="clear">close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <input
        id="search"
        #searchInput
        type="text"
        [placeholder]="'mask-table.filter.keyword-search' | translate"
        matInput
        [formControl]="searchControl"
        (keypress)="handleSearchInput($event)"
      />
      <button mat-button matSuffix *ngIf="searchInput.value" mat-icon-button (click)="clearSearchInput()">
        <mat-icon class="clear">close</mat-icon>
      </button>
      <button mat-button matSuffix mat-icon-button (click)="searchForInputValue()">
        <mat-icon class="search">search</mat-icon>
      </button>
    </mat-form-field>
  </form>

  <table mat-table [dataSource]="listItems" class="mask-table">
    <!-- Image Column -->
    <ng-container matColumnDef="image">
      <td mat-cell *matCellDef="let item">
        <img [src]="item.imageUrl" class="image" onerror="this.onerror = null; this.src = '../../../../../assets/img/image-not-found.jpg'" />
      </td>
    </ng-container>

    <!-- Content Column -->
    <ng-container matColumnDef="content">
      <td mat-cell *matCellDef="let item" class="cell-content">
        <span class="title">
          {{ item.title }}
        </span>
        <span class="footer">
          {{ item.footer }}
        </span>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'row-selected': row.selected }" (click)="selectSingleItem(row)"></tr>

    <!-- Group header -->
    <ng-container matColumnDef="groupHeader">
      <td colspan="3" mat-cell *matCellDef="let groupBy">
        <div>{{ groupBy.group }}</div>
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"></tr>
  </table>
</div>
