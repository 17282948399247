<div class="container">
  <div class="side-sheet-close">
    <button mat-icon-button (click)="closeDialog(confirmResultEnum.No)">
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div class="side-sheet-title">
    {{ 'mask.slide.reorganize.title' | translate }}
  </div>
  <div class="side-sheet-form">
    <div cdkDropList class="list" (cdkDropListDropped)="drop($event)">
      <div cdkDrag class="box" *ngFor="let slide of slides">
        <span class="box-title">
          {{ slide.name }}
        </span>
        <mat-icon class="box-icon">reorder</mat-icon>
      </div>
    </div>
  </div>
  <div class="side-sheet-actions">
    <app-busy-button class="action-button" [isBusy]="loading" [text]="'common.validate' | translate" (buttonClick)="onValidate()">
      {{ 'common.validate' | translate }}
    </app-busy-button>
    <button mat-raised-button (click)="closeDialog(confirmResultEnum.No)" class="action-button">{{ 'common.cancel' | translate }}</button>
  </div>
</div>
