<div class="row videos" *ngIf="data">
  <div class="header">
    <span>{{ data.title }}</span>
    <button
      *ngIf="viewMode === viewModeEnum.Media; else displayListModeButton"
      mat-icon-button
      [ngClass]="{ 'disable-click': data.videos.length === 1 }"
      [disableRipple]="data.videos.length === 1"
      (click)="showListView()"
    >
      <mat-icon>format_list_numbered</mat-icon>
    </button>
    <ng-template #displayListModeButton>
      <button mat-icon-button (click)="validateReorder()">
        <mat-icon>check</mat-icon>
      </button>
    </ng-template>
  </div>
  <div class="no-data" *ngIf="data.videos.length === 0; else videoList">{{ 'mask.configuration.list.no_video' | translate }}</div>
  <ng-template #videoList>
    <div class="videos-container">
      <div *ngIf="viewMode === viewModeEnum.Media; else displayList">
        <ul>
          <li *ngFor="let video of data.videos; let index = index">
            <img [src]="video.thumbnailUrl" *ngIf="video.thumbnailUrl" />
            <span class="actions">
              <button *ngIf="showDeleteButton | async" mat-icon-button (click)="removeVideo(index)">
                <mat-icon color="warn">delete</mat-icon>
              </button>
              <button mat-icon-button (click)="openAssets(index)" *ngIf="!video.blobId" title="{{ 'mask.configuration.media_library_title' | translate }}">
                <mat-icon>movie_creation</mat-icon>
              </button>
            </span>
          </li>
        </ul>
        <button mat-icon-button [disableRipple]="false" (click)="addVideo()" appTooltip [tooltipText]="'mask.configuration.list.add_video' | translate">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <ng-template #displayList>
        <div cdkDropList class="list" (cdkDropListDropped)="drop($event)">
          <div cdkDrag class="box" *ngFor="let video of data.videos">
            <span class="box-title">
              {{ video.name }}
            </span>
            <mat-icon class="box-icon">reorder</mat-icon>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
