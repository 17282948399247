import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public scrollAsked: Subject<Event> = new Subject<Event>();

  public onScroll(event: Event): void {
    this.scrollAsked.next(event);
  }
}
