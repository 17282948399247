import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { FieldTextList } from 'src/app/core/template-configuration/field-text-list';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configuration-mask-text-list',
  templateUrl: './configuration-mask-text-list.component.html',
  styleUrls: ['./configuration-mask-text-list.component.scss'],
})
export class ConfigurationMaskTextListComponent implements OnInit, ConfigurationMaskItemComponent {
  private _errors: boolean[];
  private _data: FieldTextList;
  private _valid: boolean = true;

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  public showDeleteButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @Input() public set data(value: FieldTextList) {
    this._data = value;
    this._errors = new Array<boolean>(value.texts.length);
  }

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.addMandatoryField();
  }

  public get data(): FieldTextList {
    return this._data;
  }

  public get isValid(): boolean {
    return this._valid;
  }

  public get errors(): boolean[] {
    return this._errors;
  }

  private addMandatoryField(): void {
    if (this._data.mandatory && this._data.texts.length === 0) {
      this.addText();
    } else {
      this.updateDeleteButtonVisibility();
    }
  }

  public onChange(): void {
    this.validate();
    if (this._valid) this.outputValue.next(this.data);
  }

  public onFocusOut(): void {
    this.validate();
    if (this._valid) this.outputFocus.next(this.data);
  }

  public addText(): void {
    this.data.texts.push('');
    this._errors.push(false);
    this.updateDeleteButtonVisibility();
  }

  public deleteText(index: number): void {
    const textToRemove: string = this.data.texts[index];
    this.data.texts.splice(index, 1);
    this._errors.splice(index, 1);

    if (textToRemove.length > 0) {
      this.outputFocus.next(this.data);
    }

    this.addMandatoryField();
  }

  private validate(): void {
    this._valid = this.data.isValid();
    if (!this._valid) this._errors = this.data.texts.map((item) => item.length > this.data.maxLength || item.length === 0);
  }

  public getTextError(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return this.translateService.instant('mask.configuration.text_error', { max: maxLength });
    } else if (text.length === 0) {
      return this.translateService.instant('mask.configuration.no_text_error');
    }

    return '';
  }

  public updateDeleteButtonVisibility(): void {
    const show: boolean = this._data.texts.length > 1 || this._data.mandatory === false;
    this.showDeleteButton.next(show);
  }

  /**
   * Forces Angular to track the ngFor generated inputs by their index
   * @param index item index
   * @returns
   */
  public trackByIndex(index: number) {
    return index;
  }
}
