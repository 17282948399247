import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as jsonpatch from 'fast-json-patch';
import { Guid } from 'guid-typescript';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { Client, ClientLight } from '../clients/client';
import { SettingsService } from '../setttings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private baseUrl: string = `${this.settingsService.settings.API_URL}/client`;

  constructor(private loaderService: LoaderService, private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {}

  public getLight(silently: boolean, activeOnly: boolean = true): Observable<ClientLight[]> {
    const url: string = `${this.baseUrl}/light` + (activeOnly ? '?active=true' : '');
    if (silently) this.loaderService.addExcludedUrl(url);

    return this.httpClient.get<ClientLight[]>(url).pipe(
      finalize(() => this.loaderService.removeExcludedUrl(url)),
      catchError((err) => {
        err.message = this.translateService.instant('clients.error.get_clients');
        return throwError(err);
      })
    );
  }

  /**
   * Get all clients
   */
  public getAll(): Observable<Client[]> {
    return this.httpClient.get<Client[]>(`${this.baseUrl}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('clients_accounts.error.get');
        return throwError(err);
      })
    );
  }

  /**
   * Get client by Id
   */
  public getByGuid(clientId: Guid): Observable<ClientLight> {
    return this.httpClient.get<ClientLight>(`${this.baseUrl}/light/${clientId}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('clients_accounts.error.get_by_guid', { id: clientId });
        return throwError(err);
      })
    );
  }

  /**
   * Get client by Id
   */
  public getById(clientId: number): Observable<Client> {
    return this.httpClient.get<Client>(`${this.baseUrl}/${clientId}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('clients_accounts.error.get_by_id', { id: clientId });
        return throwError(err);
      })
    );
  }

  /**
   * Update client status
   */
  public updateStatus(client: Client, clientPatch: jsonpatch.Operation[]): Observable<Client> {
    return this.httpClient
      .patch<Client>(`${this.baseUrl}/${client.id}/status`, clientPatch, { observe: 'body', headers: { impersonated_client: client.technicalId.toString() } })
      .pipe(
        catchError((err) => {
          err.message = this.translateService.instant('clients_accounts.error.patch_status', { id: client.id });
          return throwError(err);
        })
      );
  }

  /**
   * Create or update a client
   */
  public send(client: Client): Observable<Client> {
    return client.id !== 0 ? this.put(client) : this.post(client);
  }

  /**
   * Post a client
   */
  public post(client: Client): Observable<Client> {
    return this.httpClient.post<Client>(`${this.baseUrl}`, client, { observe: 'body' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('clients_accounts.error.post');
        return throwError(err);
      })
    );
  }

  /**
   * Put a client
   */
  public put(client: Client): Observable<Client> {
    return this.httpClient.put<Client>(`${this.baseUrl}/${client.id}`, client, { observe: 'body' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('clients_accounts.error.put', { name: client.name });
        return throwError(err);
      })
    );
  }
}
