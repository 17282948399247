import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserData } from '../userData';
import { Role } from './role';
import { ScopePolicies } from './scope-policies';

@Injectable({
  providedIn: 'root',
})
export class ScopeGuard implements CanActivate, CanLoad {
  constructor(protected router: Router, protected oidcSecurityService: OidcSecurityService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkGroupsClaim(state.url);
  }

  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkGroupsClaim(state.url);
  }

  public canLoad(state: Route): Observable<boolean> {
    return this.checkGroupsClaim(state.path!);
  }

  private checkGroupsClaim(url: string): Observable<boolean> {
    return this.oidcSecurityService.userData$.pipe(
      map((user: UserData | null) => {
        var success: boolean = user !== null && user.roles !== Role.None && ScopePolicies.IsRouteAccessible(url, user.roles);
        if (!success) this.router.navigate(['/forbidden']);
        return true;
      })
    );
  }
}
