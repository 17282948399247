import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DialogResult } from 'src/app/core/dialogResult';
import { Role } from 'src/app/core/guards/role';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { StorageKey } from 'src/app/core/local-storage/storageKeys';
import { ScheduleHelper } from 'src/app/core/scheduleHelper';
import { UserData } from 'src/app/core/userData';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { MaskTile } from 'src/app/shared/components/mask-tile/mask-tile';
import { Mask } from 'src/app/mask-edition/models/mask';
import { MasksService } from 'src/app/mask-edition/masks.service';
import { DialogProgressComponent } from 'src/app/shared/components/dialog-progress/dialog-progress.component';
import { DialogPreviewComponent, DialogPreviewData } from 'src/app/shared/components/preview/dialog-preview/dialog-preview.component';
import { NotificationService } from '../notification/notification.service';
import { TooltipData } from '../tooltip/tooltip-data';
import { MaskAudiencesDialogComponent } from './mask-audiences-dialog/mask-audiences-dialog.component';
import { MaskAudience } from 'src/app/mask-edition/models/mask-audience';
import { ConfirmComponent } from '../confirm/confirm.component';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { MaskScheduleStatus } from 'src/app/mask-edition/models/mask-schedule-status';

@Component({
  selector: 'app-mask-tile',
  templateUrl: './mask-tile.component.html',
  styleUrls: ['./mask-tile.component.scss'],
})
export class MaskTileComponent implements OnInit, OnDestroy {
  @Input() public mask: MaskTile;
  @Output() public deletionSucceeded: EventEmitter<number> = new EventEmitter<number>();

  public audiences: TooltipData[];
  public schedule: string;
  public detailedSchedule: string;
  public authorIsHidden: boolean = false;
  public authorPosX: string = '0px';
  public authorPosY: string = '0px';

  public deletingMask: boolean = false;

  private userData: UserData;
  private languageChangeSubscription: Subscription;

  public maskScheduleStatusEnums: typeof MaskScheduleStatus = MaskScheduleStatus;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private maskService: MasksService,
    private scheduleHelper: ScheduleHelper,
    private loaderService: LoaderService,
    private localStorageService: LocalStorageService
  ) {}

  public ngOnInit(): void {
    this.userData = this.localStorageService.readObject<UserData>(StorageKey.userData)!;
    this.audiences = this.mask.audiences.map((x) => ({ title: undefined, text: x.name }));
    this.authorIsHidden = !this.mask.authorName || !this.mask.authorEmail;
    this.initMaskScheduleLabel();
    this.setIconInfoWidth();

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((lang) => {
      this.initMaskScheduleLabel();
    });
  }

  public ngOnDestroy(): void {
    if (!this.languageChangeSubscription.closed) this.languageChangeSubscription.unsubscribe();
  }

  // wtf?
  private setIconInfoWidth(): void {
    if (this.mask.isChannelMask) document.documentElement.style.setProperty('--icon-info-width', '49%');
    else document.documentElement.style.setProperty('--icon-info-width', '33%');
  }

  private initMaskScheduleLabel(): void {
    this.schedule = this.mask.maskSchedule ? this.scheduleHelper.extractMaskSchedule(this.mask.maskSchedule) : this.translateService.instant('mask_list.tile.schedule.not_planned');
    this.detailedSchedule = this.mask.maskSchedule ? this.scheduleHelper.extractDetailedMaskSchedule(this.mask.maskSchedule) : '';
  }

  public editMask(): void {
    this.localStorageService.write(StorageKey.lastUrlBeforeMaskEdition, this.router.url);

    this.loaderService.enable();

    if (!this.mask.isReadOnly || !this.mask.isPublished) this.router.navigate(['/mask', this.mask.id, 'fill']);
    else this.openAudiencesFormDialog();
  }

  private openAudiencesFormDialog(): void {
    const dialogRef: MatDialogRef<MaskAudiencesDialogComponent, DialogResult<MaskAudience[]>> = this.dialog.open(MaskAudiencesDialogComponent, {
      width: '60vw',
      data: this.mask,
    });

    dialogRef.afterClosed().subscribe((result: DialogResult<MaskAudience[]> | undefined) => {
      if (result) this.audiences = result.entity!.map((x) => ({ title: undefined, text: x.audienceName } as TooltipData));
    });
  }

  public previewMask(): void {
    if (this.mask.slidesCount > 0) {
      this.maskService
        .getPreview(this.mask.id)
        .pipe(finalize(() => this.loaderService.hide()))
        .subscribe((slidePreviews) => {
          this.loaderService.hide();
          this.dialog.open(DialogPreviewComponent, {
            panelClass: 'pane-video-preview',
            data: { screenFormat: this.mask.screenFormatId, slidePreviews: slidePreviews } as DialogPreviewData,
          });
        });
    }
  }

  public duplicateMask(): void {
    const dialogConf: MatDialogConfig = new MatDialogConfig();
    dialogConf.data = { dialogMessage: this.translateService.instant('mask_list.duplicate_in_progress') };

    const dialogRef: MatDialogRef<DialogProgressComponent> = this.dialog.open(DialogProgressComponent, dialogConf);

    this.maskService.copy(this.mask).subscribe(
      (createdMask) => this.onDuplicateMaskSuccess(createdMask, dialogRef),
      (error) => this.notificationService.displayError(error)
    );
  }

  private onDuplicateMaskSuccess(mask: Mask, dialogRef: MatDialogRef<DialogProgressComponent>): void {
    dialogRef.close();
    this.router.navigate(['/mask', mask.id, 'informations']);
  }

  public requestMaskDeletion(): void {
    if (this.canDelete) {
      this.deleteMaskRequested(this.mask);
    }
  }

  private deleteMaskRequested(maskToDelete: MaskTile): void {
    const dialogConf: MatDialogConfig = new MatDialogConfig();

    dialogConf.data = {
      message: this.translateService.instant('mask_list.suppress_confirm', { name: maskToDelete.name }),
      confirmButtonTitle: this.translateService.instant('common.yes'),
      cancelButtonTitle: this.translateService.instant('common.no'),
    };

    const dialogRef: MatDialogRef<ConfirmComponent> = this.dialog.open(ConfirmComponent, dialogConf);
    dialogRef.afterClosed().subscribe((res: DialogResult<ConfirmResult>) => this.onConfirmDeleteClosed(maskToDelete.id, res.confirm));
  }

  private onConfirmDeleteClosed(maskId: number, res: ConfirmResult): void {
    if (res !== ConfirmResult.Yes) return;

    this.deletingMask = true;

    this.maskService
      .delete(maskId)
      .pipe(finalize(() => (this.deletingMask = false)))
      .subscribe({
        next: () => this.deletionSucceeded.emit(maskId),
        error: (error) => this.notificationService.displayError(error),
      });
  }

  public get canDelete(): boolean {
    return !this.mask.isReadOnly || this.mask.authorId.toString() === this.userData.sub || (this.userData.roles & (Role.SuperAdministrator | Role.Hotline)) > 0;
  }
}
