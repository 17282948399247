import { Guid } from 'guid-typescript';
import { SlideAsset } from './slide-asset';
import { SlideTemplate } from './slide-template';

export class Slide {
  public id: number;
  public technicalId: Guid;
  public maskId: number;
  public assets: SlideAsset[];

  public name: string;
  public order: number;
  public duration: number;

  public templateId?: number;
  public slideTemplate?: SlideTemplate; // It's for configuration only ! Not need store in database / SHOULD NOT BE SENT OVER TO THE API !!!
  // public templateName: String;

  constructor(maskId: number) {
    this.maskId = maskId;
    this.duration = 0;
  }
}
