import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';
import { FieldVideoList } from 'src/app/core/template-configuration/field-video-list';
import { AssetType } from '../list-assets/assetType';
import { MediaDropData } from '../list-assets/mediaDropData';
import { VideoData } from '../slide/slide-asset';
import { ListAssetsService } from '../list-assets/list-assets.service';
import { AssetRequest } from '../list-assets/asset-request';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SlideAssetService } from '../slide/slide-asset.service';
import { MediaAssetTitle } from '../list-assets/media-asset-title';

export enum ViewMode {
  Media = 1,
  List,
}

@Component({
  selector: 'app-configuration-mask-video-list',
  templateUrl: './configuration-mask-video-list.component.html',
  styleUrls: ['./configuration-mask-video-list.component.scss'],
})
export class ConfigurationMaskVideoListComponent implements OnInit, ConfigurationMaskItemComponent {
  @Input() public data: FieldVideoList;

  private mediaReorderingHappened: boolean = false;

  public showDeleteButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public viewMode: ViewMode = ViewMode.Media;
  public viewModeEnum: typeof ViewMode = ViewMode;

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  public get isValid(): boolean {
    return this.data.isValid();
  }

  constructor(private listAssetsService: ListAssetsService, private slideAssetService: SlideAssetService) {}

  ngOnInit(): void {
    this.addMandatoryField();
    this.slideAssetService.getMediaAssetsInfos(this.data.videos.map((v) => v.blobId)).subscribe({
      next: (result: MediaAssetTitle[]) => {
        this.data.videos.map((v) => {
          v.name = result.find((r) => r.blobId === v.blobId)?.assetTitle;
          return v;
        });
      },
    });
  }

  private addMandatoryField(): void {
    if (this.data.mandatory && this.data.videos.length === 0) {
      this.addVideo();
    } else {
      this.updateDeleteButtonVisibility();
    }
  }

  public addVideo(): void {
    this.data.videos.push(new VideoData());
    this.updateDeleteButtonVisibility();
  }

  public removeVideo(index: number): void {
    const videoToRemove: VideoData = this.data.videos[index];

    this.data.videos.splice(index, 1);

    if (videoToRemove.blobId != null) {
      this.checkAndSendUpdatedData();
    }

    this.addMandatoryField();
  }

  public drop(event: CdkDragDrop<string[]>): void {
    this.mediaReorderingHappened = true;
    moveItemInArray(this.data.videos, event.previousIndex, event.currentIndex);
  }

  public showListView(): void {
    this.viewMode = ViewMode.List;
    this.data.videos = this.filterWithFilledAssets();
  }

  private filterWithFilledAssets(): VideoData[] {
    return this.data.videos.filter((v) => v.blobId != undefined);
  }

  public validateReorder(): void {
    if (this.mediaReorderingHappened === true) {
      this.mediaReorderingHappened = false;
      this.checkAndSendUpdatedData();
    }
    this.viewMode = ViewMode.Media;
  }

  private checkAndSendUpdatedData() {
    if (this.data.isValid()) {
      this.outputValue.next(this.data);
      this.outputFocus.next(this.data);
    }
  }

  public openAssets(index: number): void {
    this.listAssetsService.listAssets(new AssetRequest(AssetType.Video, this.data.id, index));
  }

  public onAssetSelected(mediaVideoDropData: MediaDropData): void {
    if (mediaVideoDropData.mediaType !== AssetType.Video) return;

    const video: VideoData = this.data.videos[mediaVideoDropData.additionalData as number];

    video.thumbnailUrl = mediaVideoDropData.thumbnailUrl;
    video.blobId = mediaVideoDropData.blobId;
    video.videoUrl = mediaVideoDropData.blobUrl;
    video.name = mediaVideoDropData.name;

    this.checkAndSendUpdatedData();
    this.updateDeleteButtonVisibility();
  }

  public updateDeleteButtonVisibility(): void {
    const show: boolean = this.data.mandatory === false || this.data.videos.length > 1 || this.data.videos.some((i) => i.blobId);

    this.showDeleteButton.next(show);
  }
}
