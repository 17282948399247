import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';
import { Fleet } from './core/fleet/fleet';
import { FleetService } from './core/fleet/fleet.service';
import { LocalStorageService } from './core/local-storage/local-storage.service';
import { StorageKey } from './core/local-storage/storageKeys';
import { SessionStorageService } from './core/session-storage/session-storage.service';
import { Settings } from './core/setttings/settings';
import { UserData } from './core/userData';
import { UserSettingsService } from './shared/services/user-settings.service';
import { PaletteGenerator } from './core/theming/palette-generator';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly resetPwdErrorCode: string = 'AADB2C90118';

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private userSettingsService: UserSettingsService,
    private fleetService: FleetService,
    private router: Router,

    public http: HttpClient
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      if ((event as NavigationStart).url != '/autologin') localStorage.setItem(StorageKey.lastUrl, (event as NavigationStart).url);
    });

    this.sessionStorageService.delete(StorageKey.userSettings);
  }

  public ngOnInit(): void {
    if (window.location.href.includes(this.resetPwdErrorCode)) {
      const urlHandler: (url: string) => void = (url: string): void => {
        fetch('/settings/settings.json')
          .then((response: Response) => response.json())
          .then((settings: Settings) => {
            const pwdResetUrl: string = url.replace(settings.OIDC_CONFIG.b2cSignInPolicy.toLowerCase(), settings.OIDC_CONFIG.b2cPasswordResetPolicy.toLowerCase());
            return (window.location.href = pwdResetUrl);
          });
      };
      this.oidcSecurityService.authorize({ urlHandler: urlHandler });
    } else {
      this.checkAuthState();
    }
  }

  private checkAuthState(): void {
    this.oidcSecurityService.checkAuth().subscribe((isAuthenticated: boolean) => this.redirect(isAuthenticated));
  }

  private redirect(isAuthenticated: boolean): void {
    if (!isAuthenticated) {
      this.sessionStorageService.clear();
      if ('/autologin' !== window.location.pathname) {
        this.router.navigate(['/autologin']);
      }
    } else {
      this.getUserData();
    }
  }

  private getUserData(): void {
    this.oidcSecurityService.userData$.subscribe((u: UserData) => {
      if (u) {
        let userData: UserData = u;
        userData.initialName = this.getInitialName(u.name);
        this.localStorageService.writeObject(StorageKey.userData, userData);
      }

      this.fleetService.getUserFleet().subscribe((fleet: Fleet | null) => {
        PaletteGenerator.setUserTheme(fleet!);
        this.navigateToStoredEndpoint();
      });

      this.userSettingsService.initialize();
    });
  }

  private getInitialName(name: string): string {
    return name
      .split(' ')
      .splice(0, 2)
      .map((n: string) => n[0])
      .join('');
  }

  private navigateToStoredEndpoint(): void {
    const path: string | null = this.localStorageService.read(StorageKey.redirectUrl, '');

    if (!path || this.router.url === path || path === '/gca') {
      return;
    }

    this.localStorageService.delete(StorageKey.redirectUrl);
    this.router.navigate([path]);
  }
}
