import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Guid } from 'guid-typescript';
import { Observable, Subscription } from 'rxjs';
import { ClientLight } from 'src/app/core/clients/client';
import { ClientService } from 'src/app/core/clients/client.service';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { DialogResult } from 'src/app/core/dialogResult';
import { FleetLight } from 'src/app/core/fleet/fleet';
import { FleetService } from 'src/app/core/fleet/fleet.service';
import { Role } from 'src/app/core/guards/role';
import { BlobStorageService } from 'src/app/core/media-library/blob-storage.service';
import { CodecData } from 'src/app/core/media-library/codec-data';
import { MediaLibrary } from 'src/app/core/media-library/media-library';
import { MediaLibraryService } from 'src/app/core/media-library/media-library.service';
import { MediaStatus } from 'src/app/core/media-library/media-status';
import { MediaType } from 'src/app/core/media-library/media-type';
import { Permission } from 'src/app/core/media-library/permission';
import { Tag } from 'src/app/core/media-library/tag';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { MediainfoWasmService } from 'src/app/media/media-info/mediainfo-wasm.service';
import { HttpResponseManager } from '../../managers/http-responses/http-response-manager';
import { ConfirmComponent } from '../confirm/confirm.component';
import { NotificationService } from '../notification/notification.service';
import { MediaFile } from './media-file';
import { MediaFormMode } from './media-form-mode';
import { MediaInputData } from './media-input-data';
import { KeyboardKey } from 'src/app/core/enums/keyboard-key.enum';

@Component({
  selector: 'app-media-form',
  templateUrl: './media-form.component.html',
  styleUrls: ['./media-form.component.scss'],
})
export class MediaFormComponent implements OnInit, OnDestroy {
  private mediaInfoSubscription: Subscription;
  public isAdminOrHotline: boolean = false;

  public userFleet: FleetLight = { name: '', clientId: Guid.createEmpty(), id: 0, technicalId: Guid.createEmpty() };
  public userClient: ClientLight = { name: '', isActive: true, technicalId: Guid.createEmpty() };

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public confirmResultEnum: typeof ConfirmResult = ConfirmResult;
  public mediaTypeEnum: typeof MediaType = MediaType;
  public selectedTags: Array<Tag> = [];

  public isCollapsibleOpen: boolean = false;

  public mediaLibraryItem: MediaLibrary;
  public formMode: MediaFormMode = MediaFormMode.Create;
  public formModeEnum: typeof MediaFormMode = MediaFormMode;
  public formModificationHappened: boolean;

  public isMediaDeletable: boolean = true;
  public isMediaFavorite: boolean = false;

  public mediaSources?: MediaFile[];
  private mediaLibraryItems: MediaLibrary[];

  public totalLoaded: number = 0;
  public totalToLoad: number = 0;

  public totalSuccessfulUploads: number = 0;
  public totalFailedUploads: number = 0;
  public totalUploads: number = 0;

  public isBusy: boolean = false;

  public get loading(): boolean {
    return this.totalToLoad == 0 ? false : this.totalLoaded < this.totalToLoad;
  }

  public mediaForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(0, []),
    name: new UntypedFormControl('', [Validators.required]),
    isFavorite: new UntypedFormControl(false, []),
    tags: new UntypedFormControl('', []),
    permissions: new UntypedFormControl(Permission.Private, [Validators.required]),
    copyright: new UntypedFormControl('', []),
    validityEndDate: new UntypedFormControl(null, []),
  });

  public get hasMedia(): boolean {
    return this.mediaSources !== undefined && this.mediaSources.length > 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public mediaInputData: MediaInputData,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private fleetService: FleetService,
    private domSanitizer: DomSanitizer,
    private clientService: ClientService,
    private settingsService: SettingsService,
    private wasmService: MediainfoWasmService,
    private translateService: TranslateService,
    public blobStorageService: BlobStorageService,
    private mediaLibraryService: MediaLibraryService,
    private notificationService: NotificationService,
    private oidcSecurityService: OidcSecurityService,
    private dialogRef: MatDialogRef<MediaFormComponent>
  ) {
    this.formMode = this.mediaInputData.mode;
    this.mediaLibraryItem = this.mediaInputData.mediaLibrary ?? this.initDefaultMediaLibrary();

    if (this.mediaInputData.mediaLibrary && this.mediaInputData.mode == MediaFormMode.Save) {
      this.addMedia(URL.createObjectURL(this.mediaInputData.mediaLibrary.value as File), this.mediaInputData.mediaLibrary.type, this.mediaInputData.mediaLibrary.value as File);
    }

    this.addKeyboardEventsSubscription();
    this.addMediaInfoSubscription();
  }

  public ngOnInit(): void {
    this.oidcSecurityService.userData$.subscribe((x) => {
      this.isAdminOrHotline = (x.roles & (Role.Hotline | Role.SuperAdministrator)) > 0;

      let fleet$: Observable<FleetLight | null>;

      if (this.mediaLibraryItem.id > 0 && this.isAdminOrHotline) {
        // édition d'un média
        fleet$ = this.fleetService.getOneLight(this.mediaLibraryItem.fleetId!);
      } else {
        // création d'un média
        fleet$ = this.fleetService.getUserFleet();
      }

      fleet$.subscribe((f) => {
        this.userFleet = f!;
        this.clientService.getByGuid(f!.clientId).subscribe((c) => (this.userClient = c));
      });

      this.initInputData();
    });
  }

  public ngOnDestroy(): void {
    this.mediaInfoSubscription.unsubscribe();
  }

  private addKeyboardEventsSubscription(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === KeyboardKey.Escape) {
        if (!this.formModificationHappened) {
          this.dialogRef.close();
          return;
        }

        const dialogRef: MatDialogRef<ConfirmComponent> = this.dialog.open(ConfirmComponent, {
          maxWidth: '800px',
          data: {
            message: this.translateService.instant('common.cancel_warning'),
            confirmButtonTitle: this.translateService.instant('common.yes'),
            cancelButtonTitle: this.translateService.instant('common.no'),
          },
        });

        dialogRef.afterClosed().subscribe((dialogResult: DialogResult<ConfirmResult>) => {
          if (dialogResult.confirm === ConfirmResult.Yes) this.dialogRef.close();
        });
      }
    });
  }

  private addMediaInfoSubscription(): void {
    this.mediaInfoSubscription = this.wasmService.mediaInfoReceived.subscribe((mediaResult: { codecData: CodecData; file: File }) =>
      this.mediaInfoCompleted(mediaResult.codecData, mediaResult.file)
    );
  }

  private initDefaultMediaLibrary(): MediaLibrary {
    return {
      id: 0,
      name: '',
      isFavorite: false,
      permissions: Permission.Private,
      blobId: Guid.create().toString(),
      blobUrl: '',
      blobThumbnailUrl: '',
      tags: [],
      type: MediaType.Picture,
      status: MediaStatus.None,
      value: null,
      isMine: true,
      validityEndDate: null,
      copyright: '',
      created: new Date(),
      createdBy: '',
      codecData: null,
      fleetId: null,
      width: 0,
      height: 0,
    };
  }

  private initInputData(): void {
    this.isBusy = false;
    if (this.formMode !== MediaFormMode.Create) {
      this.initPropsAndEntities();

      this.mediaForm = this.initForm(this.mediaLibraryItem, this.fb);
    }
    this.onFormValueChanges();
  }

  private initPropsAndEntities(): void {
    this.selectedTags = new Array<Tag>();

    if (this.mediaLibraryItem.tags) {
      this.selectedTags = [...this.mediaLibraryItem.tags];
    }

    this.isMediaFavorite = this.mediaLibraryItem.isFavorite;
    this.isMediaDeletable = false;

    this.addMedia(this.mediaLibraryItem.blobUrl, this.mediaLibraryItem.type);
  }

  /**
   * Ajoute un média à la liste déjà existante
   * @param url URL du média
   */
  private addMedia(url: string, type: MediaType, file?: File, codecData?: CodecData): void {
    if (!this.mediaSources) this.mediaSources = new Array<MediaFile>();

    const mediaSource: MediaFile = new MediaFile(this.domSanitizer.bypassSecurityTrustUrl(url), type, file, codecData);

    ///
    // Ajout d'événements qui seront déclenchés si l'utilisateur valide le formulaire et que les fichiers sont uploadés.
    ///
    // gestion de la fin de l'upload
    mediaSource.onUploadComplete.subscribe(() => {
      this.totalSuccessfulUploads++;
      mediaSource.progress = 100;

      // si tout a été uploadé on peut fermer le formulaire
      this.checkProgress();
    });

    // gestion de l'échec de l'upload
    mediaSource.onUploadFailed.subscribe(() => {
      this.notificationService.displayError(this.translateService.instant('media.service.error.upload_file', { name: mediaSource.file!.name }));
      this.totalFailedUploads++;

      // si tout a été uploadé on peut fermer le formulaire
      this.checkProgress();
    });

    if (url && url.length > 0) {
      const tempMediaSources: MediaFile[] = [...this.mediaSources];
      tempMediaSources.push(mediaSource);

      this.mediaSources = tempMediaSources.sort((ms1, ms2) => {
        if (!ms1.file || !ms2.file) return NaN;
        else return ms1.file.name.localeCompare(ms2.file?.name);
      });
    }
  }

  private clearMedia(): void {
    this.mediaSources = undefined;
  }

  private initForm(media: MediaLibrary, builder: UntypedFormBuilder): UntypedFormGroup {
    const fg: UntypedFormGroup = builder.group({
      id: new UntypedFormControl(media.id, []),
      name: new UntypedFormControl(media.name, [Validators.required]),
      isFavorite: new UntypedFormControl(media.isFavorite, []),
      tags: new UntypedFormControl(media.tags ? media.tags : new Array<Tag>(), []),
      permissions: new UntypedFormControl(media.permissions, [Validators.required]),
      copyright: new UntypedFormControl(media.copyright, []),
      validityEndDate: new UntypedFormControl(media.validityEndDate ? new Date(media.validityEndDate) : null, []),
    });

    if (!media.isMine && !this.isAdminOrHotline) {
      fg.controls['id'].disable();
      fg.controls['name'].disable();
      fg.controls['tags'].disable();
      fg.controls['permissions'].disable();
      fg.controls['copyright'].disable();
      fg.controls['validityEndDate'].disable();
    }

    return fg;
  }

  private onFormValueChanges(): void {
    this.mediaForm.valueChanges.subscribe((form) => {
      this.formModificationHappened = false;
      if (
        form.name !== this.mediaLibraryItem.name ||
        form.isFavorite !== this.mediaLibraryItem.isFavorite ||
        form.permissions !== this.mediaLibraryItem.permissions ||
        form.copyright !== this.mediaLibraryItem.copyright ||
        form.validityEndDate !== this.mediaLibraryItem.validityEndDate ||
        this.tagsListHasChanged()
      ) {
        this.formModificationHappened = true;
      }
    });
  }

  private tagsListHasChanged(): boolean {
    if (this.mediaLibraryItem.tags.length !== this.selectedTags.length || this.selectedTags.filter((x) => !this.mediaLibraryItem.tags.find((g) => g.id === x.id)).length) {
      return true;
    }
    return false;
  }

  public fileChangeEvent(event: Event): void {
    const files: FileList | null = (<HTMLInputElement>event.target).files;

    if (!files) return;

    this.onFileDropped(files);
  }

  public onFileDropped(files: FileList): void {
    if (this.formMode === MediaFormMode.Create && files && files.length > 0) {
      this.totalLoaded = 0;
      this.totalToLoad = files.length;

      if (files.length === 1) {
        const filename: string = this.getFileNameFromFile(files[0]);
        this.mediaForm.controls['name'].setValue(filename);
      } else {
        this.clearControlValidators('name');
        this.mediaForm.controls['name'].disable({ onlySelf: true, emitEvent: false });
      }

      // remove existing media
      this.clearMedia();

      for (let i: number = 0; i < files.length; i++) {
        this.processMediaAddition(i, files);
      }
    }
  }

  private clearControlValidators(controlName: string): void {
    this.mediaForm.controls[controlName].clearValidators();
    this.mediaForm.controls[controlName].updateValueAndValidity();
  }

  private getFileNameFromFile(file?: File): string {
    if (file == undefined) {
      return '';
    }

    return file.name.substring(0, file.name.lastIndexOf('.'));
  }

  private processMediaAddition(index: number, files: FileList): void {
    const file: File = files[index];
    const fileType: MediaType = this.getFileType(file);

    // on élimine les fichiers non compatibles (qui ne sont ni des vidéos ni des images)
    switch (fileType) {
      case MediaType.Picture:
        this.addMedia(URL.createObjectURL(file), fileType, file);
        this.totalLoaded++;
        break;
      case MediaType.Video:
        this.wasmService.mediainfo(file);
        break;
      case MediaType.Unknown:
      default:
        this.totalLoaded++;
    }
  }

  private getFileType(file: File): MediaType {
    if (file.type.includes('image')) {
      return MediaType.Picture;
    }

    if (file.type.includes('video')) {
      return MediaType.Video;
    }

    return MediaType.Unknown;
  }

  private mediaInfoCompleted(codecData: CodecData, file: File): void {
    if (this.is4KVideoAuthorized(codecData)) {
      this.addMedia(URL.createObjectURL(file), MediaType.Video, file, codecData);
    } else {
      const translateError: string = this.translateService.instant('media.service.video_format_not_allowed');
      this.notificationService.displayError(translateError);
    }

    this.totalLoaded++;
  }

  public removeFile(file: MediaFile): void {
    const index: number = this.mediaSources!.indexOf(file);

    if (index >= 0) {
      this.mediaSources!.splice(index, 1);
      this.updateMediaFormControlName();
    }
  }

  private updateMediaFormControlName(): void {
    if (this.mediaSources == undefined || this.mediaSources!.length <= 1) {
      this.mediaForm.controls['name'].enable({ onlySelf: true, emitEvent: false });
      this.mediaForm.controls['name'].addValidators(Validators.required);
      this.mediaForm.controls['name'].updateValueAndValidity();

      if (this.mediaSources !== undefined && this.mediaSources!.length === 1) {
        this.mediaForm.controls['name'].setValue(this.getFileNameFromFile(this.mediaSources![0].file));
      } else {
        this.mediaForm.controls['name'].setValue('');
      }
    }
  }

  private is4KVideoAuthorized(codecData: CodecData): boolean {
    const codec4kAuthorized: string[] = this.settingsService.settings.CODEC_AUTHORIZED_4K.split(';').map((x) => x.toLowerCase());

    // Video has a 4K resolution, then check that it uses an authorized codec and framerate
    if ((codecData.height === 2160 && codecData.width === 3840) || (codecData.height === 3840 && codecData.width === 2160)) {
      return codec4kAuthorized.indexOf(codecData.codecId.toLowerCase()) > -1 && codec4kAuthorized.indexOf(codecData.format.toLowerCase()) > -1 && codecData.frameRate <= 30;
    }

    return true;
  }

  public deleteMedia(): void {
    this.clearMedia();
    this.updateMediaFormControlName();
    this.isBusy = false;
  }

  public checkFavoriteMedia(): void {
    this.isMediaFavorite = !this.isMediaFavorite;
    this.mediaForm.controls['isFavorite'].setValue(this.isMediaFavorite);
  }

  public addTag(event: MatChipInputEvent): void {
    const value: string = event.value.trim();
    if (value) {
      this.selectedTags.push({ id: 0, name: value });
      this.mediaForm.controls['tags'].setValue(null);
    }

    const input: HTMLInputElement = event.input;
    if (input) {
      input.value = '';
    }
  }

  public removeTag(tag: Tag): void {
    const index: number = this.selectedTags.indexOf(tag);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      this.mediaForm.controls['tags'].setValue(null);
    }
  }

  public openCollapsible(): void {
    this.isCollapsibleOpen = !this.isCollapsibleOpen;
  }

  public onSubmit(): void {
    this.isBusy = true;

    // On conserve le nombre de fichiers à uploader
    this.totalUploads = this.mediaSources!.length;

    // Réinitialisation du nombre de fichiers déjà uploadés
    this.totalSuccessfulUploads = 0;
    this.totalFailedUploads = 0;

    this.mediaLibraryItems = new Array<MediaLibrary>();

    this.mediaSources!.forEach((src, index) => {
      let media: MediaLibrary;

      if (this.mediaLibraryItem.isMine || this.isAdminOrHotline) {
        media = this.getMedia(src, index + 1);
      } else {
        media = { ...this.mediaLibraryItem };
        media.isFavorite = this.mediaForm.controls['isFavorite'].value;
        media.name = src.file?.name!;
      }

      src.progress = 0;

      this.mediaForm.controls['permissions'].disable();

      this.mediaLibraryService.send(media).subscribe(
        (res) => {
          this.mediaLibraryItems.push(res);

          if (this.formMode !== MediaFormMode.Edit) this.completeMediaUpload(res, src);
          else {
            this.totalSuccessfulUploads++;
            src.progress = 100;

            res.status = res.type == MediaType.Video ? (this.formMode == MediaFormMode.Edit ? this.mediaLibraryItem.status : MediaStatus.Encoding) : MediaStatus.Available;

            this.checkProgress();
          }
        },
        (err) => {
          this.notificationService.displayError(err.message);
          this.isBusy = false;

          if (HttpResponseManager.technicalErrorHappened(err.status)) {
            this.dialogRef.close({ entity: null, confirm: ConfirmResult.No });
          }
        }
      );
    });
  }

  private completeMediaUpload(media: MediaLibrary, src: MediaFile): void {
    // this.setProcessedProperties(media);
    if (media.type === MediaType.Video && this.formMode !== MediaFormMode.Edit) {
      //send video to storage_account after API ! upload result handle with this.blobStorageUploadedSubscription
      this.blobStorageService.uploadVideo(media, src);
    } else {
      this.totalSuccessfulUploads++;
      src.progress = 100;
      media.status = MediaStatus.Available;

      this.checkProgress();
    }
  }

  private checkProgress(): void {
    // si tout a été uploadé on peut fermer le formulaire
    if (this.totalSuccessfulUploads + this.totalFailedUploads >= this.totalUploads) this.closeForm();
  }

  /** Gets a media library item combining the form data and the provided uploaded file data */
  private getMedia(file: MediaFile, index: number): MediaLibrary {
    let media: MediaLibrary = { ...this.mediaForm.value } as MediaLibrary;

    // upload image data directly to the API. Video data needs to be dealt differently with a direct blob storage upload url.
    media.value = file.type === MediaType.Video ? null : this.formMode === MediaFormMode.Create ? file.file ?? null : this.mediaLibraryItem.value;
    media.blobId = this.formMode === MediaFormMode.Create ? Guid.create().toString() : this.mediaLibraryItem.blobId;
    media.status = this.getMediaStatus();
    media.isMine = this.mediaLibraryItem.isMine;

    // set the name with a suffix only if there are more than one file
    if (this.mediaSources!.length > 1) {
      if (file.file && file.file.name !== '') {
        media.name = file.file.name;
      } else {
        media.name = media.name + ' (' + index.toString().padStart(2, '0') + ')';
      }
    }

    media.id = this.formMode === MediaFormMode.Edit ? this.mediaLibraryItem.id : 0;
    media.type = file.type;
    media.tags = this.selectedTags;
    media.validityEndDate = media.validityEndDate ? new Date(media.validityEndDate) : null;
    media.copyright = media.copyright ? media.copyright : '';
    media.codecData = file.codecData ?? null;

    return media;
  }

  private getMediaStatus(): MediaStatus {
    return this.formMode === MediaFormMode.Create ? MediaStatus.None : this.mediaLibraryItem.status;
  }

  public cancelAction(): void {
    this.dialogRef.close({ entity: null, confirm: ConfirmResult.No });
  }

  private closeForm(): void {
    // All uploads succeeded
    if (this.totalUploads == this.totalSuccessfulUploads) {
      const successResource: string = this.formMode == MediaFormMode.Edit ? 'media.service.success.put' : 'media.service.success.post';
      const successMessage: string = this.translateService.instant(successResource, { name: this.mediaLibraryItems[0].name });

      this.notificationService.displaySuccess(successMessage);
    } else {
      // One or more uploads failed
      const errorMessage: string = this.translateService.instant('media.service.error.upload_video', { name: this.mediaLibraryItems[0].name });

      this.notificationService.displayError(errorMessage);
    }

    if (this.formMode == MediaFormMode.Edit) {
      this.mediaLibraryItems[0].blobThumbnailUrl = this.mediaInputData.mediaLibrary!.blobThumbnailUrl;
      this.mediaLibraryItems[0].blobUrl = this.mediaInputData.mediaLibrary!.blobUrl;
      this.mediaLibraryItems[0].createdBy = this.mediaInputData.mediaLibrary!.createdBy;
      this.mediaLibraryItems[0].fleetId = this.mediaInputData.mediaLibrary!.fleetId;
      this.mediaLibraryItems[0].height = this.mediaInputData.mediaLibrary!.height;
      this.mediaLibraryItems[0].width = this.mediaInputData.mediaLibrary!.width;
      this.mediaLibraryItems[0].isMine = this.mediaInputData.mediaLibrary!.isMine;
      this.mediaLibraryItems[0].codecData = this.mediaInputData.mediaLibrary!.codecData;
      this.mediaLibraryItems[0].created = this.mediaInputData.mediaLibrary!.created;
    }

    this.dialogRef.close({ entity: this.mediaLibraryItems, confirm: ConfirmResult.Yes });
  }
}
