import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as jsonpatch from 'fast-json-patch';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SettingsService } from '../core/setttings/settings.service';
import { SlidePreview } from '../shared/components/preview/slidePreview';
import { Channel } from './channel';
import { ChannelLight } from './channel-light';
import { ChannelPreview } from './channel-preview';
import { ChannelTile } from './channel-tile';
import { ChannelToken } from './channel-token';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  private channelsBaseUrl: string;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {
    this.channelsBaseUrl = `${this.settingsService.settings.API_URL}/channel`;
  }

  public getChannels(): Observable<ChannelTile[]> {
    return this.httpClient.get<ChannelTile[]>(`${this.channelsBaseUrl}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.error.get');
        return throwError(err);
      })
    );
  }

  public getChannelById(id: number): Observable<Channel> {
    return this.httpClient.get<Channel>(`${this.channelsBaseUrl}/${id}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.error.get_by_id');
        return throwError(err);
      })
    );
  }

  public getChannelContent(screenFormatId: number): Observable<ChannelPreview[]> {
    return this.httpClient.get<ChannelPreview[]>(`${this.channelsBaseUrl}/content?screenFormatId=${screenFormatId}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.error.get_by_id');
        return throwError(err);
      })
    );
  }

  public getChannelLightById(id: number): Observable<ChannelLight> {
    return this.httpClient.get<ChannelLight>(`${this.channelsBaseUrl}/light/${id}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.error.get_by_id');
        return throwError(err);
      })
    );
  }

  public getPreview(id: number): Observable<SlidePreview[]> {
    return this.httpClient.get<SlidePreview[]>(`${this.channelsBaseUrl}/${id}/preview`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.error.preview', {
          id: id,
        });
        return throwError(err);
      })
    );
  }

  /**
   * Create or update a channel
   */
  public send(channel: ChannelTile): Observable<ChannelTile> {
    return channel.id !== 0 ? this.put(channel) : this.post(channel);
  }

  /**
   * Post a channel
   */
  public post(channel: ChannelTile): Observable<ChannelTile> {
    return this.httpClient.post<ChannelTile>(`${this.channelsBaseUrl}`, channel, { observe: 'body' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.error.post');
        return throwError(err);
      })
    );
  }

  /**
   * Put a channel
   */
  public put(channel: ChannelTile): Observable<ChannelTile> {
    return this.httpClient.put<ChannelTile>(`${this.channelsBaseUrl}/${channel.id}`, channel, { observe: 'body' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.error.put');
        return throwError(err);
      })
    );
  }

  public createChannelToken(channelId: number, delay: number): Observable<ChannelToken> {
    return this.httpClient.post<ChannelToken>(`${this.channelsBaseUrl}/${channelId}/share?durationInDays=${delay}`, null).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.share.create_error');
        return throwError(err);
      })
    );
  }

  public createPermanentChannelToken(channelId: number): Observable<ChannelToken> {
    return this.httpClient.post<ChannelToken>(`${this.channelsBaseUrl}/${channelId}/subscribe`, null).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.share.create_error');
        return throwError(err);
      })
    );
  }

  public createTemporaryChannelToken(channelId: number): Observable<ChannelToken> {
    return this.httpClient.post<ChannelToken>(`${this.channelsBaseUrl}/${channelId}/preview`, null).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.share.create_error');
        return throwError(err);
      })
    );
  }

  public getChannelTokens(channelId: number): Observable<Array<ChannelToken>> {
    return this.httpClient.get<Array<ChannelToken>>(`${this.channelsBaseUrl}/${channelId}/share`).pipe(
      map((tokens) => {
        tokens.forEach((token) => {
          token.creationDate = moment(token.creationDate);
          token.expirationDate = moment(token.expirationDate);
        });
        return tokens;
      }),
      catchError((err) => {
        err.message = this.translateService.instant('channels.share.list_error');
        return throwError(err);
      })
    );
  }

  public deleteChannelToken(token: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.channelsBaseUrl}/share/${token}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('channels.share.delete_error');
        return throwError(err);
      })
    );
  }
}
