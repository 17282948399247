import { Component, ComponentRef, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TooltipData } from './tooltip-data';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  /**
   * Will be rendered as a bolder text at the begining of the tooltip
   */
  @Input() public title?: string;

  /**
   * List of items to display in the tooltip (will be rendered as a bullet list or as a table depending on the 'useBulletPoints' setting)
   */
  @Input() public items?: TooltipData[];

  /**
   * Simple text to display inside the tooltip.
   */
  @Input() public text?: string;

  /**
   * If set to true, the array of items will be rendered as a bullet list instead of a table
   */
  @Input() public useBulletPoints: boolean = false;

  /**
   * Element to which the tooltip is "linked" and to which events will be attached (mousemove, mouseover, mouseout)
   */
  @Input() public target: Element;

  public posXright: string;
  public posXleft: string;

  public posYtop: string;
  public posYbottom: string;

  @ViewChild('tooltipElement') public tooltipElement: ElementRef;

  public show(posX: number, posY: number): void {
    if (!this.tooltipElement || ((!this.items || this.items.length == 0) && (!this.text || this.text.trim() === ''))) return;

    this.move(posX, posY);
    this.tooltipElement.nativeElement.classList.add('active');
  }

  public ngOnInit(): void {
    if (this.target) {
      this.target.addEventListener('mousemove', (event: Event) => this.move((event as MouseEvent).x, (event as MouseEvent).y));
      this.target.addEventListener('mouseover', (event: Event) => this.show((event as MouseEvent).x, (event as MouseEvent).y));
      this.target.addEventListener('mouseout', () => this.hide());
    }
  }

  public hide(): void {
    if (!this.tooltipElement) return;

    this.tooltipElement.nativeElement.classList.remove('active');
  }

  public move(posX: number, posY: number): void {
    // détection de la proximité du borde de la fenêtre
    const useBottom: boolean = window.innerHeight - posY < 70;
    const useRight: boolean = window.innerWidth - posX < 150;

    if (useBottom) {
      this.posYbottom = window.innerHeight - posY + 14 + 'px';
      this.posYtop = '';
    } else {
      this.posYbottom = '';
      this.posYtop = posY + 14 + 'px';
    }

    if (useRight) {
      this.posXright = window.innerWidth - posX + 14 + 'px';
      this.posXleft = '';
    } else {
      this.posXright = '';
      this.posXleft = posX + 14 + 'px';
    }
  }
}
