export class CodecData {
  public id: number;
  public height: number;
  public width: number;
  public frameRate: number;
  public codecId: string;
  public format: string;

  constructor(height: string, width: string, frameRate: string, codecId: string, format: string) {
    this.height = +height;
    this.width = +width;
    this.frameRate = Math.round(+frameRate * 1.001);
    this.codecId = codecId;
    this.format = format;
  }
}
