import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxMasonryComponent } from 'ngx-masonry';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { isUserNearBottom } from 'src/app/core/layoutHelper';
import { EncodingMessagesService } from 'src/app/core/signalr/encoding-messages.service';
import { EncodingProgressMessage } from 'src/app/core/signalr/progressMessage';
import { DialogVideoPreviewComponent } from 'src/app/shared/components/dialog-video-preview/dialog-video-preview.component';
import { Asset } from './asset';
import { AssetType } from './assetType';
import { MediaDropData } from './mediaDropData';
import { AssetRequest } from './asset-request';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { MediaLibrary } from 'src/app/core/media-library/media-library';
import { MediaScope } from 'src/app/core/media-library/media-scope';
import { MediaType } from 'src/app/core/media-library/media-type';
import { MediaLibraryService } from 'src/app/core/media-library/media-library.service';
import { NotificationService } from '../../notification/notification.service';
import { EncodingMessage } from 'src/app/core/signalr/encodingMessage';
import { Renderer, Template } from 'src/app/core/template/template';
import { ScreenFormat } from 'src/app/core/enums/screen-format.enum';
import { TemplateService } from 'src/app/core/template/template.service';
import { TranslateService } from '@ngx-translate/core';
import { MediaStatus } from 'src/app/core/media-library/media-status';

/**
 * Ce composant permet l'affichage de listes d'images/vidéos/templates dans un panneau à gauche de l'écran.
 */
@Component({
  selector: 'app-list-assets',
  templateUrl: './list-assets.component.html',
  styleUrls: ['./list-assets.component.scss'],
})
export class ListAssetsComponent implements OnInit, OnDestroy {
  @Output() public assetSelected: EventEmitter<{ asset: Asset; filter?: AssetRequest }> = new EventEmitter<{ asset: Asset; filter?: AssetRequest }>();
  @Output() public templatesLoaded: EventEmitter<Template[]> = new EventEmitter<Template[]>();

  /** Only display templates which are compatible with the provided screen format */
  @Input() public screenFormat: ScreenFormat = ScreenFormat.Landscape_16_9;

  /** Shows only channel safe templates (excludes templates using a Channel or Url renderer) */
  @Input() public channelMode: boolean = false;

  public get displayMode(): AssetType {
    return this._displayMode;
  }

  /** Search string from user input */
  public searchText: string = '';
  public searchInProgress: boolean = false;

  /** List of currently displayed assets */
  public assets: Asset[] = [];
  private static pageSize: number = 20;
  private _favoritesCount: number = 0;
  private _lastPageReached: boolean = false;

  /** Used when the list is open from an asset configuration component (right hand side panel) */
  public request?: AssetRequest;

  /** Expose AssetType enumeration to template */
  public AssetType: typeof AssetType = AssetType;

  private _pictureSavedSubscription: Subscription;

  private _encodingProgressSubscription: Subscription;
  private _encodingCompletedSubscription: Subscription;
  private _encodingFailedSubscription: Subscription;

  private _medias: MediaLibrary[] = [];
  private _templates: Template[] = [];

  private _displayMode: AssetType = AssetType.None;

  @ViewChild(NgxMasonryComponent) public masonry: NgxMasonryComponent;

  constructor(
    private encodingMessagesService: EncodingMessagesService,
    private mediaLibraryService: MediaLibraryService,
    private loaderService: LoaderService,
    private templateService: TemplateService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef
  ) {}

  public ngOnInit(): void {
    // hidden by default
    this.viewContainerRef.element.nativeElement.classList.add('closed');

    this._encodingProgressSubscription = this.encodingMessagesService.progressReceived.subscribe((m) => this.reportEncodingProgress(m));
    this._encodingCompletedSubscription = this.encodingMessagesService.completeReceived.subscribe((m) => this.reportEncodingCompleted(m));
    this._encodingFailedSubscription = this.encodingMessagesService.errorReceived.subscribe((m) => this.reportEncodingFailure(m));

    this.getTemplates();
  }

  public ngOnDestroy(): void {
    if (this._encodingProgressSubscription) this._encodingProgressSubscription.unsubscribe();
    if (this._pictureSavedSubscription) this._pictureSavedSubscription.unsubscribe();
    if (this._encodingCompletedSubscription) this._encodingCompletedSubscription.unsubscribe();
    if (this._encodingFailedSubscription) this._encodingFailedSubscription.unsubscribe();
  }

  public clearSearch(): void {
    this.searchText = '';
    this.search();
  }

  /**
   * Triggers a new search, clearing any previous search results
   */
  public search(): void {
    // get the value to use for research (no need to be case sensitive here)
    const searchString: string = this.searchText.toLowerCase();

    this.clearExistingDataBeforeSearch();

    switch (this._displayMode) {
      case AssetType.Video:
        this.getMediaPage(0, searchString, MediaType.Video);
        break;
      case AssetType.Picture:
        this.getMediaPage(0, searchString, MediaType.Picture);
        break;
    }
  }

  public searchTemplates(includeSingleSlideTemplate: boolean): void {
    this.clearExistingDataBeforeSearch();
    this.getTemplates(includeSingleSlideTemplate);
  }

  private clearExistingDataBeforeSearch(): void {
    // clear existing data (except templates which we don't want to reload over and over)
    this._medias = [];
    this._thumbnailsLoaded = 0;
    this.assets = [];
    this._lastPageReached = false;
  }

  private getTemplates(includeSingleSlideTemplate: boolean = true): void {
    if (this._templates.length > 0) {
      this.setAssetsFromLocalTemplates(includeSingleSlideTemplate);
    } else {
      this.loadTemplatesAndSetAssets(includeSingleSlideTemplate);
    }
  }

  private setAssetsFromLocalTemplates(includeSingleSlideTemplate: boolean): void {
    this.assets =
      includeSingleSlideTemplate === true
        ? this._templates.map((t) => Asset.FromTemplate(t))
        : this._templates.filter((t) => t.multiSlide === true).map((t) => Asset.FromTemplate(t));
  }

  private loadTemplatesAndSetAssets(includeSingleSlideTemplate: boolean): void {
    if (this.searchInProgress) {
      return;
    }

    this.searchInProgress = true;
    this.loaderService.disable();

    this.templateService
      .getTemplates(this.screenFormat, includeSingleSlideTemplate)
      .pipe(
        finalize(() => {
          this.searchInProgress = false;
          this.loaderService.enable();
        })
      )
      .subscribe({
        next: (templates) => {
          this._templates = this.filterTemplates(templates);
          this.assets = templates.map((t) => Asset.FromTemplate(t));

          this.templatesLoaded.emit(templates);
        },
        error: (err) => this.notificationService.displayError(err.message),
      });
  }

  /**
   * Dans le cas de l'édition d'un masque de type chaîne, n'affiche pas les templates dont le renderer
   * est de type 'channel' ou 'url'
   */
  private filterTemplates(templates: Template[]): Template[] {
    return this.channelMode ? templates.filter((x) => x.rendererId !== Renderer.Channel && x.rendererId !== Renderer.URL) : templates;
  }

  private getMediaSearchQuery(pageNumber: number, searchString: string, mediaType: MediaType): Observable<MediaLibrary[]> {
    // on ne récupère que la page demandée, sans les favoris
    if (pageNumber > 0) return this.mediaLibraryService.searchWithPagination(pageNumber, ListAssetsComponent.pageSize, mediaType, MediaScope.All, searchString);
    // on récupère les favoris et la première page de médias
    else
      return forkJoin([
        this.mediaLibraryService.getUserFavorites(mediaType, searchString),
        this.mediaLibraryService.searchWithPagination(0, ListAssetsComponent.pageSize, mediaType, MediaScope.All, searchString),
      ]).pipe(
        map((medias) => {
          this._favoritesCount = medias[0].length;
          return medias[0].concat(medias[1]);
        })
      );
  }

  /**
   * Triggers a search for the next page
   */
  public getMediaPage(pageNumber: number, searchString: string, mediaType: MediaType): void {
    this.searchInProgress = true;
    this.loaderService.disable();

    this.getMediaSearchQuery(pageNumber, searchString, mediaType)
      .pipe(
        finalize(() => {
          this.searchInProgress = false;
          this.loaderService.enable();
        })
      )
      .subscribe(
        (medias) => {
          this._medias.push(...medias);
          this.assets.push(...medias.map((m) => Asset.FromMedia(m)));

          this._lastPageReached = medias.length < ListAssetsComponent.pageSize;
        },
        (err) => this.notificationService.displayError(err.message)
      );
  }

  /**
   * En cas d'erreur d'encodage, on l'indique sur la vignette de la vidéo
   */
  private reportEncodingFailure(m: EncodingMessage): void {
    this.notificationService.displayError(this.translateService.instant('media.encoding_error', { name: m.mediaName }));

    // search if the video is currently being displayed
    const asset: Asset | undefined = this.assets.find((a) => a.isVideo && a.blobId == m.mediaBlobId);

    // change its status to error
    if (asset) asset.status = MediaStatus.Error;
  }

  private reportEncodingCompleted(m: EncodingMessage): void {
    this.notificationService.displaySuccess(this.translateService.instant('media.encoding_success', { name: m.mediaName }));

    // search if the video is currently being displayed
    const assetIndex: number = this.assets.findIndex((a) => a.isVideo && a.blobId === m.mediaBlobId);
    const videoIndex: number = this._medias.findIndex((a) => a.blobId === m.mediaBlobId);

    if (assetIndex < 0 || videoIndex < 0) return;

    this.loaderService.disable();

    // download video details and update them into the list
    this.mediaLibraryService
      .getMedia(this._medias[videoIndex].id)
      .pipe(finalize(() => this.loaderService.enable()))
      .subscribe((vid) => {
        this._medias[videoIndex] = vid;
        this.assets[assetIndex] = Asset.FromMedia(vid);
      });
  }

  public onScroll(e: Event): void {
    e.preventDefault();
    if (isUserNearBottom(e.target as Element) && !this.searchInProgress && !this._lastPageReached) {
      // get the value to use for research (no need to be case sensitive here)
      const searchString: string = this.searchText.toLowerCase();

      switch (this._displayMode) {
        case AssetType.Picture:
          // load the requested page
          this.getMediaPage(this.pageNumber * ListAssetsComponent.pageSize, searchString, MediaType.Picture);
          break;

        case AssetType.Video:
          // load the requested page
          this.getMediaPage(this.pageNumber * ListAssetsComponent.pageSize, searchString, MediaType.Video);
          break;

        case AssetType.Template:
          break;
      }
    }
  }

  private get pageNumber(): number {
    return Math.ceil((this.assets.length - this._favoritesCount) / ListAssetsComponent.pageSize);
  }

  private _thumbnailsLoaded: number = 0;

  /** Permet de déterminer quand rafraîchir le layout de type "masonry" qui prend en compte les dimensiosn des images */
  public onThumbnailLoaded(): void {
    this._thumbnailsLoaded++;
    if (this._thumbnailsLoaded === this.assets.length) {
      this.masonry.reloadItems();
    }
  }

  public select(asset: Asset): void {
    if (this.request || asset.type == AssetType.Template) {
      this.assetSelected.emit({ asset: asset, filter: this.request });
      this.close();
    }
  }

  public close(): void {
    this.viewContainerRef.element.nativeElement.classList.add('closed');
  }

  public openTemplatesWindow(includeSingleSlideTemplate: boolean): void {
    this._displayMode = AssetType.Template;
    this.viewContainerRef.element.nativeElement.classList.remove('closed');

    this.searchTemplates(includeSingleSlideTemplate);
  }

  public open(askedAssetType: AssetType): void {
    this.viewContainerRef.element.nativeElement.classList.remove('closed');

    if (this._displayMode !== askedAssetType) {
      this.searchText = '';
      this._displayMode = askedAssetType;

      this.search();
    }
  }

  /** Prépare la donnée à transmettre à l'élément qui recevra l'événement de fin de drag and drop (le 'drop') */
  public onDragStart(event: DragEvent, asset: Asset): void {
    event.dataTransfer?.setData('text/plain', JSON.stringify(new MediaDropData(asset.type, asset.blobId, asset.blobUrl, asset.name, asset.thumbnailUrl)));
  }

  public openVideoPreview(event: Event, videoUrl: string): void {
    event.preventDefault();
    event.stopPropagation();

    const dialogConf: MatDialogConfig = new MatDialogConfig();
    dialogConf.data = { blobUrl: videoUrl };
    dialogConf.panelClass = 'pane-video-preview';

    this.dialog.open(DialogVideoPreviewComponent, dialogConf);
  }

  /**
   * Met à jour la barre de progression des vidéos en cours d'encodage
   */
  private reportEncodingProgress(m: EncodingProgressMessage): void {
    if (!this.assets) return;

    const search: Asset | undefined = this.assets.find((a) => a.isEncoding && a.name.toLowerCase().trim() === m.mediaName.toLowerCase().trim());
    if (!search) return;

    const index: number = this.assets.indexOf(search);
    if (index >= 0) this.assets[index].progress = m.encodingProgress;
  }
}
