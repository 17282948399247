export enum ScreenFormat {
    Landscape_16_9 = 1,
    Landscape_16_9Translate = 'mask.screen_format.landscape_16_9',
    Portrait_9_16 = 2,
    Portrait_9_16Translate = 'mask.screen_format.portrait_9_16',
    Stretch_Vertical = 3,
    Stretch_VerticalTranslate = 'mask.screen_format.stretch_vertical',
    Stretch_Horizontal = 4,
    Stretch_HorizontalTranslate = 'mask.screen_format.stretch_horizontal',
  }
  