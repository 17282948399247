import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HostListener, Output } from '@angular/core';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
// import moment from 'moment';
// import 'moment/locale/fr';
// import 'moment/locale/en-gb';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { EncodingNotificationService } from 'src/app/core/encoding-notification/encoding-notification.service';
import { Role } from 'src/app/core/guards/role';
import { ScopePolicies } from 'src/app/core/guards/scope-policies';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { StorageKey } from 'src/app/core/local-storage/storageKeys';
import { EncodingMessagesService } from 'src/app/core/signalr/encoding-messages.service';
import { Language, UserSettings } from 'src/app/core/users/user-settings';
import { NewsService } from 'src/app/news/news.service';
import { NotificationService } from 'src/app/shared/components/notification/notification.service';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';
import { HeaderData } from './headerData';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private navEndSubscription: Subscription;
  private encodingAlertCounterSubscription: Subscription;
  private encodingErrorSubscription: Subscription;
  private encodingResetCounterSubscription: Subscription;

  @Input() public headerData: HeaderData;
  @Output() public execRedirectOnUser: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() public toggleSidebar: EventEmitter<Event> = new EventEmitter<Event>();

  public title: string;
  public newsNotificationCount: number = 0;
  public encodingNotificationCount: number = 0;
  public Language: typeof Language = Language;
  public isHandsetPortrait: boolean = false;
  public isHandsetLandscape: boolean = false;
  public showActions: boolean = false;

  public get canImpersonate(): boolean {
    return ScopePolicies.CanImpersonate(this.headerData.userData.roles);
  }

  public get isMediaManager(): boolean {
    return ScopePolicies.IsRouteAccessible('/gca/medias', this.headerData.userData.roles);
  }

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private router: Router,
    private newsService: NewsService,
    private userSettingsService: UserSettingsService,
    private notificationService: NotificationService,
    private oidcSecurityService: OidcSecurityService,
    private localStorageService: LocalStorageService,
    private encodingNotificationService: EncodingNotificationService,
    private encodingMessageService: EncodingMessagesService,
    private responsive: BreakpointObserver
  ) {}

  public ngOnInit(): void {
    this.refreshPageName();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.refreshPageName());

    // Listen for changes in the news counter
    this.newsService.unreadCounterChanged.subscribe((count) => (this.newsNotificationCount = count));

    // this subscription will display a toast message if there are unread news
    this.newsService
      .getUnreadNewsCount()
      .pipe(first())
      .subscribe(
        (count) => {
          if (count > 0) {
            this.notificationService.displayInfo(this.translateService.instant('news.new_news'), '/gca/news');
          }
        },
        (err) => console.log('Error loading news count: ', err)
      );

    if (this.isMediaManager) {
      // These subscriptions manage the notification counter in relation to video encoding
      this.encodingResetCounterSubscription = this.encodingNotificationService.resetAsked.subscribe((_) => (this.encodingNotificationCount = 0));
      this.encodingAlertCounterSubscription = this.encodingNotificationService.getAlertCounter(true).subscribe((c) => (this.encodingNotificationCount += c));
      this.encodingErrorSubscription = this.encodingMessageService.errorReceived.subscribe((_) => {
        this.encodingNotificationCount++;
      });
    }

    this.responsive.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
      const breakpoints = result.breakpoints;

      this.isHandsetPortrait = breakpoints[Breakpoints.HandsetPortrait];
      this.isHandsetLandscape = breakpoints[Breakpoints.HandsetLandscape];
    });
  }

  public ngOnDestroy(): void {
    if (this.navEndSubscription) this.navEndSubscription.unsubscribe();
    if (this.encodingErrorSubscription) this.encodingErrorSubscription.unsubscribe();
    if (this.encodingAlertCounterSubscription) this.encodingAlertCounterSubscription.unsubscribe();
    if (this.encodingResetCounterSubscription) this.encodingResetCounterSubscription.unsubscribe();
  }

  public toggleCompactMenu(): void {
    this.showActions = !this.showActions;
  }

  public hideCompactMenu(): void {
    // TODO : fix this as this is never called.
    setTimeout(() => (this.showActions = false), 120);
  }

  private refreshPageName(): void {
    let params: string[] = this.router.url.split('/');
    params = params.filter((p) => p !== 'gca');

    let i18nKey: string | undefined = undefined;

    if (this.router.url === '/') i18nKey = 'custom_page.home';
    if (this.router.url === '/gca/page-not-found') i18nKey = 'custom_page.page_not_found';

    if (i18nKey !== undefined) {
      this.title = this.translateService.instant(i18nKey);
    } else {
      let lastParam: string = params[params.length - 1].replace('-', '_');
      if (params.length > 2) {
        let secondParam: string = params[1].replace('-', '_');
        i18nKey = 'menu.' + secondParam + '.' + lastParam;
      } else {
        i18nKey = 'menu.' + lastParam;
      }

      const titlei18n: string = this.translateService.instant(i18nKey);
      if (titlei18n === i18nKey) this.title = this.getHeaderPageName(this.titleService.getTitle()) ?? 'Captive Audience';
      else this.title = titlei18n;
    }
  }

  private getHeaderPageName(pageTitle: string): string {
    if (pageTitle && pageTitle.includes('-')) {
      return pageTitle.split('-')[0].trim();
    }
    return pageTitle;
  }

  public backToHome(): void {
    this.execRedirectOnUser.emit();
  }

  public openHelp(): void {
    this.showActions = false;
    this.router.navigate(['/gca', 'help']);
  }

  public openNews(): void {
    this.showActions = false;
    this.router.navigate(['/gca', 'news']);
  }

  public setLanguage(lang: Language): void {
    this.userSettingsService.updateMySettings({ language: lang } as UserSettings).subscribe();
    this.showActions = false;
  }

  public logout(): void {
    this.localStorageService.delete(StorageKey.userData);
    this.oidcSecurityService.logoffAndRevokeTokens();
  }

  public openNotifications(): void {
    this.showActions = false;
    this.router.navigate(['/gca', 'encoding-notifications']);
  }

  public triggerSidebarToggle(): void {
    this.toggleSidebar.emit();
  }
}
