export interface ITimetableItem {
  startHours: number;
  startMinutes: number;
  endHours: number;
  endMinutes: number;
}

export class TimetableItem implements ITimetableItem {
  public startHours: number;
  public startMinutes: number;

  public endHours: number;
  public endMinutes: number;

  constructor(item?: ITimetableItem) {
    this.startHours = item?.startHours ?? 0;
    this.startMinutes = item?.startMinutes ?? 0;
    this.endHours = item?.endHours ?? 0;
    this.endMinutes = item?.endMinutes ?? 0;
  }

  public toString(): string {
    return `${this.startHours.toString().padStart(2, '0')}:${this.startMinutes.toString().padStart(2, '0')} - ${
      this.endHours == 24 ? '00' : this.endHours.toString().padStart(2, '0')
    }:${this.endMinutes.toString().padStart(2, '0')}`;
  }

  public isIncluded(hours: number, minutes: number): boolean {
    const time: number = hours * 60 + minutes;

    return time >= this.startHours * 60 + this.startMinutes && time <= this.endHours * 60 + this.endMinutes;
  }
}
