<div class="container">
  <button mat-icon-button class="close" [mat-dialog-close]="true">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <div *ngIf="this.data.slidePreviews.length; else noContent" class="preview-container">
    <app-slide-preview [slides]="data.slidePreviews" [cssClass]="screenFormatCssClass" [class]="screenFormatCssClass"></app-slide-preview>
  </div>
  <ng-template #noContent>
    <div class="preview-container">
      <span>{{ 'preview.no_content' | translate }}</span>
    </div>
  </ng-template>
</div>
