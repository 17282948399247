import { ComponentRef, Directive, OnInit, ViewContainerRef } from '@angular/core';
import { DynamicDataPickerComponent } from '../components/dynamic-data-picker/dynamic-data-picker.component';

@Directive({
  selector: '[appDynamicDataPicker]',
})
export class GcaDynamicDataDirective implements OnInit {
  private pickerRef: ComponentRef<DynamicDataPickerComponent>;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    // inject picker component
    this.pickerRef = this.viewContainerRef.createComponent(DynamicDataPickerComponent);
    this.pickerRef.instance.field = this.viewContainerRef.element.nativeElement;
  }
}
