import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/components/notification/notification.service';
import { LoggingService } from '../logging/logging.service';

@Injectable({
  providedIn: 'root'
})
// Type any is defined in interface ErrorHandler
/* tslint:disable:no-any */
export class GlobalErrorHandlerService implements ErrorHandler {
  private notificationService: NotificationService;
  private loggingService: LoggingService;
  private translationService: TranslateService;

  constructor(private injector: Injector) {}

  public handleError(error: any): void {
    // When toPromise() is use in Observable
    if ((error as any).rejection instanceof HttpErrorResponse) {
      error = (error as any).rejection;
    }

    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        if (this.notificationService === undefined) {
          this.notificationService = this.injector.get(NotificationService);
        }

        if (this.translationService === undefined) {
          this.translationService = this.injector.get(TranslateService);
        }

        this.translationService.get('general.error.no_connection').subscribe((msg: string) => this.notificationService.displayError(msg));
        return;
      }
    } else {
      // Handle Client Error
      if (this.loggingService === undefined) {
        this.loggingService = this.injector.get(LoggingService);
      }

      this.loggingService.logError(error);

      throw error;
    }
  }
}
