import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  public title: string;

  constructor(private translateService: TranslateService, private titleService: Title) {
    this.translateService.get('page_not_found.page_title').subscribe((value: string) => {
      this.titleService.setTitle(value);
      this.title = this.translateService.instant('custom_page.page_not_found');
    });
  }
}
