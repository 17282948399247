import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { forkJoin, merge } from 'rxjs';
import { ClientLight } from 'src/app/core/clients/client';
import { ClientService } from 'src/app/core/clients/client.service';
import { FleetLight } from 'src/app/core/fleet/fleet';
import { FleetService } from 'src/app/core/fleet/fleet.service';
import { FleetResult } from './fleet-result';

@Component({
  selector: 'app-fleet-picker',
  templateUrl: './fleet-picker.component.html',
  styleUrls: ['./fleet-picker.component.scss'],
})
export class FleetPickerComponent implements OnInit {
  @Input() public isBusy: boolean = false;
  @Output() public fleetSelected: EventEmitter<FleetResult> = new EventEmitter<FleetResult>();

  public clientFleets: FleetLight[] = [];
  public allFleets: FleetLight[] = [];
  public clients: ClientLight[] = [];
  public fleetFormModel: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FleetResult,
    private dialogRef: MatDialogRef<FleetPickerComponent>,
    private clientService: ClientService,
    private fleetService: FleetService,
    private formBuilder: UntypedFormBuilder
  ) {}

  public ngOnInit(): void {
    forkJoin([this.clientService.getLight(true, true), this.fleetService.getLight(true, true)]).subscribe((result: [ClientLight[], FleetLight[]]) => {
      this.clients = result[0];
      this.allFleets = result[1];
      this.initForm();
    });
  }

  private initForm(): void {
    this.clientFleets = this.allFleets.filter((f) => f.clientId === this.data.clientId);
    const exists: boolean = this.clientFleets.some((fl) => fl.technicalId === this.data.fleetId);

    this.fleetFormModel = this.formBuilder.group({
      fleetId: new UntypedFormControl(exists ? this.data.fleetId : null, Validators.required),
      clientId: new UntypedFormControl(this.data.clientId, Validators.required),
    });
  }

  public onClientChanged(event: MatSelectChange): void {
    console.log('Client selection changed', event);
    this.clientFleets = this.allFleets.filter((f) => f.clientId === event.value);
    const exists: boolean = this.clientFleets.some((fl) => fl.technicalId === event.value);

    if (!exists) this.fleetFormModel.setValue({ clientId: event.value, fleetId: null });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public submitForm(): void {
    if (this.fleetFormModel.valid) {
      this.fleetSelected.emit(this.fleetFormModel.getRawValue());
      this.isBusy = true;
    }
  }
}
