<div [class]="{ toolbar: true, 'handset-portrait': isHandsetPortrait }">
  <div class="left-group">
    <button mat-icon-button aria-label="Main menu" (click)="triggerSidebarToggle()" *ngIf="isHandsetPortrait || isHandsetLandscape">
      <mat-icon>menu</mat-icon>
    </button>

    <a class="logo" (click)="backToHome()"><img src="../../../assets/img/gca-logo-white-small.png" class="logo" alt="Captive Audience" /></a>
  </div>

  <p class="title">{{ title }}</p>

  <div (click)="toggleCompactMenu()" (focusout)="hideCompactMenu()" *ngIf="isHandsetPortrait" matRipple class="action icon">
    <mat-icon>more_vert</mat-icon>
  </div>

  <div class="actions" *ngIf="!isHandsetPortrait || showActions">
    <div (click)="openNotifications()" matRipple class="action icon" *ngIf="isMediaManager">
      <mat-icon matBadge="{{ encodingNotificationCount }}" matBadgeColor="warn" matBadgePosition="after" matBadgeHidden="{{ encodingNotificationCount <= 0 }}"
        >notifications</mat-icon
      >
    </div>

    <div (click)="openNews()" matRipple class="action icon">
      <mat-icon matBadge="{{ newsNotificationCount }}" matBadgeColor="warn" matBadgePosition="after" matBadgeHidden="{{ newsNotificationCount <= 0 }}">newspaper</mat-icon>
    </div>

    <div matRipple [matMenuTriggerFor]="languageMenu" class="action icon">
      <mat-icon>public</mat-icon>
    </div>

    <mat-menu #languageMenu xPosition="after" yPosition="below">
      <button class="button-link" (click)="setLanguage(Language.Fr)" mat-menu-item>{{ 'menu.language.french' | translate }}</button>
      <button class="button-link" (click)="setLanguage(Language.En)" mat-menu-item>{{ 'menu.language.english' | translate }}</button>
    </mat-menu>

    <div (click)="openHelp()" matRipple class="action icon">
      <mat-icon>help_outline</mat-icon>
    </div>

    <app-clients-menu (fleetChanged)="showActions = false" [fleets]="headerData.fleets" [clients]="headerData.clients" *ngIf="canImpersonate"></app-clients-menu>

    <div matRipple [matMenuTriggerFor]="userMenu" class="action chevron-menu">
      <span>{{ headerData.userData.initialName }}</span>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
    <mat-menu #userMenu xPosition="after" yPosition="below">
      <button class="button-link" (click)="logout()" mat-menu-item>{{ 'menu.logout' | translate }}</button>
    </mat-menu>
  </div>
</div>
