import { PaletteColor } from './palette-color';
import { MaterialPalette } from './material-palette';
import { Fleet } from '../fleet/fleet';

export class PaletteGenerator {
  public static getPalette(accentColor: string, contrastColor: string): MaterialPalette {
    const result: MaterialPalette = new MaterialPalette();

    result.tint50 = PaletteGenerator.changeLightness(accentColor, 95);
    result.tint100 = PaletteGenerator.changeLightness(accentColor, 90);
    result.tint200 = PaletteGenerator.changeLightness(accentColor, 80);
    result.tint300 = PaletteGenerator.changeLightness(accentColor, 70);
    result.tint400 = PaletteGenerator.changeLightness(accentColor, 60);
    result.tint500 = PaletteGenerator.changeLightness(accentColor, 50);
    result.tint600 = PaletteGenerator.changeLightness(accentColor, 40);
    result.tint700 = PaletteGenerator.changeLightness(accentColor, 30);
    result.tint800 = PaletteGenerator.changeLightness(accentColor, 20);
    result.tint900 = PaletteGenerator.changeLightness(accentColor, 10);

    result.contrast.tint50 = contrastColor;
    result.contrast.tint100 = contrastColor;
    result.contrast.tint200 = contrastColor;
    result.contrast.tint300 = contrastColor;
    result.contrast.tint400 = contrastColor;
    result.contrast.tint500 = contrastColor;
    result.contrast.tint600 = contrastColor;
    result.contrast.tint700 = contrastColor;
    result.contrast.tint800 = contrastColor;
    result.contrast.tint900 = contrastColor;

    return result;
  }

  public static setUserTheme(fleet: Fleet): void {
    if (!fleet.theme) return;

    const primaryPalette: MaterialPalette = PaletteGenerator.getPalette(fleet.theme.webPrimaryColor, '#000000');
    const accentPalette: MaterialPalette = PaletteGenerator.getPalette(fleet.theme.webAccentColor, fleet.theme.webAccentColorContrast);

    document.documentElement.style.setProperty('--primary-color-50', primaryPalette.tint50);
    document.documentElement.style.setProperty('--primary-color-100', primaryPalette.tint100);
    document.documentElement.style.setProperty('--primary-color-200', primaryPalette.tint200);
    document.documentElement.style.setProperty('--primary-color-300', primaryPalette.tint300);
    document.documentElement.style.setProperty('--primary-color-400', primaryPalette.tint400);
    document.documentElement.style.setProperty('--primary-color-500', primaryPalette.tint500);
    document.documentElement.style.setProperty('--primary-color-600', primaryPalette.tint600);
    document.documentElement.style.setProperty('--primary-color-700', primaryPalette.tint700);
    document.documentElement.style.setProperty('--primary-color-800', primaryPalette.tint800);
    document.documentElement.style.setProperty('--primary-color-900', primaryPalette.tint900);

    document.documentElement.style.setProperty('--primary-color-contrast-50', primaryPalette.contrast.tint50);
    document.documentElement.style.setProperty('--primary-color-contrast-100', primaryPalette.contrast.tint100);
    document.documentElement.style.setProperty('--primary-color-contrast-200', primaryPalette.contrast.tint200);
    document.documentElement.style.setProperty('--primary-color-contrast-300', primaryPalette.contrast.tint300);
    document.documentElement.style.setProperty('--primary-color-contrast-400', primaryPalette.contrast.tint400);
    document.documentElement.style.setProperty('--primary-color-contrast-500', primaryPalette.contrast.tint500);
    document.documentElement.style.setProperty('--primary-color-contrast-600', primaryPalette.contrast.tint600);
    document.documentElement.style.setProperty('--primary-color-contrast-700', primaryPalette.contrast.tint700);
    document.documentElement.style.setProperty('--primary-color-contrast-800', primaryPalette.contrast.tint800);
    document.documentElement.style.setProperty('--primary-color-contrast-900', primaryPalette.contrast.tint900);

    document.documentElement.style.setProperty('--accent-color-50', accentPalette.tint50);
    document.documentElement.style.setProperty('--accent-color-100', accentPalette.tint100);
    document.documentElement.style.setProperty('--accent-color-200', accentPalette.tint200);
    document.documentElement.style.setProperty('--accent-color-300', accentPalette.tint300);
    document.documentElement.style.setProperty('--accent-color-400', accentPalette.tint400);
    document.documentElement.style.setProperty('--accent-color-500', accentPalette.tint500);
    document.documentElement.style.setProperty('--accent-color-600', accentPalette.tint600);
    document.documentElement.style.setProperty('--accent-color-700', accentPalette.tint700);
    document.documentElement.style.setProperty('--accent-color-800', accentPalette.tint800);
    document.documentElement.style.setProperty('--accent-color-900', accentPalette.tint900);

    document.documentElement.style.setProperty('--accent-color-contrast-50', accentPalette.contrast.tint50);
    document.documentElement.style.setProperty('--accent-color-contrast-100', accentPalette.contrast.tint100);
    document.documentElement.style.setProperty('--accent-color-contrast-200', accentPalette.contrast.tint200);
    document.documentElement.style.setProperty('--accent-color-contrast-300', accentPalette.contrast.tint300);
    document.documentElement.style.setProperty('--accent-color-contrast-400', accentPalette.contrast.tint400);
    document.documentElement.style.setProperty('--accent-color-contrast-500', accentPalette.contrast.tint500);
    document.documentElement.style.setProperty('--accent-color-contrast-600', accentPalette.contrast.tint600);
    document.documentElement.style.setProperty('--accent-color-contrast-700', accentPalette.contrast.tint700);
    document.documentElement.style.setProperty('--accent-color-contrast-800', accentPalette.contrast.tint800);
    document.documentElement.style.setProperty('--accent-color-contrast-900', accentPalette.contrast.tint900);

    document.documentElement.style.setProperty('--primary-color', fleet.theme.webPrimaryColor);
    document.documentElement.style.setProperty('--accent-color', fleet.theme.webAccentColor);
    document.documentElement.style.setProperty('--accent-color-contrast', fleet.theme.webAccentColorContrast);
    document.documentElement.style.setProperty('--header-color', fleet.theme.webHeaderColor);
    document.documentElement.style.setProperty('--header-font-color', fleet.theme.webHeaderFontColor);
    document.documentElement.style.setProperty('--link-color', fleet.theme.webLinkColor);
    document.documentElement.style.setProperty('--global-font', fleet.theme.webFont);
    document.documentElement.style.setProperty('--status-coming', fleet.theme.statusComingColor);
    document.documentElement.style.setProperty('--status-draft', fleet.theme.statusDraftColor);
    document.documentElement.style.setProperty('--status-offline', fleet.theme.statusOfflineColor);
    document.documentElement.style.setProperty('--status-online', fleet.theme.statusOnlineColor);
  }

  // Changes the RGB/HEX temporarily to a HSL-Value, modifies that value
  // and changes it back to RGB/HEX.
  private static changeHue(rgb: string, degree: number): string {
    let hsl: PaletteColor = PaletteGenerator.rgbToHSL(rgb);
    hsl.hue += degree;

    if (hsl.hue > 360) {
      hsl.hue -= 360;
    } else if (hsl.hue < 0) {
      hsl.hue += 360;
    }

    return PaletteGenerator.hslToRGB(hsl);
  }

  // Changes the RGB/HEX temporarily to a HSL-Value, modifies that value
  // and changes it back to RGB/HEX.
  private static changeLightness(rgb: string, value: number): string {
    let hsl: PaletteColor = PaletteGenerator.rgbToHSL(rgb);
    hsl.lightness = value / 100;

    if (hsl.lightness > 1) hsl.lightness = 1;
    else if (hsl.lightness < 0) hsl.lightness = 0;

    return PaletteGenerator.hslToRGB(hsl);
  }

  // exepcts a string and returns an object
  private static rgbToHSL(rgb: string): PaletteColor {
    // strip the leading # if it's there
    rgb = rgb.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (rgb.length == 3) {
      rgb = rgb.replace(/(.)/g, '$1$1');
    }

    let r = parseInt(rgb.substr(0, 2), 16) / 255,
      g = parseInt(rgb.substr(2, 2), 16) / 255,
      b = parseInt(rgb.substr(4, 2), 16) / 255,
      cMax = Math.max(r, g, b),
      cMin = Math.min(r, g, b),
      delta = cMax - cMin,
      l = (cMax + cMin) / 2,
      h = 0,
      s = 0;

    if (delta == 0) {
      h = 0;
    } else if (cMax == r) {
      h = 60 * (((g - b) / delta) % 6);
    } else if (cMax == g) {
      h = 60 * ((b - r) / delta + 2);
    } else {
      h = 60 * ((r - g) / delta + 4);
    }

    if (delta == 0) {
      s = 0;
    } else {
      s = delta / (1 - Math.abs(2 * l - 1));
    }

    return {
      hue: h,
      saturation: s,
      lightness: l,
    } as PaletteColor;
  }

  // expects an object and returns a string
  private static hslToRGB(hsl: PaletteColor): string {
    let h: number = hsl.hue,
      s: number = hsl.saturation,
      l: number = hsl.lightness,
      c: number = (1 - Math.abs(2 * l - 1)) * s,
      x: number = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m: number = l - c / 2,
      r: number,
      g: number,
      b: number;

    if (h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }

    r = PaletteGenerator.normalize_rgb_value(r, m);
    g = PaletteGenerator.normalize_rgb_value(g, m);
    b = PaletteGenerator.normalize_rgb_value(b, m);

    return PaletteGenerator.rgbToHex(r, g, b);
  }

  private static normalize_rgb_value(color: number, m: number): number {
    const floor = Math.floor((color + m) * 255);

    return floor < 0 ? 0 : floor;
  }

  private static rgbToHex(r: number, g: number, b: number): string {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
}
