import { Directive, HostBinding, Input, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'label[appControlName]',
})
export class LabelControlNameDirective {
  @Input() public appControlName: string;

  constructor(@Optional() private parent: ControlContainer, private translateService: TranslateService) {}

  @HostBinding('textContent')
  public get controlValue(): string {
    return this.parent ? this.translateService.instant('buildings.openings.opening_times.' + this.parent.control?.get(this.appControlName)?.value) : '';
  }
}
