import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-busy-button',
  templateUrl: './busy-button.component.html',
  styleUrls: ['./busy-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusyButtonComponent {
  @Input() public text: string;
  @Input() public isBusy: boolean = false;
  @Input() public inactive: boolean;
  @Input() public isFlat: boolean = false;
  @Input() public disabled: boolean = false;

  @Output() private buttonClick: EventEmitter<void> = new EventEmitter<void>();

  public onClick(event: Event): void {
    event.stopPropagation();

    if (!this.isBusy) this.buttonClick.emit();
  }
}
