export enum MaskScheduleStatus {
  NotPublished = 1,
  NotPublishedTranslate = 'mask_list.schedule_status.not_published',
  InBroadcast = 2,
  InBroadcastTranslate = 'mask_list.schedule_status.in_broadcast',
  NextBroadcast = 4,
  NextBroadcastTranslate = 'mask_list.schedule_status.next_broadcast',
  BroadcastEnded = 8,
  BroadcastEndedTranslate = 'mask_list.schedule_status.broadcast_ended',
}
