import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MaskSchedule, WeekDay } from '../mask-edition/models/mask-schedule';
import { RepetitionType } from '../mask-edition/models/repetition-type';

@Injectable({
  providedIn: 'root',
})
export class ScheduleHelper {
  constructor(private readonly translateService: TranslateService) {}

  public weekDayToString(weekDay: WeekDay): string {
    let translation: string = '';

    if ((weekDay & WeekDay.None) === WeekDay.Monday) return translation;
    if ((weekDay & WeekDay.Monday) === WeekDay.Monday) translation += this.translateService.instant('common.week.monday') + ', ';
    if ((weekDay & WeekDay.Tuesday) === WeekDay.Tuesday) translation += this.translateService.instant('common.week.tuesday') + ', ';
    if ((weekDay & WeekDay.Wednesday) === WeekDay.Wednesday) translation += this.translateService.instant('common.week.wednesday') + ', ';
    if ((weekDay & WeekDay.Thursday) === WeekDay.Thursday) translation += this.translateService.instant('common.week.thursday') + ', ';
    if ((weekDay & WeekDay.Friday) === WeekDay.Friday) translation += this.translateService.instant('common.week.friday') + ', ';
    if ((weekDay & WeekDay.Saturday) === WeekDay.Saturday) translation += this.translateService.instant('common.week.saturday') + ', ';
    if ((weekDay & WeekDay.Sunday) === WeekDay.Sunday) translation += this.translateService.instant('common.week.sunday') + ', ';

    return translation;
  }

  public extractDetailedMaskSchedule(maskSchedule: MaskSchedule): string {
    let schedule: string = '';
    const utcStartDate: moment.Moment = moment.utc(maskSchedule.startDate);
    const startTime: string = utcStartDate.format('LT');
    const endTime: string = moment.utc(maskSchedule.endDate).format('LT');

    switch (maskSchedule.repetitionType) {
      case RepetitionType.Once:
        schedule = this.translateService.instant('mask.publish.schedule_recap.once', {
          startDate: utcStartDate.format('L'),
        });
        break;
      case RepetitionType.Daily:
        schedule = this.translateService.instant('mask.publish.schedule_recap.daily');
        break;
      case RepetitionType.Weekly:
        schedule = this.translateService.instant('mask.publish.schedule_recap.weekly', { weekDay: this.weekDayToString(maskSchedule.weekDays) });
        break;
      case RepetitionType.Monthly:
        schedule = this.translateService.instant('mask.publish.schedule_recap.monthly', { monthDay: utcStartDate.format('Do') });
        break;
      case RepetitionType.Yearly:
        schedule = this.translateService.instant('mask.publish.schedule_recap.yearly', { day: utcStartDate.format('Do'), month: utcStartDate.format('MMMM') });
        break;
    }

    schedule = schedule.concat(this.translateService.instant('mask.publish.schedule_recap.time_interval', { startTime: startTime, endTime: endTime }));

    if (maskSchedule.repetitionType !== RepetitionType.Once) {
      if (maskSchedule.repetitionEndDate) {
        schedule = schedule.concat(
          this.translateService.instant('mask.publish.schedule_recap.from_to', {
            startDate: utcStartDate.format('L'),
            endDate: moment.utc(maskSchedule.repetitionEndDate).format('L'),
          })
        );
      } else {
        schedule = schedule.concat(this.translateService.instant('mask.publish.schedule_recap.from_start_date', { startDate: utcStartDate.format('L') }));
      }
    }

    return schedule;
  }

  public extractMaskSchedule(maskSchedule: MaskSchedule): string {
    let schedule: string = '';
    const utcStartDate: moment.Moment = moment.utc(maskSchedule.startDate);

    if (maskSchedule.repetitionType !== RepetitionType.Once) {
      if (maskSchedule.repetitionEndDate) {
        schedule = this.translateService.instant('mask_list.tile.schedule.from_to', {
          startDate: utcStartDate.format('L'),
          endDate: moment.utc(maskSchedule.repetitionEndDate).format('L'),
        });
      } else {
        schedule = this.translateService.instant('mask_list.tile.schedule.from_start_date', { startDate: utcStartDate.format('L') });
      }
    } else {
      schedule = this.translateService.instant('mask_list.tile.schedule.once', { startDate: utcStartDate.format('L') });
    }

    return schedule;
  }
}
