import { FieldTemplateConfiguration } from './template-configuration-base';
import { TemplateAssetType } from './template-asset-type.enum';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';

export class FieldTextList extends FieldTemplateConfiguration {
  multiline: boolean;
  maxLength: number;
  texts: string[];

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);

    this.type = TemplateAssetType.TextList;
    this.maxLength = field.maxLength!;
    this.multiline = field.multiline!;
    this.texts = asset?.texts ?? [];
  }

  override isValid(): boolean {
    return this.texts.length === 0 || !this.texts.some((text) => text.length > this.maxLength);
  }

  override isEmpty(): boolean {
    return this.texts.length === 0 || this.texts.some((t) => t.length === 0);
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    asset.texts = this.texts;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    return this.texts;
  }
}
