<div class="row" *ngIf="data">
  <div *ngIf="data.multiline; then textarea; else input"></div>
  <mat-error *ngIf="!isValid" class="error-label">{{ 'mask.configuration.text_error' | translate: { max: data.maxLength } }}</mat-error>
</div>

<ng-template #input>
  <mat-form-field>
    <mat-label>{{ data.title }}</mat-label>
    <input
      #text
      matInput
      [(ngModel)]="data.text"
      [required]="data.mandatory"
      [maxLength]="data.maxLength"
      (ngModelChange)="onChange()"
      (focusout)="onFocusOut()"
      appDynamicDataPicker
    />
  </mat-form-field>
</ng-template>
<ng-template #textarea>
  <mat-form-field>
    <mat-label>{{ data.title }}</mat-label>
    <textarea
      matInput
      mat-autosize
      cdkTextareaAutosize
      [(ngModel)]="data.text"
      [required]="data.mandatory"
      [maxLength]="data.maxLength"
      (ngModelChange)="onChange()"
      (focusout)="onFocusOut()"
      appDynamicDataPicker
    ></textarea>
  </mat-form-field>
</ng-template>
