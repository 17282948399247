import { ApplicationRef, ComponentRef, createComponent, Directive, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TooltipData } from '../components/tooltip/tooltip-data';
import { TooltipComponent } from '../components/tooltip/tooltip.component';

@Directive({
  selector: '[appTooltip]',
})
export class GcaTooltipDirective implements OnInit, OnDestroy, OnChanges {
  /**
   * (optional) title: will be rendered as a bolder text at the begining of the tooltip
   */
  @Input() public tooltipTitle: string;

  /**
   * (optional) List of items to display in the tooltip (will be rendered as a bullet list or as a table depending on the 'useBulletPoints' setting). Use at least "tooltipItems" or "tooltipText" to have it displayed correctly.
   */
  @Input() public tooltipItems: TooltipData[];

  /**
   * (optional) Simple text to display inside the tooltip. Use at least "tooltipItems" or "tooltipText" to have it displayed correctly.
   */
  @Input() public tooltipText: string;

  /**
   * If set to true, the array of items will be rendered as a bullet list instead of a table
   */
  @Input() public tooltipUseBulletPoints: boolean = false;

  private tooltipRef: ComponentRef<TooltipComponent>;
  private hostRef: HTMLElement;

  constructor(private app: ApplicationRef) {}

  public ngOnInit(): void {
    // inject tooltip component

    this.hostRef = document.createElement('div');
    document.body.appendChild(this.hostRef);

    this.tooltipRef = createComponent(TooltipComponent, {
      hostElement: this.hostRef,
      environmentInjector: this.app.injector,
    });

    this.app.attachView(this.tooltipRef.hostView);

    this.refresh();
  }

  public ngOnDestroy(): void {
    this.tooltipRef.destroy();
    this.hostRef.remove();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  private refresh(): void {
    if (!this.tooltipRef) return;

    // set tooltip data
    this.tooltipRef.instance.items = this.tooltipItems;
    this.tooltipRef.instance.title = this.tooltipTitle;
    this.tooltipRef.instance.text = this.tooltipText;
    this.tooltipRef.instance.useBulletPoints = this.tooltipUseBulletPoints;
  }

  @HostListener('mousemove', ['$event']) public onMouseMove(event: MouseEvent): void {
    if (this.tooltipRef) this.tooltipRef.instance.move(event.x, event.y);
  }

  @HostListener('mouseover', ['$event']) public onMouseOver(event: MouseEvent): void {
    if (this.tooltipRef) this.tooltipRef.instance.show(event.x, event.y);
  }

  @HostListener('mouseout') public onMouseOut(): void {
    if (this.tooltipRef) this.tooltipRef.instance.hide();
  }
}
