import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { MediaLibrary } from 'src/app/core/media-library/media-library';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  templateUrl: './dialog-image-preview.component.html',
  styleUrls: ['./dialog-image-preview.component.scss'],
})
export class DialogImagePreviewComponent {
  public readonly imageUrl: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: MediaLibrary, private dialogRef: MatDialogRef<ConfirmComponent>) {
    this.imageUrl = data.blobUrl;
  }

  public close(): void {
    this.dialogRef.close(ConfirmResult.No);
  }
}
