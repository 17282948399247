import { O } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { DraggableInterval } from './draggable-interval';
import { TimetableInterval } from './timetable-interval';
import { TimetableItem } from './timetable-item';
import { WeekSchedule } from './week-schedule';

@Component({
  selector: 'app-multiple-schedule',
  templateUrl: './multiple-schedule.component.html',
  styleUrls: ['./multiple-schedule.component.scss'],
})
export class MultipleScheduleComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor() {
    document.addEventListener('mouseup', (evt) => this.resetDrag());
    document.addEventListener('mouseleave', () => this.resetDrag());
  }

  private static readonly tickSpacing: number = 10;
  //@Input() public items: TimetableItem[];
  @Input() public schedule: WeekSchedule;
  @Input() public title: string;
  @Output() public scheduleChanged: EventEmitter<WeekSchedule> = new EventEmitter<WeekSchedule>();

  @ViewChild('day') public day: ElementRef;

  public timetable: TimetableInterval[][];

  // these two variables are used to know which element (right or left handle) is being dragged
  public currentRightHandle: DraggableInterval | null = null;
  public currentLeftHandle: DraggableInterval | null = null;
  public currentDayTimetable: TimetableInterval[] | null = null;

  public timePickerTheme: NgxMaterialTimepickerTheme = {
    container: { buttonColor: 'var(--accent-color)', primaryFontFamily: 'var(--global-font)' },
    dial: { dialBackgroundColor: '#fff', dialEditableActiveColor: '#bbb', dialInactiveColor: 'var(--accent-color)', dialActiveColor: '#fff' },
    clockFace: { clockHandColor: 'var(--accent-color)' },
  };

  public showDetails: boolean = false;
  public detailedInterval: TimetableInterval | null;

  public startTime: string;
  public endTime: string;
  public validationMessage: string | null = null;

  @ViewChild('scroller') public scroller: ElementRef;

  private static toInterval(items: TimetableItem[]): TimetableInterval[] {
    return items.map((item) => {
      const interval: TimetableInterval = new TimetableInterval(10);

      interval.setStartTime(item.startHours, item.startMinutes);
      interval.setEndTime(item.endHours, item.endMinutes);

      return interval;
    });
  }

  /**
   * Compares two times
   *
   * @returns 0 if they are equal, lower than 0 if time1 is after time 2, higher than 0 if time1 is before time2
   */
  private static compareTimes(hours1: number, minutes1: number, hours2: number, minutes2: number): number {
    const time1: number = hours1 * 60 + minutes1;
    const time2: number = hours2 * 60 + minutes2;

    return time2 - time1;
  }

  /** Returns the actual position in pixels of the handle within its parent background.
   * @offset is used to account for the handle's width in pixels
   */
  private static getX(target: HTMLElement, clientX: number, offset: number): number {
    var rect: DOMRect = target.parentElement!.parentElement!.getBoundingClientRect();

    return Math.min(rect.width, Math.max(0, Math.round(clientX - rect.left + offset)));
  }

  public ngOnDestroy(): void {
    document.removeEventListener('mouseup', () => this.resetDrag());
    document.removeEventListener('mouseleave', () => this.resetDrag());
  }

  public ngAfterViewInit(): void {
    this.scrollToMidday();
  }

  public ngOnInit(): void {
    this.timetable = new Array<TimetableInterval[]>(7);

    if (this.schedule) {
      this.timetable[0] = MultipleScheduleComponent.toInterval(this.schedule.monday);
      this.timetable[1] = MultipleScheduleComponent.toInterval(this.schedule.tuesday);
      this.timetable[2] = MultipleScheduleComponent.toInterval(this.schedule.wednesday);
      this.timetable[3] = MultipleScheduleComponent.toInterval(this.schedule.thursday);
      this.timetable[4] = MultipleScheduleComponent.toInterval(this.schedule.friday);
      this.timetable[5] = MultipleScheduleComponent.toInterval(this.schedule.saturday);
      this.timetable[6] = MultipleScheduleComponent.toInterval(this.schedule.sunday);
    }
  }

  private getDefaultInterval(): TimetableInterval {
    const defaultInterval: TimetableInterval = new TimetableInterval(10);

    defaultInterval.setStartTime(9, 0);
    defaultInterval.setEndTime(19, 0);

    return defaultInterval;
  }

  /** This method resets all the unnecessary dragging events and data when dragging ends (user releases mouse button or leavs the page) */
  public resetDrag(): void {
    // reset mouse cursor on the background
    if (this.currentRightHandle) {
      this.day.nativeElement.style.cursor = 'default';
      this.currentRightHandle.interval.dragging = false;

      this.triggerChanged();
    }

    if (this.currentLeftHandle) {
      this.day.nativeElement.style.cursor = 'default';
      this.currentLeftHandle.interval.dragging = false;

      this.triggerChanged();
    }

    // reset current data
    this.currentRightHandle = null;
    this.currentLeftHandle = null;
    this.currentDayTimetable = null;

    // reset handlers
    document.onmousemove = null;
    window.onmousemove = null;
  }

  /** Occurs repeatedly after any handle was clicked and while the mouse button is down and the mouse moving */
  public handleDragging(evt: MouseEvent): void {
    if (!this.currentLeftHandle && !this.currentRightHandle) return;

    if (this.currentRightHandle) this.rightDrag(evt);
    if (this.currentLeftHandle) this.leftDrag(evt);
  }

  /** Used to handle the start of a dragging event on any right handle */
  public rightHandleMouseDown(event: MouseEvent, interval: TimetableInterval, index: number): void {
    this.currentRightHandle = {
      interval: interval,
      target: event.target as HTMLElement,
    };

    interval.dragging = true;
    this.currentLeftHandle = null;

    this.mouseDown(event, index);
  }

  /** Used to handle the start of a dragging event on any left handle */
  public leftHandleMouseDown(event: MouseEvent, interval: TimetableInterval, index: number): void {
    this.currentLeftHandle = {
      interval: interval,
      target: event.target as HTMLElement,
    };

    interval.dragging = true;
    this.currentRightHandle = null;

    this.mouseDown(event, index);
  }

  /** Dragging start event common behaviour */
  public mouseDown(event: MouseEvent, index: number): void {
    document.onmousemove = () => {
      window.getSelection()?.removeAllRanges();
    };

    this.currentDayTimetable = this.timetable[index];

    // this is where we will start listening for mouse movements for as
    // long as the user doesn't leave the page or release the mouse button
    window.onmousemove = (evt) => this.handleDragging(evt);

    // sets background cursor so that the mouse cursor doesn't start blinking because it is only over the handle
    // when the handle is close to a quarter (15 mins) tick
    this.day.nativeElement.style.cursor = 'ew-resize';
  }

  /** Calculates and sets the timetable interval's new start time */
  public leftDrag(event: MouseEvent): void {
    const prevIndex: number = this.currentDayTimetable!.indexOf(this.currentLeftHandle!.interval) - 1;
    const leftPosition: number = MultipleScheduleComponent.getX(this.currentLeftHandle!.target, event.clientX, -5);
    const quarters: number = Math.round(leftPosition / MultipleScheduleComponent.tickSpacing);

    const hours: number = Math.floor(quarters / 4);
    const minutes: number = (quarters % 4) * 15;

    // check that the new value will not overlap with the previous interval
    if (prevIndex >= 0) {
      const prevInterval: TimetableInterval = this.currentDayTimetable![prevIndex];
      if (MultipleScheduleComponent.compareTimes(prevInterval.endHours, prevInterval.endMinutes, hours, minutes) < 0) return;
    }

    this.currentLeftHandle!.interval.setStartTime(hours, minutes);
  }

  /** Calculates and sets the timetable interval's new end time */
  public rightDrag(event: MouseEvent): void {
    const nextIndex: number = this.currentDayTimetable!.indexOf(this.currentRightHandle!.interval) + 1;
    const rightPosition: number = MultipleScheduleComponent.getX(this.currentRightHandle!.target, event.clientX, -5);
    const quarters: number = Math.round(rightPosition / MultipleScheduleComponent.tickSpacing);

    const hours: number = Math.floor(quarters / 4);
    const minutes: number = (quarters % 4) * 15;

    // check that the new value will not overlap with the next interval
    if (nextIndex < this.currentDayTimetable!.length) {
      const nextInterval: TimetableInterval = this.currentDayTimetable![nextIndex];
      if (MultipleScheduleComponent.compareTimes(nextInterval.startHours, nextInterval.startMinutes, hours, minutes) > 0) return;
    }

    this.currentRightHandle!.interval.setEndTime(Math.floor(quarters / 4), (quarters % 4) * 15);
  }

  public addInterval(event: MouseEvent, index: number): void {
    // check if click actually occured on the cursor element (and not on some other element)
    const isBackground: boolean = (event.target as HTMLElement).classList.contains('cursor');

    if (!isBackground) return;

    // calculate which minute (from the begining of the day) was clicked, and round it to the closest quarter (00, 15, 30, 45)
    const startTimeInMinutes: number = Math.floor((event.target as HTMLElement).offsetLeft / 10) * 15;
    const endTimeInMinutes: number = startTimeInMinutes + 15;

    const interval: TimetableInterval = new TimetableInterval();
    interval.setStartTime(Math.floor(startTimeInMinutes / 60), startTimeInMinutes % 60);
    interval.setEndTime(Math.floor(endTimeInMinutes / 60), endTimeInMinutes % 60);

    for (let i: number = 0; i < this.timetable[index].length; i++)
      if (this.timetable[index][i].isOverlapping(interval)) {
        console.log('Warning: not enough space available to add an interval');
        return;
      }

    this.timetable[index].push(interval);

    // start dragging to the right
    this.rightHandleMouseDown(event, interval, index);

    // this.triggerChanged();
  }

  public showActions(event: MouseEvent, interval: TimetableInterval): void {
    if (!interval.showActions) {
      interval.actionsX = event.offsetX;
    }

    // hide all others
    this.timetable.forEach((tt) => {
      tt.forEach((intvl) => {
        if (interval !== intvl) intvl.showActions = false;
      });
    });

    interval.showActions = !interval.showActions;

    event.stopPropagation();
  }

  public hideAllActions(): void {
    this.timetable.forEach((tt) => {
      tt.forEach((intvl) => (intvl.showActions = false));
    });
  }

  /** removes an interval from the list */
  public remove(evt: MouseEvent, interval: TimetableInterval): void {
    this.timetable.forEach((tt) => {
      const index: number = tt.indexOf(interval);

      if (index >= 0) {
        tt.splice(index, 1);
        this.triggerChanged();
      }

      evt.stopPropagation();
    });
  }

  // @ViewChild('cursor') cursor: ElementRef;

  public moveCursor(event: MouseEvent, cursor: HTMLElement): void {
    // check if move actually occured on the background (and not on some other element)
    const isLine: boolean = (event.target as HTMLElement).classList.contains('line');

    if (!isLine) return;

    cursor.style.left = Math.floor(event.offsetX / 10) * 10 + 'px';
  }

  public openDetails(interval: TimetableInterval): void {
    this.showDetails = true;
    this.detailedInterval = interval;
    this.startTime = interval.startTime;
    this.endTime = interval.endTime;
  }

  public closeDetails(): void {
    this.detailedInterval = null;
    this.showDetails = false;
    this.validationMessage = null;
  }

  public saveInterval(): void {
    if (!this.startTime || !this.endTime) return;

    const startSplit: Array<string> = this.startTime.split(':');
    const startHours: number = parseInt(startSplit[0]);
    const startMinutes: number = parseInt(startSplit[1]);

    const endSplit: Array<string> = this.endTime.split(':');
    const endHours: number = parseInt(endSplit[0]) == 0 ? 24 : parseInt(endSplit[0]);
    const endMinutes: number = parseInt(endSplit[1]);

    const starTime: number = startHours * 60 + startMinutes;
    const endTime: number = endHours * 60 + endMinutes;

    if (endTime <= starTime) {
      this.validationMessage = 'multiple_schedule.times_mismatch';
      return;
    }

    this.detailedInterval!.setStartTime(startHours, startMinutes);
    this.detailedInterval!.setEndTime(endHours, endMinutes);

    this.closeDetails();
    this.triggerChanged();
  }

  private triggerChanged(): void {
    // reorganize all intervals in ascending order
    for (let i: number = 0; i < 7; i++) {
      this.timetable[i] = this.timetable[i].sort((i1, i2) => i1.startHours * 60 + i1.startMinutes - (i2.startHours * 60 + i2.startMinutes));
    }

    // generate schedule
    // this.itemsChanged.emit(
    //   this.timetable.map(
    //     (interval) => ({ startHours: interval.startHours, startMinutes: interval.startMinutes, endHours: interval.endHours, endMinutes: interval.endMinutes } as TimetableItem)
    //   )
    // );

    const schedule: WeekSchedule = new WeekSchedule();

    schedule.monday = this.timetable[0].map(
      (interval) => ({ startHours: interval.startHours, startMinutes: interval.startMinutes, endHours: interval.endHours, endMinutes: interval.endMinutes } as TimetableItem)
    );

    schedule.tuesday = this.timetable[1].map(
      (interval) => ({ startHours: interval.startHours, startMinutes: interval.startMinutes, endHours: interval.endHours, endMinutes: interval.endMinutes } as TimetableItem)
    );

    schedule.wednesday = this.timetable[2].map(
      (interval) => ({ startHours: interval.startHours, startMinutes: interval.startMinutes, endHours: interval.endHours, endMinutes: interval.endMinutes } as TimetableItem)
    );

    schedule.thursday = this.timetable[3].map(
      (interval) => ({ startHours: interval.startHours, startMinutes: interval.startMinutes, endHours: interval.endHours, endMinutes: interval.endMinutes } as TimetableItem)
    );

    schedule.friday = this.timetable[4].map(
      (interval) => ({ startHours: interval.startHours, startMinutes: interval.startMinutes, endHours: interval.endHours, endMinutes: interval.endMinutes } as TimetableItem)
    );

    schedule.saturday = this.timetable[5].map(
      (interval) => ({ startHours: interval.startHours, startMinutes: interval.startMinutes, endHours: interval.endHours, endMinutes: interval.endMinutes } as TimetableItem)
    );

    schedule.sunday = this.timetable[6].map(
      (interval) => ({ startHours: interval.startHours, startMinutes: interval.startMinutes, endHours: interval.endHours, endMinutes: interval.endMinutes } as TimetableItem)
    );

    this.scheduleChanged.emit(schedule);
  }

  public scrollToMidday(): void {
    const horizontalShift: number = (this.scroller.nativeElement.scrollWidth - this.scroller.nativeElement.getBoundingClientRect().width) / 2 + 30;

    this.scroller.nativeElement.scrollTo(horizontalShift, 0);
  }
}
