import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

const primaryColor: string = '#7c79a3';
const white: string = '#ffffff';
const lightGrey: string = '#c4c4c4';
const fontFamily: string = 'Helvetica Neue';

export const timePickerTheme = (): NgxMaterialTimepickerTheme => {
  return {
    clockFace: { clockHandColor: primaryColor },
    container: { buttonColor: primaryColor, primaryFontFamily: fontFamily },
    dial: { dialBackgroundColor: white, dialEditableActiveColor: lightGrey, dialInactiveColor: primaryColor, dialActiveColor: white },
  };
};
