import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from 'src/app/general/layout/services/loader.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptor implements HttpInterceptor {
  public pendingRequests: number = 0;
  public pendingRequestsStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private loaderService: LoaderService) {}

  public intercept(req: HttpRequest<HttpEventType>, next: HttpHandler): Observable<HttpEvent<HttpEventType>> {
    const shouldBypass: boolean = this.shouldBypass(req);

    // console.log(`[Loader] Should bypass '${req.urlWithParams}': ${shouldBypass}`);

    if (!shouldBypass) {
      this.pendingRequests++;

      if (this.pendingRequests > 0) {
        this.loaderService.show();
      }
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (!shouldBypass) {
          this.pendingRequests--;

          if (0 === this.pendingRequests) {
            this.loaderService.hide();
          }
        }
      })
    );
  }

  private shouldBypassMethod(req: HttpRequest<HttpEventType>): boolean {
    return this.loaderService.filteredMethods.some((e) => {
      return e.toUpperCase() === req.method.toUpperCase();
    });
  }

  private shouldBypassHeader(req: HttpRequest<HttpEventType>): boolean {
    return this.loaderService.filteredHeaders.some((e) => {
      return req.headers.has(e);
    });
  }

  private shouldBypassUrl(url: string): boolean {
    return this.loaderService.filteredUrlPatterns.some((urlPattern) => urlPattern.test(url)) || this.loaderService.excludedUrls.some((excludedUrl) => url === excludedUrl);
  }

  private shouldBypass(req: HttpRequest<HttpEventType>): boolean {
    return this.shouldBypassMethod(req) || this.shouldBypassUrl(req.urlWithParams) || this.shouldBypassHeader(req);
  }
}
