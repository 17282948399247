import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  public read(key: string, defaultValue?: string): string | undefined {
    const data: string | null = sessionStorage.getItem(key);

    if (data) {
      return data;
    }

    return defaultValue;
  }

  public clear(): void {
    sessionStorage.clear();
  }

  public exists(key: string): boolean {
    const data: string | null = sessionStorage.getItem(key);
    return data ? true : false;
  }

  public readObject<T>(key: string): T | undefined {
    const data: string | null = sessionStorage.getItem(key);
    if (data) return JSON.parse(data);
    return;
  }

  public write(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  public writeObject<T>(key: string, value: T): void {
    if (value) sessionStorage.setItem(key, JSON.stringify(value));
  }

  public delete(key: string): void {
    sessionStorage.removeItem(key);
  }
}
