import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';
import { FieldTable } from 'src/app/core/template-configuration/field-table';
import { ExternalResourcesService } from 'src/app/shared/services/external-resources.service';
import { GroupBy, MaskItem } from './models/mask-item';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, Validators } from '@angular/forms';
import { orangeNewsOptions } from './models/referentiels/orange-news/orangenews-options';
import { FilterOptions } from './models/filter-options';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NotificationService } from '../../notification/notification.service';

@Component({
  selector: 'app-configuration-mask-table',
  templateUrl: './configuration-mask-table.component.html',
  styleUrls: ['./configuration-mask-table.component.scss'],
})
export class ConfigurationMaskTableComponent implements ConfigurationMaskItemComponent, OnInit {
  @Input() public data: FieldTable;

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  public displayedColumns: string[] = ['image', 'content'];
  public listItems: MatTableDataSource<MaskItem | GroupBy>;

  public selectedItems: (MaskItem | GroupBy)[] = [];
  public availableItems: (MaskItem | GroupBy)[] = [];

  public categoryFilterLabel: string;
  public categoryControl: FormControl;
  public options: FilterOptions[];
  public filteredOptions: Observable<FilterOptions[]>;

  public searchControl: FormControl;
  private readonly searchFieldMinLength: number = 3;

  private readonly defaultImageThumbnail: string = '../../../../../assets/img/image-not-found.jpg';

  constructor(private translateService: TranslateService, private notificationService: NotificationService, private externalResourcesService: ExternalResourcesService) {}

  ngOnInit(): void {
    this.listItems = new MatTableDataSource<MaskItem | GroupBy>();

    this.initializeData();
    this.initilializeFilter();
    this.initilializeSearchInput();
  }

  private initializeData(): void {
    const headerTitle = this.getCategoryFilterLabel();
    this.categoryFilterLabel = this.translateService.instant(headerTitle);

    forkJoin([this.getSelectedResources(), this.getAvailableResources(null, null)]).subscribe({
      next: ([selectedItems, availableItems]) => {
        const selected: (MaskItem | GroupBy)[] = this.setSelectedItems(selectedItems);
        const available = this.setAvailableItems(availableItems);

        this.listItems.data = [...selected, ...available];
      },
    });
  }

  private getSelectedResources(): Observable<MaskItem[] | null> {
    if (this.data.isEmpty()) {
      return of(null);
    }

    return this.externalResourcesService.getSelectedResources<MaskItem[]>(this.data.externalResource, this.data.selected.split(';'));
  }

  private getAvailableResources(selectedOptionFilter: string | null, searchValue: string | null): Observable<MaskItem[]> {
    return this.externalResourcesService.getResources<MaskItem[]>(this.data.externalResource, selectedOptionFilter, searchValue);
  }

  private setAvailableItems(availableItems: MaskItem[]): (MaskItem | GroupBy)[] {
    const data: (MaskItem | GroupBy)[] = this.initGroupByAvailableItems();

    if (!availableItems || availableItems.length === 0) {
      this.availableItems = data;
      return data;
    }

    data.push(
      ...availableItems.map((item: MaskItem) => {
        return {
          ...item,
          title: item.title && item.title != '' ? item.title : 'Aucun titre',
          imageUrl: item.imageUrl ?? this.defaultImageThumbnail,
          selected: false,
        };
      })
    );

    this.availableItems = data;

    return data;
  }

  private initGroupByAvailableItems(): (MaskItem | GroupBy)[] {
    const headerTitle = this.getHeaderTitle();

    return [<GroupBy>{ id: '-2', group: this.translateService.instant(headerTitle), isGroupBy: true }];
  }

  private setSelectedItems(selectedItems: MaskItem[] | null): (MaskItem | GroupBy)[] {
    if (!selectedItems) {
      return [];
    }

    const selectedDataSource: (MaskItem | GroupBy)[] = [
      <GroupBy>{ id: '-1', group: this.translateService.instant('mask-table.group.selected-items'), isGroupBy: true },
      ...selectedItems.map((item: MaskItem) => {
        return {
          ...item,
          imageUrl: item.imageUrl ?? this.defaultImageThumbnail,
          selected: true,
        };
      }),
    ];

    this.selectedItems = selectedDataSource;

    return selectedDataSource;
  }

  private initilializeFilter(): void {
    if (this.data.filterEnabled) {
      this.options = this.getFilterOptions();
      this.categoryControl = new FormControl(this.options[0]);

      this.filteredOptions = this.categoryControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || ''))
      );
    }
  }

  public clearFilterInput(): void {
    this.categoryControl.setValue('');
  }

  private _filter(value: string | FilterOptions): FilterOptions[] {
    if (typeof value === 'string') {
      const valueFiltered = value.toLowerCase();
      return this.options.filter((option) => valueFiltered == '' || option.value.toLowerCase().includes(valueFiltered));
    }

    return this.options.filter((option) => option.key == value.key);
  }

  public get filterOptionValue(): string {
    return (this.categoryControl.value as unknown as FilterOptions).key;
  }

  public displayOptionValue(option: FilterOptions): string {
    return option.value;
  }

  public selectOptionForFiltering(event: MatAutocompleteSelectedEvent | null): void {
    let filterId: string = '';

    if (event != null) {
      const selectedOption: FilterOptions = event.option.value;

      if (!selectedOption) {
        return;
      }

      filterId = selectedOption.key;
    }

    this.getAvailableResources(filterId, this.searchControlValue).subscribe({
      next: (items: MaskItem[]) => {
        this.listItems.data = [...this.selectedItems, ...this.setAvailableItems(items)];
      },
    });
  }

  private initilializeSearchInput() {
    if (this.data.searchEnabled) {
      this.searchControl = new FormControl('', [Validators.minLength(this.searchFieldMinLength)]);
    }
  }

  public clearSearchInput(): void {
    this.searchControl.setValue('');
    this.search();
  }

  public handleSearchInput(event: KeyboardEvent): void {
    if (event.key !== 'Enter' || event.altKey || event.ctrlKey) {
      return;
    }

    event.preventDefault();
    this.checkAndExecuteSearch();
  }

  public searchForInputValue(): void {
    this.checkAndExecuteSearch();
  }

  private get searchControlValue(): string {
    return this.searchControl.value;
  }

  private get isSearchLengthValid(): boolean {
    return this.searchControlValue.trim().length >= this.searchFieldMinLength;
  }

  private checkAndExecuteSearch(): void {
    if (!this.isSearchLengthValid) {
      this.notificationService.displayError(this.translateService.instant('mask-table.error.search_length'));
      return;
    }

    this.search();
  }

  private search(): void {
    this.getAvailableResources(this.filterOptionValue, this.searchControlValue).subscribe({
      next: (items: MaskItem[]) => {
        this.listItems.data = [...this.selectedItems, ...this.setAvailableItems(items)];
      },
    });
  }

  public selectSingleItem(row: MaskItem): void {
    const previousSelectedItem = this.listItems.data.find((item: MaskItem | GroupBy) => (item as MaskItem).selected === true);

    if (previousSelectedItem && row.id === previousSelectedItem.id) {
      return;
    }

    const selectedItem = this.listItems.data.find((item: MaskItem | GroupBy) => (item as MaskItem).id === row.id);

    const selected: (MaskItem | GroupBy)[] = this.setSelectedItems([selectedItem as MaskItem]);

    this.listItems.data = [...selected, ...this.availableItems];

    this.data.selected = [row.id].join(';');

    if (this.data.isValid()) {
      this.outputValue.next(this.data);
      this.outputFocus.next(this.data);
    }
  }

  public isGroup(index: number, item: GroupBy): boolean {
    return item.isGroupBy;
  }

  private getFilterOptions(): FilterOptions[] {
    switch (this.data.externalResource) {
      case 'orangenews':
        return orangeNewsOptions;
      default:
        return orangeNewsOptions;
    }
  }

  private getCategoryFilterLabel(): string {
    switch (this.data.externalResource) {
      case 'orangenews':
        return 'mask-table.filter.orange-entities';
      default:
        return 'mask-table.filter.orange-entities';
    }
  }

  private getHeaderTitle(): string {
    switch (this.data.externalResource) {
      case 'orangenews':
        return 'mask-table.group.last-news';
      default:
        return 'mask-table.group.default';
    }
  }
}
