import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/setttings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalResourcesService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {
    this.baseUrl = `${this.settingsService.settings.API_URL}/externalResources`;
  }

  public getResources<T>(resourceName: string, selectedOptionFilter: string | null, searchValue: string | null): Observable<T> {
    let queryParams: string = '';

    if (selectedOptionFilter) {
      queryParams += `?${this.getFilterName(resourceName)}=${selectedOptionFilter}`;
    }

    if (searchValue && searchValue !== '') {
      if (queryParams === '') queryParams += `?${this.getSearchName(resourceName)}=${searchValue}`;
      else queryParams += `&${this.getSearchName(resourceName)}=${searchValue}`;
    }

    return this.httpClient.get<T>(`${this.baseUrl}/${resourceName}${queryParams}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('external-resources.error.get');
        return throwError(err);
      })
    );
  }

  public getSelectedResources<T>(resourceName: string, params: any): Observable<T> {
    return this.httpClient.post<T>(`${this.baseUrl}/${resourceName}`, params, { observe: 'body' }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('external-resources.error.post');
        return throwError(err);
      })
    );
  }

  private getFilterName(resourceName: string): string {
    switch (resourceName) {
      case 'orangenews':
        return 'categoryId';
      default:
        return 'categoryId';
    }
  }

  private getSearchName(resourceName: string): string {
    switch (resourceName) {
      case 'orangenews':
        return 'keyword';
      default:
        return 'keyword';
    }
  }
}
