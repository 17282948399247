import { AssetType } from './assetType';

export class AssetRequest {
  /** Type of requested asset */
  public assetType: AssetType;

  /** This is the ID of the slide asset which requested the media asset. */
  public slideAssetId: string;

  /** Can be used to include any additional data to pass back to the component when an asset is selected */
  public additionalData?: any;

  constructor(assetType: AssetType, id: string, additionalData?: any) {
    this.assetType = assetType;
    this.slideAssetId = id;
    this.additionalData = additionalData;
  }
}
