import { FieldTemplateConfiguration } from './template-configuration-base';
import { TemplateAssetType } from './template-asset-type.enum';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';

export class FieldText extends FieldTemplateConfiguration {
  multiline: boolean;
  maxLength: number;
  text: string;

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);

    this.type = TemplateAssetType.Text;
    this.maxLength = field.maxLength!;
    this.multiline = field.multiline!;
    this.text = asset?.text ?? '';
  }

  override isValid(): boolean {
    return this.text.length <= this.maxLength;
  }

  override isEmpty(): boolean {
    return this.text == '';
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    asset.text = this.text;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    return this.text == '' ? null : this.text ?? null;
  }
}
