/**
 * Détermine si l'utilisateur a scrollé presque jusqu'en bas de l'élément
 * @param element Elément scrollé
 * @param distanceFromBottom Distance (en pixels) par rapport au bord inférieur à partir de laquelle on estime être près de la fin du scroll
 * @returns vrai si on estime être proche de la fin du scroll (en bas)
 */
export function isUserNearBottom(element: Element, distanceFromBottom: number = 15): boolean {
  // hauteur totale du contenu
  const l: number = element.scrollHeight;

  // hauteur visible de l'élément sur l'écran de l'utilisateur
  const h: number = element.getBoundingClientRect().height;

  // quantité de scroll déjà réalisée
  const s: number = element.scrollTop;

  return Math.round(s + h + distanceFromBottom) >= l;
}

export function hasScroll(): boolean {
  const main: Element = window.document.getElementsByClassName('main').item(0)!;
  if (!main) return false;

  return main.scrollHeight > main.clientHeight;
}
