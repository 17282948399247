import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, tap } from 'rxjs/operators';
import { GoogleWorkspaceService } from 'src/app/core/googleworkspace/google-workspace.service';
import { GoogleMeetingRoomModel } from './google-meetingroom-model';
import { KeyboardKey } from 'src/app/core/enums/keyboard-key.enum';

@Component({
  selector: 'app-googleworkspace-meetingroom-picker',
  templateUrl: './googleworkspace-meetingroom-picker.component.html',
  styleUrls: ['./googleworkspace-meetingroom-picker.component.scss'],
})
export class GoogleworkspaceMeetingRoomPickerComponent implements OnInit, OnDestroy, AfterViewInit {
  private meetingRooms: GoogleMeetingRoomModel[];
  public filteredMeetingRooms: GoogleMeetingRoomModel[];
  private inputSubscription: Subscription;

  @ViewChild('filter', { static: true }) public searchInput: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public buildingId: number,
    private dialogRef: MatDialogRef<GoogleworkspaceMeetingRoomPickerComponent>,
    private googleWorkspaceService: GoogleWorkspaceService
  ) {}

  ngOnDestroy(): void {
    if (this.inputSubscription) this.inputSubscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
    console.log(this.searchInput);

    this.inputSubscription = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'input')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          console.log(this.searchInput.nativeElement.value);

          this.filter(this.searchInput.nativeElement.value);
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === KeyboardKey.Escape) {
        this.dialogRef.close(false);
      }
    });

    this.googleWorkspaceService
      .getMeetingRooms(this.buildingId)
      .pipe(first())
      .subscribe((resources) => {
        this.meetingRooms = new Array<GoogleMeetingRoomModel>();

        resources.forEach((calendar) => {
          this.meetingRooms.push({
            name: calendar.name,
            id: calendar.id,
          });
        });

        this.filteredMeetingRooms = this.meetingRooms;
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submitMeetingRoom(room: GoogleMeetingRoomModel): void {
    this.dialogRef.close(room.id);
  }

  public filter(search: string): void {
    if (search.length < 2 || search.trim() === '') {
      this.filteredMeetingRooms = this.meetingRooms;
      return;
    }

    // case insensitive search
    search = search.toLowerCase();

    // remove calendars with names which don't contain the search string
    this.filteredMeetingRooms = this.meetingRooms.filter((cal) => cal.name.toLowerCase().includes(search));
  }
}
