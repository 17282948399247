import { ImageData, SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';
import { FieldTemplateConfiguration } from './template-configuration-base';
import { TemplateAssetType } from './template-asset-type.enum';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { GuidHelper } from '../guid/guid-helper';

export class FieldImageList extends FieldTemplateConfiguration {
  minHeight: number = 600;
  minWidth: number = 800;
  images: ImageData[] = [];

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);

    this.type = TemplateAssetType.ImageList;

    this.minHeight = field.minHeight!;
    this.minWidth = field.minWidth!;
    this.images = asset?.images ?? [];
  }

  override isValid(): boolean {
    return this.isEmpty() || this.images.every((img) => GuidHelper.IsValid(img.blobId) && img.imageUrl != null);
  }

  override isEmpty(): boolean {
    return this.images.length == 0;
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    asset.images = this.images;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    // on ne récupère que les données encodées en Base64
    return this.images.reduce((result, img) => {
      if (img.blobFile && img.blobDataUrl) result.push(img.blobDataUrl);
      else if (img.imageUrl != '') result.push(img.imageUrl);

      return result;
    }, [] as string[]);
  }
}
