import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { DialogResult } from 'src/app/core/dialogResult';
import { Slide } from 'src/app/shared/components/slide-editor/slide/slide';
import { SlideService } from '../slide/slide.service';
import { Observable, forkJoin } from 'rxjs';
import * as jsonpatch from 'fast-json-patch';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../notification/notification.service';

@Component({
  selector: 'app-reorganize-slide',
  templateUrl: './reorganize-slide.component.html',
  styleUrls: ['./reorganize-slide.component.scss'],
})
export class ReorganizeSlideComponent implements OnInit {
  private baseSlides: Slide[];

  public loading: boolean = false;
  public confirmResultEnum: typeof ConfirmResult = ConfirmResult;

  constructor(
    @Inject(MAT_DIALOG_DATA) public slides: Slide[],
    private slideService: SlideService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<ReorganizeSlideComponent>
  ) {}

  public ngOnInit(): void {
    this.baseSlides = this.slides.map((slide) => Object.assign({}, slide));
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.slides, event.previousIndex, event.currentIndex);
  }

  public closeDialog(confirm: ConfirmResult): void {
    const result: DialogResult<Slide[]> = new DialogResult<Slide[]>();
    result.confirm = confirm;
    if (confirm == ConfirmResult.Yes) {
      result.entity = this.slides;
    }

    this.dialogRef.close(result);
  }

  public onValidate(): void {
    this.slides.forEach((item, index) => (item.order = index + 1));
    this.saveReorderedSlides();
  }

  private saveReorderedSlides(): void {
    const orderedSlides: Slide[] = [...this.slides];
    const slidesToUpdate: Array<Observable<Slide>> = new Array<Observable<Slide>>();

    // Save new slide order
    orderedSlides.forEach((slide) => {
      const previousSlideVersion: Slide | undefined = this.baseSlides.find((x) => x.id === slide.id);

      if (previousSlideVersion != undefined) {
        let patch: jsonpatch.Operation[] | undefined = jsonpatch.compare(previousSlideVersion, slide, true);
        patch = patch.filter((o) => o.path.includes('order'));

        if (patch.some((op) => op.path.includes('order'))) {
          slidesToUpdate.push(this.slideService.patch(slide.id, patch));
        }
      }
    });

    if (slidesToUpdate.length > 0) {
      this.loading = true;

      forkJoin(slidesToUpdate)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe({
          next: () => this.closeDialog(ConfirmResult.Yes),
          error: (err) => {
            this.notificationService.displayError(err.message);
            this.closeDialog(ConfirmResult.No);
          },
        });
    }
  }
}
