import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent {
  constructor(private router: Router, private titleService: Title, private translateService: TranslateService) {
    this.translateService.get('general.forbidden.page_title').subscribe((value: string) => this.titleService.setTitle(value));
  }

  public backToHome(): void {
    this.router.navigate(['/gca']);
  }
}
