<div class="col-layout">
  <div class="asset-container">
    <div class="row-close">
      <button mat-icon-button class="close" (click)="close()" color="basic">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="row-search" *ngIf="displayMode !== AssetType.Template">
      <mat-form-field appearance="fill" class="mt-1">
        <mat-label>{{ 'mask.assets.search' | translate }}</mat-label>
        <input matInput [(ngModel)]="searchText" (keydown.enter)="search()" />
        <button mat-icon-button matSuffix (click)="search()">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-icon-button matSuffix (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="asset-list" (scroll)="onScroll($event)">
      <div *ngIf="assets.length === 0 && displayMode === AssetType.Template && !searchInProgress" class="no-data">
        {{ 'mask.assets.no_template' | translate }}
      </div>

      <div *ngIf="assets.length === 0 && displayMode === AssetType.Picture && !searchInProgress" class="no-data">
        {{ 'mask.assets.no_image' | translate }}
      </div>

      <div *ngIf="assets.length === 0 && displayMode === AssetType.Video && !searchInProgress" class="no-data">
        {{ 'mask.assets.no_video' | translate }}
      </div>

      <!-- This spinner is displayed only during the loading of the first page of results -->
      <div class="wait center" *ngIf="searchInProgress && this.assets.length === 0">
        <mat-spinner [diameter]="35"></mat-spinner>
      </div>

      <ngx-masonry #masonry [ordered]="true" *ngIf="assets.length > 0">
        <div ngxMasonryItem class="masonry-item" *ngFor="let asset of assets">
          <div *ngIf="!asset.isEncoding; else encoding" (click)="select(asset)" (dragstart)="onDragStart($event, asset)" appTooltip [tooltipText]="asset.name">
            <button *ngIf="asset.isVideo" class="play-button" mat-icon-button (click)="openVideoPreview($event, asset.blobUrl)">
              <mat-icon class="play" svgIcon="play-circle"></mat-icon>
            </button>
            <img [src]="asset.thumbnailUrl" (load)="onThumbnailLoaded()" />
          </div>
          <ng-template #encoding>
            <!-- Video encoding overlay -->
            <div class="encode-container" appTooltip [tooltipText]="asset.name">
              <mat-progress-bar mode="determinate" [value]="asset.progress"></mat-progress-bar>
              <span class="video-encoding">{{ 'media.encoding_label' | translate }}</span>
              <mat-icon class="centered" svgIcon="cached"></mat-icon>
            </div>
          </ng-template>
        </div>
      </ngx-masonry>

      <!-- This spinner is only displayed when we are loading the next page of medias -->
      <div class="wait bottom" *ngIf="searchInProgress && this.assets.length > 0">
        <mat-spinner [diameter]="35"></mat-spinner>
      </div>
    </div>
  </div>
  <div class="shadow" *ngIf="displayMode === AssetType.Template"></div>
</div>
