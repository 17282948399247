import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {
  constructor(private readonly translate: TranslateService) {}

  public getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl: MatPaginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = this.translate.instant('paginator.items_per_page_label');
    paginatorIntl.nextPageLabel = this.translate.instant('paginator.next_page_label');
    paginatorIntl.previousPageLabel = this.translate.instant('paginator.previous_page_label');
    paginatorIntl.firstPageLabel = this.translate.instant('paginator.first_page_label');
    paginatorIntl.lastPageLabel = this.translate.instant('paginator.last_page_label');
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
    return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant('paginator.range_page_label_1', { length });
    }
    // length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    
    return this.translate.instant('paginator.range_page_label_2', { startIndex: startIndex + 1, endIndex, length });
  }
}
