import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
/**
 * Use to trigger validation on this fields
 */
export function markControlAsTouchedOnForm(form: UntypedFormGroup): UntypedFormGroup {
  for (const control of Object.values(form.controls)) {
    control.markAsTouched();
  }
  return form;
}

export function createFormData(object: Object, form?: FormData, namespace?: string): FormData {
  const formData: FormData = form || new FormData();
  for (let property in object) {
    const prop: Object = object[property as keyof Object];

    if (!object.hasOwnProperty(property) || !prop) {
      continue;
    }
    const formKey: string = namespace ? `${namespace}[${property}]` : property;

    if (prop instanceof Date) {
      formData.append(formKey, (prop as Date).toISOString());
    } else if (typeof prop === 'object' && !(prop instanceof File)) {
      createFormData(prop, formData, formKey);
    } else {
      formData.append(formKey, prop as File);
    }
  }
  return formData;
}

export function toMomentDate(date: Date | moment.Moment | string): Date {
  return moment.isDate(date) ? date : moment(date).toDate();
}

export function checkOptionalFormStringEquality(formValue: string, srcValue: string): boolean {
  if ((srcValue === null && formValue === '') || (srcValue === '' && formValue === null)) return true;
  else return srcValue === formValue;
}
