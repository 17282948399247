export enum TemplateAssetType {
  Text = 1,
  Image = 2,
  Video = 3,
  Select = 4,
  Url = 6,
  Channel = 7,
  VideoList = 8,
  ImageList = 9,
  TextList = 10,
  Table = 11,
}
