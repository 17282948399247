import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector, Type } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { FleetLight } from '../fleet/fleet';
import { Role } from '../guards/role';
import { ScopePolicies } from '../guards/scope-policies';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { StorageKey } from '../local-storage/storageKeys';
import { SettingsService } from '../setttings/settings.service';
import { UserData } from '../userData';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private readonly excludedImpersonationUrls: string[] = ['user/admin'];
  private oidcSecurityService: OidcSecurityService;

  constructor(private injector: Injector, private storageService: LocalStorageService, private settingsService: SettingsService, private cookieService: CookieService) {}

  // Any rule disabled here due to HttpInterceptor inheritage.
  // tslint:disable-next-line: no-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // tslint:disable-next-line: no-any
    let requestToForward: HttpRequest<any> = req;

    if (req.url.indexOf(this.settingsService.settings.API_URL) === -1) return next.handle(requestToForward);

    if (this.oidcSecurityService === undefined) this.oidcSecurityService = this.injector.get(OidcSecurityService);

    if (this.oidcSecurityService !== undefined) {
      const token: string = this.oidcSecurityService.getToken();
      if (token !== '') {
        const tokenValue: string = 'Bearer ' + token;
        requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
      }

      if (!this.excludedImpersonationUrls.map((url) => req.url.indexOf(url) !== -1).every((v) => !v)) return next.handle(requestToForward);
      if (this.storageService === undefined) this.storageService = this.injector.get(LocalStorageService);

      const userData: UserData | undefined = this.storageService.readObject<UserData>(StorageKey.userData);

      if (userData) {
        if (!ScopePolicies.CanImpersonate(userData.roles)) return next.handle(requestToForward);

        if (this.cookieService === undefined) this.cookieService = this.injector.get(CookieService);

        const adminFleetKey: string = `${StorageKey.selectedFleet}${userData.sub}`;
        if (this.cookieService.check(adminFleetKey) && !requestToForward.headers.has('impersonation')) {
          const impersonatedFleet: FleetLight = JSON.parse(this.cookieService.get(adminFleetKey));
          requestToForward = requestToForward.clone({ setHeaders: { impersonation: `${impersonatedFleet.clientId}#${impersonatedFleet.technicalId}` } });
        }
      }
    }

    return next.handle(requestToForward);
  }
}
