<div class="dialog-component">
  <h1 mat-dialog-title>{{ dialogTitle }}</h1>
  <div class="body">
    {{ dialogMessage }}
  </div>
  <div mat-dialog-actions>
    <app-busy-button color="accent" [isBusy]="isBusy" [text]="dialogConfirmButtonTitle" (buttonClick)="close(confirmResultEnum.Yes)"></app-busy-button>
    <button mat-raised-button (click)="close(confirmResultEnum.No)" color="basic">
      {{ dialogCancelButtonTitle }}
    </button>
  </div>
</div>
