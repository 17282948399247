import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LoaderService } from 'src/app/general/layout/services/loader.service';

@Component({
  selector: 'app-loader-overlay',
  templateUrl: './loader-overlay.component.html',
  styleUrls: ['./loader-overlay.component.scss'],
})
export class LoaderOverlayComponent {
  public isVisible: Observable<boolean>;

  constructor(private loaderService: LoaderService) {
    this.isVisible = this.loaderService.loading.pipe(distinctUntilChanged());
  }
}
