import { Injectable } from '@angular/core';
import { TransferProgressEvent } from '@azure/core-http';
import { BlobServiceClient, BlobUploadCommonResponse, BlockBlobClient, ContainerClient } from '@azure/storage-blob';
import { Guid } from 'guid-typescript';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { MediaFile } from 'src/app/shared/components/media-form/media-file';
import { UploadTask } from 'src/app/shared/components/progress-bar/uploadTask';
import { SettingsService } from '../setttings/settings.service';
import { MediaLibrary } from './media-library';
import { MediaStatus } from './media-status';

@Injectable()
export class BlobStorageService {
  private rawVideoContainerName: string = 'raw-videos';
  private clientsContainerName: string = 'clients';
  private videoMediaLibraryBlobPrefixName: string = 'mediaLibrary_';

  private _uploading: ReplaySubject<UploadTask> = new ReplaySubject<UploadTask>();
  private _uploaded: Subject<boolean> = new Subject<boolean>();

  constructor(private settingsService: SettingsService) {}

  get uploading(): Observable<UploadTask> {
    return this._uploading.asObservable();
  }

  get uploaded(): Observable<boolean> {
    return this._uploaded.asObservable();
  }

  private updateProgress(loaded: number, total: number): void {
    this._uploading.next({ uploading: true, progress: Math.round((loaded / total) * 100) });
  }

  private endProgress(isSuccess: boolean): void {
    this._uploading.next({ uploading: false, progress: 100 });
    this._uploaded.next(isSuccess);
  }

  // public uploadRawVideo(browserFile: File, sharedAccessSignature: string, blobGuid: string, mediaId: number): void {
  //   const blobServiceClient: BlobServiceClient = new BlobServiceClient(`https://${this.settingsService.settings.STORAGE_ACCOUNT}.blob.core.windows.net${sharedAccessSignature}`);
  //   const containerClient: ContainerClient = blobServiceClient.getContainerClient(this.rawVideoContainerName);
  //   const blobName: string = `${this.videoMediaLibraryBlobPrefixName}${mediaId}/${blobGuid.toUpperCase()}.mp4`;
  //   const blockBlobClient: BlockBlobClient = containerClient.getBlockBlobClient(blobName);
  //   const fileSize: number = browserFile.size;
  //   blockBlobClient
  //     .uploadBrowserData(browserFile, {
  //       onProgress: (transfertProgress: TransferProgressEvent) => this.updateProgress(transfertProgress.loadedBytes, fileSize),
  //     })
  //     .then(
  //       () => this.endProgress(true),
  //       () => this.endProgress(false)
  //     );
  // }

  public uploadVideo(media: MediaLibrary, file: MediaFile): void {
    const blobServiceClient: BlobServiceClient = new BlobServiceClient(`https://${this.settingsService.settings.STORAGE_ACCOUNT}.blob.core.windows.net${media.blobUrl}`);
    const containerClient: ContainerClient = blobServiceClient.getContainerClient(this.rawVideoContainerName);
    const blobName: string = `${this.videoMediaLibraryBlobPrefixName}${media.id}/${media.blobId.toUpperCase()}.mp4`;
    const blockBlobClient: BlockBlobClient = containerClient.getBlockBlobClient(blobName);
    // const fileSize: number = file.file.size;

    blockBlobClient
      .uploadData(file.file!, {
        onProgress: (progress: TransferProgressEvent) => {
          file.progress = (progress.loadedBytes / file.file!.size) * 100;
        },
      })
      .then(
        () => {
          media.status = MediaStatus.Encoding;
          file.onUploadComplete.emit();
        },
        (err) => {
          media.status = MediaStatus.Error;
          file.onUploadFailed.emit();
        }
      );
  }

  /**
   * Used to upload a video from the client's profile in the backoffice.
   * This video will most likely be a "content is coming back soon" video
   * @param browserFile file to upload
   * @param sharedAccessSignature SAS token
   * @param blobGuid Blob item GUID
   * @param clientId Client id
   */
  public uploadClientVideo(browserFile: File, sharedAccessSignature: string, blobGuid: string, clientId: string): void {
    const blobServiceClient: BlobServiceClient = new BlobServiceClient(`https://${this.settingsService.settings.STORAGE_ACCOUNT}.blob.core.windows.net${sharedAccessSignature}`);
    const containerClient: ContainerClient = blobServiceClient.getContainerClient(this.clientsContainerName);
    const blobName: string = `${clientId}/${blobGuid.toString().toUpperCase()}.mp4`;
    const blockBlobClient: BlockBlobClient = containerClient.getBlockBlobClient(blobName);
    const fileSize: number = browserFile.size;
    blockBlobClient
      .uploadData(browserFile, {
        onProgress: (transfertProgress: TransferProgressEvent) => this.updateProgress(transfertProgress.loadedBytes, fileSize),
      })
      .then(
        () => this.endProgress(true),
        () => this.endProgress(false)
      );
  }
}
