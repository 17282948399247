import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { DialogResult } from 'src/app/core/dialogResult';
import { ConfirmData } from './confirm-data';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
  public isBusy: boolean = false;

  public dialogTitle?: string;
  public dialogMessage: string;
  public dialogConfirmButtonTitle: string;
  public dialogCancelButtonTitle: string;
  public withConfirmEvent: boolean;

  public confirmResultEnum: typeof ConfirmResult = ConfirmResult;

  @Output() public validated: EventEmitter<DialogResult<ConfirmResult>> = new EventEmitter<DialogResult<ConfirmResult>>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmData, translationService: TranslateService, private dialogRef: MatDialogRef<ConfirmComponent>) {
    this.dialogRef.disableClose = true;

    this.dialogTitle = data.title;
    this.dialogMessage = data.message;
    this.withConfirmEvent = data.withConfirmEvent == undefined ? false : data.withConfirmEvent;
    this.dialogConfirmButtonTitle = data.confirmButtonTitle === undefined ? translationService.instant('common.validate') : data.confirmButtonTitle;
    this.dialogCancelButtonTitle = data.cancelButtonTitle === undefined ? translationService.instant('common.cancel') : data.cancelButtonTitle;
  }

  public close(confirm: ConfirmResult): void {
    const result: DialogResult<ConfirmResult> = new DialogResult<ConfirmResult>();
    result.confirm = confirm;
    result.entity = confirm;

    if (confirm === ConfirmResult.Yes && this.withConfirmEvent) {
      this.isBusy = true;
      this.validated.emit(result);
    } else {
      this.dialogRef.close(result);
    }
  }
}
