import { Pipe, PipeTransform } from '@angular/core';
import { FleetLight } from 'src/app/core/fleet/fleet';

@Pipe({
  name: 'clientFilter',
})
export class ClientFilterPipe implements PipeTransform {
  public transform(value: FleetLight[], ...args: string[]): FleetLight[] {
    if (value && args.length > 0) return value.filter((i) => i.clientId.toString() === args[0]);

    return value;
  }
}
