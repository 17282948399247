import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-contextual-menu',
  templateUrl: './contextual-menu.component.html',
  styleUrls: ['./contextual-menu.component.scss'],
})
export class ContextualMenuComponent implements OnInit {
  @Input() public menuType: string;
  @Input() public hideEdit: boolean = false;
  @Input() public canDelete: boolean = true;
  @Input() public darkMode: boolean = false;
  @Input() public isEnable: boolean = false;
  @Input() public isFavorite: boolean = false;
  @Input() public hidePreview: boolean = false;
  @Input() public blockEdition: boolean = false;

  @Output() public outputEdit: EventEmitter<string> = new EventEmitter<string>();
  @Output() public outputList: EventEmitter<string> = new EventEmitter<string>();
  @Output() public outputDelete: EventEmitter<string> = new EventEmitter<string>();
  @Output() public outputEnable: EventEmitter<string> = new EventEmitter<string>();
  @Output() public outputPreview: EventEmitter<string> = new EventEmitter<string>();
  @Output() public outputDisable: EventEmitter<string> = new EventEmitter<string>();
  @Output() public outputFavorite: EventEmitter<string> = new EventEmitter<string>();
  @Output() public outputDuplicate: EventEmitter<string> = new EventEmitter<string>();

  public isVisible: boolean = false;
  public showEdit: boolean = true;
  public showFavorite: boolean = false;
  public showPreview: boolean = false;
  public showDelete: boolean = false;
  public showList: boolean = false;
  public showAvailability: boolean = false;
  public showDuplicate: boolean = false;

  public classes: string = '';

  public ngOnInit(): void {
    switch (this.menuType) {
      case 'mask':
        this.showDuplicate = true;
        this.showEdit = !this.hideEdit;
        this.showPreview = !this.hidePreview;
        this.showDelete = !this.blockEdition;
        break;
      case 'media':
        this.showDelete = !this.blockEdition;
        this.showFavorite = true;
        break;
      case 'live-event':
        this.showDelete = true;
        this.showList = true;
        break;
      case 'slide':
        this.showDelete = !this.blockEdition;
        this.showPreview = true;
        break;
      case 'channel':
        this.showAvailability = true;
        this.showList = this.isEnable;
        this.showEdit = this.isEnable && !this.blockEdition;
        break;
    }
  }

  public showMenuEvent(): void {
    this.isVisible = true;
  }

  public hideMenuEvent(): void {
    this.isVisible = false;
  }

  public edit(): void {
    this.outputEdit.emit();
    this.hideMenuEvent();
  }

  public duplicate(): void {
    this.outputDuplicate.emit();
    this.hideMenuEvent();
  }

  public preview(): void {
    this.outputPreview.emit();
    this.hideMenuEvent();
  }

  public delete(): void {
    this.outputDelete.emit();
    this.hideMenuEvent();
  }

  public disable(): void {
    this.outputDisable.emit();
    this.hideMenuEvent();
  }

  public enable(): void {
    this.outputEnable.emit();
    this.hideMenuEvent();
  }

  public favorite(): void {
    this.outputFavorite.emit();
    this.hideMenuEvent();
  }
  public list(): void {
    this.outputList.emit();
    this.hideMenuEvent();
  }
}
