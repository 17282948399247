import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatMenu } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';

interface SlideNameModel
  extends FormGroup<{
    name: FormControl<string>;
  }> {}

@Component({
  selector: 'app-rename-slide',
  templateUrl: './rename-slide.component.html',
  styleUrls: ['../slide-toolbar-popup.scss', './rename-slide.component.scss'],
})
export class RenameSlideComponent implements OnInit, OnChanges {
  @ViewChild('slideRenameMenu', { static: true }) public matMenu: MatMenu;

  @Input() public name: string;
  @Output() public nameChanged: EventEmitter<string> = new EventEmitter<string>();

  public form: SlideNameModel;
  private nameOnOpened: string;

  constructor(public fb: NonNullableFormBuilder, private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.form) return;

    if (changes != null && changes['name'].previousValue !== changes['name'].currentValue) {
      this.form.setValue({ name: this.name });
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: [this.name, [Validators.required]],
    });
  }

  public onMenuClosed(): void {
    this.form.setValue({ name: this.nameOnOpened });
  }

  public onMenuOpened(): void {
    this.nameOnOpened = this.name;
  }

  public getErrorMessage(): string {
    if (this.form.controls.name.hasError(Validators.required.name)) {
      return this.translateService.instant('mask.slide.duration.error.required');
    }

    return '';
  }

  public onValidation(): void {
    const name = this.form.value.name;

    if (name) {
      this.nameOnOpened = name;
      this.nameChanged.emit(name);
      this.matMenu.closed.emit();
    }
  }
}
