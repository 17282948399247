import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { MaskAudience } from '../models/mask-audience';

@Injectable({
  providedIn: 'root',
})
export class AudienceMaskService {
  private maskAudienceBaseUrl: string;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {
    this.maskAudienceBaseUrl = `${this.settingsService.settings.API_URL}/maskAudience`;
  }

  /** Get mask's audiences by maskId */
  public getByMaskId(maskId: number): Observable<MaskAudience[]> {
    return this.httpClient.get<MaskAudience[]>(`${this.maskAudienceBaseUrl}/${maskId}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.audience.error.get_by_mask_id', {
          id: maskId,
        });
        return throwError(err);
      })
    );
  }

  /**
   * Create or update mask's audiences
   */
  public send(isModified: boolean, maskId: number, masksAudiences: MaskAudience[]): Observable<MaskAudience[]> {
    // Why is maskId required although it's already provided as a MaskAudience class property?!
    if (isModified) {
      return this.put(maskId, masksAudiences);
    } else {
      return this.post(masksAudiences);
    }
  }

  /** Post a mask schedule */
  private post(masksAudiences: MaskAudience[]): Observable<MaskAudience[]> {
    return this.httpClient.post<MaskAudience[]>(`${this.maskAudienceBaseUrl}`, masksAudiences).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.audience.error.post');
        return throwError(err);
      })
    );
  }

  private put(maskId: number, masksAudiences: MaskAudience[]): Observable<MaskAudience[]> {
    return this.httpClient.put<MaskAudience[]>(`${this.maskAudienceBaseUrl}/${maskId}`, masksAudiences).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.audience.error.put');
        return throwError(err);
      })
    );
  }
}
