import { WeekDay } from '@angular/common';
import { TimetableItem } from './timetable-item';

export enum ScheduleStatus {
  Online,
  Offline,
}

export interface IWeekSchedule {
  monday: TimetableItem[];
  tuesday: TimetableItem[];
  wednesday: TimetableItem[];
  thursday: TimetableItem[];
  friday: TimetableItem[];
  saturday: TimetableItem[];
  sunday: TimetableItem[];
}

export class WeekSchedule implements IWeekSchedule {
  constructor(schedule?: IWeekSchedule) {
    this.monday = schedule?.monday.map((item) => new TimetableItem(item)) ?? new Array<TimetableItem>();
    this.tuesday = schedule?.tuesday.map((item) => new TimetableItem(item)) ?? new Array<TimetableItem>();
    this.wednesday = schedule?.wednesday.map((item) => new TimetableItem(item)) ?? new Array<TimetableItem>();
    this.thursday = schedule?.thursday.map((item) => new TimetableItem(item)) ?? new Array<TimetableItem>();
    this.friday = schedule?.friday.map((item) => new TimetableItem(item)) ?? new Array<TimetableItem>();
    this.saturday = schedule?.saturday.map((item) => new TimetableItem(item)) ?? new Array<TimetableItem>();
    this.sunday = schedule?.sunday.map((item) => new TimetableItem(item)) ?? new Array<TimetableItem>();
  }

  public monday: TimetableItem[];
  public tuesday: TimetableItem[];
  public wednesday: TimetableItem[];
  public thursday: TimetableItem[];
  public friday: TimetableItem[];
  public saturday: TimetableItem[];
  public sunday: TimetableItem[];

  private static GetInvertedDay(day: TimetableItem[]): TimetableItem[] {
    const invertedDay: TimetableItem[] = new Array<TimetableItem>();
    let nextStartHours: number = 0;
    let nextStartMinutes: number = 0;

    for (let i: number = 0; i < day.length; i++) {
      // no time between the begining of the day and the first interval
      if (day[i].startHours == 0 && day[i].startMinutes == 0) {
        // interval's end time will be the start of the next interval
        nextStartHours = day[i].endHours;
        nextStartMinutes = day[i].endMinutes;
        continue;
      }

      const interval: TimetableItem = new TimetableItem();

      interval.startHours = nextStartHours;
      interval.startMinutes = nextStartMinutes;
      interval.endHours = day[i].startHours;
      interval.endMinutes = day[i].startMinutes;

      invertedDay.push(interval);

      // interval's end time will be the start of the next interval
      nextStartHours = day[i].endHours;
      nextStartMinutes = day[i].endMinutes;
    }

    // if there is time left at the end of the day, save it too
    if (nextStartHours < 24) {
      const interval: TimetableItem = new TimetableItem();

      interval.startHours = nextStartHours;
      interval.startMinutes = nextStartMinutes;
      interval.endHours = 24;
      interval.endMinutes = 0;

      invertedDay.push(interval);
    }

    return invertedDay;
  }

  public getDay(weekDay: WeekDay): Array<TimetableItem> {
    switch (weekDay) {
      case WeekDay.Monday:
        return this.monday;
      case WeekDay.Tuesday:
        return this.tuesday;
      case WeekDay.Wednesday:
        return this.wednesday;
      case WeekDay.Thursday:
        return this.thursday;
      case WeekDay.Friday:
        return this.friday;
      case WeekDay.Saturday:
        return this.saturday;
      case WeekDay.Sunday:
        return this.sunday;
    }
  }

  public getStatusAtDate(weekDay: WeekDay, hours: number, minutes: number): ScheduleStatus {
    const day: TimetableItem[] = this.getDay(weekDay);

    for (let i: number = 0; i < day.length; i++) {
      const interval: TimetableItem = day[i];

      if (interval.isIncluded(hours, minutes)) return ScheduleStatus.Online;
    }

    return ScheduleStatus.Offline;
  }

  /** Retourne un planning correspondant à toutes les plages horaires se trouvant entre les plages horaires du planning source.
   * Dans les faits le nouveau planning est l'inverse du planning source.
   * Exemple : si lundi -> [10:00-12:00],[14:00-16:00], alors cette fonction retournera lundi -> [00:00-10:00],[12:00-14:00],[16:00-24:00] */
  public getInvertedSchedule(): WeekSchedule {
    const invertedSchedule: WeekSchedule = new WeekSchedule();

    invertedSchedule.monday = WeekSchedule.GetInvertedDay(this.monday);
    invertedSchedule.tuesday = WeekSchedule.GetInvertedDay(this.tuesday);
    invertedSchedule.wednesday = WeekSchedule.GetInvertedDay(this.wednesday);
    invertedSchedule.thursday = WeekSchedule.GetInvertedDay(this.thursday);
    invertedSchedule.friday = WeekSchedule.GetInvertedDay(this.friday);
    invertedSchedule.saturday = WeekSchedule.GetInvertedDay(this.saturday);
    invertedSchedule.sunday = WeekSchedule.GetInvertedDay(this.sunday);

    return invertedSchedule;
  }

  public setDay(weekDay: WeekDay, schedule: TimetableItem[]): void {
    switch (weekDay) {
      case WeekDay.Monday:
        this.monday = schedule;
        break;
      case WeekDay.Tuesday:
        this.tuesday = schedule;
        break;
      case WeekDay.Wednesday:
        this.wednesday = schedule;
        break;
      case WeekDay.Thursday:
        this.thursday = schedule;
        break;
      case WeekDay.Friday:
        this.friday = schedule;
        break;
      case WeekDay.Saturday:
        this.saturday = schedule;
        break;
      case WeekDay.Sunday:
        this.sunday = schedule;
        break;
    }
  }
}
