import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { catchError } from 'rxjs/operators';

@Component({
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss'],
})
export class AutoLoginComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, private router: Router) {}

  public ngOnInit(): void {
    this.oidcSecurityService
      .checkAuth()
      .pipe(catchError((err) => this.router.navigate(['/forbidden'])))
      .subscribe(() => this.oidcSecurityService.authorize());
  }
}
