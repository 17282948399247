import { TemplateAssetType } from './template-asset-type.enum';
import { FieldChannel } from './field-channel';
import { FieldImage } from './field-image';
import { FieldSelect } from './field-select';
import { FieldText } from './field-text';
import { FieldUrl } from './field-url';
import { FieldVideo } from './field-video';
import { FieldImageList } from './field-image-list';
import { FieldTextList } from './field-text-list';
import { FieldVideoList } from './field-video-list';
import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { IFieldTemplateConfiguration } from './template-configuration-base';
import { FieldTable } from './field-table';

export class FieldTemplateConfigurationFactory {
  static GetInstance(field: IFieldTemplateConfigurationModel, asset?: SlideAsset): IFieldTemplateConfiguration {
    const fieldType: TemplateAssetType = TemplateAssetType[(`${field.type}`[0] + `${field.type}`.slice(1)) as keyof typeof TemplateAssetType];

    switch (fieldType) {
      case TemplateAssetType.Channel:
        return new FieldChannel(field, asset);
      case TemplateAssetType.Image:
        return new FieldImage(field, asset);
      case TemplateAssetType.ImageList:
        return new FieldImageList(field, asset);
      case TemplateAssetType.TextList:
        return new FieldTextList(field, asset);
      case TemplateAssetType.VideoList:
        return new FieldVideoList(field, asset);
      case TemplateAssetType.Select:
        return new FieldSelect(field, asset);
      case TemplateAssetType.Text:
        return new FieldText(field, asset);
      case TemplateAssetType.Url:
        return new FieldUrl(field, asset);
      case TemplateAssetType.Video:
        return new FieldVideo(field, asset);
      case TemplateAssetType.Table:
        return new FieldTable(field, asset);
    }
  }
}
