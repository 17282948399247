export class TimetableInterval {
  private _startHours: number;
  private _startMinutes: number;

  private _endHours: number;
  private _endMinutes: number;

  public dragging: boolean = false;
  public showTips: boolean = false;
  public showActions: boolean = false;
  public actionsX: number = 0;

  constructor(
    // this is the width in pixels used to represent 15 minutes on the user's screen
    private quarterHourWidth: number = 10
  ) {}

  public get left(): number {
    const quartersCount: number = Math.round((this._startHours * 60 + this._startMinutes) / 15);

    return quartersCount * this.quarterHourWidth;
  }

  public get width(): number {
    const startQuartersCount: number = Math.round((this._startHours * 60 + this._startMinutes) / 15);
    const endQuartersCount: number = Math.round((this._endHours * 60 + this._endMinutes) / 15);

    const quartersCount: number = endQuartersCount - startQuartersCount;

    return quartersCount * this.quarterHourWidth + 1;
  }

  public toString(): string {
    return `${this._startHours.toString().padStart(2, '0')}:${this._startMinutes.toString().padStart(2, '0')} to ${this._endHours
      .toString()
      .padStart(2, '0')}:${this._endMinutes.toString().padStart(2, '0')}`;
  }

  public get startTime(): string {
    return `${this._startHours.toString().padStart(2, '0')}:${this._startMinutes.toString().padStart(2, '0')}`;
  }

  public get endTime(): string {
    const hours: number = this._endHours == 24 ? 0 : this._endHours;

    return `${hours.toString().padStart(2, '0')}:${this._endMinutes.toString().padStart(2, '0')}`;
  }

  public get startMinutes(): number {
    return this._startMinutes;
  }

  public get endMinutes(): number {
    return this._endMinutes;
  }

  public get startHours(): number {
    return this._startHours;
  }

  public get endHours(): number {
    return this._endHours;
  }

  public setStartTime(hours: number, minutes: number): void {
    const newStart: number = hours * 60 + minutes;
    const end: number = this._endHours * 60 + this._endMinutes;

    if (newStart >= end) return;

    this._startHours = hours;
    this._startMinutes = minutes;
  }

  public setEndTime(hours: number, minutes: number): void {
    const newEnd: number = hours * 60 + minutes;
    const start: number = this._startHours * 60 + this._startMinutes;

    if (newEnd <= start || newEnd > 1440) return;

    this._endHours = hours;
    this._endMinutes = minutes;
  }

  public isOverlapping(interval: TimetableInterval): boolean {
    const startMinutes1: number = this._startHours * 60 + this._startMinutes;
    const startMinutes2: number = interval._startHours * 60 + interval._startMinutes;
    const endMinutes1: number = this._endHours * 60 + this._endMinutes;
    const endMinutes2: number = interval._endHours * 60 + interval._endMinutes;

    // returns true if the interval's start or end time are between current (this) interval's  start and end time
    return (
      (startMinutes1 <= startMinutes2 && startMinutes2 <= endMinutes1) ||
      (startMinutes1 <= endMinutes2 && endMinutes2 <= endMinutes1) ||
      (startMinutes2 <= endMinutes1 && endMinutes1 <= endMinutes2)
    );
  }
}
