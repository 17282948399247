import { from, Observable, of } from 'rxjs';

export class FileHelper {
  public static fileToBase64(file: Blob): Observable<string> {
    if (!file) return of('');

    return from(
      new Promise<string>((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.onerror = (error) => reject(error);
      })
    );
  }

  public static base64ToFile(base64Image: string): Blob {
    const split: string[] = base64Image.split(',');
    const type: string = split[0].replace('data:', '').replace(';base64', '');
    const byteString: string = atob(split[1]);
    const ab: ArrayBuffer = new ArrayBuffer(byteString.length);
    const ia: Uint8Array = new Uint8Array(ab);

    for (let i: number = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
}
