import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { DynamicData } from 'src/app/core/dynamic-data/dynamic-data';
import { DynamicDataService } from 'src/app/core/dynamic-data/dynamic-data.service';
import { SlidePreview } from '../preview/slidePreview';

@Component({
  selector: 'app-slide-preview',
  templateUrl: './slide-preview.component.html',
  styleUrls: ['./slide-preview.component.scss'],
})
export class SlidePreviewComponent implements AfterViewInit, OnDestroy {
  @ViewChild('previewiframe') public iframe: ElementRef<HTMLIFrameElement>;
  @Input() public slides: SlidePreview[];
  @Input() public cssClass: string;

  private templateInitialized: boolean = false;
  private messageQueue: Array<Object> = new Array<Object>();
  private timerId: NodeJS.Timeout;
  private index: number = 0;
  private _dataList: DynamicData[];

  constructor(private dynamicDataService: DynamicDataService) {
    // listen to incoming messages for dynamic updates and animation control
    window.onmessage = (e: MessageEvent) => {
      if (e.data.method && (e.data.method === 'Initialized' || e.data.method === 'templateInitialized')) {
        this.templateInitialized = true;
        this.processQueuedMessages();
      }
    };
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timerId);
    window.onmessage = null;
  }

  public ngAfterViewInit(): void {
    this.dynamicDataService.get().subscribe((data) => {
      this._dataList = data;
      this.play();
    });
  }

  // this regex looks up for variables which need to be replaced inside string values (E.g. "This is ${myvariable} embedded into a string" will extract ${myvariable})
  private static variableSearch: RegExp = /\$\{([a-z0-9]*)\}/gi;

  private replaceDynamicDataVariablesInString(stringTemplate: string): string {
    /** TODO : attention, cette donnée arrive parfois trop tard et fait planter le formulaire de configuration! */
    return stringTemplate.replaceAll(SlidePreviewComponent.variableSearch, (variableName) => {
      const variable: DynamicData | undefined = this._dataList.find((d) => d.name == variableName.substring(2, variableName.length - 1));

      if (variable) return variable.value;
      else return variableName;
    });
  }

  public play(): void {
    if (this.slides.length <= 0 || !this.iframe) return;

    this.templateInitialized = false;
    this.iframe.nativeElement.src = this.slides[this.index].url;

    // holds template data
    const values: { elementId: string; type: string; value: string }[] = [];

    // prepare template data
    this.slides[this.index].parameters.forEach((sp) => {
      let value: string = sp.value;

      if (sp.type.toLowerCase() === 'channel') value = window.location.origin + sp.value;
      else value = this.replaceDynamicDataVariablesInString(value);

      values.push({
        elementId: sp.elementId,
        type: sp.type.toLowerCase(),
        value: value,
      });
    });

    const data: Object = {
      method: 'updateAll',
      value: values,
    };

    this.postData(data);

    // keep duration before changing the index value
    let duration: number = this.slides[this.index].duration;

    // update the index for next slide playback
    this.index = (this.index + 1) % this.slides.length;

    // play next slide after the duration has elapsed
    console.log('Preparing next slide timeout', duration);
    this.timerId = setTimeout(() => {
      this.play();
    }, (duration == 0 ? 20 : duration) * 1000);
  }

  private processQueuedMessages(): void {
    this.messageQueue.forEach((data) => {
      this.iframe.nativeElement.contentWindow?.postMessage(data, '*');
    });

    this.messageQueue = new Array<Object>();
  }

  /**
   * Posts data to the iframe containing the template
   * @param data data message to send to the iframe containing the template (mask content)
   * @returns nothing
   */
  private postData(data: object): void {
    if (this.templateInitialized) {
      this.iframe.nativeElement.contentWindow?.postMessage(data, '*');
      return;
    }

    this.messageQueue.push(data);
  }
}
