import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsService } from '../core/setttings/settings.service';
import { MediaPlayerTag } from '../organization/media-players/media-player-tag';
import { SlidePreview } from '../shared/components/preview/slidePreview';
import { MaskTile } from '../shared/components/mask-tile/mask-tile';
import { Mask } from './models/mask';
import { MaskLight } from './models/mask-light';
import { MaskSummary } from './models/mask-summary';
import { MaskTileResponse } from '../shared/components/mask-tile/mask-tile-response';
import * as jsonpatch from 'fast-json-patch';

@Injectable({
  providedIn: 'root',
})
export class MasksService {
  private maskBaseUrl: string;
  private timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {
    this.maskBaseUrl = `${this.settingsService.settings.API_URL}/mask`;
  }

  public getAllMasks(): Observable<MaskLight[]> {
    return this.httpClient.get<MaskLight[]>(`${this.maskBaseUrl}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.get');
        return throwError(err);
      })
    );
  }

  public getMaskById(id: number): Observable<Mask> {
    return this.httpClient.get<Mask>(`${this.maskBaseUrl}/${id}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.get_by_mask_id', {
          id: id,
        });
        return throwError(err);
      })
    );
  }

  public getMaskLightById(id: number): Observable<MaskLight> {
    return this.httpClient.get<MaskLight>(`${this.maskBaseUrl}/${id}/light`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.get_by_mask_id', {
          id: id,
        });
        return throwError(err);
      })
    );
  }

  public getMaskSummaryById(id: number): Observable<MaskSummary> {
    return this.httpClient.get<MaskSummary>(`${this.maskBaseUrl}/${id}/summary`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.get_by_mask_id', {
          id: id,
        });
        return throwError(err);
      })
    );
  }

  public getPreview(id: number): Observable<SlidePreview[]> {
    return this.httpClient.get<SlidePreview[]>(`${this.maskBaseUrl}/${id}/preview`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.preview', {
          id: id,
        });
        return throwError(err);
      })
    );
  }

  /**
   * Create a mask
   */
  public post(mask: Mask): Observable<Mask> {
    return this.httpClient.post<Mask>(`${this.maskBaseUrl}`, mask).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.post');
        return throwError(err);
      })
    );
  }

  /**
   * Update a mask
   */
  public put(mask: Mask): Observable<Mask> {
    return this.httpClient.put<Mask>(`${this.maskBaseUrl}/${mask.id}`, mask).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.put', { id: mask.id });
        return throwError(err);
      })
    );
  }

  /**
   * Update a specific mask (partially)
   */
  public patch(maskId: number, maskPatch: jsonpatch.Operation[]): Observable<Mask> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json-patch+json; charset=utf-8');

    return this.httpClient.patch<Mask>(`${this.maskBaseUrl}/${maskId}/infos`, maskPatch, { observe: 'body', headers: headers }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.patch', { id: maskId });
        return throwError(err);
      })
    );
  }

  public getAllWithPagination(
    forDrafts: boolean,
    maskScheduleStatus: number,
    start: number,
    count: number,
    onlyMines: boolean,
    selectedAudienceId: number,
    channelId?: number,
    searchString?: string,
    isPublished?: boolean
  ): Observable<MaskTileResponse> {
    let fromString: string = `start=${start}&count=${count}&maskScheduleStatus=${maskScheduleStatus}&onlyMine=${onlyMines}&selectedAudienceId=${selectedAudienceId}&timezone=${this.timezone}`;

    if (isPublished != null) {
      fromString += `&isPublished=${isPublished}`;
    }

    if (searchString != null) {
      fromString = fromString + `&searchString=${encodeURIComponent(searchString)}`;
    }

    if (channelId != null) {
      fromString = fromString + `&channelId=${channelId}`;
    }

    let params: HttpParams = new HttpParams({ fromString });

    return this.httpClient.get<MaskTileResponse>(`${this.maskBaseUrl}/all/page`, { params }).pipe(
      catchError((err) => {
        err.message = forDrafts ? this.translateService.instant('mask.error.get_by_mask_status_draft') : this.translateService.instant('mask.error.get_by_mask_status_all');
        return throwError(err);
      })
    );
  }

  /**
   * Delete a mask
   */
  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.maskBaseUrl}/${id}`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('error.delete');
        return throwError(err);
      })
    );
  }

  public copy(mask: MaskTile): Observable<Mask> {
    return this.httpClient.post<Mask>(`${this.maskBaseUrl}/copy?copyId=${mask.id}`, {}).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.copy', { name: mask.name });
        return throwError(err);
      })
    );
  }

  public getTags(maskId: number): Observable<MediaPlayerTag[]> {
    const url: string = `${this.maskBaseUrl}/${maskId}/tags`;

    return this.httpClient.get<MediaPlayerTag[]>(url).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.get_tags');
        return throwError(err);
      })
    );
  }

  public updateTags(maskId: number, tagIds: number[]): Observable<void> {
    const url: string = `${this.maskBaseUrl}/${maskId}/tags`;

    return this.httpClient.put<void>(url, tagIds).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.error.update_tags');
        return throwError(err);
      })
    );
  }
}
