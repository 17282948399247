import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/setttings/settings.service';
import { SlidePreview } from '../../preview/slidePreview';
import { Slide } from './slide';
import * as jsonpatch from 'fast-json-patch';
import { LoaderService } from 'src/app/general/layout/services/loader.service';

@Injectable({
  providedIn: 'root',
})
export class SlideService {
  private slideBaseUrl: string;

  constructor(private httpClient: HttpClient, private loaderService: LoaderService, private settingsService: SettingsService, private translateService: TranslateService) {
    this.slideBaseUrl = `${this.settingsService.settings.API_URL}/slide`;
  }

  public getPreview(id: number): Observable<SlidePreview> {
    return this.httpClient.get<SlidePreview>(`${this.slideBaseUrl}/${id}/preview`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('slide.error.preview', {
          id: id,
        });
        return throwError(err);
      })
    );
  }

  public save(slide: Slide): Observable<Slide> {
    return slide.id > 0 ? this.put(slide) : this.post(slide);
  }

  public post(slide: Slide): Observable<Slide> {
    return this.httpClient.post<Slide>(`${this.slideBaseUrl}`, slide).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide.error.post');
        return throwError(err);
      })
    );
  }

  public put(slide: Slide): Observable<Slide> {
    return this.httpClient.put<Slide>(`${this.slideBaseUrl}/${slide.id}`, slide).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide.error.put', { id: slide.id });
        return throwError(err);
      })
    );
  }

  public patch(slideId: number, slidePatch: jsonpatch.Operation[]): Observable<Slide> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json-patch+json; charset=utf-8');

    const url: string = `${this.slideBaseUrl}/${slideId}`;
    return this.httpClient.patch<Slide>(url, slidePatch, { observe: 'body', headers: headers }).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide.error.patch', { id: slideId });
        return throwError(err);
      })
    );
  }

  public delete(id: number): Observable<void> {
    const url: string = `${this.slideBaseUrl}/${id}`;
    this.loaderService.addExcludedUrl(url);

    return this.httpClient.delete<void>(url).pipe(
      finalize(() => this.loaderService.removeExcludedUrl(url)),
      catchError((err) => {
        err.message = this.translateService.instant('mask.slide.error.delete');
        return throwError(err);
      })
    );
  }
}
