<div class="row texts" *ngIf="data">
  <div class="header">
    <mat-label>{{ data.title }}</mat-label>
    <button mat-icon-button (click)="addText()" appTooltip [tooltipText]="'mask.configuration.list.add_text' | translate"><mat-icon>add</mat-icon></button>
  </div>
  <div class="no-data" *ngIf="data.texts.length === 0; else textList">{{ 'mask.configuration.list.no_text' | translate }}</div>
  <ng-template #textList>
    <div class="text-list">
      <ul>
        <li *ngFor="let text of data.texts; let index = index; trackBy: trackByIndex">
          <div *ngIf="data.multiline; then textarea; else input"></div>
          <ng-template #input>
            <div class="text-element-container">
              <mat-form-field>
                <mat-label>{{ 'mask.configuration.text_list_input' | translate: { index: index + 1 } }}</mat-label>
                <input
                  matInput
                  [(ngModel)]="data.texts[index]"
                  [required]="data.mandatory"
                  [maxLength]="data.maxLength"
                  (ngModelChange)="onChange()"
                  (focusout)="onFocusOut()"
                  appDynamicDataPicker
                />
                <mat-error class="error-label">{{ getTextError(data.texts[index], data.maxLength) }}</mat-error>
              </mat-form-field>
              <button *ngIf="showDeleteButton | async" mat-icon-button class="text-delete-btn" (click)="deleteText(index)">
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </div>
          </ng-template>
          <ng-template #textarea>
            <div class="text-element-container">
              <mat-form-field>
                <mat-label>{{ 'mask.configuration.text_list_input' | translate: { index: index + 1 } }}</mat-label>
                <textarea
                  matInput
                  mat-autosize
                  cdkTextareaAutosize
                  [(ngModel)]="data.texts[index]"
                  [required]="data.mandatory"
                  [maxLength]="data.maxLength"
                  (ngModelChange)="onChange()"
                  (focusout)="onFocusOut()"
                  appDynamicDataPicker
                ></textarea>
                <mat-error class="error-label">{{ getTextError(data.texts[index], data.maxLength) }}</mat-error>
              </mat-form-field>
              <button *ngIf="showDeleteButton | async" mat-icon-button class="text-delete-btn" (click)="deleteText(index)">
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </div>
          </ng-template>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
