import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { TreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IsbaLocalizationNode } from './isba-localization-node';

export class IsbaLocalizationDatasource implements DataSource<IsbaLocalizationNode> {
  private dataChange: BehaviorSubject<IsbaLocalizationNode[]> = new BehaviorSubject<IsbaLocalizationNode[]>([]);
  private database: IsbaLocalizationNode[];

  get data(): IsbaLocalizationNode[] {
    return this.dataChange.value;
  }

  set data(value: IsbaLocalizationNode[]) {
    // this.treeControl.dataNodes = value;
    this.database = value;

    // retrieve only the first level of the provided data
    this.treeControl.dataNodes = value.map(
      (node: IsbaLocalizationNode) =>
        ({
          level: 0,
          name: node.name,
          path: node.path,
          isbaId: node.isbaId,
          children: node.children ? [] : null,
        } as IsbaLocalizationNode)
    );
    this.dataChange.next(this.treeControl.dataNodes);
  }

  constructor(private treeControl: TreeControl<IsbaLocalizationNode>) {}

  public connect(collectionViewer: CollectionViewer): Observable<IsbaLocalizationNode[] | readonly IsbaLocalizationNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if ((change as SelectionChange<IsbaLocalizationNode>).added || (change as SelectionChange<IsbaLocalizationNode>).removed) {
        this.handleTreeControlChange(change as SelectionChange<IsbaLocalizationNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  public disconnect(collectionViewer: CollectionViewer): void {
    this.dataChange.complete();
  }

  public handleTreeControlChange(change: SelectionChange<IsbaLocalizationNode>): void {
    if (change.added.length > 0) {
      change.added.forEach((added) => {
        // lookup actual node in database to retrieve its children
        var node: IsbaLocalizationNode | null = this.findNode(added.path, this.database);

        if (node == null) return;

        // add the children nodes with a higher level at the right position into the array
        this.data.splice(
          this.data.indexOf(added) + 1,
          0,
          ...node.children!.map(
            (node: IsbaLocalizationNode) =>
              ({
                level: added.level! + 1,
                name: node.name,
                path: node.path,
                isbaId: node.isbaId,
                children: node.children ? [] : null,
              } as IsbaLocalizationNode)
          )
        );
      });
    }

    if (change.removed.length > 0) {
      change.removed.forEach((removed) => {
        var index: number = this.data.indexOf(removed);
        let count: number = 0;

        // count how many items need to be removed (with a level higher than the removed node)
        for (let i: number = index + 1; i < this.data.length && this.data[i].level! > removed.level!; i++, count++) {
          // no op
        }

        // remove nodes
        this.data.splice(index + 1, count);
      });
    }

    this.dataChange.next(this.data);
  }

  /**
   * Recursively finds a node in a tree of nodes
   * @param path technical path of the node
   * @param nodes list of nodes to search
   */
  private findNode(path: string, nodes: IsbaLocalizationNode[]): IsbaLocalizationNode | null {
    for (let i: number = 0; i < nodes.length; i++) {
      if (nodes[i].path === path) return nodes[i];

      if (nodes[i].children) {
        const childNode: IsbaLocalizationNode | null = this.findNode(path, nodes[i].children!);

        if (childNode != null) return childNode;
      }
    }

    return null;
  }
}
