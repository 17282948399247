import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { IsbaLocalization } from 'src/app/core/isba/isba-localization';
import { IsbaService } from 'src/app/core/isba/isba.service';
import { IsbaLocalizationDatasource } from './isba-localization-datasource';
import { IsbaLocalizationNode } from './isba-localization-node';
import { KeyboardKey } from 'src/app/core/enums/keyboard-key.enum';

@Component({
  selector: 'app-isba-picker',
  templateUrl: './isba-localization-picker.component.html',
  styleUrls: ['./isba-localization-picker.component.scss'],
})
export class IsbaLocalizationPickerComponent implements OnInit {
  public isLoaded: boolean = false;

  public treeControl: FlatTreeControl<IsbaLocalizationNode>;
  public dataSource: IsbaLocalizationDatasource;

  public getLevel = (node: IsbaLocalizationNode) => node.level!;
  public isExpandable = (node: IsbaLocalizationNode) => !!node.children;
  public hasChild = (_: number, node: IsbaLocalizationNode) => !!node.children;

  constructor(private dialogRef: MatDialogRef<IsbaLocalizationPickerComponent>, private isbaService: IsbaService) {
    this.treeControl = new FlatTreeControl<IsbaLocalizationNode>(this.getLevel, this.isExpandable);
    this.dataSource = new IsbaLocalizationDatasource(this.treeControl);
  }

  public ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === KeyboardKey.Escape) {
        this.dialogRef.close(false);
      }
    });
    this.isbaService
      .getIsbaLocalizations()
      .pipe(first())
      .subscribe((resources) => {
        this.dataSource.data = this.buildDataSource(resources);
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submitBuilding(node: IsbaLocalizationNode): void {
    this.dialogRef.close(node.isbaId);
  }

  private buildDataSource(localizations: IsbaLocalization[]): IsbaLocalizationNode[] {
    const tree: IsbaLocalizationNode[] = new Array<IsbaLocalizationNode>();

    localizations.forEach((localization) => {
      const node: IsbaLocalizationNode = this.getNode(localization.path.split('/'), localization.displayPath.split('/'), '', tree);

      if (node == null) return;

      // update leaf data
      node.name = localization.label;
      node.isbaId = localization.id;

      this.isLoaded = true;
    });

    return tree;
  }

  private getNode(path: Array<string>, displayPath: Array<string>, parentPath: string, tree: IsbaLocalizationNode[]): IsbaLocalizationNode {
    if (path.length != displayPath.length) {
      console.log('Path length mismatch: ', path, displayPath);
      displayPath = path;
    }

    for (let i: number = 0; i < tree.length; i++) {
      if (tree[i].path == `${parentPath}/${path[0]}`) {
        if (path.length == 1) return tree[i];
        else if (tree[i].children) return this.getNode(path.slice(1), displayPath.slice(1), parentPath, tree[i].children!);
        else return this.buildChildren(path.slice(1), displayPath.slice(1), parentPath, tree[i]);
      }
    }

    // not found in current tree, let's create it
    const newNode: IsbaLocalizationNode = {
      path: `${parentPath}/${path[0]}`,
      name: displayPath[0],
    };

    tree.push(newNode);

    // order alphabetically
    tree = tree.sort((n1, n2) => n1.name.localeCompare(n2.name));

    if (path.length > 1) return this.buildChildren(path.slice(1), displayPath.slice(1), newNode.path, newNode);
    return newNode;
  }

  private buildChildren(path: Array<string>, displayPath: Array<string>, parentPath: string, root: IsbaLocalizationNode): IsbaLocalizationNode {
    if (!root.children) root.children = new Array<IsbaLocalizationNode>();

    const node: IsbaLocalizationNode = {
      path: `${parentPath}/${path[0]}`,
      name: displayPath[0],
    };

    root.children.push(node);

    if (path.length == 1) return node;
    return this.buildChildren(path.slice(1), displayPath.slice(1), node.path, node);
  }
}
