import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-progress',
  templateUrl: './dialog-progress.component.html',
  styleUrls: ['./dialog-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogProgressComponent {
  public dialogTitle: string;
  public dialogMessage: string;

  constructor(
    // tslint:disable-next-line: no-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogProgressComponent>) {
    this.dialogTitle = data.dialogTitle;
    this.dialogMessage = data.dialogMessage;
    this.dialogRef.disableClose = true;
  }

}
