<div class="container">
  <mat-card layout-align="center center" class="panel">
    <mat-card-title>
      {{ 'general.forbidden.title' | translate }}
    </mat-card-title>
    <mat-card-content>
      <p>
        {{ 'general.forbidden.label' | translate }}<br />
        {{ 'general.forbidden.contact' | translate }}
      </p>
      <a href="/" (click)="backToHome()">{{ 'general.back_home' | translate }}</a>
    </mat-card-content>
  </mat-card>
</div>
