import { FieldTemplateConfiguration } from './template-configuration-base';
import { TemplateAssetType } from './template-asset-type.enum';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { SlideAsset } from 'src/app/shared/components/slide-editor/slide/slide-asset';

export class FieldSelect extends FieldTemplateConfiguration {
  options: { key: string; value: string }[];
  selected: string;

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);

    this.options = field.values!;
    this.type = TemplateAssetType.Select;
    this.selected = asset?.selected ?? '';
  }

  override isValid(): boolean {
    return this.selected == '' || this.options.some((v) => v.value === this.selected);
  }

  override isEmpty(): boolean {
    return this.selected == '';
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    asset.selected = this.selected;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    return this.selected ?? '';
  }
}
