import { GuidHelper } from '../guid/guid-helper';
import { TemplateAssetType } from './template-asset-type.enum';
import { FieldTemplateConfiguration } from './template-configuration-base';
import { IFieldTemplateConfigurationModel } from './template-configuration-model';
import { SlideAsset, VideoData } from 'src/app/shared/components/slide-editor/slide/slide-asset';

export class FieldVideoList extends FieldTemplateConfiguration {
  public videos: VideoData[];

  constructor(field: IFieldTemplateConfigurationModel, asset?: SlideAsset) {
    super(field, asset);

    this.type = TemplateAssetType.VideoList;
    this.videos = asset?.videos ?? [];
  }

  override isValid(): boolean {
    return this.isEmpty() || this.videos.every((vid) => GuidHelper.IsValid(vid.blobId));
  }

  override isEmpty(): boolean {
    return this.videos.length == 0;
  }

  override getSlideAsset(slideId: number): SlideAsset {
    const asset: SlideAsset = super.getSlideAsset(slideId);

    asset.videos = this.videos;

    return asset;
  }

  override getPreviewValue(): string | string[] | null {
    return this.videos
      .filter((val) => GuidHelper.IsValid(val.blobId))
      .map((val) => val.videoUrl)
      .join(';');
  }
}
