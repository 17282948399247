import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { Chart, ArcElement, BarController, BarElement, CategoryScale, ChartData, ChartOptions, ChartType, DoughnutController, Legend, LineController, LinearScale, Tooltip, PointElement, LineElement } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() public options: ChartOptions;
  @Input() public data: ChartData;
  @Input() public type: ChartType;
  @ViewChild('canvas') private canvas: ElementRef<HTMLCanvasElement>;

  private chart: Chart;

  constructor(private zone: NgZone) {
    if (!Chart.registry.plugins.get(zoomPlugin.id)) Chart.register(zoomPlugin);
    Chart.register([CategoryScale, LinearScale, LineController, LineElement, BarController, BarElement, DoughnutController, ArcElement, PointElement, Tooltip, Legend]);
  }

  public ngAfterViewInit(): void {
    var ctx: CanvasRenderingContext2D = this.canvas.nativeElement.getContext('2d')!;
    if (this.data) this.chart = new Chart(ctx, { type: this.type, data: this.data, options: this.options });
  }

  public ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (this.chart) {
      for (let propName in changes) {
        let change: SimpleChange = changes[propName];

        switch (propName) {
          case 'data':
            this.chart.data = change.currentValue as ChartData;
            break;
          case 'options':
            this.chart.options = change.currentValue as ChartOptions;
            break;
        }
      }

      this.zone.runOutsideAngular(() => this.chart.update());
    }
  }

  public ngOnDestroy(): void {
    if (this.chart) this.chart.destroy();
  }
}
