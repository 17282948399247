import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Moment } from 'moment';
import { RepetitionEndType, RepetitionType } from '../models/repetition-type';

export function dateConsistencyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDateValue: Moment = control.get('startDate')!.value;

    if (startDateValue == undefined) return null;

    const now: Date = new Date();

    const startTimeValue: string = control.get('startTime')!.value;
    const endTimeValue: string = control.get('endTime')!.value;

    const startDate: Date = new Date(startDateValue.year(), startDateValue.month(), startDateValue.date(), +startTimeValue.split(':')[0], +startTimeValue.split(':')[1], 0);
    const endDate: Date = new Date(startDateValue.year(), startDateValue.month(), startDateValue.date(), +endTimeValue.split(':')[0], +endTimeValue.split(':')[1], 0);

    if (startDate.getTime() > endDate.getTime()) {
      return { dateConsistency: true };
    }

    const repetitionTypeValue: RepetitionType = control.get('repetition')!.value;

    if (repetitionTypeValue === RepetitionType.Once && endDate.getTime() < now.getTime()) {
      return { dateConsistency: true };
    }

    const repetitionEndDateValue: Moment = control.get('repetitionEndDate')!.value;
    const repetitionEndTypeValue: RepetitionEndType = control.get('repetitionEndType')!.value;
    const repetitionEndDate: Date = getRepetitionEndDate(repetitionEndDateValue, endTimeValue);

    if (startDate > repetitionEndDate && repetitionTypeValue !== RepetitionType.Once && repetitionEndTypeValue !== RepetitionEndType.Never) {
      return { dateConsistency: true };
    }

    return null;
  };
}

const getRepetitionEndDate = (repetitionEndDateValue: Moment, endTimeValue: string): Date => {
  return new Date(repetitionEndDateValue.year(), repetitionEndDateValue.month(), repetitionEndDateValue.date(), +endTimeValue.split(':')[0], +endTimeValue.split(':')[1], 0);
};
