import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG, Settings } from './app/core/setttings/settings';
import { environment } from './environments/environment';

/**
 * Load initial settings (like api base url, ...)
 * Source : https://offering.solutions/blog/articles/2021/11/11/loading-configuration-before-your-angular-app-starts/
 */
fetch('/settings/settings.json')
  .then((response: Response) => response.json())
  .then((config: Settings) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch((error: Error) => {
    console.error(error);
  });
