import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { FieldImageList } from 'src/app/core/template-configuration/field-image-list';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';
import { ListAssetsService } from '../list-assets/list-assets.service';
import { AssetRequest } from '../list-assets/asset-request';
import { AssetType } from '../list-assets/assetType';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmResult } from 'src/app/core/confirmResult';
import { DialogResult } from 'src/app/core/dialogResult';
import { CropperData } from '../configuration-mask-picture/dialog-configuration-mask-picture/cropper-data';
import { DialogConfigurationMaskPictureComponent } from '../configuration-mask-picture/dialog-configuration-mask-picture/dialog-configuration-mask-picture.component';
import { CroppedImageData } from '../configuration-mask-picture/image-cropper/imageDataEvent';
import { CropperConfiguration } from '../configuration-mask-picture/image-cropper/imageCropperConfiguration';
import { ImageData } from '../slide/slide-asset';
import { FileHelper } from 'src/app/core/fileHelper';
import { MediaDropData } from '../list-assets/mediaDropData';
import { MediaLibraryService } from 'src/app/core/media-library/media-library.service';
import { NotificationService } from '../../notification/notification.service';
import { deepCopy } from 'src/app/core/helpers/object-helper';

@Component({
  selector: 'app-configuration-mask-image-list',
  templateUrl: './configuration-mask-image-list.component.html',
  styleUrls: ['./configuration-mask-image-list.component.scss'],
})
export class ConfigurationMaskImageListComponent implements ConfigurationMaskItemComponent, OnInit {
  @Input() public set data(value: FieldImageList) {
    this._data = value;
  }

  @ViewChild('input') public fileInput: ElementRef<HTMLInputElement>;

  private _data: FieldImageList;
  private _cropperConfig: CropperConfiguration;

  public get data(): FieldImageList {
    return this._data;
  }

  public showDeleteButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  constructor(
    private listAssetsService: ListAssetsService,
    private pictureLibraryService: MediaLibraryService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this._cropperConfig = new CropperConfiguration(this.data.minWidth, this.data.minHeight);
    this._cropperConfig.autoCrop = false;
    this._cropperConfig.showControl = true;

    this.addMandatoryField();
  }

  private addMandatoryField(): void {
    if (this._data.mandatory && this._data.images.length === 0) {
      this.addImage();
    } else {
      this.updateDeleteButtonVisibility();
    }
  }

  public get isValid(): boolean {
    return this.data.isValid();
  }

  public addImage(): void {
    this.data.images.push(new ImageData(crypto.randomUUID()));
    this.updateDeleteButtonVisibility();
  }

  public removeImage(index: number): void {
    const imageToRemove: ImageData = this.data.images[index];

    this._data.images.splice(index, 1);

    const data: FieldImageList = this.buildCleandData();

    if (data.isValid() && imageToRemove.imageUrl) {
      this.outputValue.next(data);
      this.outputFocus.next(data);
    }

    this.addMandatoryField();
  }

  private buildCleandData(): FieldImageList {
    const data = deepCopy(this._data);
    data.images = data.images.filter((i) => i.imageUrl != null);

    return data;
  }

  public openAssets(index: number): void {
    this.listAssetsService.listAssets(new AssetRequest(AssetType.Picture, this.data.id, index));
  }

  public fileChangeEvent(event: Event, image: ImageData): void {
    const files: FileList | null = (<HTMLInputElement>event.target).files;
    if (!files || files.length == 0 || (files[0].type != 'image/jpeg' && files[0].type != 'image/png')) return;

    this.openCropper(files[0], image);
  }

  public openCropper(file: File, imageData: ImageData): void {
    const dialogConf: MatDialogConfig = new MatDialogConfig();

    dialogConf.data = new CropperData(file, this._cropperConfig);
    dialogConf.height = '90vh';
    dialogConf.width = '70vw';

    const dialogRef: MatDialogRef<DialogConfigurationMaskPictureComponent, DialogResult<CroppedImageData>> = this.dialog.open(DialogConfigurationMaskPictureComponent, dialogConf);

    dialogRef.afterClosed().subscribe((res: DialogResult<CroppedImageData> | undefined) => {
      if (res && res.entity && res.confirm === ConfirmResult.Yes) {
        // conversion en base 64 utilisée pour transmettre l'image à l'aperçu de la slide (slide.component.ts)
        FileHelper.fileToBase64(res.entity.file).subscribe((dataUrl) => {
          imageData.blobDataUrl = dataUrl;
          imageData.imageUrl = res.entity!.croppedImage;
          imageData.blobFile = res.entity!.file;

          const data: FieldImageList = this.buildCleandData();
          const index = data.images.findIndex((x) => x.blobId === imageData.blobId);
          data.images[index].blobFile = res.entity!.file;

          if (data.isValid()) {
            this.outputValue.next(data);

            // fake 'blur' event
            this.outputFocus.next(data);
          }

          this.updateDeleteButtonVisibility();
        });
      }
    });
  }

  public onAssetSelected(mediaImageDropData: MediaDropData): void {
    if (mediaImageDropData.mediaType !== AssetType.Picture) return;

    this.pictureLibraryService.getMediaAsset(mediaImageDropData.blobUrl).subscribe(
      (asset: Blob) => {
        const image: ImageData = this.data.images[mediaImageDropData.additionalData as number];
        this.openCropper(asset as File, image);
      },
      (err: Error) => this.notificationService.displayError(err.message)
    );
  }

  public updateDeleteButtonVisibility(): void {
    const show: boolean = this._data.mandatory === false || this._data.images.length > 1 || this._data.images.some((i) => i.blobDataUrl || i.imageUrl);

    this.showDeleteButton.next(show);
  }

  /**
   * Forces Angular to track the ngFor generated inputs by their index
   * @param index item index
   * @returns
   */
  public trackByIndex(index: number) {
    return index;
  }
}
