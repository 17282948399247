import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from 'src/app/general/layout/services/loader.service';
import { SettingsService } from '../setttings/settings.service';
import { NotificationLight } from './notification';

@Injectable({
  providedIn: 'root',
})
export class EncodingNotificationService {
  private baseUrl: string = `${this.settingsService.settings.API_URL}/notification`;

  public readonly resetAsked: Subject<void> = new Subject();

  constructor(private loaderService: LoaderService, private httpClient: HttpClient, private settingsService: SettingsService, private translateService: TranslateService) {}

  public resetNotificationCounter(): void {
    this.resetAsked.next();
  }

  /**
   * Get last user's notifications
   */
  public getLast(): Observable<NotificationLight[]> {
    return this.httpClient.get<NotificationLight[]>(`${this.baseUrl}/last`).pipe(
      catchError((err) => {
        err.message = this.translateService.instant('notifications.error.get_notifications');
        return throwError(err);
      })
    );
  }

  /**
   * Get user's alert counter
   */
  public getAlertCounter(silent: boolean = true): Observable<number> {
    const url: string = `${this.baseUrl}/alert/count`;
    if (silent) this.loaderService.addExcludedUrl(url);

    return this.httpClient.get<number>(url).pipe(
      finalize(() => this.loaderService.removeExcludedUrl(url)),
      catchError((err) => {
        err.message = this.translateService.instant('notifications.error.get_alert_counter');
        return throwError(err);
      })
    );
  }
}
