export class EnumTranslate {
  public index: number;
  public name: string;
  public translate: string;

  constructor(index: number, name: string, translate: string) {
    this.index = index;
    this.name = name;
    this.translate = translate;
  }
}
