import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/core/guards/role';
import { ScopePolicies } from 'src/app/core/guards/scope-policies';
import { UserData } from 'src/app/core/userData';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  @Input() public isVisible: boolean = false;
  @Input() public url: string;
  @Input() public userData: UserData;

  @Output() public execAddMask: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() public navigating: EventEmitter<void> = new EventEmitter<void>();

  public expandedRoute: string = '';
  public isHandsetPortrait: boolean = false;
  public isHandsetLandscape: boolean = false;

  constructor(private responsive: BreakpointObserver, private router: Router) {}

  public ngOnInit(): void {
    this.expandedRoute = this.url.substring(0, SidebarComponent.GetPosition(this.url, '/', 3));

    this.responsive.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
      const breakpoints = result.breakpoints;

      this.isHandsetPortrait = breakpoints[Breakpoints.HandsetPortrait];
      this.isHandsetLandscape = breakpoints[Breakpoints.HandsetLandscape];
    });
  }

  private static GetPosition(str: string, delimiter: string, index: number): number {
    return str.split(delimiter, index).join(delimiter).length;
  }

  public addMask(event: Event): void {
    this.execAddMask.emit(event);
  }

  public isActive(route: string): string {
    return this.url === route ? 'active-link' : '';
  }

  public isNavItemVisible(route: string): boolean {
    return ScopePolicies.IsRouteAccessible(
      route,
      this.userData.roles
    );
  }

  public isExpanded(route: string): boolean {
    return this.expandedRoute.length > 0 && this.expandedRoute !== '/gca' && route.startsWith(this.expandedRoute);
  }

  public expandMenu(route: string): void {
    if (this.expandedRoute === route) this.expandedRoute = '';
    else this.expandedRoute = route;
  }

  public navigate(route: string[]): void {
    this.navigating.emit();
    this.router.navigate(route);
  }
}
