import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ScreenFormat } from 'src/app/core/enums/screen-format.enum';
import { FleetTheme } from 'src/app/core/template/fleetTheme';
import { Template } from 'src/app/core/template/template';
import { Slide } from './slide';

/**
 * Ce composant est utilisé pour la prévisualisation du contenu d'une slide, dans l'étape d'édition d'un masque
 */
@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
})
export class SlideComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() public theme: FleetTheme;
  @Input() public slide?: Slide;
  @Input() public screenFormat: ScreenFormat;
  @Output() public initConfiguration: EventEmitter<Slide> = new EventEmitter<Slide>();
  @Output() public durationChanged: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('previewiframe') public iframe: ElementRef<HTMLIFrameElement>;

  private templateInitialized: boolean = false;
  private messageQueue: Array<Object> = new Array<Object>();

  public template: Template | null;
  public templateUrl: SafeUrl;

  constructor(private host: ElementRef, private sanitizer: DomSanitizer) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    // let the browser enough time to render the page with correct dimensions
    setTimeout(() => this.initIframeRatio(), 50);
  }

  public ngOnInit(): void {
    this.templateUrl = this.sanitizer.bypassSecurityTrustResourceUrl('about: blank');
  }

  public ngOnDestroy(): void {
    window.onmessage = null;
  }

  public ngAfterViewInit(): void {
    this.initIframeRatio();

    // listen to incoming messages for dynamic updates and animation control
    window.onmessage = (e) => {
      switch (e.data.method) {
        case 'Initialized':
        case 'templateInitialized':
          this.templateInitialized = true;
          this.processQueuedMessages();
          break;
        case 'Duration':
          const milliseconds: number = e.data.param.data as number;

          if (milliseconds > 0 && this.template!.automaticDuration) this.durationChanged.emit(milliseconds);
          break;
      }
    };
  }

  private initIframeRatio(): void {
    switch (this.screenFormat) {
      case ScreenFormat.Landscape_16_9:
        this.setIframeRatio(0.5625);
        break;
      case ScreenFormat.Portrait_9_16:
        this.setIframeRatio(1.778);
        break;
      case ScreenFormat.Stretch_Vertical:
        this.setIframeRatio(3.2);
        break;
    }
  }

  /**
   * Posts data to the iframe containing the template
   * @param data data message to send to the iframe containing the template (mask content)
   * @returns nothing
   */
  public postData(data: object): void {
    if (this.templateInitialized) {
      this.iframe.nativeElement.contentWindow?.postMessage(data, '*');
      return;
    }

    this.messageQueue.push(data);
  }

  private processQueuedMessages(): void {
    this.messageQueue.forEach((data) => {
      this.iframe.nativeElement.contentWindow?.postMessage(data, '*');
    });
  }

  /** Sets the iframe dimensions with the provided ratio
   * @param ratio used to compute the height according the the element's actual width
   */
  private setIframeRatio(ratio: number): void {
    const refHeight: number = this.host.nativeElement.parentElement!.clientHeight - 34; // 34px is the height of the control buttons
    const refWidth: number = this.host.nativeElement.parentElement!.clientWidth;
    let width: number = 0;
    let height: number = 0;

    width = refWidth;
    height = Math.round(width * ratio);

    // 130px is the height of the two toolbars + the control buttons
    if (height > window.innerHeight - 130) {
      height = refHeight;
      width = Math.round(height / ratio);
    }

    this.iframe.nativeElement.style.width = width + 'px';
    this.iframe.nativeElement.style.height = height + 'px';
  }

  private generateIframeUrl(): void {
    let path: string = `${this.template!.templateBlob.templateHtmlUrl}?webpreview=true&autorestart=true`;

    if (this.theme) {
      path += `&bgcolor=${encodeURIComponent(this.theme.backgroundColor)}&ttcolor=${encodeURIComponent(this.theme.titleColor)}&sttcolor=${encodeURIComponent(
        this.theme.subtitleColor
      )}`;
      path += `&txtcolor=${encodeURIComponent(this.theme.textColor)}&animcolor=${encodeURIComponent(this.theme.animationColor)}`;
      path += `&font=${encodeURIComponent(this.theme.globalFont)}&ttfont=${encodeURIComponent(this.theme.titleFont)}`;
    }

    path += `&cfg=${encodeURIComponent(this.template!.templateBlob.templateConfigUrl)}`;

    this.templateInitialized = false;
    this.messageQueue = new Array<object>();

    this.template!.templateAdditionalAttributes.forEach((addAttr) => (path += `&${addAttr.name}=${addAttr.default}`));

    this.templateUrl = this.sanitizer.bypassSecurityTrustResourceUrl(path);

    this.postData({ method: 'updateDuration', param: this.slide!.duration });
    this.initConfiguration.emit();
  }

  public changeDuration(duration: number): void {
    this.postData({ method: 'updateDuration', param: duration });
    this.replayTemplate();
  }

  public replayTemplate(): void {
    this.postData({ method: 'restartAnimation' });
  }

  public setTemplate(template: Template | null): void {
    this.template = template;

    if (this.template && this.slide) this.generateIframeUrl();
  }

  public pauseTemplate(): void {
    this.postData({ method: 'pauseAnimation' });
  }

  public playTemplate(): void {
    this.postData({ method: 'playAnimation' });
  }
}
