import { Pipe, PipeTransform } from '@angular/core';

/**
 * Usage :
 * <mat-select formControlName="httpVerb">
 *     <mat-option *ngFor="let verb of httpVerbs | enumToKeyValue : 'http_verb'" [value]="verb.value">
 *         {{ verb.translation | translate }}
 *     </mat-option>
 * </mat-select>
 */
@Pipe({
  name: 'enumToKeyValue',
})
export class EnumToKeyValuePipe implements PipeTransform {
  transform(value: Object, enumName: string): EnumKeyValue[] {
    const result: EnumKeyValue[] = [];
    const keys = Object.keys(value);
    const values = Object.values(value);

    // permet de remplacer les lettres majuscules non suivies d'une autre lettre majuscule
    const upperCaseReplace: RegExp = /([A-Z])([a-z0-9_]|[A-Z]*|$)/g;
    const trimUnderscore: RegExp = /^_+/;

    for (var i = 0; i < keys.length; i++) {
      const key: string = keys[i];

      // filter out string keys
      if (isNaN(+key)) continue;

      const name: string = values[i].replace(upperCaseReplace, '_$1$2').toLowerCase();

      if (name.startsWith('_'))
        result.push({
          value: parseInt(key),
          key: key,
          translation: `enums.${enumName.toLowerCase()}.${name.replace(trimUnderscore, '')}`,
        });
    }

    return result;
  }
}

export interface EnumKeyValue {
  key: string;
  value: number;
  translation: string;
}
