import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ChannelTile } from 'src/app/channels/channel-tile';
import { ChannelsService } from 'src/app/channels/channels.service';
import { FieldChannel } from 'src/app/core/template-configuration/field-channel';
import { Mask } from 'src/app/mask-edition/models/mask';
import { ConfigurationMaskItemComponent } from '../configuration-mask/configuration-mask-item.component';
import { IFieldTemplateConfiguration } from 'src/app/core/template-configuration/template-configuration-base';

@Component({
  selector: 'app-configuration-mask-channel',
  templateUrl: './configuration-mask-channel.html',
  styleUrls: ['./configuration-mask-channel.scss'],
})
export class ConfigurationMaskChannelComponent implements ConfigurationMaskItemComponent, OnInit {
  @Input() public data: FieldChannel;
  public mask: Mask;
  public channels: Array<ChannelTile>;
  public selectedChannel: ChannelTile;

  outputValue: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public valueOnChange: Observable<IFieldTemplateConfiguration> = this.outputValue.asObservable();

  outputFocus: Subject<IFieldTemplateConfiguration> = new Subject<IFieldTemplateConfiguration>();
  public saveOnChange: Observable<IFieldTemplateConfiguration> = this.outputFocus.asObservable();

  constructor(private channelService: ChannelsService, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.mask = this.route.parent!.snapshot.data['mask'];
    this.getContent();
  }

  public getContent(): void {
    this.channelService.getChannels().subscribe((chans) => {
      this.channels = chans;

      if (this.data.channelId > 0) {
        this.selectedChannel = chans.find((c) => c.id == +this.data.channelId)!;
        this.onChange(this.selectedChannel);
      }
    });
  }

  public onChange(channel: ChannelTile): void {
    // Create 2 tokens: permanent for final value, temporary for current display
    forkJoin({
      tempToken: this.channelService.createTemporaryChannelToken(channel.id),
      permanentToken: this.channelService.createPermanentChannelToken(channel.id),
    }).subscribe((result) => {
      this.data.channelId = channel.id;
      this.data.playerToken = result.permanentToken.token;

      if (this.data.isValid()) {
        this.outputValue.next(this.data);
        this.outputFocus.next(this.data);
      }
    });
  }
}
