<div class="tile-container">
  <div class="card" [ngClass]="deletingMask ? 'card deleting-mask' : 'card'">
    <div class="card-title">
      <span class="span-title" appTooltip [tooltipText]="authorIsHidden ? ('mask_list.tile.hidden_author' | translate) : mask.authorName + ' - ' + mask.authorEmail">{{
        mask.name
      }}</span>
      <div class="card-title-menu">
        <div *ngIf="!deletingMask; else deleting">
          <app-contextual-menu
            menuType="mask"
            [hidePreview]="mask.maskScheduleStatus === maskScheduleStatusEnums.NotPublished"
            [hideEdit]="mask.maskScheduleStatus === maskScheduleStatusEnums.BroadcastEnded"
            (outputEdit)="editMask()"
            (outputPreview)="previewMask()"
            (outputDuplicate)="duplicateMask()"
            (outputDelete)="requestMaskDeletion()"
            [canDelete]="canDelete"
          ></app-contextual-menu>
        </div>
        <ng-template #deleting>
          <button class="deletion-spinner-btn" mat-icon-button disabled>
            <mat-icon>
              <mat-spinner diameter="22"></mat-spinner>
            </mat-icon>
          </button>
        </ng-template>
      </div>
    </div>
    <div [class]="'card-divider-' + mask.maskScheduleStatus"></div>
    <div class="card-body">
      <div class="card-body-schedule" appTooltip [tooltipText]="detailedSchedule">
        <mat-icon>event</mat-icon>
        <span>{{ schedule }}</span>
      </div>
      <div class="card-footer">
        <div class="card-footer-duration">
          <mat-icon>schedule</mat-icon>
          <span>{{ mask.totalDuration }} s</span>
        </div>
        <div
          *ngIf="!mask.isChannelMask"
          class="card-footer-audience"
          appTooltip
          [tooltipItems]="audiences"
          [tooltipUseBulletPoints]="true"
          [tooltipTitle]="'mask_list.tile.audience_title' | translate"
        >
          <span>{{ audiences.length }}</span>
          <mat-icon>tv</mat-icon>
        </div>
        <div class="card-footer-slide">
          <span>{{ mask.slidesCount }}</span>
          <mat-icon>collections</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
