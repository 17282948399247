import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult } from 'src/app/core/dialogResult';
import { MaskTile } from 'src/app/shared/components/mask-tile/mask-tile';
import { PlanAudiencesEvent } from '../../plan-audiences-form/plan-audiences-event';
import { MaskAudience } from 'src/app/mask-edition/models/mask-audience';
import { AudienceMaskService } from 'src/app/mask-edition/step3-audience-mask/audience-mask.service';

@Component({
  templateUrl: './mask-audiences-dialog.component.html',
  styleUrls: ['./mask-audiences-dialog.component.scss'],
})
export class MaskAudiencesDialogComponent implements OnInit {
  private mask: MaskTile;
  public maskAudiences: MaskAudience[] = [];
  public rowCount: number = 8;
  public columns: number;
  public isBusy: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) mask: MaskTile, private dialogRef: MatDialogRef<MaskAudiencesDialogComponent>, private audienceMaskService: AudienceMaskService) {
    this.mask = mask;
  }

  public ngOnInit(): void {
    this.audienceMaskService.getByMaskId(this.mask.id).subscribe((audiences) => {
      this.maskAudiences = audiences;
      this.columns = Math.round(audiences.length / this.rowCount) + 1;
    });
  }

  public updateMaskAudiencesValues(event: PlanAudiencesEvent): void {
    this.maskAudiences.forEach((maud) => {
      if (maud.readOnly) return;
      maud.selected = event.selectedAudienceIds.indexOf(maud.audienceId) >= 0;
    });
  }

  public validate(): void {
    this.isBusy = true;

    this.audienceMaskService
      .send(
        true,
        this.mask.id,
        this.maskAudiences.filter((aud) => aud.selected)
      )
      .subscribe(() => {
        this.isBusy = false;
        this.close();
      });
  }

  public close(): void {
    const dialogResult: DialogResult<MaskAudience[]> = new DialogResult<MaskAudience[]>();
    dialogResult.entity = this.maskAudiences.filter((aud) => aud.selected);
    this.dialogRef.close(dialogResult);
  }
}
