import { TemplateAdditionalAttribute } from './templateAdditionalAttribute';
import { TemplateBlob } from './templateBlob';

export class Template {
  public id: number;
  public name: string;
  public screenFormatId: number;
  public blobId: string;
  public templateBlob: TemplateBlob;
  public templateClientId: string;
  public version: string;
  public tags: string;
  public rendererId: Renderer;
  public minDuration?: number;
  public automaticDuration: boolean;
  public multiSlide: boolean;
  public templateAdditionalAttributes: TemplateAdditionalAttribute[];
}

export enum Renderer {
  Video = 1,
  Template = 2,
  Live = 3,
  URL = 4,
  Channel = 5,
}
